import Marquee from "Components/Marquee";
import styled from "styled-components";
import WatchListBlock from "./WatchListBlock";
import AddWatchList from "./AddWatchListModal";
import { useEffect, useState } from "react";
import { PlusCircleFilled, PlusCircleOutlined } from "@ant-design/icons";
import { Typography } from "antd";
import moment from "moment";
import { getTickerDetailList, getUserWatchList } from "Api/WatchlistApi";
import { TickerDetail } from "Interfaces/watchList";
import { useSelector } from "react-redux";
import { RootState } from "Redux/RootReducer";

const FooterContainer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  background: rgba(255, 255, 255, 0.25);
  backdrop-filter: blur(10px);
  padding: 10px;
  box-shadow: 0 8px 32px rgba(31, 38, 135, 0.37);

  overflow: hidden;
  display: flex;

  align-items: center;
  justify-content: center;
  gap: 10px;
`;

const FooterWatchList = () => {
  const {
    kycDetails: { id: currentUserId },
  } = useSelector((state: RootState) => state?.protectedReducer);

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [userWatchListSlugList, setUserWatchListSlugList] = useState<string[]>([
    // "ADBL",
    // "API",
    // "AKPL",
    // "AHPC",
    // "ALICL",
    // "BARUN",
    // "BNT",
  ]);

  const [userWatchListDetailList, setUserWatchListDetailList] = useState<
    TickerDetail[]
  >([]);

  useEffect(() => {
    getTickerDetailList(userWatchListSlugList).then((list) => {
      setUserWatchListDetailList(list);
    });
  }, [userWatchListSlugList]);

  useEffect(() => {
    getUserWatchList(currentUserId).then((list) => {
      setUserWatchListSlugList(list);
    });
  }, [currentUserId]);

  return (
    <>
      <FooterContainer>
        <div
          style={{
            display: "flex",
            // alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            minWidth: "120px",
          }}
        >
          <Typography.Text style={{ fontWeight: "bold" }}>
            My Watch List
          </Typography.Text>
          <div>
            <Typography.Text
              style={{ fontWeight: "lighter", fontSize: "11px" }}
            >
              {userWatchListDetailList?.[0]?.calculated_on && (
                <>
                  Updated{" "}
                  {moment(
                    userWatchListDetailList?.[0]?.calculated_on
                  ).fromNow()}{" "}
                </>
              )}
            </Typography.Text>
          </div>
        </div>

        <Marquee itemCount={userWatchListDetailList.length}>
          {userWatchListDetailList.map((item, index) => (
            <WatchListBlock key={item?.ticker} {...item} />
          ))}
        </Marquee>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          onClick={() => setIsModalOpen(true)}
        >
          <PlusCircleOutlined style={{ fontSize: "25px", cursor: "pointer" }} />
        </div>
      </FooterContainer>
      <AddWatchList
        userWatchListSlugList={userWatchListSlugList}
        setUserWatchListSlugList={setUserWatchListSlugList}
        visible={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
      />
    </>
  );
};

export default FooterWatchList;
