import { combineReducers } from "redux";
import authReducer from "./Auth/Reducer";
import protectedReducer from "./Protected/Reducer";
import staticReducer from "./Static/Reducer";
import { connectRouter, RouterState } from "connected-react-router";

const reducers = (history: any) =>
  combineReducers({
    authReducer,
    protectedReducer,
    staticReducer,
    router: connectRouter(history),
  });

export default reducers;

export type RootState = {
  authReducer: ReturnType<typeof authReducer>;
  protectedReducer: ReturnType<typeof protectedReducer>;
  staticReducer: ReturnType<typeof staticReducer>;
  router: RouterState;
};
