import React, {
  useEffect,
  useState,
} from 'react';

import { useSelector } from 'react-redux';
import {
  Link,
  useHistory,
  useParams,
} from 'react-router-dom';
import { RootState } from 'Redux/RootReducer';

const navList = {
  "dematt": [
    {
      title: "Initial",
      path: ""
    },
    {
      title: "Your Details",
      path: "your-details"
    },
    {
      title: "Family",
      path: "family-details"
    },
    {
      title: 'Occupations',
      path: 'occupations'
    },
    {
      title: "Financial Details",
      path: "financial"
    },
    {
      title: "Nominee",
      path: "nominee"
    }
  ],
  "trading": [
    {
      title: "BOID",
      path: "boid"
    },
    {
      title: "Initial",
      path: ""
    },
    {
      title: "Your Details",
      path: "your-details"
    },
    {
      title: "Family",
      path: "family-details"
    },
    {
      title: 'Occupations',
      path: 'occupations'
    },
    {
      title: "Financial Details",
      path: "financial"
    },
    {
      title: "Additional Info",
      path: "additional-info"
    }
  ]
} as any


interface FormStepsProps {
  path?: string
}

export const FormSteps = ({ path }: FormStepsProps) => {
  const history = useHistory()
  const { oid } = useParams<any>()
  const { kycFormValues } =
    useSelector((state: RootState) => state?.protectedReducer)


  const [formType, setFormType] = useState("dematt")

  useEffect(() => {
    if(formType === "dematt") {
      if (kycFormValues?.minor && formType) {
        navList[formType][1].title = "Guardian Details"
  
      }else {
        navList[formType][1].title = "Your Details"
      }
    }else {
      if (kycFormValues?.minor && formType) {
        navList[formType][2].title = "Guardian Details"
  
      }else {
        navList[formType][2].title = "Your Details"
      }
    }
  
  }, [kycFormValues, formType, ])


  useEffect(() => {
    if (history.location.pathname.includes("dematt")) {
      setFormType("dematt")
    }
    if (history.location.pathname.includes("trading")) {
      setFormType("trading")
    }


    let path = history.location.pathname.split("/")

    let newPath = path[path.length - 1]

    const ele = document.getElementById(newPath)

    ele?.scrollIntoView({ behavior: "auto", inline: "nearest" })


  }, [history.location.pathname])



  return (
    <div className='d-flex ' style={{
      borderRadius: 20,
      backgroundColor: "#ffff",
      overflowY: 'auto'

    }}>
      {
        navList[formType].map((list: any, index:number) => {
          return <Link id={list.path} key={index} to={`/app/${oid}/${formType}/${list.path}`} className='b-0' style={{
            backgroundColor: (path === "initial" && list.path === "") || path === list.path ? "#1db954" : "transparent",
            color: (path === "initial" && list.path === "") || path === list.path ? "#ffff" : "#9eadad",
            padding: "10px 20px",
            // cursor: "pointer",
            height: 44,
            borderRadius: (path === "initial" && list.path === "") || path === list.path ? 20 : 0,
            fontWeight: 400,
            whiteSpace: "nowrap"

          }}

          > {list.title} </Link>
        })
      }
    </div>
  )
}
