import {
  useCallback,
  useEffect,
  useState,
} from 'react';

import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  Modal,
  Row,
  Spin,
} from 'antd';
import { signOutApi } from 'Api/Auth';
import { ReactComponent as MailIcon } from 'Assets/Icons/mail.svg';
import { ReactComponent as PhoneIcon } from 'Assets/Icons/phone.svg';
import { ReactComponent as UserIcon } from 'Assets/Icons/user.svg';
import { Storage } from 'aws-amplify';
import {
  OPACITY_0,
  OPACITY_1,
} from 'Components/AnimationHelpers';
import dayjs from 'dayjs';
import { motion } from 'framer-motion';
import { IPageProps } from 'Interfaces/Page';
import { createUser } from 'Interfaces/profile';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import { bindActionCreators } from 'redux';
import { ProtectedActionCreators } from 'Redux/Protected';
import {
  resetPaymentDetails,
  resetPaymentYears,
} from 'Redux/Protected/Action';
import { RootState } from 'Redux/RootReducer';
import { capitalizeFirstLetter } from 'Utils/capitalizeFirstLetter';
import checkFormStatus, { formInterface } from 'Utils/checkFormStatus';
import { downloadBlob } from 'Utils/DownloadFunction';
import { VALIDATIONS } from 'Utils/formValidationRules';

import { DownloadOutlined } from '@ant-design/icons';

import StatsSection from './Stats/StatsSection';
import TopSection from './TopSection/TopSection';
import FooterWatchList from './WatchList/FooterWatchList';

// import { setNewKycFormValues } from 'Redux/Protected/Action';

const Profile = ({ pageNumber, history }: IPageProps) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [formTypeIndex, setFormTypeIndex] = useState<0 | 1 | 2 | 3>(0);
  const {
    resetMainSignature,
    clearContinueFormId,
    completedUserPdfRequest,
    createNewUserRequest,
    forceReloadRequest,
    resetCompletedUserPdfLink,
    resetCreateUserSuccess,
    resetPdfLink,
    setCurrentUser,
    updateKycDetailRequest2,
    setNewKycFormValues,
  } = bindActionCreators(ProtectedActionCreators, dispatch);
  useEffect(() => {
    resetMainSignature();
  }, []);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isPdfModalVisible, setIsPdfModalVisible] = useState(false);
  const [isLogoutModalVisible, setIsLogoutModalVisible] = useState(false);
  const [isCreateNewUserModalVisible, setIsCreateNewUserModalVisible] =
    useState(false);
  const [pdfUserId, setPdfUserId] = useState("");
  const [downloadLoading, setDownloadLoading] = useState(false);

  const {
    kycDetails,
    actionLoading,
    initLoading,
    pdfLoading,
    createUserSuccess,
    completedUserPdfName,
    completedUserPdfLink,
    continueFormId,
  } = useSelector((state: RootState) => state.protectedReducer);

  const allForms = kycDetails?.others;
  const dematForms = kycDetails?.others?.filter((i: any) => i?.form?.demat);
  const meroshareForms = kycDetails?.others?.filter(
    (i: any) => i?.form?.meroshare
  );
  const tradingForms = kycDetails?.others?.filter((i: any) => i?.form?.trading);

  const selectedFormGroup = () => {
    switch (formTypeIndex) {
      case 0:
        return allForms;
      case 1:
        return dematForms;
      case 2:
        return meroshareForms;
      case 3:
        return tradingForms;
      default:
        return allForms;
    }
  };

  useEffect(() => {
    if (createUserSuccess) {
      const newKycDetails = kycDetails?.others[kycDetails.others.length - 1];
      setCurrentUser(newKycDetails.id);
      dispatch(resetPaymentYears())
      dispatch(resetPaymentDetails())
      forceReloadRequest();

      setNewKycFormValues(newKycDetails)
      history.push(`/app/${newKycDetails.id}/form-selection`)


      // forceReloadRequest();
    }
    return () => {
      resetCreateUserSuccess();
    };
  }, [createUserSuccess]);

  useEffect(() => {
    if (continueFormId !== "") {
      setCurrentUser(continueFormId);
    }
    return () => {
      clearContinueFormId();
    };
  }, [continueFormId]);

  const logoutHandler = () => {
    signOutApi();
    localStorage.removeItem("userId");
    localStorage.removeItem("user-logged-in");
    localStorage.clear();
    window.location.replace("/");
  };

  const submitModalHandler = ({ username, email, phone }: createUser) => {
    const finalPhoneNumber = `+977${phone!
      .replace("+977", "")
      .replace("-", "")}`;

    createNewUserRequest(kycDetails?.id, {
      username: username.trimEnd(),
      phone: finalPhoneNumber,
      email,
    })
  };

  const submitHandler = () => {
    form
      .validateFields()
      .then((values) => {
        submitModalHandler(values);
      })
      .catch((errorInfo) => { });
  };

  const renderLogoutModal = () => {
    return (
      <div className="d-flex flex-center flex-column w-100p h-100p align-items-center">
        <div className="fw-600 text-light-black mb-2">
          Are you sure you want to logout?
        </div>
        <Button
          // loading={loading}
          htmlType="submit"
          className="submit-btn mb-1"
          block
          shape="round"
          onClick={logoutHandler}
        >
          Yes, log me out
        </Button>
        <Button
          // loading={loading}
          htmlType="submit"
          className="mb-1"
          type="text"
          block
          shape="round"
          onClick={() => {
            setIsModalVisible(false);
            setIsLogoutModalVisible(false);
            setIsPdfModalVisible(false);
            setIsCreateNewUserModalVisible(false);
          }}
        >
          Cancel
        </Button>
      </div>
    );
  };

  const pdfUrlCallback = async (s3Url: string, pdfName: string = "") => {
    setDownloadLoading(true);
    try {
      const result: any = await Storage.get(s3Url, {
        download: true,
        customPrefix: {
          public: "",
        },
      });
      downloadBlob(result.Body, `${pdfName ?? "MyFormData"}.pdf`);
    } catch (err) {
    } finally {
      resetPdfLink();
      setDownloadLoading(false);
    }
  };

  useEffect(() => {
    if (completedUserPdfLink !== "") {
      pdfUrlCallback(completedUserPdfLink, completedUserPdfName);
    }

    return () => {
      resetCompletedUserPdfLink();
    };
  }, [completedUserPdfLink]);

  const downloadPdfHandler = (
    userId: string,
    target: string,
    userName: string
  ) => completedUserPdfRequest(kycDetails?.id, userId, target, userName);

  const renderCompletedUserModal = () => {
    const selectedUserObject = kycDetails?.others?.filter(
      (each: any) => each.id === pdfUserId
    );
    let targetTypeArray = [];
    if (
      selectedUserObject[0]?.form?.demat &&
      selectedUserObject[0]?.form?.meroshare
    )
      targetTypeArray.push("demat_meroshare");
    else if (selectedUserObject[0]?.form?.demat) targetTypeArray.push("demat");
    else if (selectedUserObject[0]?.form?.meroshare)
      targetTypeArray.push("meroshare");
    if (selectedUserObject[0]?.form?.trading) targetTypeArray.push("trading");
    const selectedUserName = selectedUserObject[0].username;

    const fillUpTradingHandler = () => {
      updateKycDetailRequest2(selectedUserObject[0].id, {
        form: {
          trading: true,
        },
      });
    };
    const fillUpMeroshareHandler = () => {
      let formData;
      if (selectedUserObject[0]?.minor) {
        //minor case
        formData = {
          // full_name: selectedUserObject[0]?.minor_details?.name,
          phone: selectedUserObject[0]?.phone,
          signature: selectedUserObject[0]?.guardian_details?.signature,
          updated_signature:
            selectedUserObject[0]?.guardian_details?.updated_signature ??
            selectedUserObject[0]?.guardian_details?.signature,
          email: selectedUserObject[0]?.email,
        };
      } else {
        formData = {
          // full_name: selectedUserObject[0]?.general_details?.full_name,
          phone: selectedUserObject[0]?.phone,
          signature: selectedUserObject[0]?.signature,
          updated_signature:
            selectedUserObject[0]?.updated_signature ??
            selectedUserObject[0]?.signature,
          email: selectedUserObject[0]?.email,
        };
      }

      updateKycDetailRequest2(selectedUserObject[0].id, {
        form: {
          meroshare: true,
        },
        meroshare: formData,
      });
    };

    return (
      <>
        <div className="s-text fw-600 text-green">DOWNLOAD PDF</div>
        {targetTypeArray?.length > 0 &&
          targetTypeArray.map((targetItem: string) => (
            <div className="d-flex flex-between align-items-center mb-1">
              <div className="s-text fw-600 text-light-black">
                {capitalizeFirstLetter(targetItem).replaceAll("_", " + ")}
              </div>
              <Button
                onClick={downloadPdfHandler.bind(
                  this,
                  pdfUserId,
                  targetItem,
                  selectedUserName
                )}
              >
                <DownloadOutlined style={{ color: "#00dda2" }} />
              </Button>
            </div>
          ))}
        {selectedUserObject[0].form?.meroshare &&
          selectedUserObject[0].form?.meroshare_status?.toLowerCase() ===
          "completed" &&
          !selectedUserObject[0].form?.trading && (
            <>
              <Divider />
              <div className="d-flex flex-between align-items-center mt-1">
                <div className="s-text fw-600 text-green">
                  Fill up Trading form
                </div>
                <div
                  className={`dematFormStatusIncomplete xxs-text fw-300 cursor-hover`}
                  onClick={fillUpTradingHandler}
                >
                  Continue
                </div>
              </div>
            </>
          )}
        {selectedUserObject[0].form?.demat &&
          selectedUserObject[0].form?.demat_status.toLowerCase() ===
          "completed" &&
          !selectedUserObject[0].form?.meroshare && (
            <>
              <hr />
              <div className="d-flex flex-between align-items-center mt-1">
                <div className="s-text fw-600 text-green">
                  Fill up Meroshare form
                </div>
                <div
                  className={`dematFormStatusIncomplete xxs-text fw-300 cursor-hover`}
                  onClick={fillUpMeroshareHandler}
                >
                  Continue
                </div>
              </div>
            </>
          )}
      </>
    );
  };

  const renderCreateUserModal = useCallback(
    () => (
      <Row justify="center">
        <Col span="24" className="text-center">
          <Form
            form={form}
            layout="vertical"
            name="basic"
            onFinish={submitHandler}
          >
            <Form.Item name="username" rules={VALIDATIONS.username}>
              <Input
                placeholder="Enter full name"
                bordered={false}
                className="underlineInput"
                name="username"
                prefix={<UserIcon />}
              />
            </Form.Item>
            <Form.Item name="phone" rules={VALIDATIONS.phone}>
              <Input
                placeholder="Enter Phone Number"
                bordered={false}
                className="underlineInput"
                name="phone"
                type="number"
                prefix={<PhoneIcon />}
              />
            </Form.Item>
            <Form.Item name="email" rules={VALIDATIONS.email}>
              <Input
                placeholder="Email ID"
                name="email"
                bordered={false}
                className="underlineInput"
                type="email"
                prefix={<MailIcon />}
              />
            </Form.Item>
          </Form>
          <Button
            loading={actionLoading}
            htmlType="submit"
            className="submit-btn mt-1"
            block
            shape="round"
            onClick={submitHandler}
          >
            Continue
          </Button>
        </Col>
      </Row>
    ),
    []
  );

  const DematRenderUsers = useCallback(
    ({
      username,
      id,
      date,
      form,
    }: {
      username: string;
      id: string;
      date: string;
      form: formInterface;
    }) => {
      const isComplete = checkFormStatus(form);

      const data = kycDetails.others.find((item: any) => item.id === id)

      return (
        <motion.div
          animate={OPACITY_1}
          initial={OPACITY_0}
          exit={OPACITY_0}
          layout
          className={`dematListItemContainer cursor-hover`}
          onClick={() => {
            if (!isComplete) {
              setCurrentUser(id);
              forceReloadRequest();
              dispatch(resetPaymentYears())
              history.push(`/app/${id}/form-selection`)
              setNewKycFormValues(data)
              dispatch(resetPaymentDetails())
            } else {
              setIsModalVisible(true);
              setIsPdfModalVisible(true);
              setPdfUserId(id);
            }
          }}
        >
          <div
            className="d-flex flex-column"
            style={{
              gap: ".3rem",
            }}
          >
            <div className="s-text fw-600 text-light-black">{username}</div>
            <div className="xxs-text text-light-grey">
              {dayjs(date).format("MMM DD, YYYY")}
            </div>
          </div>
          <div
            className={`${isComplete
                ? "dematFormStatusComplete"
                : "dematFormStatusIncomplete"
              } xxs-text fw-300`}
          >
            {isComplete ? "View" : "Continue"}
          </div>
        </motion.div>
      );
    },
    [kycDetails]
  );

  const loaderVal = (): boolean => {
    let valueCount = 0;
    if (actionLoading) valueCount = valueCount + 1;
    if (pdfLoading) valueCount = valueCount + 1;
    if (downloadLoading) valueCount = valueCount + 1;

    if (valueCount === 0) return false;
    else return true;
  };

  return (
    <Spin spinning={initLoading}>
      <div className="profileContainer">
        <TopSection
          name={
            (Object.keys(kycDetails).length &&
              kycDetails?.others?.length &&
              kycDetails?.others[0]?.username) ??
            ""
          }
          imageUrl={
            (Object.keys(kycDetails).length &&
              kycDetails?.others?.length &&
              kycDetails?.others[0]?.profile) ??
            kycDetails?.others[0]?.minor_details?.profile ??
            ""
          }
        />
        <div className="BottomProfileContainer">
          <StatsSection
            users={
              Object.keys(kycDetails).length &&
              kycDetails?.others?.length &&
              kycDetails?.others
            }
            {...{ formTypeIndex }}
            {...{ setFormTypeIndex }}
          />
          <div className="dematListsContainer">
            <div className="text-light-black b-text fw-500">FORM Lists</div>
            <motion.div layout>
              {Object.keys(kycDetails).length &&
                kycDetails?.others?.length &&
                selectedFormGroup()
                  ?.map((each: any, index: any) => {
                    return (
                      <DematRenderUsers
                        username={each.username}
                        id={each.id}
                        date={each.created_at}
                        form={each.form}
                        key={each.id}
                      />
                    );
                  })
                  .reverse()}
            </motion.div>
          </div>

          <Modal
            centered
            visible={isModalVisible}
            onCancel={() => {
              setIsModalVisible(false);
              setIsPdfModalVisible(false);
              setIsLogoutModalVisible(false);
              setIsCreateNewUserModalVisible(false);
              setPdfUserId("");
            }}
            footer={null}
            maskClosable={!loaderVal()}
            // width={"70vw"}
            destroyOnClose
            closable={false}
          >
            <Spin spinning={pdfLoading || downloadLoading || actionLoading}>
              {isCreateNewUserModalVisible && renderCreateUserModal()}
              {isPdfModalVisible && renderCompletedUserModal()}
              {isLogoutModalVisible && renderLogoutModal()}
            </Spin>
          </Modal>

          <div style={{ paddingInline: "5vw" }}>
            <Button
              htmlType="submit"
              className="submit-btn mb-1"
              block
              shape="round"
              onClick={() => {
                setIsModalVisible(true);
                setIsCreateNewUserModalVisible(true);
              }}
            >
              Fill a new form
            </Button>
            <Button
              htmlType="submit"
              className="mb-5"
              type="text"
              block
              shape="round"
              onClick={() => {
                setIsModalVisible(true);
                setIsLogoutModalVisible(true);
              }}
            >
              Logout
            </Button>
          </div>
        </div>
      </div>
      <FooterWatchList />
    </Spin>
  );
};

export default Profile;
