import {
  useEffect,
  useState,
} from 'react';

import {
  Checkbox,
  Col,
  message,
  Row,
  Spin,
} from 'antd';
import Progressbar from 'Components/Progressbar';
import StorageGetImage from 'Components/StorageGetImage';
import useCheckMeroshareStatusHook
  from 'CustomHooks/useCheckMeroshareStatusHook';
import { useSetKycFormValue } from 'CustomHooks/useSetKycFormValue';
import useTimerHook from 'CustomHooks/useTimerHook';
import { IPageProps } from 'Interfaces/Page';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import {
  clearSuccess,
  setKycFormValues,
  updateKycDetailRequest,
} from 'Redux/Protected/Action';
import { RootState } from 'Redux/RootReducer';
import RenderFooter from 'Views/Dmat/components/RenderFooter';

const GreyText = ({ data }: { data: string }) => {
  return (
    <div
      className="xs-text mt-1 text-light-grey text-justify"
      style={{ lineHeight: "20px" }}
    >
      {data}
    </div>
  );
};
const MediumGreyText = ({ data }: { data: string }) => {
  return (
    <div
      className="xs-text text-dark-grey text-justify"
      style={{ marginTop: "0.5rem" }}
    >
      {data}
    </div>
  );
};

const BlackText = ({ data }: { data: string }) => (
  <div className="s-text mt-1 text-light-black">{data}</div>
);

const MeroshareTermsNepali = ({ history, pageNumber }: IPageProps) => {
  const dispatch = useDispatch();
  const [agreeVal, setAgreeVal] = useState<boolean>(false);
  const [signatureImage, setSignatureImage] = useState<any>("");
  const [getImageLoading, setGetImageLoading] = useState(false);

  const {
    kycFormValues,
    actionLoading,
    kycDetails,
    success,
    globalTimer,
    mainSignature,
  } = useSelector((state: RootState) => state?.protectedReducer);

  const timeVal = useTimerHook();
  const [meroshareType] = useCheckMeroshareStatusHook();
  const {oid} = useSetKycFormValue()

  const handleCheck = () => {
    setAgreeVal((curr) => !curr);
  };

  const backHandler = () => {
    history.push(`/app/${oid}/meroshare`);
    // if (kycFormValues?.form?.demat) {
    //   history.push("/app/meroshare");
    // } else {
    //   history.push("/app/meroshare");
    // }
  };
  const submitHandler = () => {
    if (agreeVal) {
      dispatch(
        setKycFormValues({
          ...kycFormValues,
          meroshare: {
            ...kycFormValues.meroshare,
            terms_conditions_status: true,
          },
        })
      );
      dispatch(
        updateKycDetailRequest({
          meroshare: { terms_conditions_status: true },
          time_tracker: {
            time_tracking: {
              [meroshareType]: [
                {
                  page: pageNumber,
                  time: timeVal,
                },
              ],
            },
          },
        })
      );
    } else {
      message.error("Please agree to the terms and conditions first.");
    }
  };
  const getSignature = async () => {
    // let imageKey = "";
    // if (mainSignature !== "") imageKey = mainSignature;
    // else {
    //   imageKey =
    //     // kycFormValues?.meroshare?.updated_signature ??
    //     kycFormValues?.meroshare?.signature ??
    //     "";
    // }

    // console.log("image key", imageKey);
    

    setGetImageLoading(true);
    StorageGetImage({
      imageKey: kycFormValues?.meroshare?.signature,
      successCB: (base64data) => {
        setSignatureImage(base64data);
      },
      finalCB: () => {
        setGetImageLoading(false);
      },
    });
  };
  useEffect(() => {
    if (
      kycFormValues?.meroshare?.signature ||
      kycFormValues?.form?.demat
    ) {
      getSignature();
    }
  }, [kycFormValues, mainSignature]);

  useEffect(() => {
    if (success) {
      history.push(`/app/${oid}/meroshare/payment`);
    }
    return () => {
      dispatch(clearSuccess());
    };
  }, [success]);

  const formattedTime: any =
    ("0" + Math.floor((globalTimer / 60) % 60)).slice(-2) +
    ":" +
    ("0" + Math.floor(globalTimer % 60)).slice(-2) +
    " min";

  return (
    <>
      <Progressbar percent={93} leftText={"93%"} rightText={formattedTime} />

      <Row className="termsLayout">
        <Col span="24" className="termsTopContainer">
          <div className="text-green sb-text fw-500">Terms and Condition</div>
        </Col>
        <div
        >
          <Col span="24" className="termsMiddleContainer">
            <BlackText data={`“मेरो शेयर” को सेवा सम्बन्धी नियम तथा शर्तहरू`} />

            <GreyText
              data={`१. “मेरो शेयर” सेवा केवल व्यक्तिगत प्रयोजनको लागि मात्र हो । यो सेवा अन्य कसैलाई हस्तान्तरण गरिने छैन ।`}
            />
            <GreyText
              data={`२. ग्राहकले “मेरो शेयर” सेवासंग सम्बन्धित विद्युतीय कारोवारको लागि दिद्वएका सुरक्षित गोप्य नाम, पासवर्ड, पिन आदि जानकारीको गोपनियता कायम गर्नेछ र यी जानकारीहरू कुनै पनि अवस्थामा कसैलाई कहिले पनि दिने छैन ।`}
            />
            <GreyText
              data={`३. यदि आफ्ना गोप्य पासवर्ड नम्बर पिन हराएको वा सोको जानकारीहरू कतै कोही असम्बन्धित व्यक्तिको जानकारीमा आएको थाहा भएमा तुरूनत आफ्नो निक्षेप सदस्यलाई जानकारी गराई रद्द गराउनु पर्नेछ र परिवर्तन गराउनु पर्नेछ ।`}
            />
            <GreyText
              data={`४. “मेरो शेयर” मार्फत गरिएका सम्पूर्ण कारोबारको जिम्मेवारी ग्राहक स्वयंको हुनेछ ।`}
            />
            <GreyText
              data={`५. “मेरो शेयर ”को प्रयोग सं सिडिएससिको प्रचलित नियम र विनियमको अधीनमा रहने छ ।`}
            />
            <GreyText
              data={`६. प्राविधिक कारणले सेवा अवरूद्ध हुन गई ग्राहकलाई हुन गएको असुबिधा, क्षति वा हानि-नोक्सानीको लागि निक्षेप सदस्य /सिडिएससि कुनै पनि किसिमले जिम्मेवार हुने छैन ।`}
            />
            <GreyText
              data={`७. यो निवेदनमा निक्षेप सदस्य /सिडिएससिको स्वीकृत आवश्यक पर्नेछ। निक्षेप सदस्य /सिडिएससिसंग कुनै कारण नखुलाई आफ्नो स्वविवेकमा निवेदन अस्वीकृत गर्ने अधिकार रहने छ ।`}
            />
            <GreyText
              data={`८. ग्राहकलाई सूचित गरी वा नगरी यो सुविधा नवीकरण गर्ने, रद्द गर्ने वा फिर्ता लिने अधिकार निक्षेप सदस्य/सिडिएससिलाई रहने छ ।`}
            />

            <BlackText data={`उद्घोषण:`} />
            <div className="d-flex mt-1 mb-2 ">
              <Checkbox
                onChange={handleCheck}
                checked={agreeVal}
                className="br-8"
              />
              <div
                className="ml-1 cursor-hover text-light-grey text-justify"
                style={{ lineHeight: "22px" }}
                onClick={handleCheck}
              >
                म/हामी माथि उल्लिखित सम्पूर्ण विवरण साँचो रहेको घोषणा
                गर्दछु/गर्दछौं ्र यदि उल्लिखित विवरणहरू कुनै कारणवश गलत भएको
                अथवा गलत प्रमाणित भएको अवस्थामा त्यसबाट सिर्जना हुने सम्पूर्ण
                परिस्थितिको जिम्मेवार म/हामी स्वयं हुने छु/छौं । साथै, माथि
                उल्लिखित मोबाइल नम्बर तथा इमेल मेरो/हाम्रो व्यक्तिगत प्रयोजनका
                लागि प्रयोग हुनेछ तथा मेरो शेयरको सेवाका लागि सिडिएससिबाट प्रदान
                गरिने ीयनष्ल तथा एबककधयचम हरू उललेखित मोबाइल नम्बर वा इमेल
                ठेगानामा प्राप्त गर्न मेरो /हाम्रो मञज्जुरी छ ्र मेरो शेयरको
                सेवा उपभोग गर्ने सम्बन्धमा सिडिएससिद्वारा जारी गरिएका यस सेवासंग
                सम्बन्धित माथि उल्लेख गरिएका सम्पूर्ण नियम, शर्तहरू राम्ररी पढी
                बुभी स्वीकार गर्दछु/गर्दछौं।
              </div>
            </div>

            <div className="mb-2">
              <Spin spinning={getImageLoading}>
                {signatureImage ? (
                  <img className="signature-terms" src={signatureImage} />
                ) : null}
              </Spin>
              <MediumGreyText data={`....................................`} />
              <BlackText data={`आधिकारिक दस्तखत :`} />
            </div>
          </Col>
        </div>
        <Col span="24" className="termsBottomContainer">
          <RenderFooter
            backHandler={backHandler}
            loading={actionLoading}
            submitHandler={submitHandler}
          />
        </Col>
      </Row>
    </>
  );
};

export default MeroshareTermsNepali;
