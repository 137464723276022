import { useEffect, useState } from "react";
import { Row, Col, Form, Input, Button } from "antd";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as UserIcon } from "Assets/Icons/user.svg";
import { ReactComponent as MailIcon } from "Assets/Icons/mail.svg";
import { ReactComponent as PhoneIcon } from "Assets/Icons/phone.svg";
import { signIn, signUp } from "Redux/Auth/Action";
import { clearSuccess } from "Redux/Protected/Action";
import { RootState } from "Redux/RootReducer";

export interface GeneralDetailsFormData {
  full_name: string;
  email: string;
  phone_number: string;
}

export default function GeneralDetails() {
  let history = useHistory();
  const dispatch = useDispatch();
  const { signUpSuccessful, success, generalDetails, loading } = useSelector(
    (state: RootState) => state.authReducer
  );
  const InitialVlaues: GeneralDetailsFormData = {
    full_name: "",
    email: "",
    phone_number: "",
  };
  const [formValues, setFormValues] = useState(InitialVlaues);
  const [counter, setCounter] = useState(0);

  const handleInputChange = (e: any, name: string) => {
    setFormValues({ ...formValues, [name]: e.target.value });
  };

  const submitFormValues = () => {
    dispatch(
      signUp({
        ...formValues,
        phone_number: `+977${formValues?.phone_number
          .replace("+977", "")
          .replace("-", "")}`,
      })
    );
  };

  useEffect(() => {
    if (signUpSuccessful) {
      dispatch(
        signIn({
          ...formValues,
          phone_number: `+977${formValues?.phone_number
            .replace("+977", "")
            .replace("-", "")}`,
        })
      );
    }
  }, [signUpSuccessful]);

  useEffect(() => {
    if (success) {
      history.push("/auth/otp-verification");
    }
    return () => {
      dispatch(clearSuccess());
    };
  }, [success]);

  useEffect(() => {
    if (Object.keys(generalDetails).length) {
      setFormValues({
        ...formValues,
        full_name: generalDetails?.full_name,
        email: generalDetails?.email,
        phone_number: `+977${generalDetails?.phone_number
          .replace("+977", "")
          .replace("-", "")}`,
      });
      setCounter(counter + 1);
    }
  }, []);

  return (
    <Row>
      <Col
        span="24"
        className="fade-in-down-transition"
        style={{ minHeight: "20vh" }}
      ></Col>
      <Col span="24" className="content-container">
        <Row>
          <Col span="24" className="mb-3 fade-in-right-transition">
            <div className="title">General Details</div>
            <div className="subtitle">
              Please input your general information below
            </div>
          </Col>
          <Col span="24">
            <Form
              layout="vertical"
              name="basic"
              onFinish={submitFormValues}
              key={counter}
            >
              <Row>
                <Col span="24" className="mb-1 fade-in-right-transition">
                  <Form.Item
                    name="full_name"
                    rules={[
                      { required: true, message: "Full Name is required" },
                      {
                        min: 5,
                        message:
                          "Full Name should be atleast 5 characters long",
                      },
                      {
                        max: 50,
                        message: "Full Name can be upto 50 characters long",
                      },
                    ]}
                    initialValue={formValues.full_name}
                  >
                    <Input
                      placeholder="Full Name"
                      bordered={false}
                      value={formValues.full_name}
                      className="underlineInput"
                      onChange={(e) => handleInputChange(e, "full_name")}
                      prefix={<UserIcon />}
                    />
                  </Form.Item>
                </Col>
                <Col span="24" className="mb-1 fade-in-right-transition">
                  <Form.Item
                    name="email"
                    rules={[
                      { required: true, message: "Email ID is required" },
                      {
                        type: "email",
                        message: "Please enter valid email",
                      },
                    ]}
                    initialValue={formValues.email}
                  >
                    <Input
                      placeholder="Email ID"
                      bordered={false}
                      value={formValues.email}
                      className="underlineInput"
                      onChange={(e) => handleInputChange(e, "email")}
                      type="email"
                      prefix={<MailIcon />}
                    />
                  </Form.Item>
                </Col>
                <Col span="24" className="mb-1 fade-in-right-transition">
                  <Form.Item
                    name="phone_number"
                    rules={[
                      { required: true, message: "Phone Number is required" },
                      {
                        min: 10,
                        message: "Phone Number should be 10 characters long",
                      },
                      {
                        max: 10,
                        message: "Phone Number should be 10 characters long",
                      },
                    ]}
                    initialValue={formValues.phone_number}
                  >
                    <Input
                      placeholder="Mobile Number"
                      bordered={false}
                      value={formValues.phone_number}
                      className="underlineInput"
                      onChange={(e) => handleInputChange(e, "phone_number")}
                      prefix={<PhoneIcon />}
                      type="number"
                    />
                  </Form.Item>
                </Col>
                <Col
                  span="24"
                  className="text-center mt-1 fade-in-up-transition"
                >
                  <Button
                    loading={loading}
                    htmlType="submit"
                    className="submit-btn"
                    block
                    shape="round"
                  >
                    Save and Next
                  </Button>
                  <Button
                    type="link"
                    className="cancel-link-btn"
                    onClick={() =>
                      window.location.replace("https://abcsecurities.com.np")
                    }
                  >
                    Cancel
                  </Button>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
