import { message } from 'antd';
import { RcFile } from 'antd/lib/upload/interface';
import { Storage } from 'aws-amplify';

interface Props {
  directoryPath: string;
  fileObj: RcFile;
  successCB: (data: string) => void;
  failureCB?: () => void;
  finalCB?: () => void;
}

/**
 * 
 * Helper function to store image from s3 with s3Key
 */
export const StoragePut = async (props: Props) => {
  const {
    directoryPath,
    fileObj,
    successCB,
    failureCB = () => {},
    finalCB = () => {},
  } = props;
  await Storage.put(directoryPath, fileObj, {
    contentType: fileObj.type,
    customPrefix: {
      public: "",
    },
  })
    .then((res: any) => {
      const data = res?.key;
      successCB(data);
    })
    .catch((err) => {
      message.error("Error Uploading file. Please try again");
      failureCB();
    })
    .finally(() => {
      finalCB();
    });
};

