import { Input } from "antd";

const GreyText = ({ data }: { data: string }) => {
  return (
    <div
      className="xs-text mt-1 text-light-grey text-justify"
      style={{ lineHeight: "1.5rem" }}
    >
      {data}
    </div>
  );
};
const MediumGreyText = ({ data }: { data: string }) => {
  return (
    <div
      className="xs-text text-dark-grey text-justify"
      style={{ marginTop: "0.5rem" }}
    >
      {data}
    </div>
  );
};

const BlackText = ({ data }: { data: string }) => (
  <div className="s-text mt-1 text-light-black fw-600">{data}</div>
);

const DottedInputText = ({
  value,
}: {
  value: string | number | readonly string[];
}) => (
  <Input
    className="dottedInput"
    value={value}
    disabled
    bordered={false}
    style={{ width: 200 ,textAlign:"center"}}
  />
);

export { GreyText, MediumGreyText, BlackText, DottedInputText };
