import { CustomNotification } from "Components/CustomNotification";

/**
 * 
 * @param file 
 * @returns false if file is corrupt and a popup notification
 */
export async function checkValidFile(file: any): Promise<boolean> {
  try {
    await file.slice(0, 1).arrayBuffer();
    return true;
  } catch (err) {
    CustomNotification({
      title: "File Selection Error",
      description:
        "Error selecting this file. This likely is an issue with your Cloud Storage.",
      type: "error",
    });

    return false;
  }
}
