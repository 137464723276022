import {
  all,
  call,
  fork,
  put,
  StrictEffect,
  takeEvery,
} from "redux-saga/effects";
import { SIGN_IN, SIGN_OUT, SIGN_UP, VERIFY_OTP } from "./Constant";
import {
  signInSuccess,
  signInFailed,
  signUp,
  signUpFailed,
  verifyOtpFailed,
  signUpSuccess,
  verifyOtpSuccess,
} from "./Action";
import { signInApi, signOutApi, signUpApi, verifyOtpApi } from "../../Api/Auth";
import { message } from "antd";

function* handleSignIn({ payload }: any): any {
  const { phone_number } = payload;
  try {
    const user = yield call(() => signInApi({ phone_number }));
    yield put(signInSuccess(user));
  } catch (error: any) {
    yield put(signInFailed(error));
    message.error(
      error?.response?.data?.message ?? error?.message ?? "Something went wrong"
    );
  }
}

// function* handleSetUser(): any {
//   try {
//     const response = yield call(() => getSession());

//     const user = response.attributes || {};
//     const idToken = response.signInUserSession.idToken.jwtToken;
//     const accessType =
//       response.signInUserSession.idToken.payload["custom:role"];

//     yield put(
//       signInSuccess({
//         userObj: response,
//         user: { ...user, idToken, accessType },
//         successMessage: "Sign In Success",
//       })
//     );
//   } catch (error) {
//     yield put(signInFailed(error));
//     window.location.replace("/auth/login");
//   }
// }
function* watchSignInUser() {
  yield takeEvery(SIGN_IN, handleSignIn);
}

function* handleSignUp({ payload }: ReturnType<typeof signUp>): any {
  const { email, phone_number, full_name } = payload;
  try {
    const res = yield call(() => signUpApi({ email, phone_number, full_name }));
    yield put(signUpSuccess(res));
  } catch (error: any) {
    if (error?.response?.data?.message === "User Exist") {
      yield put(signUpSuccess("Successfully signed up"));
    } else if (
      error?.response?.data?.message === "User with this Email already Exists"
    ) {
      message.error(
        `${
          email ?? "This email"
        } is already associated with another phone number`
      );
      yield put(signUpFailed(error?.response?.data?.message));
    } else {
      message.error("Couldn't login at the moment");
      yield put(signUpFailed(error?.response?.data?.message));
    }
  }
}
function* watchSignUpUser() {
  yield takeEvery(SIGN_UP, handleSignUp);
}

function* handleVerifyOtp({ payload }: ReturnType<typeof signUp>): any {
  try {
    const otpRes = yield call(() => verifyOtpApi(payload));

    if (
      otpRes?.challengeParam &&
      Object.keys(otpRes?.challengeParam).length === 0
    ) {
      const err = "Please enter the correct OTP";
      message.error(err);
      yield put(verifyOtpFailed({ message: err }));
    } else {
      yield put(verifyOtpSuccess(otpRes));
      yield localStorage.setItem("userId", otpRes?.attributes["custom:id"]);
      yield localStorage.setItem("user-logged-in", "true");
      window.location.replace("/");
    }
  } catch (error: any) {
    yield put(verifyOtpFailed(error));
    if (error?.message === "Invalid session for the user.") {
      message.error("The OTP has now expired. Please request for a new code.");
    } else {
      message.error(
        error?.response?.data?.message ??
          error.message ??
          "Something went wrong"
      );
    }
  }
}
function* watchVerifyOtp() {
  yield takeEvery(VERIFY_OTP, handleVerifyOtp);
}

function* handleSignOut({ payload }: any) {
  const { history } = payload;
  try {
    yield call(() => signOutApi());
    yield localStorage.removeItem("amplify-authenticator-authState");
    yield history.push("/auth/login");
  } catch (error) {}
}

function* watchSignOut() {
  yield takeEvery(SIGN_OUT, handleSignOut);
}

export default function* rootSaga(): Generator<StrictEffect, void> {
  yield all([
    fork(watchSignInUser),
    fork(watchSignUpUser),
    fork(watchSignOut),
    fork(watchVerifyOtp),
  ]);
}
