import {compose, createStore, applyMiddleware} from 'redux';
import {createBrowserHistory} from 'history';
import createSagaMiddleware from 'redux-saga';
import RootReducer from './RootReducer';
import RootSaga from './RootSaga';

export const history = createBrowserHistory();
const sagaMiddleware = createSagaMiddleware();
const middleware = [sagaMiddleware]

let store;

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION__?: typeof compose;
  }
}

export const configureStore = (initialState: any) => {
    const reduxDevTools = window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : compose;

    store = createStore(RootReducer(history), initialState, compose(applyMiddleware(...middleware), process.env.NODE_ENV === "development" ? reduxDevTools : compose));

    sagaMiddleware.run(RootSaga);

    return store;
}

export default store;