import {
  useEffect,
  useState,
} from 'react';

import {
  Image,
  message,
  Spin,
  Upload,
} from 'antd';
import { ReactComponent as ReUploadIcon } from 'Assets/Icons/reupload.svg';
import { checkValidFile } from 'Utils/checkValidFile';
import { toBase64 } from 'Utils/convertTobase64';
import { dummyRequest } from 'Utils/DummyCall';
import { resizeImage } from 'Utils/ImageCompressor';

import { CameraFilled } from '@ant-design/icons';

import StorageGetImage from './StorageGetImage';

const { Dragger } = Upload;
interface Props {
  onSelect: (file: any, imageData: any) => void;
  circleImage?: boolean;
  label: string;
  imageUrl: string;
}
/**
 * 
 * @param circleImage if true,renders a circular div 
 * @param label text inside the dragger box 
 * @param imageUrl s3key of the image to load it 
 * @param onSelect callback function with fileObj and base64data as args 
 */
const CommonImagePicker = ({
  onSelect,
  circleImage,
  label,
  imageUrl,
}: Props) => {
  const [imageData64, setImageData64] = useState<string | any>("");
  const [getImageLoading, setGetImageLoading] = useState(false);

  const beforeUpload = async (file: any) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";

    if (!isJpgOrPng) {
      message.error("You can only upload JPG file and PNG Files!");
    } else {
      setGetImageLoading(true);
      const checkFile = await checkValidFile(file);
      if (!checkFile) {
        setGetImageLoading(false);
        return;
      }
      const compressedFile = await resizeImage(file);
      const imageData = await toBase64(compressedFile);
      setGetImageLoading(false);
      setImageData64(imageData);
      onSelect(compressedFile, imageData);
    }
  };
  const getImage = async (s3Key: string) => {
    setGetImageLoading(true);
    StorageGetImage({
      imageKey: s3Key,
      successCB: (base64data) => {
        setImageData64(base64data);
      },
      finalCB: () => {
        setGetImageLoading(false);
      },
    });
  };
  useEffect(() => {
    if (imageUrl !== "") {
      getImage(imageUrl);
    }
  }, [imageUrl]);

  return (
    <div>
      <Spin spinning={getImageLoading}>
        {imageData64 === "" ? (
          <Dragger
            multiple={false}
            className="imagepicker-dragger"
            accept="image/*"
            style={{
              background: "#F6F8FA",
              borderColor: "#0AB290",
              height: "180px",
              width: "216px",
              borderRadius: "8px",
            }}
            showUploadList={false}
            customRequest={dummyRequest}
            beforeUpload={beforeUpload}
          >
            <p className="ant-upload-drag-icon">
              <CameraFilled style={{ color: "#00DDA2", fontSize: 60 }} />
            </p>
            <p
              className="xs-text text-light-grey text-center"
              style={{ paddingInline: "1vw" }}
            >
              {label}
            </p>
          </Dragger>
        ) : (
          <div
            style={{
              position: "relative",
              height: "150px",
              width: circleImage ? "150px" : " 15rem",
              borderRadius: circleImage ? "75px" : "8px",
            }}
          >
            <Image
              src={imageData64}
              style={{
                objectFit: "cover",
                position: "absolute",
                height: "150px",
                width: circleImage ? "150px" : " 15rem",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                borderRadius: circleImage ? "75px" : "8px",
              }}
              preview={true}
            />
            <div
              style={{
                position: "absolute",
                right: -10,
                bottom: 0,
                zIndex: 300,
              }}
            >
              <Upload
                multiple={false}
                accept="image/*"
                showUploadList={false}
                customRequest={dummyRequest}
                beforeUpload={beforeUpload}
              >
                <ReUploadIcon />
              </Upload>
            </div>
          </div>
        )}
        <br />
      </Spin>
    </div>
  );
};

CommonImagePicker.defaultProps = {
  circleImage: false,
  label: "Add/ Clear Image",
  imageUrl: "",
};

export default CommonImagePicker;
