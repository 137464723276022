import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "Redux/RootReducer";

//hook to check whether the current Merostaus form is for individual or continuous flow

function useCheckMeroshareStatusHook() {
  const [meroshareType, setMeroShareType] = useState<string>("");
  const {
    kycFormValues: { form },
  } = useSelector((state: RootState) => state?.protectedReducer);

  useEffect(() => {
    if (form?.meroshare && !form?.demat && !form.trading) {
      setMeroShareType("meroshare_individual");
    } else {
      setMeroShareType("meroshare");
    }
  }, [form]);

  return [meroshareType];
}

export default useCheckMeroshareStatusHook;
