import {
  Button,
  Col,
} from 'antd';

import {
  LeftOutlined,
  RightOutlined,
} from '@ant-design/icons';

interface Props {
  backHandler: () => void;
  submitHandler: () => void;
  showForwardBtn: boolean;
  showSkip: boolean;
  skipHandler: () => void;
  loading?: boolean;
  reduceHeight?: boolean;
}

const RenderFooter = ({
  backHandler,
  submitHandler,
  reduceHeight,
  showForwardBtn,
  showSkip,
  skipHandler,
  loading = false,
}: Props) => {
  return (
    <Col
      span="24"
      className={`d-flex px-5vw ${reduceHeight} ? "h-50p" : "h-100p"`}
    >
      <div className="w-100p h-100p d-flex flex-between align-items-center footerDiv">
        <Button type="text" onClick={backHandler}>
          <LeftOutlined /> Back
        </Button>
        {showSkip ? (
          <Button type="text" onClick={skipHandler}>
            Skip <RightOutlined />
          </Button>
        ) : (
          showForwardBtn && (
            // <ForwardButton
            //   disabled={false}
            //   type="forward"
            //   loading={loading}
            //   onClick={submitHandler}
            // />
            <Button className='continue' loading={loading} onClick={submitHandler}>
              Continue
            </Button>
          )
        )}
      </div>
    </Col>
  );
};

RenderFooter.defaultProps = {
  backHandler: () => {},
  submitHandler: () => {},
  showForwardBtn: true,
  reduceHeight: false,
  showSkip: false,
  skipHandler: () => {},
};

export default RenderFooter;
