import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import {
  Button,
  Col,
  Image,
  message,
  Progress,
  Row,
  Upload,
} from 'antd';
import ImgCrop from 'antd-img-crop';
import CloseIcon from 'Assets/Icons/close.svg';
import ImageIcon from 'Assets/Icons/image.png';
import StorageGetImage from 'Components/StorageGetImage';
import SubLabelWrapper from 'Components/SubLabelWrapper';
import { useSetKycFormValue } from 'CustomHooks/useSetKycFormValue';
import { useSelector } from 'react-redux';
import {
  useHistory,
  useLocation,
} from 'react-router-dom';
import { RootState } from 'Redux/RootReducer';
import { checkValidFile } from 'Utils/checkValidFile';
import { dummyRequest } from 'Utils/DummyCall';

interface SignatureUploadProps {
    setSignatureKey: Dispatch<SetStateAction<string>>
    initKycFormValues: () => void
}

export const SignatureUpload = ({ setSignatureKey, initKycFormValues }: SignatureUploadProps) => {
    //var
    const history = useHistory()
    const { kycFormValues, } =
        useSelector((state: RootState) => state?.protectedReducer)

    //ref
    const signatureRef = useRef<HTMLDivElement>(null);

    //hooks
    const { oid, store } = useSetKycFormValue()
    const location = useLocation<any>()

    //state
    const [signatureImage, setSignatureImage] = useState<any>(null);
    const [imagePercent, setImagePercent] = useState<any>(0);



    const getImage = useCallback(() => {
        let imageKey = kycFormValues.minor ? kycFormValues?.guardian_details?.signature : kycFormValues?.signature ?? "";
        if (imageKey) {
            StorageGetImage({
                imageKey,
                successCB: (base64data) => {
                    setSignatureImage(base64data);
                },
            });
            setImagePercent(100);
        }
    }, [kycFormValues?.signature, kycFormValues?.guardian_details?.signature, kycFormValues?.minor ])


    useEffect(() => {
        if (kycFormValues?.signature || kycFormValues?.guardian_details?.signature) {
            getImage();

        }
        setSignatureKey(kycFormValues?.guardian_details?.signature ?? kycFormValues?.signature ?? "")
    }, [kycFormValues?.signature, kycFormValues?.guardian_details?.signature,  getImage, setSignatureKey])

    useEffect(() => {
        if (location.state?.scrollPosition) {

            signatureRef.current?.scrollIntoView({ behavior: "auto", })
        }
    }, [location.state?.scrollPosition,])


    const resetSignature = () => {
        setSignatureImage(null);
        setImagePercent(0);
    };

    const handleDigitalSignature = () => {

        initKycFormValues()

        if (history.location.pathname.includes("dematt")) {
            history.push(`/app/${oid}/dematt/your-details/digital-signature`,);
        } else {
            history.push(`/app/${oid}/trading/your-details/digital-signature`);
        }
    };

    const beforeUpload = async (file: any) => {
        const checkFile = await checkValidFile(file);
        if (!checkFile) return;
        const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";

        if (!isJpgOrPng) {
            message.error("You can only upload JPG file and PNG Files!");
        } else {
            setImagePercent(0);
            setSignatureImage(file);
            let extension = file?.type.split("/")[1];
            await store(extension, file, kycFormValues.minor ? 'guardianSignature' : 'nonMinorSignature', setSignatureKey)
            setImagePercent(100)
        }
    };


    return (
        <Col xs={24} lg={12} >
            <SubLabelWrapper
                label={`Upload Signature `}
                subLabel="Digitally sign or Manually upload signature"
                render={
                    <Row
                        ref={signatureRef}
                    >
                        {signatureImage || imagePercent === 100 ? (
                            <Col
                                span="24"
                                style={{ border: "1px dashed #0AB290" }}
                                className="br-8 p-1"
                            >
                                <Row className="mb-1">
                                    <Col span="22" className="d-flex">
                                        <Image src={ImageIcon} preview={false} />
                                        <span className="pl-1" style={{ color: "#60706F" }}>
                                            Signature.png
                                        </span>
                                    </Col>
                                    <Col span="2" className="text-right pointer">
                                        <Image
                                            onClick={resetSignature}
                                            src={CloseIcon}
                                            preview={false}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span="22">
                                        {" "}
                                        <Progress
                                            strokeColor="#00DDA2"
                                            showInfo={false}
                                            percent={imagePercent}
                                        />
                                    </Col>
                                    <Col
                                        span="22"
                                        className="text-right"
                                        style={{ marginTop: "-0.4rem" }}
                                    >
                                        {imagePercent}%
                                    </Col>
                                </Row>
                            </Col>
                        ) : (
                            <>
                                <Col span="24">
                                    <Button
                                        onClick={handleDigitalSignature}
                                        block
                                        className="br-8 mb-1"
                                        type="dashed"
                                    >
                                        Add your signature digitally
                                    </Button>
                                </Col>
                                <Col span="24">
                                    <ImgCrop>
                                        <Upload
                                            // style={{ width: "100%" }}
                                            multiple={false}
                                            accept="image/*"
                                            showUploadList={false}
                                            beforeUpload={beforeUpload}
                                            customRequest={dummyRequest}
                                            className='upload-signature'
                                        >
                                            <Button

                                                block
                                                className="br-8 w-100"
                                                type="dashed"
                                            >
                                                Upload your signature
                                            </Button>
                                        </Upload>
                                    </ImgCrop>
                                </Col>
                            </>
                        )}
                    </Row>
                }
            />
        </Col>
    )
}
