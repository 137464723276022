import {
  useEffect,
  useState,
} from 'react';

import {
  Button,
  Card,
  Col,
  message,
  Row,
} from 'antd';
import { StoragePut } from 'Components/StoragePut';
import SubLabelWrapper from 'Components/SubLabelWrapper';
import dayjs from 'dayjs';
import { IPageProps } from 'Interfaces/Page';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import { useParams } from 'react-router-dom';
import SignatureCanvas from 'react-signature-canvas';
import {
  clearSuccess,
  setKycFormValues,
  signatureBackgroundRemoverRequest,
  updateKycDetailRequest,
} from 'Redux/Protected/Action';
import { RootState } from 'Redux/RootReducer';
import { capitalizeFirstLetterEntireWord } from 'Utils/capitalizeFirstLetter';
import { s3Paths } from 'Utils/s3paths';
import RenderFooter from 'Views/Dmat/components/RenderFooter';
import ProgressbarLayout from 'Views/Dmat/Layout/ProgressbarLayout';

const PersonalSignature = ({ history, pageNumber }: IPageProps) => {
  //var
  const screenWidth = window.screen.width - 50
  const screenHeight = window.screen.height - 500
  const {
    kycFormValues,
    kycDetails,
    actionLoading,
    success,
    currentUserId,
    globalTimer,
  } = useSelector((state: RootState) => state?.protectedReducer)
  //hooks
  const dispatch = useDispatch()
  const { oid } = useParams<any>()

  //state
  const [imageLoading, setImageLoading] = useState<any>('')
  const [canvasRef, setCanvasRef] = useState<any>(null)
  const [dataUrl, setDataUrl] = useState<any>(null)


  const trim = async () => {
    if (canvasRef?.isEmpty()) {
      message.error('Please sign the document')
    } else {
      setImageLoading(true)
      let signatureFile: any
      setDataUrl(canvasRef.getTrimmedCanvas().toDataURL('image/png'))
      await fetch(canvasRef.getTrimmedCanvas().toDataURL('image/png'))
        .then((res) => res.blob())
        .then((blob) => {
          signatureFile = new File([blob], 'signature.png', {
            type: 'image/png',
          })
        })
      let extension = signatureFile?.type.split('/')[1]
      StoragePut({
        directoryPath: `${s3Paths({
          currentUserId,
          mainId: kycDetails?.id,
          type: kycFormValues.minor ? 'guardianSignature' : 'nonMinorSignature',
        })}/${dayjs().format('YYMMDD-HHmmss')}.${extension}`,
        fileObj: signatureFile,
        finalCB: () => {
          setImageLoading(false)
        },
        successCB: (data) => {
          let submitValues = {
            ...kycFormValues
          }
          let postData = {}

          if (kycFormValues.minor) {
            submitValues = {
              guardian_details: {
                ...submitValues.guardian_details,
                signature: data,
              }
            }
            postData = {
              guardian_details: {
                signature: data,
              }
            }
          } else {
            submitValues = {
              signature: data,

            }
            postData = {
              signature: data,
            }
          }

          dispatch(setKycFormValues(submitValues))
          dispatch(
            updateKycDetailRequest({
              ...postData,
            })
          )
          dispatch(
            signatureBackgroundRemoverRequest(
              {
                url: data,
                transparent: true,
              },
              true
            )
          )
        },
      })
    }
  }
  const clear = () => {
    canvasRef.clear()
    setDataUrl(null)
  }

  const backHandler = () => {
    history.goBack()
  }

  useEffect(() => {
    if (success) {
      if (history.location.pathname.includes('dematt')) {
        history.push(`/app/${oid}/dematt/your-details`, { scrollPosition: "signature" })
      } else {
        history.push(`/app/${oid}/trading/your-details`, { scrollPosition: "signature" })
      }
    }
    return () => {
      dispatch(clearSuccess())
    }
  }, [success, history, dispatch, oid])


  return (
    <ProgressbarLayout
      progressLeftText={
        history.location.pathname.includes('dematt') ? '69%' : '73%'
      }
      progressRightText={globalTimer}
      showIconHeader={false}
      progressPercent={history.location.pathname.includes('dematt') ? 69 : 73}
      iconTitle={
        history.location.pathname.includes('dematt') ? 'DEMAT' : 'TRADING'
      }
      title=''
      animationIn={history.action === 'PUSH' ? true : false}
      renderFooter={
        <RenderFooter
          backHandler={backHandler}
          loading={imageLoading || actionLoading}
          submitHandler={trim}
        />
      }
    >
      <SubLabelWrapper
        label={`Add Signature`}
        subLabel={capitalizeFirstLetterEntireWord(
          kycFormValues?.username ?? ''
        )}
        render={
          <Row>
            <Col
              span='24'
              className='text-right'
              style={{ marginTop: '-2.5rem' }}
            >
              <Button onClick={clear} className='clear-btn' shape='round'>
                Clear Signature
              </Button>
            </Col>
            <Col span='24'>
              <Card className='signature'>
                <SignatureCanvas
                  penColor='black'
                  ref={(ref) => {
                    setCanvasRef(ref)
                  }}
                  canvasProps={{ width: screenWidth, height: screenHeight }}
                />
              </Card>
            </Col>
          </Row>
        }
      />
    </ProgressbarLayout>
  )
}

export default PersonalSignature
