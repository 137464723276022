import Progressbar from "Components/Progressbar";

interface Props {
  title: string;
  progressPercent: number;
  progressLeftText: string;
  progressRightText: string;
  showSubTitle?: boolean;
  subTitle1?: string;
  subTitle2?: string;
  showTitle?: boolean;
}

const commonStyle = {
  marginRight: "5vw",
};

const DmatHeaderProgress = ({
  title,
  progressPercent,
  progressLeftText,
  progressRightText,
  showSubTitle,
  subTitle1,
  subTitle2,
  showTitle,
}: Props) => {
  return (
    <div className="d-flex w-100p flex-column flex-between h-100p">
      <div>
        <Progressbar
          percent={progressPercent}
          leftText={progressLeftText}
          rightText={progressRightText}
        />
      </div>
      {showSubTitle && (
        <>
          <div
            className="sb-text text-black text-right mt-1"
            style={commonStyle}
          >
            {subTitle1}
          </div>
          <div
            className="sb-text text-light-grey text-right"
            style={commonStyle}
          >
            {subTitle2}
          </div>
        </>
      )}
      {showTitle && (
        <div
          className="sb-text text-green text-right fw-800"
          style={commonStyle}
        >
          {title}
        </div>
      )}
    </div>
  );
};
DmatHeaderProgress.defaultProps = {
  showTitle: true,
  showSubTitle: false,
  subTitle1: "",
  subTitle2: "",
};
export default DmatHeaderProgress;
