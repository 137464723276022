import React, {
  Dispatch,
  SetStateAction,
  useEffect,
} from 'react';

import {
  Col,
  Form,
  FormInstance,
  Input,
  Row,
  Select,
  Typography,
} from 'antd';
import DatePicker from 'Components/CustomDatePicker';
import LabelWrapper from 'Components/LabelWrapper';
import SelectLabelDisplay from 'Components/SelectLabelDisplay';
import {
  genderOptions,
  relationshipOptions,
} from 'Constants/SelectDefaultOptions';
import dayjs from 'dayjs';
import NepaliDate from 'nepali-date-converter';
import { NepaliDatePicker } from 'nepali-datepicker-reactjs';
import { useSelector } from 'react-redux';
import { RootState } from 'Redux/RootReducer';
import { capitalizeFirstLetterEntireWord } from 'Utils/capitalizeFirstLetter';
import { disabledDobValidation } from 'Utils/DateValidation';
import { VALIDATIONS } from 'Utils/formValidationRules';
import converToNepalDate, { formEnglishDate } from 'Utils/NepaliDateHelper';

interface GeneralInfoProps {
    setFormValues: Dispatch<SetStateAction<any>>
    formValues: any
    form: FormInstance
}

export const GeneralInfo = ({ formValues, setFormValues, form }: GeneralInfoProps) => {
    //var
    const { kycFormValues, } =
        useSelector((state: RootState) => state?.protectedReducer)


    useEffect(() => {
        if (
            (kycFormValues)
        ) {

            let values: any = {}

            if (kycFormValues.minor) {
                values = {
                    ...kycFormValues?.guardian_details,
                    reference_number: kycFormValues?.reference_number ?? "",
                };
            } else {
                values = {
                    ...kycFormValues?.general_details,
                    reference_number: kycFormValues?.reference_number ?? "",

                }
            }


            let setValues: any = {
                username: kycFormValues.minor ? capitalizeFirstLetterEntireWord(kycFormValues?.guardian_details?.full_name) : capitalizeFirstLetterEntireWord(kycFormValues?.username),
                dob: values?.dob ? dayjs(values?.dob) : "",
                dob_np: values?.dob_np ?? "",
                gender: values?.gender || "",
                nationality: "Nepali",
                reference_number: values?.reference_number
                    ? values.reference_number.replace("+977", "").replace("-", "")
                    : "",

            }

            if (kycFormValues?.minor) {
                setValues = {
                    ...setValues,
                    relation_with_applicant: values.relation_with_applicant
                }
            }

            form.setFieldsValue(setValues)
            setFormValues((prevState: any) => ({
                ...prevState,
                ...setValues
            }))
        }
    }, [kycFormValues, form, setFormValues])


    const handleInputChange = (e: any) => {

        setFormValues({ ...formValues, [e.target.name]: e.target.value });

    };

    const handleSelectChange = (e: any, name: string) => {
        setFormValues({ ...formValues, [name]: e });

    };

    const handleDateChange = (date: any, dateString: string, name: string) => {
        if (name === "dob") {

            const dob_np = converToNepalDate(date);
            form.setFieldsValue({ dob: date, dob_np });
            setFormValues({ ...formValues, [name]: date, dob_np });
        }
        if (name === "issued_date") {

            const issued_np = converToNepalDate(date);
            form.setFieldsValue({ issued_date: date, issued_np });

            setFormValues({
                ...formValues,
                [name]: date,
                issued_np,
            });
        }
    };

    const handleNepaliDateChange2 = (date: string) => {
        if (date) {
            const englishDateObject = new NepaliDate(date).getAD();
            const englishDate = formEnglishDate(englishDateObject);
            form.setFieldsValue({ dob_np: date, dob: dayjs(englishDate) });
            setFormValues({
                ...formValues,
                dob_np: date,
                dob: dayjs(englishDate),
            });
        }
    };

    return (
        <Row gutter={[16, 16]} className='groupWrapper mt-2' >
            <Typography className={`r-text fw-500 text-light-black `}>General Information </Typography>
            <Col xs={24} lg={24} id='username'>
                <LabelWrapper
                    label="Full Name"
                    render={
                        <Form.Item rules={ kycFormValues.minor  ? VALIDATIONS.full_name : []}  name="username">
                            <Input
                                name="username"
                                onChange={handleInputChange}
                                bordered={false}
                                className="underlineInput"
                                disabled={kycFormValues.minor ? false : true}
                            />
                        </Form.Item>
                    }
                />
            </Col>
            <Col xs={24} lg={12} id='dob'>
                <LabelWrapper
                    label="Date of Birth"
                    render={
                        <Form.Item rules={VALIDATIONS.dob} name="dob">
                            <div className="datepicker-underline">
                                <DatePicker
                                    onChange={(date: any, dateString: string) =>
                                        handleDateChange(date, dateString, "dob")
                                    }
                                    placeholder=""
                                    value={formValues.dob ? formValues.dob : null}
                                    bordered={false}
                                    inputReadOnly={false}
                                    disabledDate={disabledDobValidation}
                                    className="w-100p pl-0"
                                />
                            </div>
                        </Form.Item>
                    }
                />
            </Col>
            <Col xs={24} lg={12} id='dob_np'>
                <LabelWrapper
                    label="Date of Birth (BS)"
                    render={
                        <Form.Item rules={VALIDATIONS.dob} name="dob_np">
                            <div className="nepali-calendar-wrapper">
                                <div className="nepali-calendar-value">
                                    {formValues.dob_np}
                                </div>

                                <NepaliDatePicker
                                    className="nepali-calendar"
                                    onSelect={(date: any) => {
                                        handleNepaliDateChange2(date);
                                    }}
                                    value={formValues.dob_np}
                                />
                            </div>
                        </Form.Item>
                    }
                />
            </Col>
            <Col xs={24} lg={12} id='nationality'>
                <LabelWrapper
                    label="Nationality"
                    render={
                        <Form.Item
                            name="nationality"
                            rules={VALIDATIONS.nationality}
                            initialValue={formValues.nationality}
                        >
                            <Input
                                name="nationality"
                                onChange={handleInputChange}
                                bordered={false}
                                className="underlineInput"
                            />
                        </Form.Item>
                    }
                />
            </Col>
            <Col xs={24} lg={12} id='gender'>
                <LabelWrapper
                    label="Gender"
                    render={
                        <Form.Item
                            name="gender"
                            rules={VALIDATIONS.gender}
                            initialValue={formValues.gender}
                        >
                            <Select
                                className="underlineSelect"
                                onChange={(e) => handleSelectChange(e, "gender")}

                            >
                                {genderOptions.map((each: any, index: number) => {
                                    return (
                                        <Select.Option key={index} value={each.value}>
                                            {each.label}
                                        </Select.Option>
                                    );
                                })}
                            </Select>
                        </Form.Item>
                    }
                />
            </Col>

            <Col xs={24} lg={12} id='reference_number'>
                <LabelWrapper
                    label="Alternate Phone Number"
                    render={
                        <Form.Item
                            name="reference_number"
                            rules={VALIDATIONS.reference_number}
                            initialValue={formValues.reference_number}
                        >
                            <Input
                                name="reference_number"
                                type="number"
                                bordered={false}
                                className="underlineInput"
                                onChange={handleInputChange}
                            />
                        </Form.Item>
                    }
                />
            </Col>
            <Col xs={24} lg={12} id='relation_with_applicant'>
                {kycFormValues.minor &&

                    <LabelWrapper
                        label="Relationship with Applicant"
                        render={
                            <Form.Item
                                name="relation_with_applicant"
                                rules={VALIDATIONS.relation}
                                initialValue={formValues.relation_with_applicant}
                            >
                                <Select
                                    onChange={(e) =>
                                        handleSelectChange(e, "relation_with_applicant")
                                    }
                                    optionLabelProp="customlabel"
                                    className="underlineSelect"
                                >
                                    {relationshipOptions.map((each: any, index: number) => {
                                        return (
                                            <Select.Option
                                                key={index}
                                                value={each.value!}
                                                customlabel={<div>{each.label}</div>}
                                            >
                                                <SelectLabelDisplay
                                                    label={each.label}
                                                    value={each.value}
                                                    currentVal={formValues.relation_with_applicant}
                                                />
                                            </Select.Option>
                                        );
                                    })}
                                </Select>
                            </Form.Item>
                        }
                    />
                }
            </Col>
        </Row>
    )
}
