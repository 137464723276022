import { Button } from "antd";
import { signOutApi } from "Api/Auth";

interface Props {}

//Helper route
//Direct route to logout from the app

const LogoutTest = (props: Props) => {
  const logoutHandler = () => {
    alert("logged out");
    signOutApi();
    localStorage.removeItem("userId");
    localStorage.removeItem("user-logged-in");
  };

  return (
    <div
      className="d-flex h-100p w-100p flex-center align-items-center"
      style={{ height: "100vh" }}
    >
      <Button onClick={logoutHandler}>Logout</Button>
    </div>
  );
};

export default LogoutTest;
