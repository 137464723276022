/**
 * 
 * @param users 
 * @returns stats for each type of form
 */
export default function findStats(users: any) {
  let dematCount = 0;
  let meroshareCount = 0;
  let tradingCount = 0;
  if (users) {
    users.map((each: any) => {
      if (each.form !== undefined) {
        if (each?.form?.demat) {
          dematCount = dematCount + 1;
        }
        if (each?.form?.meroshare) {
          meroshareCount = meroshareCount + 1;
        }
        if (each?.form?.trading) {
          tradingCount = tradingCount + 1;
        }
      }
    });
  }

  return {
    dematCount,
    meroshareCount,
    tradingCount,
  };
}
