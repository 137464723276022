import dayjs from 'dayjs';

import { Action } from './Action';
import {
  ADD_CREATE_USER_FAILURE,
  ADD_CREATE_USER_REQUEST,
  ADD_CREATE_USER_SUCCESS,
  ADD_KYC_DETAIL_FAILURE,
  ADD_KYC_DETAIL_REQUEST,
  ADD_KYC_DETAIL_SUCCESS,
  ADD_PDF_READER_REQUEST,
  CLEAR_PDF_SUCCESS,
  CLEAR_SUCCESS,
  COMPLETED_USER_PDF_FAILURE,
  COMPLETED_USER_PDF_REQUEST,
  COMPLETED_USER_PDF_SUCCESS,
  FORCE_RELOAD_REQUEST,
  GET_DEMATPDF_FAILURE,
  GET_DEMATPDF_REQUEST,
  GET_DEMATPDF_SUCCESS,
  GET_KYC_DETAIL_FAILURE,
  GET_KYC_DETAIL_REQUEST,
  GET_KYC_DETAIL_SUCCESS,
  GET_PAYMENT_DETAILS,
  GET_PAYMENT_DETAILS_FAILURE,
  GET_PAYMENT_DETAILS_SUCCESS,
  GET_TRADING_PDFDATA_FAILURE,
  GET_TRADING_PDFDATA_REQUEST,
  GET_TRADING_PDFDATA_SUCCESS,
  PAYMENT_FAILURE,
  PAYMENT_INIT,
  PAYMENT_SUCCESS,
  REQUEST_KHALTI_URL,
  REQUEST_KHALTI_URL_FAILURE,
  REQUEST_KHALTI_URL_SUCCESS,
  RESET_COMPLETED_PDF_LINK,
  RESET_CONTINUE_FORM_ID,
  RESET_CREATE_USER_SUCCESS,
  RESET_GLOBAL_TIMER,
  RESET_MAIN_SIGNATURE,
  RESET_PAYMENT_DETAILS,
  RESET_PAYMENT_YEARS,
  RESET_PDF_LINK,
  SCHEDULE_DATE_TIME,
  SCHEDULE_DATE_TIME_FAILURE,
  SCHEDULE_DATE_TIME_SUCCESS,
  SET_CURRENT_USER,
  SET_GLOBAL_TIMER,
  SET_INITIAL_GLOBAL_TIMER,
  SET_KYC_FORM_VALUES,
  SET_NEW_KYC_FORM_VALUES,
  SET_PAYMENT_YEARS,
  SIGNATURE_BACKGOUND_REMOVER_SUCCESS,
  TOGGLE_IMAGE_LOADER,
  TOGGLE_IMAGE_LOADER2,
  UPDATE_KYC_DETAIL_FAILURE,
  UPDATE_KYC_DETAIL_FAILURE_2,
  UPDATE_KYC_DETAIL_FAILURE_WITHOUT_FLAG,
  UPDATE_KYC_DETAIL_REQUEST,
  UPDATE_KYC_DETAIL_REQUEST_2,
  UPDATE_KYC_DETAIL_REQUEST_WITHOUT_FLAG,
  UPDATE_KYC_DETAIL_SUCCESS,
  UPDATE_KYC_DETAIL_SUCCESS_2,
  UPDATE_KYC_DETAIL_SUCCESS_WITHOUT_FLAG,
} from './Constant';

interface InitialState {
  loading: boolean;
  actionLoading: boolean;
  error: string;
  message: string;
  success?: boolean;
  kycDetails: any;
  kycFormValues: any;
  paymentYears: number;
  totalPayment: number;
  pdfLoading: boolean;
  pdfSuccess: boolean;
  pdfLink: string;
  completedUserPdfLink: string;
  completedUserPdfName?: string;
  currentUserId: string;
  forceReloadCount?: number;
  globalTimer: number;
  imageLoader: boolean;
  imageLoader2: boolean;
  initLoading?: boolean;
  createUserSuccess: boolean;
  pdfReaderLoading: boolean;
  pdfReaderSuccess: boolean;
  bankScanLoading: boolean;
  mainSignature: string;
  continueFormId: "";
  paymentData?: any;
  requestingKhaltiUrl: boolean;
  requestKhaltiUrl: any;
  paymentDataLoading: boolean,
  paymentId: string
}

const ProtectedInitialState: InitialState = {
  error: "",
  loading: false,
  actionLoading: false,
  message: "",
  success: false,
  kycDetails: {},
  kycFormValues: {},
  paymentYears: 1,
  totalPayment: 0,
  pdfLoading: false,
  pdfSuccess: false,
  pdfLink: "",
  completedUserPdfLink: "",
  completedUserPdfName: "",
  currentUserId: "",
  forceReloadCount: 0,
  globalTimer: 0,
  imageLoader: false,
  initLoading: false,
  createUserSuccess: false,
  pdfReaderLoading: false,
  pdfReaderSuccess: false,
  imageLoader2: false,
  bankScanLoading: false,
  mainSignature: "",
  continueFormId: "",
  paymentData: {},
  requestingKhaltiUrl: false,
  requestKhaltiUrl: {},
  paymentDataLoading: false,
  paymentId: ""
};

export default function protectedReducer(
  state = ProtectedInitialState,
  action: Action
): InitialState {
  switch (action.type) {
    case FORCE_RELOAD_REQUEST: {
      return {
        ...state,
        forceReloadCount: state.forceReloadCount! + 1,
      };
    }

    case ADD_KYC_DETAIL_REQUEST: {
      return {
        ...state,
        error: "",
        success: false,
        actionLoading: true,
      };
    }
    case ADD_KYC_DETAIL_SUCCESS: {
      return {
        ...state,
        error: "",
        success: true,
        actionLoading: false,
        message: "Successfully added kyc details",
      };
    }
    case ADD_KYC_DETAIL_FAILURE: {
      return {
        ...state,
        actionLoading: false,
        success: false,
        error: action.payload.message,
        message: "",
      };
    }
    case UPDATE_KYC_DETAIL_REQUEST: {
      return {
        ...state,
        error: "",
        success: false,
        actionLoading: true,
      };
    }

    case UPDATE_KYC_DETAIL_SUCCESS: {
      return {
        ...state,
        error: "",
        success: true,
        actionLoading: false,
        message: "Successfully added kyc details",
      };
    }

    case UPDATE_KYC_DETAIL_FAILURE: {
      return {
        ...state,
        actionLoading: false,
        success: false,
        error: action.payload.message,
        message: "",
      };
    }
    case UPDATE_KYC_DETAIL_REQUEST_2: {
      return {
        ...state,
        error: "",
        // success: false,
        success: false,
        actionLoading: true,
        continueFormId: "",
      };
    }
    case UPDATE_KYC_DETAIL_SUCCESS_2: {
      //find index of that id
      const indexNumber = state?.kycDetails?.others.findIndex(
        (item: any) => item?.id === action?.payload?.id
      );
      const arrayOfKycOthers = [...state?.kycDetails?.others];
      arrayOfKycOthers[indexNumber] = action.payload;
      return {
        ...state,
        error: "",
        actionLoading: false,
        continueFormId: action.payload.id,
        success: false,
        message: "Successfully added kyc details",
        kycDetails: {
          ...state?.kycDetails,
          others: arrayOfKycOthers,
        },
      };
    }
    case UPDATE_KYC_DETAIL_FAILURE_2: {
      return {
        ...state,
        actionLoading: false,
        success: false,
        error: action.payload.message,
        message: "",
        continueFormId: "",
      };
    }
    case RESET_CONTINUE_FORM_ID: {
      return {
        ...state,
        continueFormId: "",
      };
    }
    case UPDATE_KYC_DETAIL_REQUEST_WITHOUT_FLAG: {
      return {
        ...state,
        bankScanLoading: true,
      };
    }
    case UPDATE_KYC_DETAIL_SUCCESS_WITHOUT_FLAG: {
      return {
        ...state,
        bankScanLoading: false,
      };
    }
    case UPDATE_KYC_DETAIL_FAILURE_WITHOUT_FLAG: {
      return {
        ...state,
        bankScanLoading: false,
      };
    }

    case GET_KYC_DETAIL_REQUEST: {
      return {
        ...state,
        error: "",
        loading: true,
        initLoading: true,
      };
    }
    case GET_KYC_DETAIL_SUCCESS: {
      return {
        ...state,
        error: "",
        loading: false,
        kycDetails: action.payload.data,
        initLoading: false,

        message: "Successfully fetched kyc details",
      };
    }
    case GET_KYC_DETAIL_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.payload.message,
        message: "",
        initLoading: false,
      };
    }

    case SET_KYC_FORM_VALUES: {
      return {
        ...state,
        kycFormValues: { ...state.kycFormValues, ...action.payload },
      };
    }
    case SET_NEW_KYC_FORM_VALUES: {
      return {
        ...state,
        kycFormValues: { ...action.payload },
      };
    }

    case PAYMENT_INIT: {
      return {
        ...state,
        error: "",
        success: false,
        actionLoading: true,
        paymentData: {},
      };
    }
    case PAYMENT_SUCCESS: {
      return {
        ...state,
        error: "",
        actionLoading: false,
        success: true,
        paymentId: action?.payload?.data.payment,
        message: "Payment successfuly",
      };
    }
    case PAYMENT_FAILURE: {
      return {
        ...state,
        actionLoading: false,
        success: false,
        paymentData: {},
        error: action.payload.message,
        message: "",
      };
    }

    case GET_DEMATPDF_REQUEST: {
      return {
        ...state,
        error: "",
        pdfLoading: true,
        pdfSuccess: false,
        pdfLink: "",
      };
    }

    case GET_DEMATPDF_SUCCESS: {
      return {
        ...state,
        error: "",
        pdfLoading: false,
        pdfSuccess: true,
        pdfLink: action.payload.data,
      };
    }

    case GET_DEMATPDF_FAILURE: {
      return {
        ...state,
        pdfLoading: false,
        error: action.payload.message,
        pdfSuccess: false,
        pdfLink: "",
      };
    }

    case CLEAR_PDF_SUCCESS: {
      return {
        ...state,
        error: "",
        pdfSuccess: false,
      };
    }
    case CLEAR_SUCCESS: {
      return {
        ...state,
        error: "",
        success: false,
      };
    }

    case SET_CURRENT_USER: {
      return {
        ...state,
        currentUserId: action.payload.userId,
      };
    }

    case ADD_CREATE_USER_REQUEST: {
      return {
        ...state,
        actionLoading: true,
        createUserSuccess: false,
      };
    }
    case ADD_CREATE_USER_SUCCESS: {
      return {
        ...state,
        actionLoading: false,
        createUserSuccess: true,
      };
    }
    case ADD_CREATE_USER_FAILURE: {
      return {
        ...state,
        actionLoading: false,
        createUserSuccess: false,
      };
    }
    case RESET_CREATE_USER_SUCCESS: {
      return {
        ...state,
        createUserSuccess: false,
      };
    }
    case SET_GLOBAL_TIMER: {
      return {
        ...state,
        globalTimer: state.globalTimer! + 1,
      };
    }

    case SET_INITIAL_GLOBAL_TIMER: {
      return {
        ...state,
        globalTimer: action.payload,
      };
    }

    case RESET_GLOBAL_TIMER: {
      return {
        ...state,
        globalTimer: 0,
      };
    }
    case ADD_PDF_READER_REQUEST: {
      return {
        ...state,
        actionLoading: true,
        pdfReaderLoading: true,
      };
    }

    case TOGGLE_IMAGE_LOADER: {
      return {
        ...state,
        imageLoader: action.payload,
      };
    }

    case TOGGLE_IMAGE_LOADER2: {
      return {
        ...state,
        imageLoader2: action.payload,
      };
    }

    case GET_TRADING_PDFDATA_REQUEST: {
      return {
        ...state,
        pdfReaderLoading: true,
        pdfReaderSuccess: false,
      };
    }
    //autofill trading pdf data
    case GET_TRADING_PDFDATA_SUCCESS: {
      return {
        ...state,
        pdfReaderLoading: false,
        pdfReaderSuccess: true,

        kycFormValues: {
          ...state.kycFormValues,
          reference_number: action.payload?.phone ?? "",
          trading: {
            ...state?.kycFormValues?.trading,
            boid: action?.payload?.boid ?? "",
            email: action.payload?.email ?? "",
          },
          general_details: {
            ...(state?.kycFormValues?.general_details ?? ""),
            // full_name:
            //   state.kycFormValues?.username ?? action?.payload?.full_name ?? "",
            dob: action?.payload?.dob ?? dayjs().format("YYYY-MM-DD"),
            gender: action?.payload?.gender ?? "",
            issued_district: action?.payload?.issued_district ?? "",
            citizenship_id: action?.payload?.citizenship_id ?? "",
          },
          minor_details: {
            ...(state?.kycFormValues?.minor_details ?? ""),
            // name:
            //   state.kycFormValues?.username ?? action?.payload?.full_name ?? "",
            dob: action?.payload?.dob ?? dayjs().format("YYYY-MM-DD"),
            gender: action?.payload?.gender ?? "",
            issued_district: action?.payload?.issued_district ?? "",
            birth_certificate_number: action?.payload?.citizenship_id ?? "",
          },
          family_details: {
            ...(state?.kycFormValues?.family_details ?? ""),
            father_name: action?.payload?.father_name ?? "",
            mother_name: action?.payload?.mother_name ?? "",
            grandfather_name: action?.payload?.grandfather_name ?? "",
            spouse_name: action?.payload?.spouse_name ?? "",
            in_laws: [],
          },
          trading_bank_details: {
            ...state?.kycFormValues?.trading_bank_details,
            bank_name: action.payload?.bank_name ?? "",
            branch: action.payload?.branch ?? "",
            account_number: action?.payload?.account_number ?? "",
            account_type: action.payload?.account_type ?? "",
          },
        },
      };
    }

    case GET_TRADING_PDFDATA_FAILURE: {
      return {
        ...state,
        pdfReaderLoading: false,
        pdfReaderSuccess: false,
      };
    }

    case COMPLETED_USER_PDF_REQUEST: {
      return {
        ...state,
        pdfLoading: true,
        completedUserPdfLink: "",
        completedUserPdfName: `${action?.payload?.userName ?? "Form Data"} - ${action?.payload?.target ?? ""
          }`,
      };
    }
    case COMPLETED_USER_PDF_SUCCESS: {
      return {
        ...state,
        pdfLoading: false,
        completedUserPdfLink: action.payload,
      };
    }
    case COMPLETED_USER_PDF_FAILURE: {
      return {
        ...state,
        pdfLoading: false,
        completedUserPdfLink: "",
      };
    }
    case RESET_PDF_LINK: {
      return {
        ...state,
        pdfLink: "",
      };
    }
    case RESET_COMPLETED_PDF_LINK: {
      return {
        ...state,
        completedUserPdfName: "",
        completedUserPdfLink: "",
      };
    }

    case SIGNATURE_BACKGOUND_REMOVER_SUCCESS: {
      return {
        ...state,
        mainSignature: action.payload,
      };
    }

    case RESET_MAIN_SIGNATURE: {
      return {
        ...state,
        mainSignature: "",
      };
    }

    case SET_PAYMENT_YEARS: {
      return {
        ...state,
        paymentYears: action.payload,
      };
    }
    case RESET_PAYMENT_YEARS: {
      return {
        ...state,
        paymentYears: 1,
      };
    }
    case SCHEDULE_DATE_TIME: {
      return {
        ...state,
        error: "",
        success: false,
        actionLoading: true,
      };
    }
    case SCHEDULE_DATE_TIME_SUCCESS: {
      return {
        ...state,
        error: "",
        success: true,
        actionLoading: false,
      };
    }
    case SCHEDULE_DATE_TIME_FAILURE: {
      return {
        ...state,
        error: "",
        success: false,
        actionLoading: false,
      };
    }

    case REQUEST_KHALTI_URL: {
      return {
        ...state,
        requestingKhaltiUrl: true,
      };
    }

    case REQUEST_KHALTI_URL_SUCCESS: {
      return {
        ...state,
        requestingKhaltiUrl: false,
        requestKhaltiUrl: action.payload,
      };
    }

    case REQUEST_KHALTI_URL_FAILURE: {
      return {
        ...state,
        requestingKhaltiUrl: false,
        requestKhaltiUrl: {},
      };
    }

    case GET_PAYMENT_DETAILS: {
      return {
        ...state,
        paymentDataLoading: true
      }
    }

    case GET_PAYMENT_DETAILS_SUCCESS: {
      return {
        ...state,
        paymentDataLoading: false,
        paymentData: action.payload

      }
    }
    case GET_PAYMENT_DETAILS_FAILURE: {
      return {
        ...state,
        paymentDataLoading: false,
        paymentData: {}

      }
    }

    case RESET_PAYMENT_DETAILS: 
    return {
      ...state,
      paymentData: {}
    }

    default: {
      return state;
    }
  }
}
