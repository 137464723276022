import {
  useEffect,
  useState,
} from 'react';

import { Form } from 'antd';
import { useSetKycFormValue } from 'CustomHooks/useSetKycFormValue';
import { IPageProps } from 'Interfaces/Page';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import {
  clearSuccess,
  setKycFormValues,
  updateKycDetailRequest,
} from 'Redux/Protected/Action';
import { RootState } from 'Redux/RootReducer';
import RenderFooter from 'Views/Dmat/components/RenderFooter';
import ProgressbarLayout from 'Views/Dmat/Layout/ProgressbarLayout';

import { Beneficiary } from './Component/Beneficiary/Beneficiary';
import { EngPolitics } from './Component/EngPolitics/EngPolitics';
import { Guilt } from './Component/Guilt/Guilt';
import { InPolitics } from './Component/InPolitics/InPolitics';
import { KarjaSuchana } from './Component/KarjaSuchana/KarjaSuchana';
import { Rent } from './Component/Rent/Rent';
import { Sampati } from './Component/Sampati/Sampati';

const InitialValues = {
    owner_full_name: "",
    owner_mobile: "",
    politics_full_name: "",
    politics_relations: "",
    beneficiary_full_name: "",
    beneficiary_relation: "",
    samp_sudhi_2064: ""
};

const TradingAdditionalInfo = ({ history, pageNumber }: IPageProps) => {
    //var
    const { kycFormValues, actionLoading, success, globalTimer } =
        useSelector((state: RootState) => state?.protectedReducer);

    //hooks
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const { oid } = useSetKycFormValue()

    //state
    const [onRent, setOnRent] = useState(false)
    const [karjaKalosuchi, setKarjaKalosuchi] = useState(false)
    const [inPolitics, setInpolitics] = useState(false)
    const [engagedInPolitics, setEngagedInPolitics] = useState(false)
    const [isBeneficiary, setIsBeneficiary] = useState(false)
    const [isGuilty, setIsGuilty] = useState(false)
    const [formValues, setFormValues] = useState(InitialValues);


    useEffect(() => {
        if (success) {
            history.push(`/app/${oid}/trading/terms/1`);
        }

        return () => {
            dispatch(clearSuccess());
        };
    }, [success, history, dispatch, oid]);


    const backHandler = () => {

        history.goBack()
    };

    const submitHandler = () => {
        form
            .validateFields()
            .then((values: any) => {
                submitForm();
            })
            .catch((errorInfo: any) => {
                const element = document.getElementById(`${errorInfo.errorFields[0].name[0]}`)
                element?.scrollIntoView({ behavior: "smooth" })
            });
    };

    const submitForm = () => {

        let submitValue: any = {
            karja_kalosuchi: karjaKalosuchi,
            poli_high_pos: inPolitics,
            benificiary: isBeneficiary,
            guilt_case: isGuilty,
            rent: onRent,
            eng_poli_high_pos: engagedInPolitics
        }

        if (onRent) {
            submitValue = {
                ...submitValue,
                rent_details: {
                    full_name: formValues.owner_full_name,
                    mobile: `+977${formValues.owner_mobile
                        .replace("+977", "")
                        .replace("-", "")}`,
                },
            }
        }
        if (engagedInPolitics) {
            submitValue = {
                ...submitValue,
                engaged_details: {
                    full_name: formValues.politics_full_name,
                    relation: formValues.politics_relations,
                },
            }
        }
        if (isBeneficiary) {
            submitValue = {
                ...submitValue,
                benificiary_details: {
                    full_name: formValues.beneficiary_full_name,
                    relation: formValues.beneficiary_relation,
                },
            }
        }

        if (formValues.samp_sudhi_2064) {
            submitValue = {
                ...submitValue,
                samp_sudhi_2064: formValues.samp_sudhi_2064
            }
        }

        dispatch(
            setKycFormValues({
                ...kycFormValues,
                trading: {
                    ...kycFormValues?.trading,
                    ...submitValue
                },
            })
        );

        dispatch(
            updateKycDetailRequest({
                trading: submitValue,
            })
        );

    };
    return (
        <ProgressbarLayout
            progressLeftText={"85%"}
            iconTitle={
                history.location.pathname.includes("dematt") ? "DEMAT" : "TRADING"
            }
            progressRightText={globalTimer}
            progressPercent={85}
            title=""
            animationIn={history.action === "PUSH" ? true : false}
            renderFooter={
                <RenderFooter
                    loading={actionLoading}
                    backHandler={backHandler}
                    submitHandler={submitHandler}
                    showForwardBtn={true}
                />
            }
            path='additional-info'
        >
            <Form

                form={form}
                layout='vertical'
                name='basic'
                onFinish={submitHandler}
            >
                <KarjaSuchana karjaKalosuchi={karjaKalosuchi} setKarjaKalosuchi={setKarjaKalosuchi} />
                <Rent onRent={onRent} setOnRent={setOnRent} formValues={formValues} setFormValues={setFormValues} form={form} />
                <InPolitics inPolitics={inPolitics} setInpolitics={setInpolitics} />
                <EngPolitics form={form} engagedInPolitics={engagedInPolitics} setEngagedInPolitics={setEngagedInPolitics} formValues={formValues} setFormValues={setFormValues} />
                <Beneficiary form={form} isBeneficiary={isBeneficiary} setIsBeneficiary={setIsBeneficiary} formValues={formValues} setFormValues={setFormValues} />
                <Guilt isGuilty={isGuilty} setIsGuilty={setIsGuilty} />
                <Sampati form={form} formValues={formValues} setFormValues={setFormValues} />

            </Form>

        </ProgressbarLayout>
    );
};

export default TradingAdditionalInfo;
