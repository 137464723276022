export function findGender(genderString: string): string {
  switch (genderString) {
    case "M":
      return "Male";
    case "F":
      return "Female";
    case "0":
      return "Others";
    default:
      return "";
  }
}
