const BirthMonthNumber: any = {
  Jan: "01",
  Feb: "02",
  Mar: "03",
  Apr: "04",
  May: "05",
  Jun: "06",
  Jul: "07",
  Aug: "08",
  Sep: "09",
  Oct: "10",
  Nov: "11",
  Dec: "12",
  JAN: "01",
  FEB: "02",
  MAR: "03",
  APR: "04",
  MAY: "05",
  JUN: "06",
  JUL: "07",
  AUG: "08",
  SEP: "09",
  OCT: "10",
  NOV: "11",
  DEC: "12",
  "DEC.": "12",
  "01": "01",
  "02": "02",
  "03": "03",
  "04": "04",
  "05": "05",
  "06": "06",
  "07": "07",
  "08": "08",
  "09": "09",
  "10": "10",
  "11": "11",
  "12": "12",
};


/**
 * 
 * @param extractedKeyValueData response?.text?.keyValues from textract
 * @returns object containing name,gender,citizenship and dob(null currently)
 */
export const getCitizenshipOcrData = (extractedKeyValueData: any) => {
  let full_name = extractedKeyValueData.filter((each: any) =>
    each.key.includes("Full Name")
  );
  let citizenship_number = extractedKeyValueData.filter((each: any) =>
    each.key.includes("Certificate")
  );
  let citizenship_number_value = citizenship_number?.length
    ? citizenship_number[0].value?.text
    : "";
  let gender = extractedKeyValueData.filter((each: any) =>
    each.key.includes("Sex")
  );
  let genderValue = gender?.length ? gender[0].value?.text : null;
  let requiredGenderValue = genderValue
    ? genderValue.includes("F")
      ? "Female"
      : genderValue.includes("M") && !genderValue.includes("F")
      ? "Male"
      : "Others"
    : null;

  let day = extractedKeyValueData.filter((each: any) =>
    each.key.includes("Day")
  );
  let dayValue = day?.length ? day[0]?.value?.text : "";
  let month = extractedKeyValueData.filter((each: any) =>
    each?.key?.includes("Month")
  );
  let monthValue =
    month?.length &&
    Object.keys(BirthMonthNumber).includes(month[0].value?.text)
      ? BirthMonthNumber[month[0]?.value?.text]
      : "";
  let year = extractedKeyValueData.filter((each: any) =>
    each.key.includes("Year")
  );
  let yearValue = year?.length ? year[0].value?.text : "";

  let dobVal = extractedKeyValueData.filter((each: any) =>
    each.key.includes("Date")
  );
  let dobValue = dobVal?.length ? dobVal[0].value?.text : "";
  if (dobValue) {
    dobValue = dobValue.replace("Year", "");
    yearValue = dobValue.slice(0, 5);

    if (dobValue.includes("Month")) {
      let z = dobValue.substring(dobValue.indexOf("Month") + 6);
      if (Object.keys(BirthMonthNumber).includes(z)) {
        monthValue = z ? BirthMonthNumber[z] : "";
      }
    }
  }
  //TODO : need a better algorithm here
  let requiredBirthDate =
    yearValue.trim() +
    (monthValue ? "-" : "") +
    monthValue +
    (dayValue ? "-" : "") +
    (dayValue?.length === 1 ? "0" + dayValue : dayValue);

  const finalPayload = {
    full_name: full_name?.length ? full_name[0]?.value?.text : "", //use only for guardian details
    gender: requiredGenderValue,
    citizenship_id: citizenship_number_value,
    dob: "", //need a better algortihm
  };
  return finalPayload;
};
