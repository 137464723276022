// @ts-ignore:next-line
import Compress from "compress.js";
import { RcFile } from "antd/lib/upload";

export async function resizeImage(file: any): Promise<RcFile> {
  // await delay(5000);
  const compress = new Compress();

  const resizedImage = await compress.compress([file], {
    size: 1, // the max size in MB, defaults to 2MB
    quality: 0.9, // the quality of the image, max is 1,
    maxWidth: 600, // the max width of the output image, defaults to 1920px
    maxHeight: 600, // the max height of the output image, defaults to 1920px
    resize: true, // defaults to true, set false if you do not want to resize the image width and height
  });
  const img = resizedImage[0];
  const base64str = img.data;
  const imgExt = img.ext;
  const resizedFiile = Compress.convertBase64ToFile(base64str, imgExt);
  var finalFile = new File([resizedFiile], file?.name ?? "Imagefile", {
    lastModified: file?.lastModified ?? Date.now(),
    type: file?.type,
  });
  return resizedFiile ?? file;
}
