import { useEffect } from "react";
import { Route, RouteChildrenProps, Switch, Redirect } from "react-router";
import GeneralDetails from "../Views/Auth/GeneralDetails";
import OtpVerification from "../Views/Auth/OtpVerification";
export const AuthRoute = (props: RouteChildrenProps) => {
  useEffect(() => {
    if (localStorage.getItem("user-logged-in") === "true") {
      window.location.replace("/app/profile");
    }
  }, []);
  return (
    <div style={{ paddingInline: "5vh" }}>
      <Switch>
        <Redirect
          exact
          from={`${props.match?.path}/`}
          to={`${props.match?.path}/general-details`}
        />
        <Route
          path={`${props.match?.path}/general-details`}
          exact
          component={GeneralDetails}
        />
        <Route
          path={`${props.match?.path}/otp-verification`}
          exact
          component={OtpVerification}
        />
      </Switch>
    </div>
  );
};

export default AuthRoute;
