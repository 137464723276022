import { API } from "aws-amplify";

export const getAddressApi = () => {
  const url = `search/address`;
  return API.get("kycApi", url, {});
};

export const getBankApi = () => {
  const url = `search/bank`;
  return API.get("kycApi", url, {});
};

export const getOrganizationApi = () => {
  const url = `search/organization`;
  return API.get("kycApi", url, {});
};

export const getPaymentRateApi = () => {
  const url = `search/rate`;
  return API.get("kycApi", url, {});
};

export const getBrokerApi = () => {
  const url = `search/broker`;
  return API.get("kycApi", url, {});
};
