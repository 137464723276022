import { BANK_KEYWORD_ARRAY_NAMES } from "./bankKeywords";

/**
 * 
 * @param wordsArraywithObject response.text.words from textract 
 * @returns bankName
 */
export function findBankName2(wordsArraywithObject: Array<any>) {
  const wordsArray = wordsArraywithObject.map((itm: any) => itm.text);
  const CAPS_WORDS_ARRAY = JSON.parse(JSON.stringify(wordsArray).toUpperCase());
  let bankName = "";

  BANK_KEYWORD_ARRAY_NAMES.every((BANK_ARRAY: Array<any>) => {
    if (bankName !== "") return false;
    let count = 0;
    BANK_ARRAY.map((each: string) => {
      if (CAPS_WORDS_ARRAY.includes(each.toUpperCase())) {
        count = count + 1;
      }
      if (count >= 2) {
        bankName = BANK_ARRAY[BANK_ARRAY.length - 1];
        return;
      }
    });
    return true;
  });
  return bankName;
}
