import { CheckCircleFilled } from "@ant-design/icons";
interface Props {
  label: string;
  active: boolean;
  onClick: () => void;
}

const OutlineButton = ({ label, active, onClick }: Props) => {
  return (
    <div
      className={`outlineBtn-checkbox ${active && "outlineBtn-checkboxActive"}`}
      onClick={onClick}
    >
      <div className="d-flex h-100p w-100p flex-between align-items-center">
        <div></div>
        <div className={`text-grey ${active && "fw-500"}`}>{label}</div>
        <div>{active && <CheckCircleFilled className="text-green" />}</div>
      </div>
    </div>
  );
};

export default OutlineButton;
