import React, {
  useEffect,
  useState,
} from 'react';

import {
  AutoComplete,
  Col,
  Form,
  Input,
  Row,
  Select,
  Spin,
  Typography,
} from 'antd';
import LabelWrapper from 'Components/LabelWrapper';
import SelectLabelDisplay from 'Components/SelectLabelDisplay';
import {
  businessTypeOptions,
  designationOptions,
  occupationOptions,
} from 'Constants/SelectDefaultOptions';
import { useSetKycFormValue } from 'CustomHooks/useSetKycFormValue';
import { IPageProps } from 'Interfaces/Page';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import {
  clearSuccess,
  setKycFormValues,
  updateKycDetailRequest,
} from 'Redux/Protected/Action';
import { RootState } from 'Redux/RootReducer';
import { VALIDATIONS } from 'Utils/formValidationRules';
import RenderFooter from 'Views/Dmat/components/RenderFooter';
import ProgressbarLayout from 'Views/Dmat/Layout/ProgressbarLayout';

import { SearchOutlined } from '@ant-design/icons';

const initialFormValues: any = {
    occupation_type: "",
    phone: "",
    organization: "",
    business_type: "",
    designation: ""
}

export const OccupationDetails = ({ history, pageNumber }: IPageProps) => {

    //var
    const { kycFormValues, success, actionLoading, globalTimer, } =
        useSelector((state: RootState) => state?.protectedReducer)
    const { orgDetails, organizationLoading } = useSelector(
        (state: RootState) => state.staticReducer
    );

    //hooks
    const [form] = Form.useForm();
    const dispatch = useDispatch()
    const { oid } = useSetKycFormValue()

    //state
    const [formValues, setFormValues] = useState(initialFormValues)
    const [orgState, setOrgState] = useState<any>([]);
    const { organization } = formValues;

    useEffect(() => {
        if (success) { //success handler
            if (history.location.pathname.includes("dematt")) {

                history.push(`/app/${oid}/dematt/financial`)
            } else {
                history.push(`/app/${oid}/trading/financial`)

            }
        }
        return () => {
            dispatch(clearSuccess())
        };
    }, [success]);


    useEffect(() => { //set organization list
        if (organization) {
            let tempState = [];
            if (/\S/.test(organization!) && organization!.length >= 3) {
                tempState = orgDetails.filter((item: any) => {
                    if (item.info.toLowerCase().includes(organization!.toLowerCase())) {
                        return item;
                    }
                });
            } else {
                tempState = [];
            }

            setOrgState(tempState);
        }
    }, [organization]);

    useEffect(() => { //set occupation values
        if (kycFormValues && kycFormValues.occupation && Object.keys(kycFormValues?.occupation).length > 0) {
            const finalValues = {
                occupation_type: kycFormValues?.occupation?.occupation_type ?? "",
                phone: kycFormValues?.occupation?.phone
                    ? kycFormValues?.occupation.phone.replace("+977", "").replace("-", "")
                    : "",
                business_type: kycFormValues?.occupation?.business_type ?? "",
                organization: kycFormValues?.occupation?.organization ?? "",
                designation: kycFormValues?.occupation?.designation ?? "",
            };

            setFormValues(finalValues);
            form.setFieldsValue(finalValues);
        }
    }, [kycFormValues, form])



    const handleSelectChange = (e: any, name: string) => {
        setFormValues({ ...formValues, [name]: e });
    };
    const handleInputChange = (e: any) => {
        setFormValues({ ...formValues, [e.target.name]: e.target.value });
    };

    const handleOnSearch = (e: any) => {
        setFormValues({ ...formValues, organization: e });
    };


    const backHandler = () => {
        if (history.location.pathname.includes("dematt")) {
            history.push(`/app/${oid}/dematt/family-details`)
        } else {
            history.push(`/app/${oid}/trading/family-details`)
        }

    }

    const submitHandler = () => {
        form
            .validateFields()
            .then((values) => {
                submitForm();
            })
            .catch((errorInfo: any) => {
                const element = document.getElementById(`${errorInfo.errorFields[0].name[0]}`)
                element?.scrollIntoView({ behavior: "smooth" })
            });
    };

    const submitForm = () => {
        dispatch(setKycFormValues({
            ...kycFormValues,
            occupation: {
                ...formValues,
                phone: `+977${formValues?.phone!.replace("+977", "").replace("-", "")}`,
            },
        }))
        dispatch(updateKycDetailRequest({
            occupation: {
                ...formValues,
                phone: `+977${formValues?.phone!.replace("+977", "").replace("-", "")}`,
            },

        }))

    }

    return (
        <ProgressbarLayout
            progressLeftText={history.location.pathname.includes('dematt') ? "60%" : "70%"}
            progressRightText={globalTimer}
            progressPercent={history.location.pathname.includes('dematt') ? 60 : 70}
            iconTitle={
                history.location.pathname.includes('dematt') ? 'DEMAT' : 'TRADING'
            }
            title=''
            animationIn={history.action === 'PUSH' ? true : false}
            renderFooter={
                <RenderFooter
                    loading={actionLoading}
                    backHandler={backHandler}
                    submitHandler={submitHandler}
                />
            }
            path='occupations'
        >
            < >
                <div>

                    <Form
                        form={form}
                        layout="horizontal"
                        name="basic"
                        onFinish={submitHandler}
                    >
                        <Row gutter={[16, 16]} className='groupWrapper'>
                            <Col xs={24}>
                                <Typography className={`r-text fw-500 text-light-black `}>Organization's Information </Typography>

                            </Col>
                            <Col xs={24} lg={12} id='occupation_type'>
                                <LabelWrapper
                                    label="Occupation"
                                    render={
                                        <Form.Item
                                            name="occupation_type"
                                            rules={VALIDATIONS.occupation_type}
                                            initialValue={formValues.occupation_type}
                                        >
                                            <Select
                                                onChange={(e) => handleSelectChange(e, "occupation_type")}
                                                optionLabelProp="customlabel"
                                                className="underlineSelect"
                                            >
                                                {occupationOptions.map((each: any, index: number) => {
                                                    return (
                                                        <Select.Option
                                                            key={index}
                                                            value={each.value}
                                                            customlabel={<div>{each.label}</div>}
                                                        >
                                                            <SelectLabelDisplay
                                                                label={each.label}
                                                                value={each.value}
                                                                currentVal={formValues.occupation_type}
                                                            />
                                                        </Select.Option>
                                                    );
                                                })}
                                            </Select>
                                        </Form.Item>
                                    }
                                />
                            </Col>
                            <Col xs={24} lg={12} id='phone'>
                                <LabelWrapper
                                    label="Office Phone Number"
                                    render={
                                        <Form.Item
                                            name="phone"
                                            rules={VALIDATIONS.organizationPhone}
                                            initialValue={formValues.phone}
                                        >
                                            <Input
                                                name="phone"
                                                onChange={handleInputChange}
                                                bordered={false}
                                                className="underlineInput"
                                                type="number"
                                            // value={formValues.pan_number}
                                            />
                                        </Form.Item>
                                    }

                                />
                            </Col>
                            <Col xs={24} lg={12} id='organization'>
                                <LabelWrapper
                                    label="Organization"
                                    render={
                                        <>
                                            {organizationLoading ? (
                                                <div className="d-flex flex-center">
                                                    <Spin />
                                                </div>
                                            ) : (
                                                <Form.Item
                                                    name="organization"
                                                    rules={VALIDATIONS.organization}
                                                    initialValue={formValues.organization}
                                                >
                                                    <AutoComplete
                                                        onChange={(e) => handleSelectChange(e, "organization")}
                                                        // optionLabelProp="customlabel"
                                                        onSearch={handleOnSearch}
                                                        filterOption={true}
                                                        showSearch={true}
                                                        suffixIcon={<SearchOutlined />}
                                                        className="underlineSelect"

                                                    >
                                                        {orgState.length &&
                                                            orgState.sort().map((each: any, index: number) => {
                                                                return (
                                                                    <AutoComplete.Option
                                                                        key={index}
                                                                        value={each.info}
                                                                    // customlabel={<div>{each.info}</div>}
                                                                    >
                                                                        <SelectLabelDisplay
                                                                            label={each.info}
                                                                            value={each.info}
                                                                            currentVal={formValues.organization}
                                                                        />
                                                                    </AutoComplete.Option>
                                                                );
                                                            })}
                                                    </AutoComplete>
                                                </Form.Item>
                                            )}
                                        </>
                                    }

                                />
                            </Col>
                            <Col xs={24} lg={12} id='business_type'>
                                <LabelWrapper
                                    label="Business Type"
                                    render={
                                        <>
                                            <Form.Item
                                                name="business_type"
                                                rules={VALIDATIONS.business_type}
                                                initialValue={formValues.business_type}
                                            >
                                                <Select
                                                    onChange={(e) => handleSelectChange(e, "business_type")}
                                                    optionLabelProp="customlabel"
                                                    className="underlineSelect"

                                                >
                                                    {businessTypeOptions.map((each: any, index: number) => {
                                                        return (
                                                            <Select.Option
                                                                key={index}
                                                                value={each.value}
                                                                customlabel={<div>{each.label}</div>}
                                                            >
                                                                <SelectLabelDisplay
                                                                    label={each.label}
                                                                    value={each.value}
                                                                    currentVal={formValues.business_type}
                                                                />
                                                            </Select.Option>
                                                        );
                                                    })}
                                                </Select>
                                            </Form.Item>
                                        </>
                                    }

                                />
                            </Col>
                            <Col xs={24} lg={12} id='designation' >
                                <LabelWrapper
                                    label="Designation"
                                    render={
                                        <>
                                            <Form.Item
                                                name="designation"
                                                rules={VALIDATIONS.designation}
                                                initialValue={formValues.designation}
                                            >
                                                <Select
                                                    onChange={(e) => handleSelectChange(e, "designation")}
                                                    optionLabelProp="customlabel"
                                                    className="underlineSelect"

                                                >
                                                    {designationOptions.map((each: any, index: number) => {
                                                        return (
                                                            <Select.Option
                                                                key={index}
                                                                value={each.value}
                                                                customlabel={<div>{each.label}</div>}
                                                            >
                                                                <SelectLabelDisplay
                                                                    label={each.label}
                                                                    value={each.value}
                                                                    currentVal={formValues.designation}
                                                                />
                                                            </Select.Option>
                                                        );
                                                    })}
                                                </Select>
                                            </Form.Item>
                                        </>
                                    }

                                />
                            </Col>
                        </Row>
                    </Form>
                </div>
            </>
        </ProgressbarLayout>
    )
}
