import React, {
  Dispatch,
  SetStateAction,
  useEffect,
} from 'react';

import {
  Col,
  Row,
} from 'antd';
import OutlineButton from 'Components/OutlineButton';
import SubLabelWrapper from 'Components/SubLabelWrapper';
import { useSelector } from 'react-redux';
import { RootState } from 'Redux/RootReducer';

interface GuiltProps {
    isGuilty: boolean,
    setIsGuilty: Dispatch<SetStateAction<boolean>>

}

export const Guilt = ({ isGuilty, setIsGuilty }: GuiltProps) => {
    //var
    const { kycFormValues, } =
        useSelector((state: RootState) => state?.protectedReducer);

    useEffect(() => { //set value
        if (kycFormValues?.trading && Object.keys(kycFormValues?.trading).length) {
            const values = kycFormValues?.trading;
            if (values?.guilt_case) {
                setIsGuilty(true)
            } else {
                setIsGuilty(false)

            }
        }

    }, [kycFormValues, setIsGuilty])
    return (
        <Row gutter={[16, 16]} className='groupWrapper mt-1'>
            <Col xs={24} lg={12}>
                <SubLabelWrapper
                    label="Are you verified in any guilt case?"
                    subLabel=""
                    render={
                        <Row gutter={[16, 16]}>
                            <Col xs={12} lg={6} style={{ marginTop: "1rem" }}>
                                <OutlineButton
                                    label="Yes"
                                    active={isGuilty}
                                    onClick={() => {
                                        setIsGuilty(true)
                                    }}
                                />
                            </Col>
                            <Col xs={12} lg={6} style={{ marginTop: "1rem" }}>
                                <OutlineButton
                                    label="No"
                                    active={!isGuilty}
                                    onClick={() =>
                                        setIsGuilty(false)
                                    }
                                />
                            </Col>
                        </Row>
                    }
                />
            </Col>
        </Row>
    )
}
