import React, {
  Dispatch,
  SetStateAction,
  useEffect,
} from 'react';

import {
  Col,
  Form,
  FormInstance,
  Input,
  Row,
} from 'antd';
import LabelWrapper from 'Components/LabelWrapper';
import { useSelector } from 'react-redux';
import { RootState } from 'Redux/RootReducer';

interface SampatiProps {
    formValues: any,
    setFormValues: Dispatch<SetStateAction<any>>
    form: FormInstance
}

export const Sampati = ({ formValues, setFormValues, form }: SampatiProps) => {
    //var
    const { kycFormValues, } =
        useSelector((state: RootState) => state?.protectedReducer);

    useEffect(() => { //set value
        if (kycFormValues?.trading && Object.keys(kycFormValues?.trading).length) {
            const values = kycFormValues?.trading;
            if (values?.samp_sudhi_2064) {
                setFormValues((prevState: any) => ({
                    ...prevState,
                    samp_sudhi_2064: values?.samp_sudhi_2064
                }))
                form.setFieldsValue({
                    samp_sudhi_2064: values?.samp_sudhi_2064
                })
            }
        }

    }, [kycFormValues, form, setFormValues])

    const handleInputChange = (e: any) => {
        setFormValues({ ...formValues, [e.target.name]: e.target.value });
    };
    return (
        <Row gutter={[16, 16]} className='mt-1 groupWrapper'>
            <Col xs={24}>
                <LabelWrapper
                    bold
                    label={
                        "Sampati sudhikaran nibaran yain, 2064 ko dafa 2 ko khada (sha) bamojimkaa kasurharu cha bhaney kripaya ullekh garnuhos"
                    }
                    render={
                        <Form.Item
                            name="samp_sudhi_2064"
                            // rules={VALIDATIONS.sampati}
                            initialValue={formValues.samp_sudhi_2064}
                        >
                            <Input
                                name="samp_sudhi_2064"
                                onChange={handleInputChange}
                                bordered={false}
                                className="underlineInput"
                            />
                        </Form.Item>
                    }
                />
            </Col>
        </Row>
    )
}
