import {
  useEffect,
  useState,
} from 'react';

import { Form } from 'antd';
import DatePicker from 'Components/CustomDatePicker';
import LabelWrapper from 'Components/LabelWrapper';
import { useSetKycFormValue } from 'CustomHooks/useSetKycFormValue';
import dayjs from 'dayjs';
import { IPageProps } from 'Interfaces/Page';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import {
  clearSuccess,
  getKycDetailRequest,
  scheduleDateTime,
  setCurrentUser,
} from 'Redux/Protected/Action';
import { RootState } from 'Redux/RootReducer';
import {
  scheduleKycDateValidation,
  scheduleKycTimeValidation,
} from 'Utils/DateValidation';
import { VALIDATIONS } from 'Utils/formValidationRules';
import RenderFooter from 'Views/Dmat/components/RenderFooter';
import ProgressbarLayout from 'Views/Dmat/Layout/ProgressbarLayout';

const ScheduleKyc = ({ history, pageNumber }: IPageProps) => {
    //var
    const {
        kycFormValues,
        kycDetails,
        success,
        actionLoading,
        globalTimer,
        paymentData
    } = useSelector((state: RootState) => state?.protectedReducer);
    //hooks
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const { oid } = useSetKycFormValue()

    //state
    const [scheduledDate, setScheduledDate] = useState(null);


    useEffect(() => {
        if (success && scheduledDate) {
            if (history.location.pathname.includes("trading")) {
                history.push(`/app/${oid}/trading/complete`);
            } else {
                history.push(`/app/${oid}/meroshare/complete`);
            }
        }
        return () => {
            dispatch(clearSuccess());
        };
    }, [success, dispatch, history, oid]);

    const backHandler = () => {
        if (history.location.pathname.includes("dematt")) {
            if (kycFormValues?.demat?.terms_conditions_status) {
                dispatch(setCurrentUser(""));
                dispatch(getKycDetailRequest(kycDetails?.id));
            } else {
                history.push("/app/form-selection");
            }
        } else {
            //inside trading
            //if meroshare bata ako ho
            if (kycFormValues?.form?.meroshare) {
                history.push("/app/meroshare/complete");
            } else {
                //trading individual
                if (kycFormValues?.trading?.terms_conditions_status) {
                    dispatch(setCurrentUser(""));
                    dispatch(getKycDetailRequest(kycDetails?.id));
                } else {
                    history.push("/app/form-selection");
                }
            }
        }
    };



    const handleDateChange = (date: any, dateString: string) => {
        form.setFieldsValue({ scheduledDate: date, date });
        setScheduledDate(date);
    };


    const submitForm = () => {

        if (scheduledDate) {
            const formattedDate = dayjs(scheduledDate).format("YYYY-MM-DD HH:mm:ss");
            dispatch(scheduleDateTime(formattedDate));
        }
    };

    const submitHandler = () => {
        form
            .validateFields()
            .then((values: any) => {
                submitForm();
            })
            .catch((errorInfo: any) => {
            });
    };



    return (
        <ProgressbarLayout
            progressLeftText={"99%"}
            progressRightText={globalTimer}
            progressPercent={history.location.pathname.includes("dematt") ? 99 : 99}
            iconTitle={
                history.location.pathname.includes("dematt") ? "DEMAT" : history.location.pathname.includes("meroshare") ? "MEROSHARE" :  "TRADING"
            }
            title=""
            animationIn={history.action === "PUSH" ? true : false}
            renderFooter={
                <RenderFooter
                    loading={actionLoading}
                    backHandler={backHandler}
                    submitHandler={submitHandler}
                // showForwardBtn={pressed}
                />
            }
        >
            <h1> Video KYC Verification </h1>

            <div>
                <h1> Schedule a Date &amp; Time </h1>

                <LabelWrapper
                    label="Select Date and Time"
                    render={
                        <Form
                            form={form}
                            layout="vertical"
                            name="basic"
                            onFinish={submitHandler}
                        >
                            <Form.Item rules={VALIDATIONS.scheduledDate} name="scheduledDate">
                                <div className="datepicker-underline">
                                    <DatePicker
                                        onChange={(date: any, dateString: string) =>
                                            handleDateChange(date, dateString)
                                        }
                                        placeholder=""
                                        value={scheduledDate}
                                        bordered={false}
                                        inputReadOnly={false}
                                        showTime
                                        disabledDate={scheduleKycDateValidation}
                                        disabledTime={scheduleKycTimeValidation}
                                        className="w-100p pl-0"
                                    />
                                </div>
                            </Form.Item>
                        </Form>
                    }
                />
            </div>


        </ProgressbarLayout>
    );
};

export default ScheduleKyc;
