/**
 *
 * @param extractedBankData response.text from textract
 * @returns accountNumber 
 */
export const getOcrAccountNumber = (extractedBankData: any): string => {
  let accountNumber = "";
  extractedBankData?.lines.map((itm: any) => {
    let x = itm.replace(/\D/g, "");
    if (accountNumber !== "") return;

    for (var i = 0; i < extractedBankData?.lines.length; i++) {
      if (x.length > 9 && x.length < 18) {
        accountNumber = x;
        break;
      }
    }
  });
  return accountNumber;
};
