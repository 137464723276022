import React, {
  Dispatch,
  SetStateAction,
  useEffect,
} from 'react';

import {
  Col,
  Row,
} from 'antd';
import OutlineButton from 'Components/OutlineButton';
import SubLabelWrapper from 'Components/SubLabelWrapper';
import { useSelector } from 'react-redux';
import { RootState } from 'Redux/RootReducer';

interface InPoliticsProps {
    inPolitics: boolean,
    setInpolitics: Dispatch<SetStateAction<boolean>>
}

export const InPolitics = ({ inPolitics, setInpolitics }: InPoliticsProps) => {
    //var
    const { kycFormValues, } =
        useSelector((state: RootState) => state?.protectedReducer);

    useEffect(() => { //set value
        if (kycFormValues?.trading && Object.keys(kycFormValues?.trading).length) {
            const values = kycFormValues?.trading;
            if (values?.poli_high_pos) {
                setInpolitics(true)
            } else {
                setInpolitics(false)

            }
        }

    }, [kycFormValues, setInpolitics])

    return (
        <Row gutter={[16, 16]} className='mt-1 groupWrapper'>
            <Col xs={24} lg={12}>
                <SubLabelWrapper
                    label="Are you in politics or higher position person?"
                    subLabel=""
                    render={
                        <Row gutter={[16, 16]}>
                            <Col xs={12} lg={6} style={{ marginTop: "1rem" }}>
                                <OutlineButton
                                    label="Yes"
                                    active={inPolitics}
                                    onClick={() => {

                                        setInpolitics(true)
                                    }}
                                />
                            </Col>
                            <Col xs={12} lg={6} style={{ marginTop: "1rem" }}>
                                <OutlineButton
                                    label="No"
                                    active={!inPolitics}
                                    onClick={() =>
                                        setInpolitics(false)
                                    }
                                />
                            </Col>
                        </Row>
                    }
                />
            </Col>
        </Row>
    )
}
