import {
  useEffect,
  useState,
} from 'react';

import {
  Checkbox,
  Col,
  Input,
  message,
  Row,
  Spin,
} from 'antd';
import Progressbar from 'Components/Progressbar';
import StorageGetImage from 'Components/StorageGetImage';
import { useSetKycFormValue } from 'CustomHooks/useSetKycFormValue';
import { IPageProps } from 'Interfaces/Page';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import {
  clearSuccess,
  setKycFormValues,
  updateKycDetailRequest,
} from 'Redux/Protected/Action';
import { RootState } from 'Redux/RootReducer';
import { capitalizeFirstLetterEntireWord } from 'Utils/capitalizeFirstLetter';

import RenderFooter from '../components/RenderFooter';

const GreyText = ({ data }: { data: string }) => {
  return (
    <div
      className="xs-text mt-1 text-light-grey text-justify"
      style={{ lineHeight: "20px" }}
    >
      {data}
    </div>
  );
};
const MediumGreyText = ({ data }: { data: string }) => {
  return (
    <div
      className="xs-text text-dark-grey text-justify"
      style={{ marginTop: "0.5rem" }}
    >
      {data}
    </div>
  );
};

const BlackText = ({ data }: { data: string }) => (
  <div className="s-text mt-1 text-light-black">{data}</div>
);

const DematTerms = ({ history, pageNumber }: IPageProps) => {
  //var
  const { kycFormValues, actionLoading, success, mainSignature, globalTimer } =
    useSelector((state: RootState) => state?.protectedReducer);
  //hooks
  const dispatch = useDispatch();
  const { oid } = useSetKycFormValue()

  //state
  const [agreeVal, setAgreeVal] = useState<boolean>(false);
  const [signatureImage, setSignatureImage] = useState<any>("");
  const [getImageLoading, setGetImageLoading] = useState(false);

  const handleCheck = () => {
    setAgreeVal((curr) => !curr);
  };


  const backHandler = () => {
    history.push(`/app/${oid}/dematt/transaction-info`)
  };

  const submitHandler = () => {
    if (agreeVal) {
      dispatch(setKycFormValues({
        ...kycFormValues,
        demat: { ...kycFormValues.demat, terms_conditions_status: true },
      }));
      dispatch(
        updateKycDetailRequest({
          demat: { terms_conditions_status: true },
        })
      )
        ;
    } else {
      message.error("Please agree to the terms and conditions first.");
    }
  };
  const getSignature = async (minor: boolean) => {
    let imageKey = "";

    if (minor) {
      imageKey = kycFormValues?.guardian_details.signature
    } else {
      imageKey = kycFormValues?.signature
    }

    setGetImageLoading(true);

    StorageGetImage({
      imageKey,
      successCB: (base64data) => {
        setSignatureImage(base64data);
      },
      finalCB: () => {
        setGetImageLoading(false);
      },
    });
  };
  useEffect(() => {
    if (kycFormValues?.minor) {
      getSignature(true);
    } else if (kycFormValues?.signature || mainSignature !== "") {
      getSignature(false);
    }
  }, [kycFormValues, mainSignature]);

  useEffect(() => {
    if (success) {
      // history.push("/app/dematt-terms/3");
      //check if solo demat or meroshare exists
      if (kycFormValues?.form?.meroshare) {
        history.push(`/app/${oid}/dematt/complete`);
      } else {
        history.push(`/app/${oid}/dematt/payment`);
      }
    }
    return () => {
      dispatch(clearSuccess());
    };
  }, [success]);

  const formattedTime: any =
    ("0" + Math.floor((globalTimer / 60) % 60)).slice(-2) +
    ":" +
    ("0" + Math.floor(globalTimer % 60)).slice(-2) +
    " min";

  return (
    <>
      <Progressbar percent={95} leftText={"95%"} rightText={formattedTime} />

      <Row className="termsLayout">
        <Col span="24" className="termsTopContainer">
          <div className="text-green sb-text fw-500">Terms and Condition</div>
        </Col>
        <div
        >
          <Col span="24" className="termsMiddleContainer">
            <BlackText data={`अनुसूची - १५`} />
            <BlackText data={`(विनियम २0 को उपविनियम ३ सँग सम्बन्धित)`} />
            <BlackText
              data={`   निक्षेप सदस्य र हितग्राही व्यक्ति वा संस्थाबीचको सम्झौता`}
            />

            <span
              className="xs-text mt-1 text-light-grey text-justify"
              style={{ lineHeight: "20px" }}
            >
              न्यू प्लाजा, काठमाडौस्थित कार्यालय रहेको एबीसी सेक्युरिटिज
              प्रा.लि. यसपछि “सदस्य” भनिएको (प्रथम पक्ष र{" "}
              <Input
                className="dottedInput"
                value="ABC Securities Pvt. Ltd."
                disabled
                bordered={false}
                style={{ width: 200 }}
              />{" "}
              स्थित कार्यालय रहेको
              <Input
                className="dottedInput"
                value={
                  capitalizeFirstLetterEntireWord(
                    kycFormValues?.username ?? ""
                  ) ?? ""
                }
                disabled
                bordered={false}
                style={{ width: 200 }}
              />{" "}
              यसपछि “हितग्राही” भनिएको) दोस्रो पक्ष बीच देहायका शर्तहरु पालना
              गर्नें सहमतिसाथ यो सम्झौता गरिएको छ ।
            </span>

            <GreyText
              data={`१. सामान्य प्रावधान : यस सम्झौताका पक्षहरू धितोपत्रको केन्द्रीय निक्षेप सेवा नियमावली, २0६८ का व्यवस्था र सिडिएससिले समयसमयमा जारी गरेका विनियमलाई यसै सम्झौतामा उल्लेख भए सरह र यसै सम्झौताको हिस्साको रूपमा पालना गर्न सहमत छौं।`}
            />
            <GreyText
              data={`२. रकम असुल उपर : हितग्राहीले सदस्यलाई तिर्नुपर्ने रकम तोकिएको मितिमित्र बुझाउनु पर्नेछ ।`}
            />
            <GreyText
              data={`३. खाताको फाँटवारी (स्टेटमेन्ट) : सदस्यले रहेक तीन महिनाको अन्तमा हितग्राहीलाई निजका खाताको फाँटवारी प्रदान गर्नेंछ । तर यस अवधिमा कुनै कारोबार नभएमा खाताको फाँटवारी दिनु पर्ने छैन ।`}
            />
            <GreyText
              data={`४. विवरणमा भएका परिवर्तनहरूबारे हितग्राहीले सूचित गर्नुपर्ने : हितग्राहीले सूचित गरेको अवस्था बाहेक, हितग्राहीले विवरणमा भएको परिवर्तन सदस्यलाई जानकारी नगराएको कारणबाट हितग्राहीलाई हुन सकने कुनै हानी उपर सदस्य उत्तरदायी वा जिम्मेवार हुने छैन ।`}
            />
            <GreyText
              data={`५. हितग्राहीको दावीप्रति निक्षेप सदस्य उत्तरदायी नहुने : सुरक्षणका लागि हितग्राहीको खाताबाट खर्च लेखिएको/क्रेडिट गरिएको तेम्रो पक्षका दावी अदालत वा राजस्व निकायबाट तोकिएको वा भाग भएको कूनै शुल्क, दस्तुर, कर प्रति सिडिएससि तथा सदस्य उत्तरदायी हुने छैनन्।`}
            />
            <GreyText
              data={`६. प्रत्येक हितग्राही निम्न कुरामा विशेष रूपले जिम्मेवार हुनेछ :`}
            />
            <GreyText
              data={`(क) निक्षेप सदस्यसँग भएको सम्भौता र खाता खोल्दा विववरणहरु तथा तथ्य सम्बन्धमा,`}
            />
            <GreyText
              data={`(ख) निक्षेप सदस्यसँग खाता खोल्दा पेश गरेका लिखितको अधिकारीकता र सत्यता सम्बन्धमा,`}
            />
            <GreyText
              data={`(ग) निक्षेप सदस्यबाट प्रत्येक कारोबार निर्देशन बमोजिम खाताबाट घटाएको र थपेको कुराको सुनिश्चित गर्ने,`}
            />
            <GreyText
              data={`(घ) हितग्राहीको खातामा भएको परिवर्तनका विवरण सम्बन्धमा जस्तै: ठेगाना, बैंक विवरण स्थिति अख्तियारी, आदेश मनोनयन दस्तखत आदि,`}
            />
            <GreyText
              data={`(ड) कुनैपनि निष्काशित घितोपत्र खरिद गÞरेकोमा सो को सत्य विवरण ।`}
            />
            <GreyText
              data={`७. आधिकारिक प्रतिनिधि : हितग्राही सज्ठित संस्था वा कानूनी व्यक्ति भएमा त्यसले संस्था वा व्यक्तिको तर्फबाट प्रतिनिधित्व गर्ने अख्तियार प्राप्त व्यक्ति सदस्यसँगको सम्झौता क्रियान्वित गर्नेछन्‌ । प्रतिनिधिको हेरफेर वा अन्य कुनै किसिमको परिवर्तन भएमा हितग्राहीले सदस्यलाई तुरुन्त जानकारी गराउनेछ ।`}
            />
            <GreyText
              data={`८. सम्भौता रद्द गर्ने :विनियमावली तथा सञ्चालन निदेशिकामा उल्लेख गरिएअनुसारका अधिनमा रही पक्षहरुले यो सम्झौता जुनसुके समयमा रद्द गर्न सकनेछन्‌ । कुनै पनि पक्षले सम्झौता रद्द गरेमा हितग्राहीको खातामा भएका धितोपत्रहरुलाई सोही हितग्राहीको निर्देशनवमोजिम सदस्यले व्यस्थापन गर्नेछ ।`}
            />
            <GreyText
              data={`९. काबुबाहिरको परिस्थिति : यस सम्झौता वा विनियमावलीमा जुनसुकै कुरा लेखिएको भए तापनि आँधी, तुफान, बाढी, चट्याड, भूँ इचालो, आगलागी, विस्फोटन वा दैवी प्रकोप, युद्ध, विद्रोह, क्रान्ति, हुलदंगा, निषेधाज्ञा, नाकाबन्दी, अवरोध, दंगा, नागरिक कलह, हड्ताल, तालाबन्दी, बहिष्कार, कुनै उपयोगी सेवामा अवरोध जस्ता मजदुर कारवाही वा अशान्ति, शत्रुको कारवाही, अपराधिक षड्यन्त्र, आतंकारीको गतिविधि वा तोडफोड, विध्वंस, प्रणालीमा गडबडी, अनाहक प्रवेश वा प्रतिकार गर्न नसकिने अन्य कुनै शक्ति वा बाध्यता लगायत काबु वा नियन्त्र बाहिरका घटनाद्वारा यस सम्झौता अन्तर्गतको दायित्वमा कुनै कार्य सम्पादन नगरेको, विलम्ब गरेको वा उल्लंघन भएकोमा कूनै एक पक्षलाई हुन गएको हानीनोक्सानी, क्षतिको सोधभर्ना वा क्षतिपूर्ति दिन अर्को पक्ष उत्तरदायी हुनेछैन ।`}
            />
            <GreyText
              data={`१0. जनाउ : यस सम्झौता अनुसार दिइने अथवा आवश्यक हुने कुनै पनि जनाउ वा संञ्चार लिखित रुपमा र प्रापकको हालसालको ठेगाना नपठाएसम्म बन्धनकारी हुनेछैन ।`}
            />
            <GreyText
              data={`११. विवादको समाधान : पक्षहरुका बीचमा उत्पन्न हुन सकने विवाद तथा भिन्नाताका सम्बन्धमा विनियमालीमा तोकिएअनुसारको मध्यस्थता समितिको व्यवस्था यस सम्झौताका पक्षहरुलाई पनि लागु हुनेछ ।`}
            />
            <GreyText
              data={`१२. नियमनकारी कानून : यो सम्झौता प्रचलित नेपाल कानूनद्वारा नियमन तथा व्याख्या हुनेछ ।`}
            />
            <div className="d-flex my-2 align-items-center">
              <Checkbox
                onChange={handleCheck}
                checked={agreeVal}
                className="br-8"
              />
              <div className="ml-1 cursor-hover" onClick={handleCheck}>
                I agree
              </div>
            </div>
            <div className="mb-2">
              <BlackText data={`सम्झौताका प्रथम पछ्या :`} />
              <MediumGreyText data={`निछ्याप सदस्यको तर्फबाट अधिकार प्राप्त`} />
              <MediumGreyText
                data={`व्यक्तिको नाम : एबिसी सेकुरितेज प्रा लि`}
              />
              <MediumGreyText data={`दस्तखत : `} />
              <MediumGreyText data={`कम्पनीको छाप : `} />
              <div className="my-2">
                <MediumGreyText data={`साछी : `} />
              </div>
              <MediumGreyText data={`१ ....................................`} />
            </div>
            <div className="mb-2">
              <BlackText data={`सम्झौताका दोश्रो पछ्या :`} />
              <MediumGreyText data={`हीत्ग्राहीको तर्फबाट अधिकार प्राप्त `} />
              <MediumGreyText
                data={`व्यक्तिको नाम: ${capitalizeFirstLetterEntireWord(
                  kycFormValues?.username ?? ""
                ) ?? ""
                  }`}
              />
              <div className="d-flex">
                <MediumGreyText data={`दस्तखत : `} />
                <Spin spinning={getImageLoading}>
                  {signatureImage ? (
                    <img className="signature-terms" src={signatureImage} />
                  ) : null}
                </Spin>
              </div>
              <MediumGreyText data={`कम्पनीको छाप : `} />
              <div className="my-2">
                <MediumGreyText data={`साछी : `} />
              </div>
              <MediumGreyText data={`२ ....................................`} />
            </div>
          </Col>
        </div>
        <Col span="24" className="termsBottomContainer">
          <RenderFooter
            backHandler={backHandler}
            loading={actionLoading}
            submitHandler={submitHandler}
          />
        </Col>
      </Row>
    </>
  );
};

export default DematTerms;
