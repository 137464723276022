import { useEffect, useRef } from "react";

/**
 * we send time spend on each page with the help of this hook
 * the data can be used for analytics purposes later on
 * @returns increasing timer state (doesnt cause rerender)
 */
function useTimerHook() {
  const timer = useRef<any>(null);
  const timerVal = useRef<number>(0);

  useEffect(() => {
    timer.current = setInterval(() => {
      timerVal.current = timerVal.current + 1;
    }, 1000);
    return () => {
      clearInterval(timer.current);
    };
  }, []);

  return timerVal.current;
}

export default useTimerHook;
