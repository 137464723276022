import React from "react";

interface Props {
  label: String;
  render: React.ReactNode;
  bold: boolean;
}

const LabelWrapper = (props: Props) => {
  return (
    // <Space direction="vertical" style={{ width: "100%", marginTop: ".8rem" }}>
    <div
      className="d-flex w-100p flex-column"
      // style={{ width: "100%"}}
    >
      <div
        className={`${
          props.bold
            ? "r-text fw-500 text-light-black"
            : "xs-text text-light-grey"
        }`}
        style={{ width: "100%" }}
      >
        {props.label}
      </div>
      <>{props.render}</>
    </div>
  );
};

LabelWrapper.defaultProps = {
  bold: false,
};

export default LabelWrapper;
