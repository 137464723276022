export const GET_ADDRESS_REQUEST = "GET_ADDRESS_REQUEST";
export const GET_ADDRESS_SUCCESS = "GET_ADDRESS_SUCCESS";
export const GET_ADDRESS_FAILURE = "GET_ADDRESS_FAILURE";

export const GET_BANK_REQUEST = "GET_BANK_REQUEST";
export const GET_BANK_SUCCESS = "GET_BANK_SUCCESS";
export const GET_BANK_FAILURE = "GET_BANK_FAILURE";

export const GET_ORGANIZATION_REQUEST = "GET_ORGANIZATION_REQUEST";
export const GET_ORGANIZATION_SUCCESS = "GET_ORGANIZATION_SUCCESS";
export const GET_ORGANIZATION_FAILURE = "GET_ORGANIZATION_FAILURE";

export const GET_BROKER_REQUEST = "GET_BROKER_REQUEST";
export const GET_BROKER_SUCCESS = "GET_BROKER_SUCCESS";
export const GET_BROKER_FAILURE = "GET_BROKER_FAILURE";

export const GET_PAYMENTRATE_REQUEST = "GET_PAYMENTRATE_REQUEST";
export const GET_PAYMENTRATE_SUCCESS = "GET_PAYMENTRATE_SUCCESS";
export const GET_PAYMENTRATE_FAILURE = "GET_PAYMENTRATE_FAILURE";
