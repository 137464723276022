import DematStatsIcon from 'Assets/Icons/dematstats.svg';
import MeroshareStatsIcon from 'Assets/Icons/merosharestats.svg';
import TradingStatsIcon from 'Assets/Icons/tradingstats.svg';
import { Stats_Section } from 'Interfaces/profile';
import findStats from 'Utils/findStats';

import RenderStats from './RenderStats';

const StatsSection = ({
  users,
  formTypeIndex,
  setFormTypeIndex,
}: Stats_Section) => {
  const { dematCount, meroshareCount, tradingCount } = findStats(users);
  return (
    <div className="profileStatsContainer">
      <RenderStats
        title="DEMAT"
        numberValue={dematCount ?? 0}
        boxBackground="#D3FBF0"
        boxBorder="#80eed0"
        mainBackground="#e5fcf6"
        icon={DematStatsIcon}
        selected={formTypeIndex === 1}
        idx={1}
        {...{ setFormTypeIndex }}
        currentIdx={formTypeIndex}
      />
      <RenderStats
        title="Meroshare"
        numberValue={meroshareCount ?? 0}
        boxBackground="#D3FBF0"
        boxBorder="#80eed0"
        mainBackground="#e5fcf6"
        icon={MeroshareStatsIcon}
        selected={formTypeIndex === 2}
        idx={2}
        {...{ setFormTypeIndex }}
        currentIdx={formTypeIndex}
      />
      <RenderStats
        title="Trading"
        numberValue={tradingCount ?? 0}
        boxBackground="#D3FBF0"
        boxBorder="#80eed0"
        mainBackground="#e5fcf6"
        icon={TradingStatsIcon}
        selected={formTypeIndex === 3}
        idx={3}
        {...{ setFormTypeIndex }}
        currentIdx={formTypeIndex}
      />
    </div>
  );
};
export default StatsSection;
