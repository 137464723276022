import React, {
  Dispatch,
  SetStateAction,
  useEffect,
} from 'react';

import {
  Col,
  Row,
  Typography,
} from 'antd';
import ImgPickerNomineeCitizenship
  from 'Components/ImgPickerNomineeCitizenship';
import LabelWrapper from 'Components/LabelWrapper';
import { useSelector } from 'react-redux';
import { RootState } from 'Redux/RootReducer';

interface CitizenshipUploadProps {
    setSelectedFront: Dispatch<SetStateAction<string>>
    setSelectedBack: Dispatch<SetStateAction<string>>
}

export const CitizenshipUpload = ({ setSelectedFront, setSelectedBack }: CitizenshipUploadProps) => {
    //var
    const { kycFormValues, } =
        useSelector((state: RootState) => state?.protectedReducer)

    useEffect(() => {//set citizenship image
        if (kycFormValues.nominee) {
            setSelectedFront(kycFormValues.nominee_details?.citizenship_images?.front)
            setSelectedBack(kycFormValues.nominee_details?.citizenship_images?.back)
        }

    }, [kycFormValues, setSelectedBack, setSelectedFront])

    const onSelectFront = (file: string) => {
        setSelectedFront(file);
    };
    const onSelectBack = (file: string) => {
        setSelectedBack(file);
    };

    return (
        <Row className='mt-2 groupWrapper pb-3'>
            <Col xs={24} lg={24}>
                <Col xs={24}>
                    <Typography className={`r-text fw-500 text-light-black `}>Nominee Citizenship Certificate  </Typography>

                </Col>
                <LabelWrapper
                    label={`Upload Nominee Citizenship`}
                    render={
                        <div>
                            <ImgPickerNomineeCitizenship
                                onSelectFront={onSelectFront}
                                onSelectBack={onSelectBack}
                            />
                        </div>
                    }
                />
            </Col>
        </Row>
    )
}
