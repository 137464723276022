/**
 *
 * @returns box used to display thubmprint container in terms and conditions trading
 */
const ThumbprintBox = () => {
  return (
    <div className="d-flex mt-1">
      <div className="thumbprintBoxContainer">
        <div className="xs-text text-light-grey text-center">दाँया / Right</div>
      </div>
      <div className="thumbprintBoxContainer">
        <div className="xs-text text-light-grey text-center">बाँया / Left</div>
      </div>
    </div>
  );
};

export default ThumbprintBox;
