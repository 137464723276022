import SubLabelWrapper from "Components/SubLabelWrapper";
import { useSelector } from "react-redux";
import { IPageProps } from "Interfaces/Page";
import RenderFooter from "Views/Dmat/components/RenderFooter";
import ProgressbarLayout from "Views/Dmat/Layout/ProgressbarLayout";
import Photo from "Assets/Icons/boy.svg";
import Cheque from "Assets/Icons/chequee.svg";
import Certificate from "Assets/Icons/certificate.svg";
import Signature from "Assets/Icons/signature.svg";
import Card from "Assets/Icons/id-card.svg";
import Fingerprint from "Assets/Icons/fingerprint.svg";
import PdfSvg from "Assets/Icons/pdf.svg";
import { ReactComponent as DematIcon } from "Assets/Icons/demat.svg";
import { RootState } from "Redux/RootReducer";

interface document {
  icon: any;
  title: string;
}

interface docsType {
  title: string;
  docs?: document[];
}

const docs = {
  applicantsDocs: [
    {
      icon: Certificate,
      title: "Birth Certificate",
    },
    {
      icon: Photo,
      title: "Photo",
    },
  ],
  applicantsDocsTrading: [
    {
      icon: Certificate,
      title: "Birth Certificate",
    },
    {
      icon: Photo,
      title: "Photo",
    },
    {
      icon: PdfSvg,
      title: "BOID pdf",
    },
  ],

  guardianDocs: [
    {
      icon: Card,
      title: "Citizenship",
    },
    {
      icon: Photo,
      title: "Photo",
    },
    {
      icon: Cheque,
      title: "Cheque",
    },
    {
      icon: Fingerprint,
      title: "Thumb Print",
    },
    {
      icon: Signature,
      title: "Signature",
    },
  ],
  guardianDocsTrading: [
    {
      icon: Card,
      title: "Citizenship",
    },
    {
      icon: Photo,
      title: "Photo",
    },
    {
      icon: Cheque,
      title: "Cheque",
    },
    {
      icon: Fingerprint,
      title: "Thumb Print",
    },
    {
      icon: Signature,
      title: "Signature",
    },
    {
      icon: PdfSvg,
      title: "BOID pdf",
    },
  ],

  nomineeDocs: [
    {
      icon: Card,
      title: "Citizenship",
    },

    {
      icon: Fingerprint,
      title: "Thumb Print",
    },
    {
      icon: Signature,
      title: "Signature",
    },
  ],
};

const DocsType = ({ title, docs = [] }: docsType) => {
  return (
    <>
      <h3>{title}</h3>
      <div className="docHolder">
        {docs.map((doc, idx) => (
          <div className="doc" key={`idx${doc?.title ?? ""}${idx}`}>
            <div className="iconHolder">
              <img src={doc.icon} alt={doc.icon} onClick={() => {}} />
            </div>
            <h5>{doc.title}</h5>
          </div>
        ))}
      </div>
    </>
  );
};

const DocsRequired = ({ history }: IPageProps) => {
  const { actionLoading, globalTimer, kycFormValues } = useSelector(
    (state: RootState) => state?.protectedReducer
  );

  const backHandler = () => {
    if (history.location.pathname.includes("dematt")) {
      history.push("/app/dematt");
    } else {
      // history.push("/app/trading/boid/5");
      history.push("/app/trading/individual/checkminor");
    }
  };

  const submitHandler = () => {
    if (history.location.pathname.includes("dematt")) {
      if (kycFormValues?.minor) {
        history.push("/app/dematt-minor");
      } else {
        history.push("/app/dematt-citizenship-ocr");
      }
    } else {
      //trading minor/non-minor redirects
      if (kycFormValues?.form?.meroshare) {
        history.push("/app/trading/boid/1");
      } else {
        history.push("/app/trading/uploadphoto/boid");
      }
    }
  };

  return (
    <ProgressbarLayout
      progressLeftText="2%"
      progressRightText={globalTimer}
      progressPercent={2}
      title={kycFormValues?.minor ? "Minor" : "Non-Minor"}
      animationIn={history.action === "PUSH" ? true : false}
      showIconHeader={false}
      renderFooter={
        <RenderFooter
          loading={actionLoading}
          backHandler={backHandler}
          submitHandler={submitHandler}
          showForwardBtn={true}
        />
      }
    >
      {kycFormValues?.minor ? (
        <div className="d-flex align-items-center">
          <DematIcon />
          <div className="ml-1 d-flex flex-column w-100p">
            <div className="r-text fw-500 text-light-black ">Documents</div>
            <div className="documentSubLabel">
              Things you need before adding{" "}
              {history.location.pathname.includes("dematt")
                ? "DEMAT"
                : "TRADING"}
            </div>
          </div>
        </div>
      ) : (
        <DematIcon />
      )}

      <SubLabelWrapper
        label={kycFormValues?.minor ? "" : "Documents"}
        subLabel=""
        render={
          <div className="dematDetails">
            {!kycFormValues?.minor && (
              <p>
                Things you need before adding{" "}
                {history.location.pathname.includes("dematt")
                  ? "DEMAT"
                  : "TRADING"}
              </p>
            )}

            {kycFormValues?.minor && (
              <DocsType
                title="Applicant"
                docs={
                  history.location.pathname.includes("dematt")
                    ? docs.applicantsDocs
                    : docs.applicantsDocsTrading
                }
              />
            )}
            <DocsType
              title={kycFormValues?.minor ? "Guardians" : "Applicants"}
              docs={
                history.location.pathname.includes("dematt")
                  ? docs.guardianDocs
                  : kycFormValues?.minor
                  ? docs.guardianDocs
                  : docs.guardianDocsTrading
              }
            />
            <DocsType title="Nominee (if Any)" docs={docs.nomineeDocs} />
          </div>
        }
      />
    </ProgressbarLayout>
  );
};

export default DocsRequired;
