type Props = {
  mainId: string
  currentUserId: string
  type: directoryKey
}

export type directoryKey =
  | 'guardianProfile'
  | 'guardianSignature'
  | 'guardianFingerprint'
  | 'bankCheque'
  | 'birthCertificate'
  | 'minorProfile'
  | 'nomineeSignature'
  | 'nomineeFingerPrint'
  | 'nonMinorFingerPrint'
  | 'nonMinorProfile'
  | 'nonMinorSignature'
  | 'meroshareSignature'
  | 'boid_pdf'
  | 'nonMinorCitizenshipFront'
  | 'nonMinorCitizenshipBack'
  | 'guardianCitizenhipFront'
  | 'guardianCitizenshipFront'
  | 'guardianCitizenshipBack'
  | 'nomineeCitizenshipFront'
  | 'nomineeCitizenshipBack'

export type directoryType = {
  [k in directoryKey]: string
}

const DIRECTORY_PATHS: directoryType = {
  guardianProfile: 'profile/guardian/user',
  guardianSignature: 'signature/guardian/user',
  guardianFingerprint: 'finger-print/guardian/user',
  bankCheque: 'cheque/user',
  birthCertificate: 'birth-certificate/user',
  minorProfile: 'profile/user',
  nomineeSignature: 'signature/nominee/user',
  nomineeFingerPrint: 'finger-print/nominee/user',
  nonMinorFingerPrint: 'finger-print/user',
  nonMinorProfile: 'profile/user',
  nonMinorSignature: 'signature/user',
  meroshareSignature: 'signature/meroshare/user',
  boid_pdf: 'boid/user',
  nonMinorCitizenshipFront: 'citizenship/user',
  nonMinorCitizenshipBack: 'citizenship/user',
  guardianCitizenhipFront: 'citizenship/guardian/user',
  guardianCitizenshipFront: 'citizenship/guardian/user',
  guardianCitizenshipBack: 'citizenship/guardian/user',
  nomineeCitizenshipFront: 'citizenship/nominee/user',
  nomineeCitizenshipBack: 'citizenship/nominee/user',
}

/**
 *
 * @param mainId kycDetails.id
 * @param currentUserId currentUserId
 * @param type see autocomplete
 * @returns url path where files are uploaded, make sure to concat this with /{filename.extension}
 */
export function s3Paths({ mainId, currentUserId, type }: Props): string {
  let myPath = ''
  if (type.toLowerCase().includes('citizenship')) {
    let citizenshipSide = type.toLowerCase().includes('front')
      ? 'front'
      : 'back'
    myPath = `${DIRECTORY_PATHS[type]}/${mainId}/other/${currentUserId}/${citizenshipSide}`
  } else {
    myPath = `${DIRECTORY_PATHS[type]}/${mainId}/other/${currentUserId}`
  }
  return myPath
}
