import { Stats_Item } from "Interfaces/profile";

const RenderStats = ({
  title,
  numberValue,
  mainBackground,
  icon,
  selected,
  idx,
  boxBorder,
  currentIdx,
  setFormTypeIndex,
}: Stats_Item) => {
  return (
    <div
      className="statsMainBox cursor-hover"
      style={{
        background: selected ? mainBackground : "white",
        border: `1px solid ${selected ? boxBorder : "#D3E0E0"}`,
      }}
      onClick={() => {
        if (currentIdx === idx) setFormTypeIndex(0);
        else setFormTypeIndex(idx);
      }}
    >
      <div className="d-flex flex-column flex-between h-100p">
        <img src={icon} className="miniBox" />
        <div>
          <div className="xxs-text  text-light-black">{title}</div>
          <div className="text-grey m-text fw-600">{numberValue}</div>
        </div>
      </div>
    </div>
  );
};

export default RenderStats;
