import Amplify, { Auth } from "aws-amplify";
import config from "./config";

const configureAmplify = (): void => {
  Amplify.configure({
    Auth: {
      mandatorySignIn: true,
      region: config.cognito.REGION,
      userPoolId: config.cognito.USER_POOL_ID,
      identityPoolId: config.cognito.IDENTITY_POOL_ID,
      userPoolWebClientId: config.cognito.USERPOOL_WEB_CLIENT_ID,
      authenticationFlowType: "CUSTOM_AUTH",
    },
    API: {
      endpoints: [
        {
          name: "kycApi",
          endpoint: config.apiGateway.BACKEND,
          region: config.apiGateway.REGION,
          custom_header: async () => {
            const session = await Auth.currentSession();
            return {
              Authorization: `Bearer ${session.getIdToken().getJwtToken()}`,
            };
          },
        },
        {
          name: "paymentApi",
          endpoint: config.apiGateway.PAYMENT,
          region: config.apiGateway.REGION,
          custom_header: async () => {
            const session = await Auth.currentSession();
            return {
              Authorization: `Bearer ${session.getIdToken().getJwtToken()}`,
            };
          },
        },

        {
          name: "docker_api",
          endpoint: config.apiGateway.DOCKER_API,
          custom_header: async () => {
            const session = await Auth.currentSession();
            return {
              Authorization: `Bearer ${session.getIdToken().getJwtToken()}`,
            };
          },
        },
        {
          name: "signupApi",
          endpoint: config.apiGateway.BACKEND,
          region: config.apiGateway.REGION,
        },
        {
          name: "nepseApi",
          endpoint: config.nepseApi,
        },
      ],
    },
    Storage: {
      AWSS3: {
        bucket: config.storage.BUCKET,
        region: config.storage.REGION,
      },
    },
    predictions: {
      identify: {
        identifyText: {
          proxy: false,
          region: config.cognito.REGION,
          defaults: {
            format: "PLAIN",
          },
        },
      },
    },
  });
};

export default configureAmplify;
