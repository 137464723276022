/**
 *
 * @param a  string
 * @param b string
 * @returns similarity percent
 */
export const similarPercent = (a: string, b: string): number => {
  var equivalency = 0;
  var minLength = a.length > b.length ? b.length : a.length;
  var maxLength = a.length < b.length ? b.length : a.length;
  for (var i = 0; i < minLength; i++) {
    if (a[i]?.toLowerCase() == b[i]?.toLowerCase()) {
      equivalency++;
    }
  }
  var weight = equivalency / maxLength;
  return weight * 100;
};
