import { useEffect } from 'react';

import {
  Button,
  Col,
  message,
  Row,
} from 'antd';
import {
  ReactComponent as DmatCompletion,
} from 'Assets/Icons/dmatcomplete.svg';
import { ReactComponent as GreenPlus } from 'Assets/Icons/greenplus.svg';
import { Storage } from 'aws-amplify';
import { CustomNotification } from 'Components/CustomNotification';
import Progressbar from 'Components/Progressbar';
import { ConnectedRouterProps } from 'connected-react-router';
import { useSetKycFormValue } from 'CustomHooks/useSetKycFormValue';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import {
  clearSuccess,
  getDematPdfLink,
  getKycDetailRequest,
  resetPdfLink,
  setCurrentUser,
  setKycFormValues,
  updateKycDetailRequest,
} from 'Redux/Protected/Action';
import { RootState } from 'Redux/RootReducer';
import { downloadBlob } from 'Utils/DownloadFunction';

const DematComplete = ({ history }: ConnectedRouterProps) => {
    //var
    const {
        kycFormValues,
        actionLoading,
        kycDetails,
        success,
        pdfLoading,
        pdfLink,

        globalTimer,
        mainSignature,
    } = useSelector((state: RootState) => state?.protectedReducer);
    //hooks
    const dispatch = useDispatch();

    const { oid } = useSetKycFormValue()

    useEffect(() => {
        const handlePopEvent = () => {
            history.go(1);
        };
        window.addEventListener("popstate", handlePopEvent);
        return () => {
            window.removeEventListener("popstate", handlePopEvent);
        };
    }, []);

    useEffect(() => {
        if (Object.keys(kycFormValues).length) {
            dispatch(getDematPdfLink(kycDetails?.id, oid, "demat"));
        }
        return () => {
            dispatch(resetPdfLink());
        };
    }, [kycFormValues, dispatch, kycDetails, oid]);

    const downloadPdfHandler = async () => {
        if (pdfLink !== "") {
            try {
                const result: any = await Storage.get(pdfLink, {
                    download: true,
                    customPrefix: {
                        public: "",
                    },
                });
                downloadBlob(
                    result.Body,
                    `${kycFormValues?.username ?? "demat"} - demat.pdf`
                );
            } catch (err) {
                message.error("Failed to download pdf at this time");
            }
        }
    };

    const submitHandler = () => {
        if (kycFormValues?.form?.meroshare) {
            let formData;

            if (kycFormValues?.minor) {
                //minor case
                formData = {
                    // full_name: kycFormValues?.minor_details?.name,
                    phone: kycFormValues?.phone,
                    signature: kycFormValues?.guardian_details?.signature,
                    updated_signature:
                        mainSignature !== ""
                            ? mainSignature
                            : kycFormValues?.guardian_details?.updated_signature ??
                            kycFormValues?.guardian_details?.signature,
                    email: kycFormValues?.email,
                };
            } else {
                formData = {
                    // full_name: kycFormValues?.general_details?.full_name,
                    phone: kycFormValues?.phone,
                    signature: kycFormValues?.signature,
                    updated_signature:
                        mainSignature !== ""
                            ? mainSignature
                            : kycFormValues?.updated_signature ?? kycFormValues?.signature,
                    email: kycFormValues?.email,
                };
            }

            dispatch(setKycFormValues({
                ...kycFormValues,
                meroshare: formData,
            }))
            dispatch(updateKycDetailRequest({
                meroshare: formData,
            }))
                ;
        } else if (kycFormValues?.form?.trading) {
            history.push("/app/trading/transactionrelated");
        } else {
            dispatch(setCurrentUser(""));
            dispatch(
                getKycDetailRequest(kycDetails?.id))

            // message.success("Demat Form completed");

            CustomNotification({
                title: "Form Completion",
                description:
                    "Thank you for choosing ABC Securities Pvt. Ltd. We will get back to you shortly.",
                type: "success",
            });
        }
    };

    useEffect(() => {
        if (success) {
            history.push(`/app/${oid}/meroshare`);
        }

        return () => {

            dispatch(clearSuccess())
        };
    }, [success, dispatch, history, oid]);

    const formattedTime: any =
        ("0" + Math.floor((globalTimer / 60) % 60)).slice(-2) +
        ":" +
        ("0" + Math.floor(globalTimer % 60)).slice(-2) +
        " min";

    return (
        <>
            <Progressbar percent={100} leftText={"100%"} rightText={formattedTime} />

            <Row
                className="termsLayout"
                style={{ paddingInline: "5vw", paddingBlock: "2rem" }}
            >
                <Col
                    span="24"
                    className="d-flex"
                    style={{
                        justifyContent: "flex-end",
                    }}
                >
                    <Button
                        type="default"
                        className="manuallyBtn"
                        shape="round"
                        loading={pdfLoading}
                        onClick={downloadPdfHandler}
                    >
                        {!pdfLoading && (
                            <div className="d-flex h-100p w-100p flex-between align-items-center">
                                <GreenPlus style={{ marginRight: 10 }} /> Download PDF
                            </div>
                        )}
                    </Button>
                </Col>

                <div
                    className="termsMiddleContainer"
                >
                    <Col span="24" className="termsMiddleContainer">
                        <div className="d-flex flex-column h-100p flex-center align-items-center">
                            <DmatCompletion />
                            <div className="mt-5 mb-1 sb-text">DEMAT Form Completion</div>
                            <div className="text-center text-light-grey">
                                {kycFormValues?.form?.meroshare
                                    ? " You've successfully completed the DEMAT Form. Proceed Forward to Create Meroshare Account."
                                    : "The Payment was successful. You've successfully completed the DEMAT Form."}
                            </div>
                        </div>
                        <Button
                            loading={actionLoading}
                            htmlType="submit"
                            className="submit-btn"
                            block
                            shape="round"
                            onClick={submitHandler}
                        >
                            {kycFormValues?.form?.meroshare
                                ? "Continue to Meroshare"
                                : "Complete"}
                        </Button>
                    </Col>
                </div>
            </Row>
        </>
    );
};

export default DematComplete;