import {
  useEffect,
  useState,
} from 'react';

import {
  Button,
  Checkbox,
  Col,
  Image,
  message,
  Row,
} from 'antd';
import { ReactComponent as DematIcon } from 'Assets/Icons/demat.svg';
import FileBackgroundIcon from 'Assets/Icons/file-bg.svg';
import ScheduleIcon from 'Assets/Icons/schedule.svg';
import { useSetKycFormValue } from 'CustomHooks/useSetKycFormValue';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { ProtectedActionCreators } from 'Redux/Protected';
import { RootState } from 'Redux/RootReducer';

const InitialValues: any = {
  demat: false,
  demat_status: "N/A",
  meroshare: false,
  meroshare_status: "N/A",
  trading: false,
  trading_status: "N/A",
};

export default function FormSelection() {
  let history = useHistory();
  const dispatch = useDispatch();
  const {
    clearSuccess,
    getKycDetailRequest,
    setCurrentUser,
    setKycFormValues,
    updateKycDetailRequest,
  } = bindActionCreators(ProtectedActionCreators, dispatch);

  const { kycFormValues, actionLoading, kycDetails, success } = useSelector(
    (state: RootState) => state?.protectedReducer
  );
  const [formValues, setFormValues] = useState(InitialValues);
  const [counter, setCounter] = useState(0);

  const { oid } = useSetKycFormValue()


  const backhandler = () => {
    // history.push("/app/profile");
    setCurrentUser("");
    getKycDetailRequest(kycDetails?.id);
  };

  const handleCheckboxChange = (e: any, name: string) => {
    setFormValues({
      ...formValues,
      [name]: e.target.checked,
    });
  };

  const handleSelectAll = (e: any) => {
    if (e.target.checked) {
      setFormValues({
        demat: true,
        meroshare: true,
        trading: true,
        demat_status: "N/A",
        meroshare_status: "N/A",
        trading_status: "N/A",
      });
    } else {
      setFormValues({
        demat: false,
        meroshare: false,
        trading: false,
        demat_status: "N/A",
        meroshare_status: "N/A",
        trading_status: "N/A",
      });
    }
    setCounter(counter + 1);
  };

  const handleContainerInput = (name: string) => {
    if ((name === "demat" && formValues.demat_status !== "N/A") || (name === "meroshare" && formValues.meroshare_status !== "N/A") || (name === "trading" && formValues.trading_status !== "N/A")) {
      return
    }
    setFormValues({
      ...formValues,
      [name]: !formValues[name],
    });
  };

  const handleSubmit = () => {
    let finalValues = {};
    if (formValues.demat) {
      finalValues = { ...finalValues, demat: true, demat_status: formValues.demat_status !== "N/A" ? formValues.demat_status : "N/A" };
    }
    if (!formValues.demat) {
      finalValues = { ...finalValues, demat: false, demat_status: "N/A" };
    }
    if (formValues.meroshare) {
      finalValues = {
        ...finalValues,
        meroshare: true,
        meroshare_status: formValues.meroshare_status !== "N/A" ? formValues.meroshare_status : "N/A",
      };
    }
    if (!formValues.meroshare) {
      finalValues = {
        ...finalValues,
        meroshare: false,
        meroshare_status: "N/A",
      };
    }
    if (formValues.trading) {
      finalValues = { ...finalValues, trading: true, trading_status: formValues.trading_status !== "N/A" ? formValues.trading_status : "N/A" };
    }
    if (!formValues.trading) {
      finalValues = { ...finalValues, trading: false, trading_status: "N/A" };
    }

    if (formValues?.demat && formValues?.trading && !formValues?.meroshare) {
      message.error(
        "Meroshare is also required if both demat and trading are selected"
      );
    } else {

      setKycFormValues({ ...kycFormValues, form: finalValues });

      updateKycDetailRequest({
        form: finalValues,
      });
    }
  };


  useEffect(() => {
    if (Object.keys(kycFormValues).length && kycFormValues.form) {
      setFormValues(kycFormValues.form);
    }
  }, [kycFormValues]);

  useEffect(() => {
    if (success) {

      if (formValues.demat && formValues.demat_status !== "N/A" && formValues.meroshare && formValues.meroshare_status !== "N/A" && formValues.trading && formValues.trading_status === "N/A") {
        history.push(`/app/${oid}/trading/financial`);
      } else if ((formValues.demat && formValues.demat_status === "N/A" && formValues.meroshare && formValues.meroshare_status === "N/A" && !formValues.trading) || (formValues.demat && formValues.demat_status === "N/A" && !formValues.meroshare && !formValues.trading) || (formValues.demat  && formValues.meroshare  && formValues.trading)) {
        history.push(`/app/${oid}/dematt`);
      } else if ((!formValues.demat && formValues.meroshare && formValues.meroshare_status === "N/A" && !formValues.trading)  ) {
        history.push(`/app/${oid}/meroshare`);
      } else if ((!formValues.demat && !formValues.meroshare && formValues.trading) || (!formValues.demat && formValues.meroshare && formValues.meroshare_status === "N/A" && formValues.trading) || (!formValues.demat && formValues.meroshare && formValues.meroshare_status !== "N/A" && formValues.trading)) {
        history.push(`/app/${oid}/trading/boid`);

      }

    }
    return () => {
      clearSuccess();
    };
  }, [success]);

  return (
    <Row style={{ padding: "5vh" }}>
      <Col span="24" className="content-container">
        <Row>
          <Col span="24" className="mb-3">
            <Link to={'/app/profile'}>

              <DematIcon />
            </Link>
            <div className="title">Choose Forms</div>
            <div className="subtitle">
              Please select one or more options below
            </div>
          </Col>
          <Col span="24">
            <Row>
              <Col
                onClick={() => handleContainerInput("demat")}
                span="24"
                className={
                  `
                  ${formValues.demat
                    ? "checkbox-container-bg checkboxContainer"
                    : "checkboxContainer"}
                    ${formValues.demat_status !== "N/A" ? " cursor-not-allowed " : ""}
                  `
                }
              >
                <Row align="middle" gutter={20}>
                  <Col span="5">
                    <Image src={FileBackgroundIcon} preview={false} />
                  </Col>
                  <Col span="13">
                    <div className="checkboxTitle">DEMAT</div>
                    <Row>
                      <Col style={{ margin: "3px 5px 0 0" }}>
                        <Image src={ScheduleIcon} preview={false} />
                      </Col>
                      <Col className="checkboxSubtitle">08 - 10 min</Col>
                    </Row>
                  </Col>
                  <Col span="6" className="text-right">
                    <Checkbox
                      onChange={(e: any) => handleCheckboxChange(e, "demat")}
                      checked={formValues.demat}
                      className="round-checkbox"
                      value="demat"
                    // disabled={true}
                    ></Checkbox>
                  </Col>
                </Row>
              </Col>
              <Col
                onClick={() => handleContainerInput("meroshare")}
                span="24"
                className={
                  `${formValues.meroshare
                    ? "checkbox-container-bg checkboxContainer mt-1"
                    : "checkboxContainer mt-1"}
                    ${formValues.meroshare_status !== "N/A" ? " cursor-not-allowed " : ""}
                    `

                }
              >
                <Row align="middle" gutter={20}>
                  <Col span="5">
                    <Image src={FileBackgroundIcon} preview={false} />
                  </Col>
                  <Col span="13">
                    <div className="checkboxTitle">Meroshare</div>
                    <Row>
                      <Col style={{ margin: "3px 5px 0 0" }}>
                        <Image src={ScheduleIcon} preview={false} />
                      </Col>
                      <Col className="checkboxSubtitle">05 - 07 min</Col>
                    </Row>
                  </Col>
                  <Col span="6" className="text-right">
                    <Checkbox
                      onChange={(e: any) =>
                        handleCheckboxChange(e, "meroshare")
                      }
                      checked={formValues.meroshare}
                      className="round-checkbox"
                      value="meroshare"
                    ></Checkbox>
                  </Col>
                </Row>
              </Col>
              <Col
                onClick={() => handleContainerInput("trading")}
                span="24"
                className={
                  `${formValues.trading
                    ? "checkbox-container-bg checkboxContainer mt-1"
                    : "checkboxContainer mt-1"}
                  ${formValues.trading_status !== "N/A" ? " cursor-not-allowed " : ""}
                  `
                }
              >
                <Row align="middle" gutter={20}>
                  <Col span="5">
                    <Image src={FileBackgroundIcon} preview={false} />
                  </Col>
                  <Col span="13">
                    <div className="checkboxTitle">Trading</div>
                    <Row>
                      <Col style={{ margin: "3px 5px 0 0" }}>
                        <Image src={ScheduleIcon} preview={false} />
                      </Col>
                      <Col className="checkboxSubtitle">08 - 10 min</Col>
                    </Row>
                  </Col>
                  <Col span="6" className="text-right">
                    <Checkbox
                      onChange={(e: any) => handleCheckboxChange(e, "trading")}
                      checked={formValues.trading}
                      className="round-checkbox"
                      value="trading"
                    ></Checkbox>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col span="24" className="text-right mt-1">
            <span className="checkboxLabel mr-1">Select All</span>
            <Checkbox
              onChange={handleSelectAll}
              checked={
                formValues.demat && formValues.meroshare && formValues.trading
              }
            ></Checkbox>
          </Col>
          {formValues.demat || formValues.meroshare || formValues.trading ? (
            <Col span="24" className="text-center mt-2">
              <Button
                loading={actionLoading}
                onClick={handleSubmit}
                className="submit-btn"
                block
                shape="round"
              >
                {"Save & Next"}
              </Button>
              <Button
                type="link"
                className="cancel-link-btn mt-1"
                onClick={backhandler}
              >
                Cancel
              </Button>
            </Col>
          ) : null}
        </Row>
      </Col>
    </Row>
  );
}
