import {
  GET_ADDRESS_REQUEST,
  GET_ADDRESS_SUCCESS,
  GET_ADDRESS_FAILURE,
  GET_BANK_FAILURE,
  GET_BANK_REQUEST,
  GET_BANK_SUCCESS,
  GET_ORGANIZATION_FAILURE,
  GET_ORGANIZATION_REQUEST,
  GET_ORGANIZATION_SUCCESS,
  GET_BROKER_FAILURE,
  GET_BROKER_REQUEST,
  GET_BROKER_SUCCESS,
  GET_PAYMENTRATE_FAILURE,
  GET_PAYMENTRATE_REQUEST,
  GET_PAYMENTRATE_SUCCESS,
} from "./Constant";

interface Message {
  errorMessage?: string;
  successMessage?: string;
  userName?: string;
  message?: string;
}

type ActionWithPayload = {
  type:
    | typeof GET_ADDRESS_SUCCESS
    | typeof GET_BANK_SUCCESS
    | typeof GET_ORGANIZATION_SUCCESS
    | typeof GET_BROKER_SUCCESS
    | typeof GET_PAYMENTRATE_SUCCESS;
  payload: any;
};

type ActionWithoutPayload = {
  type:
    | typeof GET_ADDRESS_REQUEST
    | typeof GET_ADDRESS_FAILURE
    | typeof GET_BANK_REQUEST
    | typeof GET_BANK_FAILURE
    | typeof GET_ORGANIZATION_REQUEST
    | typeof GET_ORGANIZATION_FAILURE
    | typeof GET_BROKER_REQUEST
    | typeof GET_BROKER_FAILURE
    | typeof GET_PAYMENTRATE_REQUEST
    | typeof GET_PAYMENTRATE_FAILURE;
};

export type StaticAction = ActionWithPayload | ActionWithoutPayload;

export const getAddressRequest = (): StaticAction => ({
  type: GET_ADDRESS_REQUEST,
});

export const getAddressSuccess = (payload: any): StaticAction => ({
  type: GET_ADDRESS_SUCCESS,
  payload,
});

export const getAddressFailure = (): StaticAction => ({
  type: GET_ADDRESS_FAILURE,
});

export const getBankRequest = (): StaticAction => ({
  type: GET_BANK_REQUEST,
});

export const getBankSuccess = (payload: any): StaticAction => ({
  type: GET_BANK_SUCCESS,
  payload,
});

export const getBankFailure = (): StaticAction => ({
  type: GET_BANK_FAILURE,
});

export const getOrganizationRequest = (): StaticAction => ({
  type: GET_ORGANIZATION_REQUEST,
});

export const getOrganizationSuccess = (payload: any): StaticAction => ({
  type: GET_ORGANIZATION_SUCCESS,
  payload,
});

export const getOrganizationFailure = (): StaticAction => ({
  type: GET_ORGANIZATION_FAILURE,
});

export const getBrokerRequest = (): StaticAction => ({
  type: GET_BROKER_REQUEST,
});
export const getBrokerSuccess = (payload: any): StaticAction => ({
  type: GET_BROKER_SUCCESS,
  payload,
});

export const getBrokerFailure = (): StaticAction => ({
  type: GET_BROKER_FAILURE,
});

export const getPaymentRateRequest = (): StaticAction => ({
  type: GET_PAYMENTRATE_REQUEST,
});

export const getPaymentRateSuccess = (payload: any): StaticAction => ({
  type: GET_PAYMENTRATE_SUCCESS,
  payload,
});

export const getPaymentRateFailure = (): StaticAction => ({
  type: GET_PAYMENTRATE_FAILURE,
});
