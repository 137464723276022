import React, { useEffect } from "react";

const ErrorPage = () => {
  useEffect(() => {
    window.location.replace("/");
  }, []);
  return <div></div>;
};

export default ErrorPage;
