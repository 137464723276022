/**
 * 
 * @param mobileString string from pdf reader
 * @returns first mobile number it finds from the string
 */
export function findMobileNumber(mobileString: string): string {
  const cleanedMobileString = mobileString.replace(/\s/g, "");
  if (!/\S/.test(mobileString)) return "";
  if (cleanedMobileString === ",") return "";

  if (cleanedMobileString.length < 10) return "";

  const arrayNumbers = cleanedMobileString.split(",");
  if (arrayNumbers.length < 1) return "";

  const filterTenDigitNumber = arrayNumbers.filter(
    (each: any) => each.length === 10
  );
  if (filterTenDigitNumber.length < 1) return "";
  else return filterTenDigitNumber[0];
}
