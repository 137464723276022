import { message } from "antd";
import { Storage } from "aws-amplify";

interface Props {
  imageKey: string;
  successCB: (data: string | ArrayBuffer | null) => void;
  failureCB?: () => void;
  finalCB?: () => void;
}
/**
 * 
 * Helper function to Get image from s3 with s3Key
 */
export default async function StorageGetImage({
  imageKey,
  successCB,
  failureCB = () => {},
  finalCB = () => {},
}: Props) {
  try {
    const data: any = await Storage.get(imageKey, {
      download: true,
      customPrefix: {
        public: "",
      },
    });
    var reader = new FileReader();
    reader.readAsDataURL(data.Body);
    reader.onloadend = function () {
      var base64data = reader.result;
      successCB(base64data);
    };
  } catch (err) {
    message.error("Error getting image.");
    failureCB();
  } finally {
    finalCB();
  }
}
