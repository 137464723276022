import { API } from "aws-amplify";
import { Ticker, TickerDetail } from "Interfaces/watchList";
import { ApiHeaders } from "./config";

const NEPSE_API_NAME = "nepseApi";
const KYC_API_NAME = "kycApi";

export const getTickerDetailList = async (
  tickerSlugList: string[]
): Promise<TickerDetail[]> => {
  const promises = tickerSlugList.map((slug) =>
    API.get(NEPSE_API_NAME, `/ticker-live-data/${slug}`, {
      headers: ApiHeaders,
    })
  );

  try {
    const responses = await Promise.all(promises);
    return responses.map((response) => response.response);
  } catch (error) {
    console.error("Error fetching ticker detail list :", error);
    return [];
  }
};

export const getTickerList = async (): Promise<Ticker[]> => {
  try {
    const response = await API.get(NEPSE_API_NAME, `/ticker-list`, {
      headers: ApiHeaders,
    });
    return response.response;
  } catch (error) {
    console.error("Error fetching ticker list:", error);
    return [];
  }
};

export const getUserWatchList = async (userId: string): Promise<string[]> => {
  try {
    const response = await API.get(
      KYC_API_NAME,
      `user/${userId}/watchlist/list`,
      {
        headers: ApiHeaders,
      }
    );
    return response.data.watchlist;
  } catch (error) {
    console.error("Error fetching watchlist:", error);
    return [];
  }
};

export const updateUserWatchList = async (
  userId: string,
  list: string[]
): Promise<string[]> => {
  try {
    const response = await API.patch(
      KYC_API_NAME,
      `user/${userId}/watchlist/update`,
      {
        headers: ApiHeaders,
        body: {
          watchlist: list,
        },
      }
    );
    return response.data.watchlist;
  } catch (error) {
    console.error("Error fetching watchlist:", error);
    return [];
  }
};
