import 'nepali-datepicker-reactjs/dist/index.css';

import React, {
  useEffect,
  useState,
} from 'react';

import {
  Form,
  message,
  Row,
} from 'antd';
import CustomMapComponent from 'Components/CustomMapComponent';
import { useSetKycFormValue } from 'CustomHooks/useSetKycFormValue';
import dayjs from 'dayjs';
import { IPageProps } from 'Interfaces/Page';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import {
  clearSuccess,
  setKycFormValues,
  signatureBackgroundRemoverRequest,
  updateKycDetailRequest,
} from 'Redux/Protected/Action';
import { RootState } from 'Redux/RootReducer';
import { findAddressCode } from 'Utils/findAddressCode';
import RenderFooter from 'Views/Dmat/components/RenderFooter';
import ProgressbarLayout from 'Views/Dmat/Layout/ProgressbarLayout';

import { AddressInfo } from './Components/AddressInfo/AddressInfo';
import { CitizenInfo } from './Components/CitizenshipInfo/CitizenInfo';
import { GeneralInfo } from './Components/GeneralInfo/GeneralInfo';
import { LocationPicker } from './Components/LocationPicker/LocationPicker';
import { CitizenshipUpload } from './Components/Upload/CitizenshipUpload';
import { ProfileUpload } from './Components/Upload/ProfileUpload';
import { SignatureUpload } from './Components/Upload/SignatureUpload';
import { ThumbPrintUpload } from './Components/Upload/ThumbPrintUpload';

const InitialValues: any = {
    username: "",
    dob: null,
    nationality: "Nepali",
    gender: "",
    dob_np: "",
    citizenship_id: null,
    issued_date: null,
    issued_district: "",
    reference_number: "",
    issued_np: "",
    permanent_address: {
        country: "",
        district: "",
        province: "",
        nearest_landmark: "",
        tole: "",
        ward: ""
    },
    temporary_address: {
        country: "",
        district: "",
        province: "",
        nearest_landmark: "",
        tole: "",
        ward: ""
    }
};

export const PersonalDetails = ({ history, pageNumber }: IPageProps) => {
    //var
    const { kycFormValues, success, actionLoading, globalTimer } =
        useSelector((state: RootState) => state?.protectedReducer)
    const { addressDetails, addressLoading } = useSelector(
        (state: RootState) => state.staticReducer
    );

    //hooks
    const { oid } = useSetKycFormValue()
    const [form] = Form.useForm();
    const dispatch = useDispatch()

    //state
    const [formValues, setFormValues] = useState(InitialValues)
    const [thumbPrintData, setThumbPrintData] = useState("")
    const [selectedFront, setSelectedFront] = useState<string>("");
    const [selectedBack, setSelectedBack] = useState<string>("");
    const [profileData, setProfileData] = useState("")
    const [currentAddress, setCurrentAddress] = useState<"same" | "new">("same")
    const [signatureKey, setSignatureKey] = useState<string>("");
    const [showMapPage, setShowMapPage] = useState<boolean>(false);
    const [autoCompleteValue, setAutoCompleteValue] = useState<any>(null);


    const closeMapHandler = () => setShowMapPage(false);


    useEffect(() => { //success handler
        if (success && !showMapPage) {
            if (history.location.pathname.includes('dematt')) {
                history.push(`/app/${oid}/dematt/family-details`)
            } else {
                history.push(`/app/${oid}/trading/family-details`)
            }
        }
        return () => {
            dispatch(clearSuccess())
        }
    }, [success, dispatch, history, oid, showMapPage])

    //back handler
    const backHandler = () => {
        if (history.location.pathname.includes("dematt")) {

            history.push(`/app/${oid}/dematt`)
        } else {
            history.push(`/app/${oid}/trading/boid`)
        }
    }


    //submit handler
    const submitHandler = () => {

        form
            .validateFields() //check for validation
            .then(() => {
                submitForm();
            })
            .catch((errorInfo: any) => {
                const element = document.getElementById(`${errorInfo.errorFields[0].name[0]}`)
                element?.scrollIntoView({ behavior: "smooth" })
            });

    };

    const postVal = () => {

        let address_code = ""


        if (formValues?.issued_district) {
            address_code = findAddressCode({
                addressArray: addressDetails,
                districtName: formValues?.issued_district ?? "",
            });
        }

        const reference_number = `+977${formValues?.reference_number ?? ""
            .replace("+977", "")
            .replace("-", "")}`

        let submitValues: any = {
            reference_number,
            username: kycFormValues.username ,
            permanent_address: {
                ...kycFormValues?.permanent_address,
                sub: formValues?.permanent_address?.sub ?? "",
                district: formValues?.permanent_address?.district ?? "",
                province: formValues?.permanent_address?.province ?? "",
                country: formValues?.permanent_address?.country ?? "",
                nearest_landmark: formValues.permanent_address?.nearest_landmark ?? "",
                tole: formValues?.permanent_address?.tole ?? "",
                ward: formValues?.permanent_address?.ward ? parseInt(formValues.permanent_address.ward) : "",
                // block: null,
                // fax: null,
            },

        }
        if (kycFormValues.minor) {
            submitValues = {
                ...submitValues,
                guardian_details: {
                    ...kycFormValues?.guardian_details,
                    dob: formValues?.dob ? dayjs(formValues?.dob).format("YYYY-MM-DD") : "",
                    dob_np: formValues.dob_np,
                    full_name: formValues?.username,
                    issued_date: formValues?.issued_date ?  dayjs(formValues?.issued_date).format("YYYY-MM-DD") : "",
                    citizenship_id: formValues.citizenship_id,
                    issued_district: formValues.issued_district,
                    issued_np: formValues.issued_np,
                    nationality: formValues.nationality,
                    gender: formValues.gender,
                    phone: kycFormValues.phone,
                    email: kycFormValues.email,
                    thumbprint: thumbPrintData,
                    relation_with_applicant: formValues.relation_with_applicant,

                    citizenship_images: {
                        ...kycFormValues?.citizenship_images,
                        front: selectedFront,
                        back: selectedBack,
                    },

                    profile: profileData,
                    address_code: address_code ? address_code : "",


                },

            }
        } else {
            submitValues = {
                ...submitValues,
                general_details: {
                    ...kycFormValues?.general_details,
                    dob: formValues?.dob ? dayjs(formValues?.dob).format("YYYY-MM-DD") : "",
                    dob_np: formValues.dob_np,
                    full_name: formValues?.username,
                    issued_date: formValues?.issued_date ? dayjs(formValues?.issued_date).format("YYYY-MM-DD") : "",
                    address_code,
                    citizenship_id: formValues.citizenship_id,
                    issued_district: formValues.issued_district,
                    issued_np: formValues.issued_np,
                    nationality: formValues.nationality,
                    gender: formValues.gender
                },
                thumbprint: thumbPrintData,
                citizenship_images: {
                    ...kycFormValues?.citizenship_images,
                    front: selectedFront,
                    back: selectedBack,
                },
                profile: profileData,
                address_code: address_code ? address_code : "",


            }
        }

        //check temporary address status

        if (currentAddress === "same") {
            submitValues = {
                ...submitValues,
                same_as_permanent: true,
                temporary_address: {
                    ...submitValues.permanent_address
                }
            }
        } else {
            submitValues = {
                ...submitValues,
                same_as_permanent: false,
                temporary_address: {
                    ...kycFormValues?.temporary_address,
                    sub: formValues.temporary_address.sub,
                    district: formValues.temporary_address.district,
                    province: formValues.temporary_address.province,
                    country: formValues.temporary_address.country,
                    address_code: formValues.temporary_address.address_code,
                    nearest_landmark: formValues.temporary_address?.nearest_landmark ?? "",
                    tole: formValues?.temporary_address?.tole ?? "",
                    ward: formValues?.temporary_address?.ward ? parseInt(formValues.temporary_address.ward) : "",
                    // block: null,
                    // fax: null,
                },

            }
        }

        if (signatureKey) {
            if (kycFormValues?.minor) {
                submitValues = {
                    ...submitValues,
                    guardian_details: {
                        ...submitValues.guardian_details,
                        signature: signatureKey,
                    },
                }

            } else {
                submitValues = {
                    ...submitValues,
                    signature: signatureKey,

                }
            }

        }

        return submitValues

    }

    const initKycFormValues = () => {
        const val = postVal()
        dispatch(setKycFormValues({
            ...kycFormValues,
            ...val
        }))
    }


    const submitForm = () => {
        if (!kycFormValues?.location_map) {
            return message.error("Location map is required")
        }
        if (!signatureKey) {
            return message.error("Signature is required")

        }
        if (!profileData) {
            return message.error("Photo is required")
        }
        if (!selectedFront && !selectedBack) {
            return message.error("Both side citizenship is required")
        }
        if (!thumbPrintData) {
            return message.error("Thumb print is required")

        }
        const val = postVal()
        dispatch(setKycFormValues({
            ...kycFormValues,
            ...val
        }))
        dispatch(
            updateKycDetailRequest({
                ...val,

            })
        )
        dispatch(
            signatureBackgroundRemoverRequest(
              {
                url: signatureKey,
                transparent: false,
              },
              true
            )
          );
    }

    return (
        <>

            {showMapPage ?

                <CustomMapComponent
                    autocompleteValue={
                        autoCompleteValue?.value?.description ?? "Enter a place"
                    }
                    mapBackClick={closeMapHandler}

                    pageNumber={pageNumber}
                />
                :
                <ProgressbarLayout
                    progressLeftText={history.location.pathname.includes('dematt') ? "5%" : "15%"}
                    progressRightText={globalTimer}
                    progressPercent={history.location.pathname.includes('dematt') ? 5 : 15}
                    iconTitle={
                        history.location.pathname.includes('dematt') ? 'DEMAT' : 'TRADING'
                    }
                    title=''
                    animationIn={history.action === 'PUSH' ? true : false}
                    renderFooter={
                        <RenderFooter
                            loading={actionLoading}
                            backHandler={backHandler}
                            submitHandler={submitHandler}
                        />
                    }
                    path='your-details'
                >
                    <Form
                        form={form}
                        layout="horizontal"
                        name="basic"
                        onFinish={submitHandler}
                    >

                        <Row gutter={[16, 16]} className='mt-2 groupWrapper'>
                            <ProfileUpload setProfileData={setProfileData} />

                            <CitizenshipUpload initKycFormValues={initKycFormValues} setSelectedBack={setSelectedBack} setSelectedFront={setSelectedFront} selectedBack={selectedBack} selectedFront={selectedFront} />

                        </Row>
                        <GeneralInfo form={form} formValues={formValues} setFormValues={setFormValues} />
                        <CitizenInfo form={form} formValues={formValues} setFormValues={setFormValues} />
                        <AddressInfo form={form} formValues={formValues} setFormValues={setFormValues} currentAddress={currentAddress} setCurrentAddress={setCurrentAddress} />

                        <Row gutter={[16, 16]} className='mt-2 groupWrapper'>
                            <ThumbPrintUpload setThumbPrintData={setThumbPrintData} />
                            <SignatureUpload setSignatureKey={setSignatureKey} initKycFormValues={initKycFormValues} />
                        </Row>
                        <LocationPicker setShowMapPage={setShowMapPage} autoCompleteValue={autoCompleteValue} setAutoCompleteValue={setAutoCompleteValue} initKycFormValues={initKycFormValues} />

                    </Form>

                </ProgressbarLayout>
            }


        </>

    )
}
