import {
  useEffect,
  useState,
} from 'react';

import {
  Checkbox,
  Col,
  Divider,
  Modal,
  Row,
  Select,
  Spin,
} from 'antd';
import config from 'Api/config';
import { esewaConfig } from 'Api/esewaConfig';
import { ReactComponent as DematIcon } from 'Assets/Icons/demat.svg';
import EsewaIcon from 'Assets/Icons/EsewaIcon';
import KhaltiIcon from 'Assets/Icons/KhaltiIcon';
import SelectLabelDisplay from 'Components/SelectLabelDisplay';
import SubLabelWrapper from 'Components/SubLabelWrapper';
import { ConnectedRouterProps } from 'connected-react-router';
import { useSetKycFormValue } from 'CustomHooks/useSetKycFormValue';
import dayjs from 'dayjs';
import { Payments_select } from 'Interfaces/Page';
import queryString from 'querystring';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import { Link } from 'react-router-dom';
import {
  clearSuccess,
  getPaymentDetails,
  initPayments,
  requestKhaltiUrl,
  sendEmailRequest,
  setPaymentYears,
  updateKycDetailRequest,
} from 'Redux/Protected/Action';
import { RootState } from 'Redux/RootReducer';
import RenderFooter from 'Views/Dmat/components/RenderFooter';
import ProgressbarLayout from 'Views/Dmat/Layout/ProgressbarLayout';

interface dataValues {
    dematVal?: number | null;
    meroshareVal?: number | null;
    tradingVal?: number | null;
    dematOpeningVal?: number | null;
}

const yearsOptions = [
    { label: "1", value: 1 },
    { label: "2", value: 2 },
    { label: "3", value: 3 },
    { label: "4", value: 4 },
    { label: "5", value: 5 },
];

const InitRateData: dataValues = {
    dematVal: 0,
    meroshareVal: 0,
    tradingVal: 0,
    dematOpeningVal: 0,
};

const InitialValues: Payments_select = {
    online_banking: false,
    khalti: false,
    esewa: false,
    pressed: false,
};

const PaymentLabel = ({
    label,
    value,
}: {
    label: string;
    value?: number | null;
}) => {
    return (
        <div className="d-flex w-100p flex-between mt-2">
            <div className="text-light-grey s-text">{label}</div>
            <div className="text-light-black s-text fw-500">Rs. {value}</div>
        </div>
    );
};

const Payment = ({ history }: ConnectedRouterProps) => {
    //var
    const {
        kycFormValues,
        kycDetails,
        actionLoading,
        success,
        currentUserId,
        kycFormValues: { form },
        globalTimer,
        paymentYears,
        initLoading,
        requestKhaltiUrl: khaltiData,
        requestingKhaltiUrl,
        paymentData,
        paymentId
    } = useSelector((state: RootState) => state?.protectedReducer);

    const { paymentLoading, paymentRate } = useSelector(
        (state: RootState) => state.staticReducer
    );


    //hooks
    const dispatch = useDispatch();
    const { oid } = useSetKycFormValue()

    //state
    const [rateDataVal, setRateDataVal] = useState(InitRateData);
    const [total, setTotal] = useState<number>();
    const [formType, setFormType] = useState<any>([]);
    const [formValues, setFormValues] = useState(InitialValues);
    const { online_banking, khalti, esewa, pressed } = formValues;
    const [paymentStatus, setPaymentStatus] = useState("")

    const updateKycDetails = () => {

        let formVal = {}
        if (kycFormValues?.form.demat) {
            formVal = {
                ...formVal,
                demat: true,
                demat_status: "Pending"
            }
        }
        if (kycFormValues?.form.meroshare) {
            formVal = {
                ...formVal,
                meroshare: true,
                meroshare_status: "Pending"
            }
        }
        dispatch(
            updateKycDetailRequest({

                form: {
                    ...kycFormValues.form,
                    ...formVal
                }

            })
        )

    }


    useEffect(() => {
        if (success) { //payment success handler
            if (paymentId) {

                dispatch(getPaymentDetails(paymentId)) //get payment details from id

                updateKycDetails()

            }
            if (history.location.pathname.includes("dematt")) {
                //demat only flow completed dispatch mail of demat only completed
                dispatch(sendEmailRequest(currentUserId, "DEMAT"));
                history.push(`/app/${oid}/dematt/kyc`);
            } else {
                if (!kycFormValues?.form?.trading) {
                    if (kycFormValues?.form?.demat) {
                        dispatch(sendEmailRequest(currentUserId, "DEMAT and Meroshare"));
                    } else {
                        dispatch(sendEmailRequest(currentUserId, "Meroshare"));
                    }
                }
                history.push(`/app/${oid}/meroshare/kyc`);
            }
        }
        return () => {
            dispatch(clearSuccess());
        };
    }, [success, dispatch, currentUserId, history, kycFormValues, oid, paymentId]);


    useEffect(() => {
        //calculate total
        //todo check for new or old demat

        let tempTotal = 0;
        let tempFormType: any = "";
        if (form?.demat) {
            if (form?.demat) tempTotal += rateDataVal.dematVal! * paymentYears;
            if (form?.demat) tempTotal += rateDataVal.dematOpeningVal!;
        }

        if (form?.meroshare) tempTotal += rateDataVal.meroshareVal! * paymentYears;
        // if (form?.trading) tempTotal += rateDataVal.tradingVal!;

        //find the type of form
        //only trading ko case ma chai there might be errors
        if (
            form?.demat &&
            form?.meroshare

        ) {
            tempFormType = ["demat_with_meroshare", "demat_opening"];
        } else if (
            form?.demat &&
            form?.meroshare
        ) {
            tempFormType = ["meroshare"];
        } else {
            if (form?.demat) tempFormType = ["demat", "demat_opening"];
            if (form?.meroshare) tempFormType = ["meroshare"];
        }

        setFormType(tempFormType);

        setTotal(tempTotal);
    }, [rateDataVal, form, paymentYears]);

    useEffect(() => {
        //set rates value
        let tempDematVal = 0;
        let tempDematOpeningVal = 0;
        let tempMeroshareVal = 0;
        paymentRate.forEach((each: any) => {
            if (each.rate === "demat") tempDematVal = each.value;
            if (each.rate === "demat_opening") tempDematOpeningVal = each.value;
            if (each.rate === "meroshare") tempMeroshareVal = each.value;
        });
        setRateDataVal((prevState) => ({
            ...prevState,
            dematVal: tempDematVal,
            meroshareVal: tempMeroshareVal,
            dematOpeningVal: tempDematOpeningVal,
        }));
    }, [paymentRate]);



    useEffect(() => {
        //to toggle forward arrow
        if (online_banking || esewa || khalti) {
            setFormValues({ ...formValues, pressed: true });
        } else {
            setFormValues({ ...formValues, pressed: false });
        }
    }, [online_banking, khalti, esewa]);


    //check for esewa redirect
    useEffect(() => {
        //ESEWA PAYSUCCESS
        const parsed = queryString.parse(window.location.search);

        if (
            currentUserId !== "" &&
            kycDetails?.id &&
            formType.length > 0 &&
            paymentYears
        ) {
            if (parsed["?esewasuccess"]) {
                const base64String = parsed.paymentYears
                    .toString()
                    .split("?")[1]
                    .split("=")[1];
                const decodedString = JSON.parse(atob(base64String));

                const submitData = {
                    amount: Number(decodedString.total_amount),
                    pay_for: formType,
                    pid: parsed.oid,
                    rid: parsed.refId,
                    medium: "Esewa",
                    new: true,
                    meta: {
                        ...decodedString,
                    },
                    years: Number(parsed?.paymentYears.toString().split("?")[0])
                };
                dispatch(initPayments(kycDetails?.id, currentUserId, submitData));

            } else if (parsed["?khaltisuccess"]) {
                const submitData = {
                    amount: Number(parsed.amount),
                    pay_for: formType,
                    token: parsed.paymentYears.toString().split("?")[1].split("=")[1],
                    medium: "Khalti",
                    new: true,
                    years: Number(parsed?.paymentYears.toString().split("?")[0])

                };
                dispatch(initPayments(kycDetails?.id, currentUserId, submitData));



            }
        }
    }, [kycDetails, formType, currentUserId, dispatch, paymentYears]);

    useEffect(() => {
        const years = queryString.parse(window.location.search)?.paymentYears?.toString().split("?")[0];

        if (years) {
            dispatch(setPaymentYears(Number(years)))

        }
    }, [])


    const backHandler = () => {
        if (history.location.pathname.includes("dematt")) {
            history.push(`/app/${oid}/dematt/terms-and-condition`);
        } else {
            history.push(`/app/${oid}/meroshare/terms-nepali`);

        }

    };

    useEffect(() => {
        if (khaltiData.payment_url) {
            window.location.href = khaltiData.payment_url;
        }
    }, [khaltiData]);

    useEffect(() => {
        if (paymentData.expire_date) { //check payment status with expiry date
            if (dayjs(new Date()) < dayjs(paymentData.expire_date)) {

                setPaymentStatus("PAID")
            } else {
                setPaymentStatus("")
            }
        } else {
            setPaymentStatus("")
        }
    }, [paymentData])


    const submitHandler = async () => {
        if (paymentData.expire_date) {
            if (dayjs(new Date()) < dayjs(paymentData.expire_date)) {
                if (history.location.pathname.includes("dematt")) {
                    updateKycDetails()
                    history.push(`/app/${oid}/dematt/kyc`);

                } else {
                    updateKycDetails()
                    history.push(`/app/${oid}/meroshare/kyc`);

                }

            }
        } else {
            if (khalti && total) {


                let amountBreakdown: { label: string; amount: number }[] = [];

                formType.forEach((element: any) => {
                    const data = paymentRate.find((item: any) => item.rate === element);

                    amountBreakdown = [
                        ...amountBreakdown,
                        {
                            label: data.rate,
                            amount: data.rate === "demat_opening" ? data.value * 100 : data.value * 100 * paymentYears,
                        },
                    ];
                });
                let type = "meroshare";
                if (history.location.pathname.includes("dematt")) {
                    type = "dematt";
                }

                let payload = {
                    id: kycDetails.id,
                    oid: currentUserId,
                    formValues: {
                        amount_breakdown: amountBreakdown,
                        return_url: `${config.frontendBaseUrl}/app/${oid}/${type}/payment?khaltisuccess=${currentUserId}&timecount=${globalTimer}&paymentYears=${paymentYears}`,
                        website_url: config.frontendBaseUrl,
                        amount: total * 100,
                        customer_info: {
                            name: kycFormValues.username,
                            email: kycFormValues.email,
                            phone: kycFormValues.phone.replace("+977", ""),
                        },
                    },
                };

                dispatch(requestKhaltiUrl(payload));



            } else if (esewa && total) {
                //amount is in rs
                const timeInSeconds = globalTimer;
                if (history.location.pathname.includes("dematt")) {
                    esewaConfig({
                        currentUserId,
                        total,
                        paymentYears,
                        globalTimer,
                        type: "dematt",
                        oid
                    });
                } else {
                    esewaConfig({
                        currentUserId,
                        total,
                        paymentYears,
                        globalTimer,
                        type: "meroshare",
                        oid
                    });
                }
            } else {
                alert("Try out khalti and esewa");
            }
        }

    };

    const handleCheckboxChange = (e: any, name: string) => {
        setFormValues({
            ...InitialValues,
            [name]: e.target.checked,
        });
    };

    const handleContainerInput = (name: string) => {
        setFormValues({
            ...InitialValues,
            // @ts-ignore:next-line
            [name]: !formValues[name],
        });
    };


    const PaymentCheckBox = ({
        label,
        value,
        icon,
        stateName,
    }: {
        label: string;
        stateName: "online_banking" | "khalti" | "esewa";
        value: boolean | undefined;
        icon: any;
    }) => {
        return (
            <Row
                align="middle"
                className={`mb-1 checkboxContainer ${value && "checkbox-container-bg checkboxContainer"
                    }`}
                onClick={() => handleContainerInput(stateName)}
            >
                <Col span="4">{icon}</Col>
                <Col span="18">
                    <div className="text-light-black r-text fw-700">{label}</div>
                </Col>
                <Col span="2">
                    <Checkbox
                        onChange={(e: any) => handleCheckboxChange(e, stateName)}
                        checked={value}
                        className="br-8"
                    />
                </Col>
            </Row>
        );
    };

    return (
        <Spin
            spinning={
                actionLoading || paymentLoading || initLoading || requestingKhaltiUrl
            }
        >
            <ProgressbarLayout
                progressLeftText={
                    history.location.pathname.includes("dematt") ? "97%" : "94%"
                }
                progressRightText={globalTimer}
                progressPercent={history.location.pathname.includes("dematt") ? 97 : 94}
                title=""
                showIconHeader={false}
                renderFooter={
                    <RenderFooter
                        backHandler={backHandler}
                        submitHandler={submitHandler}
                        showForwardBtn={paymentStatus === "PAID" ? true : pressed}
                    // loading={actionLoading}
                    />
                }
            >
                <Modal
                    centered
                    footer={null}
                    closable={false}
                    visible={paymentLoading}
                    width={"70vw"}
                >
                    <Row justify="center">
                        <Col span="24" className="text-center">
                            <Spin></Spin>
                            <div>Fetching Data. Please wait</div>
                        </Col>
                    </Row>
                </Modal>
                <Col span="24">
                    <Link to={"/app/profile"}>

                        <DematIcon />
                    </Link>
                    <div className="sb-text text-green">Payment Reciept</div>
                </Col>
                <Col span="24" className="paymentSummaryContainer ">
                    <div className='d-flex flex-between align-items-center mb-1'>
                        <div className="text-light-black r-text ">Payment Summary</div>
                        <div className="text-green r-text "> {paymentStatus} </div>
                    </div>
                    <div className="d-flex flex-between align-items-center">
                        <div className="text-grey s-text fw-500 ">Select No. of Years</div>
                        <Select
                            onChange={(val) => dispatch(setPaymentYears(val))
                            }
                            optionLabelProp="customlabel"
                            className="borderedSelect"
                            value={paymentYears}
                        // disabled={true}
                        >
                            {yearsOptions.map((each: any, index: number) => {
                                return (
                                    <Select.Option
                                        key={index}
                                        value={each.value}
                                        customlabel={<div>{each.label}</div>}
                                    >
                                        <SelectLabelDisplay
                                            label={each.label}
                                            value={each.value}
                                            currentVal={paymentYears}
                                        />
                                    </Select.Option>
                                );
                            })}
                        </Select>
                    </div>
                    <Divider />
                    {form?.demat && (
                        <>
                            <PaymentLabel
                                label="DEMAT Account Opening Charge"
                                value={rateDataVal.dematOpeningVal}
                            />
                            <PaymentLabel
                                label="DEMAT Annual Maintaince Charge"
                                value={rateDataVal.dematVal! * paymentYears}
                            />
                        </>
                    )}
                    {form?.meroshare && (
                        <PaymentLabel
                            label="Meroshare Annual Charge"
                            value={rateDataVal.meroshareVal! * paymentYears}
                        />
                    )}
                    {form?.trading && (
                        <PaymentLabel
                            label="Trading Account Opening Form"
                            value={rateDataVal.tradingVal}
                        />
                    )}
                    <Divider />
                    <div className="d-flex w-100p flex-between mt-2">
                        <div className="text-light-black r-text fw-600">Total</div>
                        <div className="text-light-black r-text fw-600">Rs. {total}</div>
                    </div>

                </Col>

                {paymentStatus !== "PAID" &&

                    <div className=" mt-2 ">
                        <Col xs={24} lg={12}>
                            <SubLabelWrapper
                                label="Add Payment"
                                subLabel="Please select a Payment Method"
                                render={
                                    <Row gutter={[16, 16]}>
                                        <Col xs={24} lg={12}>
                                            <PaymentCheckBox
                                                label="Khalti"
                                                stateName="khalti"
                                                value={formValues.khalti}
                                                icon={<KhaltiIcon />}
                                            />
                                        </Col >
                                        <Col xs={24} lg={12}>

                                            <PaymentCheckBox
                                                label="Esewa"
                                                stateName="esewa"
                                                value={formValues.esewa}
                                                icon={<EsewaIcon />}
                                            />
                                        </Col>
                                    </Row>
                                }
                            />
                        </Col>
                    </div>
                }
            </ProgressbarLayout>
        </Spin>
    );
};

export default Payment;
