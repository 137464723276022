// import ProtectedLayout from "Layouts/ProtectedLayout";
import { useEffect } from 'react';

import {
  Col,
  Row,
  Spin,
} from 'antd';
import ScrollToTop from 'Components/ScrollToTop';
import { AnimatePresence } from 'framer-motion';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import {
  Redirect,
  Route,
  RouteChildrenProps,
  RouteComponentProps,
  Switch,
  useLocation,
  useParams,
} from 'react-router';
import {
  getKycDetailRequest,
  resetGlobalTimer,
  setGlobalTimer,
} from 'Redux/Protected/Action';
import { RootState } from 'Redux/RootReducer';
import {
  getAddressRequest,
  getBankRequest,
  getBrokerRequest,
  getOrganizationRequest,
  getPaymentRateRequest,
} from 'Redux/Static/Action';

import DocsRequired from '../Views/Static/docsRequired';
import { routeConfig } from './routeConfig';

export const ProtectedRoute = (props: RouteChildrenProps) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const {oid} = useParams<any>()
  const { pathname } = location;
  const { kycDetails, loading, currentUserId, forceReloadCount, kycFormValues } = useSelector(
    (state: RootState) => state.protectedReducer
  );

  useEffect(() => {
    if (!(localStorage.getItem("user-logged-in") === "true")) {
      window.location.replace("/auth/general-details");
    } else {
     
      const userId = localStorage.getItem("userId");
      dispatch(getAddressRequest());
      dispatch(getBankRequest());
      dispatch(getOrganizationRequest());
      dispatch(getKycDetailRequest(userId));
      dispatch(getPaymentRateRequest());
      dispatch(getBrokerRequest());
    }
  }, [dispatch,]);

  useEffect(() => {
    if(pathname === "/app/profile" && Object.keys(kycFormValues).length > 0) {
      const userId = localStorage.getItem("userId");
      dispatch(getKycDetailRequest(userId));

    }
  }, [dispatch, pathname, kycFormValues])



  useEffect(() => {
    let timer: any = null;
    if (currentUserId !== "") {
      timer = setInterval(() => {
        dispatch(setGlobalTimer());
      }, 1000);
    } else {
      clearInterval(timer);
      dispatch(resetGlobalTimer());
    }
    return () => {
      clearInterval(timer);
    };
  }, [currentUserId, forceReloadCount]);

  return (
    <>
      <ScrollToTop />

      {!(localStorage.getItem("user-logged-in") === "true") ? (
        <Row
          justify="center"
          align="middle"
          style={{ textAlign: "center", height: "100vh" }}
        >
          <Col span={24}>
            {" "}
            <Spin size="large" />
          </Col>
        </Row>
      ) : loading ? (
        <div className="d-flex align-items-center flex-center h-100vh">
          <Spin></Spin>
        </div>
      ) : (
      
          <AnimatePresence exitBeforeEnter>
            <Switch location={location} key={location.key}>
              <Redirect
                exact
                from={`${props.match?.path}/`}
                to={`${props.match?.path}/profile`}
              />
              {routeConfig.map((route, index) => {
                return (
                  <Route
                    key={index}
                    path={`${props.match?.path}${route.path}`}
                    exact={route.exact}
                    render={(props2: RouteComponentProps<any>) => (
                      <route.commponent
                        {...props2}
                        // {...route.props}
                        pageNumber={route.pageNumber}
                      />
                    )}
                  />
                );
              })}
              <Route
                path={`${props.match?.path}/dematt/required-documents`}
                exact
                component={DocsRequired}
              />
              <Route
                path={`${props.match?.path}/trading/required-documents`}
                exact
                component={DocsRequired}
              />

              <Redirect to="/error" />
            </Switch>
          </AnimatePresence>
      )}
    </>
  );
};

export default ProtectedRoute;
