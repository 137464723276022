import { ReactComponent as DematIcon } from 'Assets/Icons/demat.svg';
import { Link } from 'react-router-dom';

interface Props {
  title: string;
  direction?: "row" | "column";
}

const DematHeader = (props: Props) => {
  return (
    <>
      {props.direction === "column" ? (
        <div className='d-flex align-items-center '>
          <Link to={'/app/profile'}>

            <DematIcon />
          </Link>
          <div className="r-text text-grey fw-500 ml-1">{props.title} Account Opening</div>
          {/* <div className="r-text text-grey fw-500">Account Opening</div> */}
          {/* <div className="r-text text-grey fw-500">Form</div> */}
        </div>
      ) : (
        <>
          <div className="d-flex mb-1" style={{ gap: 15 }}>
            <DematIcon />
            <div>
              <div className="r-text text-grey fw-500">
                {props.title} Account
              </div>
              <div className="r-text text-grey fw-500">Opening Form</div>
            </div>
          </div>
        </>
      )}
    </>
  );
};
DematHeader.defaultProps = {
  title: "DEMAT",
  direction: "column",
};

export default DematHeader;
