//TODO : BACK HANDLER ANIMATION ko case
//AILEY static one side right to left case matra gareko cha

export const animationVariant = {
  hidden: {
    x: "100vw",
    opacity: 0,
  },
  visible: {
    x: 0,
    opacity: 1,
    transition: {
      duration: 0.6,
      ease: "easeInOut",
      delay: 0.3,
    },
  },
  exit: {
    x: "-100vw",
    opacity: 0,
    transition: {
      duration: 0.6,
      ease: "easeInOut",
    },
  },
};

export const OPACITY_1 = { opacity: 1 };
export const OPACITY_0 = { opacity: 0 };
