import { ReactComponent as ScanIcon } from 'Assets/Icons/scan.svg';

import {
  LoadingOutlined,
  RightOutlined,
} from '@ant-design/icons';

interface Props {
  disabled: boolean;
  onClick: () => void | any;
  loading?: boolean;
  type: "forward" | "scan";
}

const ForwardButton = ({ disabled, onClick, loading, type }: Props) => {
  return (
    <div
      className={`forwardBtn ${
        disabled && "btnDisabled"
      }`}
      onClick={() => {
        if (!loading && !disabled) onClick();
      }}
    >
      {loading ? (
        <LoadingOutlined className="forwardIcon" />
      ) : type === "forward" ? (
        <RightOutlined className="forwardIcon" />
      ) : (
        <ScanIcon className="forwardIcon" />
      )}
    </div>
  );
};

ForwardButton.defaultProps = {
  loading: false,
  type: "forward",
  onClick: () => {},
  disabled: false,
};

export default ForwardButton;
