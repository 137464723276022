import React, {
  ChangeEvent,
  useEffect,
  useState,
} from 'react';

import {
  Col,
  Form,
  Input,
  Row,
  Select,
  Typography,
} from 'antd';
import { ReactComponent as PlusIcon } from 'Assets/Icons/plus.svg';
import LabelWrapper from 'Components/LabelWrapper';
import OutlineButton from 'Components/OutlineButton';
import SubLabelWrapper from 'Components/SubLabelWrapper';
import { childrenOptions } from 'Constants/SelectDefaultOptions';
import { useSetKycFormValue } from 'CustomHooks/useSetKycFormValue';
import { IPageProps } from 'Interfaces/Page';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import {
  clearSuccess,
  setKycFormValues,
  updateKycDetailRequest,
} from 'Redux/Protected/Action';
import { RootState } from 'Redux/RootReducer';
import { VALIDATIONS } from 'Utils/formValidationRules';
import RenderFooter from 'Views/Dmat/components/RenderFooter';
import ProgressbarLayout from 'Views/Dmat/Layout/ProgressbarLayout';

import { MinusCircleOutlined } from '@ant-design/icons';

const initialFormValues = {
  father_name: "",
  mother_name: "",
  grandfather_name: "",
  grandmother_name: ""
}

const inLawData = [
  { label: "Spouse", value: "Spouse" },
  { label: "Father-in-law", value: "Father-in-law" },
  { label: "Mother-in-law", value: "Mother-in-law" },
  { label: "Grandfather-in-law", value: "Grandfather-in-law" },
  { label: "GrandMother-in-law", value: "GrandMother-in-law" },
];

export type dropdownInputType = { relation: string; name: string };
const initialValues: dropdownInputType = {
  relation: "Spouse",
  name: "",
};

export interface InlawsType {
  relation: string;
  name: string;
}

export interface InitialValueType {
  inLaws: InlawsType[];
  children: InlawsType[];

}


export const FamilyDetails = ({ history, pageNumber }: IPageProps) => {
  //var
  const { kycFormValues, success, actionLoading, globalTimer } =
    useSelector((state: RootState) => state?.protectedReducer)

  //hooks
  const [form] = Form.useForm();
  const dispatch = useDispatch()
  const { oid } = useSetKycFormValue()


  //state
  const [formValues, setFormValues] = useState(initialFormValues)
  const [maritalStatus, setMaritalStatus] = useState<"married" | "unmarried">("unmarried")
  const [counter, setCounter] = useState(0);
  const [inivalue, setIniValue] = useState<InitialValueType>({
    inLaws: kycFormValues?.family_details?.in_laws?.length
      ? kycFormValues?.family_details?.in_laws
      : [{ relation: "Spouse", name: "" }],
    children: kycFormValues?.family_details?.children?.length
      ? kycFormValues?.family_details?.children
      : [{ relation: "Son", name: "" }],
  });
  const [firstChild, setFirstChild] = useState("");



  const dynamicEmailChange = (e: any, key: any) => {
    if (key === 0) setFirstChild(e.target.value);
  };



  useEffect(() => { //success handler
    if (success) {
      if (history.location.pathname.includes("dematt")) {
        history.push(`/app/${oid}/dematt/occupations`)
      } else {
        history.push(`/app/${oid}/trading/occupations`)

      }

    }

    return () => {
      dispatch(clearSuccess())
    };
  }, [success]);


  useEffect(() => { //set family details

    if (kycFormValues.family_details) {

      let fetchVal = {
        father_name: kycFormValues.family_details.father_name || "",
        mother_name: kycFormValues.family_details.mother_name || "",
        grandfather_name: kycFormValues.family_details.grandfather_name || "",
        grandmother_name: kycFormValues.family_details.grandmother_name || "",
      }

      form.setFieldsValue(fetchVal)
      setFormValues(fetchVal)
    }

    if (kycFormValues?.family_details?.married) {
      setMaritalStatus("married")
    }


  }, [kycFormValues.family_details])



  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFormValues({
      ...formValues,
      [e.target.name]: e.target.value
    })
  }

  const backHandler = () => {
    if (history.location.pathname.includes("dematt")) {
      history.push(`/app/${oid}/dematt/your-details`)
    } else {
      history.push(`/app/${oid}/trading/your-details`)
    }

  }

  const submitHandler = () => {
    form
      .validateFields()
      .then((values) => {
        submitForm();
      })
      .catch((errorInfo: any) => {
        const element = document.getElementById(`${errorInfo.errorFields[0].name[0]}`)
        element?.scrollIntoView({ behavior: "smooth" })
      });
  };

  const submitForm = () => {


    let familyDetails: any = {
      father_name: formValues.father_name || "",
      mother_name: formValues.mother_name || "",
      grandfather_name: formValues.grandfather_name || "",
    }

    if (formValues.grandmother_name) {
      familyDetails = {
        ...familyDetails,
        grandmother_name: formValues.grandmother_name || "",

      }
    }

    if (maritalStatus === "married") {
      familyDetails = {
        ...familyDetails,
        married: true,
        in_laws: form.getFieldValue("inLaws"),
        children: form.getFieldValue("children"),
      }
    } else {
      familyDetails = {
        ...familyDetails,
        married: false
      }
    }

    dispatch(setKycFormValues({
      ...kycFormValues,
      family_details: familyDetails
    }))
    dispatch(updateKycDetailRequest({
      family_details: familyDetails,
    }))


  }


  return (
    <ProgressbarLayout
      progressLeftText={history.location.pathname.includes('dematt') ? "30%" : "45%"}
      progressRightText={globalTimer}
      progressPercent={history.location.pathname.includes('dematt') ? 30 : 45}
      iconTitle={
        history.location.pathname.includes('dematt') ? 'DEMAT' : 'TRADING'
      }
      title=''
      animationIn={history.action === 'PUSH' ? true : false}
      renderFooter={
        <RenderFooter
          loading={actionLoading}
          backHandler={backHandler}
          //@ts-ignore
          submitHandler={submitHandler}
        />
      }
      path='family-details'
    >
      < >
        <div>

          <Form
            form={form}
            layout="horizontal"
            name="basic"
            onFinish={submitHandler}
            initialValues={inivalue}
          >
            <Row gutter={[16, 16]} className='groupWrapper '>
              <Col xs={24}>
                <Typography className={`r-text fw-500 text-light-black `}>Family Details </Typography>

              </Col>
              <Col xs={24} lg={12} id='father_name'>
                <LabelWrapper
                  label="Father's Name"
                  render={
                    <Form.Item
                      name="father_name"
                      rules={VALIDATIONS.father_name}
                      initialValue={formValues.father_name}
                    >
                      <Input
                        name="father_name"
                        bordered={false}
                        className="underlineInput"
                        // value={formValues.father_name}
                        onChange={handleInputChange}
                      />
                    </Form.Item>
                  }
                />
              </Col>
              <Col xs={24} lg={12} id='mother_name' >
                <LabelWrapper
                  label=" Mother's Name"
                  render={
                    <Form.Item
                      name="mother_name"
                      rules={VALIDATIONS.mother_name}
                      initialValue={formValues.mother_name}
                    >
                      <Input
                        name="mother_name"
                        bordered={false}
                        className="underlineInput"
                        onChange={handleInputChange}
                      // value={formValues.mother_name}
                      />
                    </Form.Item>
                  }
                />
              </Col>
              <Col xs={24} lg={12} id='grandfather_name'>
                <LabelWrapper
                  label="Grandfather's Name"
                  render={
                    <Form.Item
                      name="grandfather_name"
                      rules={VALIDATIONS.grandfather_name}
                      initialValue={formValues.grandfather_name}
                    >
                      <Input
                        name="grandfather_name"
                        bordered={false}
                        className="underlineInput"
                        onChange={handleInputChange}
                      // value={formValues.grandfather_name}
                      />
                    </Form.Item>
                  }
                />
              </Col>
              <Col xs={24} lg={12} id='grandmother_name' >
                <LabelWrapper
                  label="Grandmother's Name (Optional)"
                  render={
                    <Form.Item
                      name="grandmother_name"
                      rules={VALIDATIONS.grandmother_name}
                      initialValue={formValues.grandmother_name}
                    >
                      <Input
                        name="grandmother_name"
                        bordered={false}
                        className="underlineInput"
                        onChange={handleInputChange}
                      // value={formValues.grandfather_name}
                      />
                    </Form.Item>
                  }
                />
              </Col>
              <SubLabelWrapper
                label="Married Status"
                subLabel="Please select married status"
                render={
                  <Row gutter={[16, 16]}>
                    <Col xs={24} lg={6} style={{ marginTop: "1rem" }}>
                      <OutlineButton
                        label="Married"
                        active={maritalStatus === "married" && true}
                        onClick={() => {
                          setMaritalStatus("married")
                        }}
                      />
                    </Col>
                    <Col xs={24} lg={6} style={{ marginTop: "1rem" }}>
                      <OutlineButton
                        label="Unmarried"
                        active={maritalStatus === "unmarried" && true}
                        onClick={() =>
                          setMaritalStatus("unmarried")

                        }
                      />
                    </Col>
                  </Row>
                }
              />
            </Row>

            {maritalStatus === "married" &&

              <div className='groupWrapper mt-2'>
                <Typography className={`r-text fw-500 text-light-black `}>Maritial Information </Typography>

                <div className="dynamicForm">
                  <Form.List name="inLaws">
                    {(fields, { add, remove }) => (
                      <>
                        {fields.map(({ key, name, fieldKey }) => (
                          <Form.Item style={{ marginBottom: 0 }} key={key}>
                            <Row gutter={10} key={counter} align="middle">
                              <Col span="8">
                                <Form.Item
                                  name={[name, "relation"]}
                                  fieldKey={[fieldKey, "relation"]}
                                  rules={VALIDATIONS.relation}
                                >
                                  <Select
                                    placeholder="Select Relation Type"
                                    style={{ width: "100%" }}
                                    disabled={key ? false : true}
                                    className="underlineSelect"
                                  >
                                    {inLawData
                                      .filter(
                                        (itm) =>
                                          !form
                                            .getFieldValue("inLaws")
                                            .map((inlaw: any) => inlaw?.relation)
                                            .includes(itm.label)
                                      )
                                      .map((each: any, index: number) => {
                                        return (
                                          <Select.Option
                                            key={index}
                                            value={each.value}
                                          >
                                            {each.value}
                                          </Select.Option>
                                        );
                                      })}
                                  </Select>
                                </Form.Item>
                              </Col>
                              <Col span="14">
                                <Form.Item
                                  name={[name, "name"]}
                                  fieldKey={[fieldKey, "name"]}
                                  rules={VALIDATIONS.full_name}
                                >
                                  <Input placeholder="Name" bordered={false}
                                    className="underlineInput" />
                                </Form.Item>
                              </Col>
                              <Col span="2">
                                <MinusCircleOutlined
                                  onClick={() => remove(name)}
                                  style={{
                                    color: "#c74242",
                                    position: "absolute",
                                    right: 0,
                                    bottom: 5,
                                  }}
                                />
                              </Col>
                            </Row>{" "}
                          </Form.Item>
                        ))}
                        <Form.Item>
                          {fields?.length < 5 ? (
                            <div className="d-flex w-100p flex-center">
                              <PlusIcon
                                className="cursor-hover"
                                onClick={() => add()}
                              />
                            </div>
                          ) : null}
                        </Form.Item>
                      </>
                    )}
                  </Form.List>

                  <div className="dynamicForm">
                    <Form.List name="children">
                      {(fields, { add, remove }) => (
                        <>
                          {fields.map(({ key, name, fieldKey }) => (
                            <Form.Item style={{ marginBottom: 0 }} key={key}>
                              <Row gutter={10} key={counter} align="middle">
                                <Col span="8">
                                  <Form.Item
                                    name={[name, "relation"]}
                                    fieldKey={[fieldKey, "relation"]}
                                  // rules={VALIDATIONS.relation}
                                  >
                                    <Select
                                      placeholder="Select Relation Type"
                                      style={{ width: "100%" }}
                                      // disabled={key ? false : true}
                                      className="underlineSelect"
                                      options={childrenOptions}
                                    />
                                  </Form.Item>
                                </Col>
                                <Col span="14">
                                  <Form.Item
                                    name={[name, "name"]}
                                    fieldKey={[fieldKey, "name"]}
                                  // rules={VALIDATIONS.full_name}
                                  >
                                    <Input
                                      placeholder="Name"
                                      bordered={false}
                                      className="underlineInput"
                                      onChange={(e) => dynamicEmailChange(e, key)}
                                    />
                                  </Form.Item>
                                </Col>
                                <Col span="2">
                                  <MinusCircleOutlined
                                    onClick={() => remove(name)}
                                    style={{
                                      color: "#c74242",
                                      position: "absolute",
                                      right: 0,
                                      bottom: 5,
                                    }}
                                  />
                                </Col>
                              </Row>{" "}
                            </Form.Item>
                          ))}
                          <Form.Item>
                            <div className="d-flex w-100p flex-center">
                              <PlusIcon
                                className="cursor-hover"
                                onClick={() => add()}
                              />
                            </div>
                          </Form.Item>
                        </>
                      )}
                    </Form.List>

                  </div>

                </div>
              </div>
            }
          </Form>
        </div>
      </>
    </ProgressbarLayout>
  )
}
