import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
} from 'react';

import {
  Col,
  Form,
  FormInstance,
  Input,
  Row,
  Select,
  Typography,
} from 'antd';
import { RcFile } from 'antd/lib/upload';
import CommonImagePicker from 'Components/CommonImagePicker';
import DatePicker from 'Components/CustomDatePicker';
import LabelWrapper from 'Components/LabelWrapper';
import SelectLabelDisplay from 'Components/SelectLabelDisplay';
import SubLabelWrapper from 'Components/SubLabelWrapper';
import { genderOptions } from 'Constants/SelectDefaultOptions';
import dayjs from 'dayjs';
import { MinorDetails } from 'Interfaces/Page';
import NepaliDate from 'nepali-date-converter';
import { NepaliDatePicker } from 'nepali-datepicker-reactjs';
import { useSelector } from 'react-redux';
import { RootState } from 'Redux/RootReducer';
import { createDistrictList } from 'Utils/createDistrictList';
import { disabledDateValidationMinor } from 'Utils/DateValidation';
import { VALIDATIONS } from 'Utils/formValidationRules';
import converToNepalDate, { formEnglishDate } from 'Utils/NepaliDateHelper';

interface MinorFormProps {
    setSelectedMinorProfile: Dispatch<SetStateAction<RcFile>>
    setSelectedMinorCertificate: Dispatch<SetStateAction<RcFile>>
    formValues: MinorDetails
    setFormValues: Dispatch<SetStateAction<MinorDetails>>
    form: FormInstance
}

export const MinorForm = ({ setSelectedMinorProfile, setSelectedMinorCertificate, formValues, setFormValues, form }: MinorFormProps) => {
    //var
    const { kycFormValues, } =
        useSelector((state: RootState) => state?.protectedReducer)
    const { addressDetails, addressLoading } = useSelector(
        (state: RootState) => state.staticReducer
    );

    //state
    const [districtDetails, setDistrictDetails] = useState<any>([]);

    useEffect(() => {
        //set address list
        if (addressDetails.length) {
            const requiredDistrictList = createDistrictList({
                addressArray: addressDetails,
            });
            setDistrictDetails(requiredDistrictList);
        }
    }, [addressDetails]);

    const handleInputChange = (e: any) => { //set input form values
        setFormValues({ ...formValues, [e.target.name]: e.target.value });
    };

    const handleSelectChange = (e: any, name: string) => { //set select input values
        setFormValues({ ...formValues, [name]: e });
    };

    const onChangeDate = (date: any, dateString: string) => { //set date input values
        if (dateString === "issued") {
            const issued_np = converToNepalDate(date);

            form.setFieldsValue({
                issued_date: date,
                issued_np: issued_np,
            });
            setFormValues({ ...formValues, issued_date: date, issued_np });
        }
        if (dateString === "dob") {
            const dob_np = converToNepalDate(date);
            form.setFieldsValue({
                dob: date,
                dob_np: dob_np,
            });
            setFormValues({ ...formValues, dob: date, dob_np });
        }
    };

    const handleNepaliDateChange2 = (date: string, dateString:string) => { //covert nepali date to english and set form values
        if (date) {
            const englishDateObject = new NepaliDate(date).getAD();
            const englishDate = formEnglishDate(englishDateObject);

            if(dateString === "issued") {
                form.setFieldsValue({ issued_np: date, issued_date: dayjs(englishDate) });
                setFormValues({
                    ...formValues,
                    issued_np: date,
                    issued_date: dayjs(englishDate),
                });
            }
            if(dateString === "dob") {
                form.setFieldsValue({ dob_np: date, dob: dayjs(englishDate) });
                setFormValues({
                    ...formValues,
                    dob_np: date,
                    dob: dayjs(englishDate),
                });
            }

            
        }
    };

    return (
        <Row gutter={[16, 16]} className='mt-1 '>
            <Col lg={12} className='groupWrapper'>
                <Row gutter={[16, 16]}>
                    <Col xs={12}>
                        <SubLabelWrapper
                            label='Upload minor photo'
                            subLabel="Please perform this step under good lighting condition"
                            render={
                                <Form.Item name={"minor_profile"} rules={[{
                                    required: true,
                                    message: "Photo is required"
                                }]}>
                                    <CommonImagePicker
                                        imageUrl={kycFormValues?.minor_details?.profile}
                                        onSelect={(file) => setSelectedMinorProfile(file)}

                                        circleImage
                                    />
                                </Form.Item>
                            }
                        />
                    </Col>
                    <Col xs={12}>
                        <SubLabelWrapper
                            label='Upload Birth Certificate'
                            subLabel="Please perform this step under good lighting condition"
                            render={
                                <Form.Item name={"birth_certificate"} rules={[{
                                    required: true,
                                    message: "Birth certificate is required"
                                }]}>
                                    <CommonImagePicker
                                        onSelect={(file) => setSelectedMinorCertificate(file)}
                                        imageUrl={
                                            kycFormValues?.minor_details?.birth_certificate_image ?? ""
                                        }
                                    />
                                </Form.Item>
                            }
                        />
                    </Col>
                </Row>
            </Col>
            <Row gutter={[16, 16]} className='groupWrapper w-100p'>
                <Col xs={24}>
                    <Typography className={`r-text fw-500 text-light-black `}>Minor Information </Typography>

                </Col>
                <Col xs={24} lg={12}>
                    <LabelWrapper
                        label="Name Of Beneficial Owner"
                        render={
                            <Form.Item name="name"
                                initialValue={kycFormValues.username}
                            >
                                <Input
                                    bordered={false}
                                    className="underlineInput"
                                    name="name"
                                    onChange={handleInputChange}
                                    disabled={true}
                                />
                            </Form.Item>
                        }
                    />
                </Col>
                <Col xs={24} lg={12}>
                    <LabelWrapper
                        label="Gender"
                        render={
                            <Form.Item
                                name="gender"
                                rules={VALIDATIONS.gender}
                                initialValue={formValues.gender}
                            >
                                <Select
                                    className="underlineSelect"
                                    onChange={(e) => handleSelectChange(e, "gender")}
                                    optionLabelProp="customlabel"
                                >
                                    {genderOptions.map((each: any, index: number) => {
                                        return (
                                            <Select.Option
                                                key={index}
                                                value={each.value}
                                                customlabel={<div>{each.label}</div>}
                                            >
                                                <SelectLabelDisplay
                                                    label={each.label}
                                                    value={each.value}
                                                    currentVal={formValues.gender}
                                                />
                                            </Select.Option>
                                        );
                                    })}
                                </Select>
                            </Form.Item>
                        }
                    />
                </Col>
                <Col xs={24} lg={12}>
                    <LabelWrapper
                        label="Date of Birth (AD)"
                        render={
                            <div className="datepicker-underline">
                                <Form.Item rules={VALIDATIONS.dob} name="dob">
                                    <DatePicker
                                        onChange={(date) => onChangeDate(date, "dob")}
                                        placeholder=""
                                        value={formValues.dob ? formValues.dob : null}
                                        bordered={false}
                                        className="w-100p pl-0"
                                        disabledDate={disabledDateValidationMinor}
                                    // className="underlineInput"
                                    />
                                </Form.Item>
                            </div>
                        }
                    />
                </Col>
                <Col xs={24} lg={12}>
                    <LabelWrapper
                        label="Date of Birth (BS)"
                        render={
                            <Form.Item rules={VALIDATIONS.dob} name="dob_np">
                                <div className="nepali-calendar-wrapper">
                                    <div className="nepali-calendar-value">
                                        {formValues.dob_np}
                                    </div>

                                    <NepaliDatePicker
                                        className="nepali-calendar"
                                        onSelect={(date: any) => {
                                            handleNepaliDateChange2(date, "dob");
                                        }}
                                        value={formValues.dob_np}
                                    />
                                </div>
                            </Form.Item>
                        }
                    />
                </Col>
            </Row>
            <Row gutter={[16, 16]} className='groupWrapper mt-1 w-100p '>
                <Col xs={24}>
                    <Typography className={`r-text fw-500 text-light-black `}>Birth Certificate Information </Typography>

                </Col>
                <Col xs={24} lg={12}>
                    <LabelWrapper
                        label="Birth Certificate Number"
                        render={
                            <Form.Item
                                name="birth_certificate_number"
                                rules={VALIDATIONS.birth_certificate_number}
                            >
                                <Input
                                    bordered={false}
                                    className="underlineInput"
                                    name="birth_certificate_number"
                                    // value={formValues.birth_certificate_number}
                                    onChange={handleInputChange}
                                />
                            </Form.Item>
                        }
                    />
                </Col>
                <Col xs={24} lg={12}>
                    <LabelWrapper
                        label="Issued Date (AD)"
                        render={
                            <div className="datepicker-underline">

                                <Form.Item rules={VALIDATIONS.issued_date} name="issued_date">
                                    <DatePicker
                                        onChange={(date) => onChangeDate(date, "issued")}
                                        placeholder=""
                                        value={formValues.issued_date ? formValues.issued_date : null}
                                        bordered={false}
                                        className="w-100p br-8"
                                        disabledDate={disabledDateValidationMinor}
                                    />
                                </Form.Item>
                            </div>

                        }
                    />
                </Col>
                <Col xs={24} lg={12}>
                    <LabelWrapper
                        label="Issued Date(BS)"
                        render={
                            <Form.Item rules={VALIDATIONS.issued_date} name="issued_np">
                                <div className="nepali-calendar-wrapper">
                                    <div className="nepali-calendar-value">
                                        {formValues.issued_np}
                                    </div>

                                    <NepaliDatePicker
                                        className="nepali-calendar"
                                        onSelect={(date: any) => {
                                            handleNepaliDateChange2(date, "issued");
                                        }}
                                    />
                                </div>
                            </Form.Item>
                        }
                    />
                </Col>
                <Col xs={24} lg={12}>
                    <LabelWrapper
                        label="Issued District"
                        render={
                            <Form.Item
                                name="issued_district"
                                rules={VALIDATIONS.issued_district}
                            >
                                <Select
                                    value={formValues.issued_district}
                                    loading={addressLoading}
                                    showSearch={true}
                                    onChange={(e) => handleSelectChange(e, "issued_district")}
                                    optionLabelProp="customlabel"
                                    className="underlineSelect"
                                >
                                    {districtDetails?.length &&
                                        districtDetails?.sort().map((each: any, index: number) => {
                                            return (
                                                <Select.Option
                                                    key={index}
                                                    value={each}
                                                    customlabel={<div>{each}</div>}
                                                >
                                                    <SelectLabelDisplay
                                                        currentVal={formValues.issued_district}
                                                        label={each}
                                                        value={each}
                                                    />
                                                </Select.Option>
                                            );
                                        })}
                                </Select>
                            </Form.Item>
                        }
                    />
                </Col>
            </Row>
        </Row>
    )
}
