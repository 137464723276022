import { Upload, message } from "antd";
import { CameraFilled } from "@ant-design/icons";
import { checkValidFile } from "Utils/checkValidFile";
import { dummyRequest } from "Utils/DummyCall";
import { RcFile } from "antd/lib/upload";

const { Dragger } = Upload;
//NOT USED
interface Props {
  onSelect: (file: any, imageData: any) => void;
  label: string;
}

const toBase64 = (file: any) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const BoidLetterUpload = ({ onSelect, label }: Props) => {
  const beforeUpload = async (file: RcFile) => {
    const isPdf = file.type === "application/pdf";
    if (!isPdf) {
      message.error("You can only upload pdf file");
    } else {
      const checkFile = await checkValidFile(file);
      if (!checkFile) {
        return;
      }
      const isLt4M = file.size / 1024 / 1024 < 4;
      if (!isLt4M) {
        message.error("Image must be smaller than 4MB!");
        return;
      }
      const imageData = await toBase64(file);
      onSelect(file, imageData);
    }
  };

  return (
    <div>
      <Dragger
        multiple={false}
        className="imagepicker-dragger"
        accept=".pdf"
        style={{
          background: "#F6F8FA",
          borderColor: "#0AB290",
          height: "180px",
          width: "216px",
          borderRadius: "8px",
        }}
        showUploadList={false}
        customRequest={dummyRequest}
        beforeUpload={beforeUpload}
      >
        <p className="ant-upload-drag-icon">
          <CameraFilled style={{ color: "#00DDA2", fontSize: 60 }} />
        </p>
        <p
          className="xs-text text-light-grey text-center"
          style={{ paddingInline: "1vw" }}
        >
          {label}
        </p>
      </Dragger>
      <br />
    </div>
  );
};

BoidLetterUpload.defaultProps = {
  label: "Upload Meroshare PDF",
};

export default BoidLetterUpload;
