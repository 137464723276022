import { getTickerList, updateUserWatchList } from "Api/WatchlistApi";
import CustomButton from "Components/CustomButton";
import { CustomNotification } from "Components/CustomNotification";
import Modal from "Components/Modal";
import { Ticker } from "Interfaces/watchList";
import { RootState } from "Redux/RootReducer";
import {
  AutoComplete,
  Button,
  Input,
  ModalFuncProps,
  ModalProps,
  Space,
  Tag,
  Typography,
  message,
} from "antd";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { styled } from "styled-components";

const StyledTag = styled(Tag)`
  background-color: #f1f1f1;
  border-radius: 10px;
  display: flex;
  font-weight: bold;
  align-items: center;
  justify-content: center;
  padding: 5px 10px;
  gap: 10px;
`;

type Props = {
  userWatchListSlugList: string[];
  setUserWatchListSlugList: Dispatch<SetStateAction<string[]>>;
} & ModalProps &
  ModalFuncProps;

const AddWatchListModal = ({
  userWatchListSlugList,
  setUserWatchListSlugList,
  ...rest
}: Props) => {
  const screens = useBreakpoint();
  const [searchKey, setSearchKey] = useState("");
  const [searchOptions, setSearchOptions] = useState<{ value: string }[]>([]);

  const [watchList, setWatchList] = useState<Ticker[]>([]);
  const [userWatchList, setUserWatchList] = useState<string[]>([]);

  const {
    kycDetails: { id: currentUserId },
  } = useSelector((state: RootState) => state.protectedReducer);

  const handleSelect = (value: string) => {
    if (!userWatchList.includes(value)) {
      setUserWatchList([...userWatchList, value]);
    }
    setSearchOptions(watchList.map((val) => ({ value: val?.ticker })));
    setSearchKey("");
    // message.success(`Added ${value} to watch list`, 1);

    CustomNotification({
      type: "success",
      title: `Added ${value} to watch list`,
      description: "",
    });
  };

  const handleSearch = (text: string) => {
    setSearchOptions(
      watchList
        ?.filter((val) =>
          val?.ticker?.toLowerCase().includes(text.toLowerCase())
        )
        .map((val) => ({ value: val?.ticker }))
    );
  };

  useEffect(() => {
    getTickerList().then((data) => {
      setWatchList(data);
    });
  }, []);

  useEffect(() => {
    setUserWatchList(userWatchListSlugList);
  }, [userWatchListSlugList]);

  useEffect(() => {
    setSearchOptions(watchList.map((val) => ({ value: val?.ticker })));
  }, [watchList]);

  return (
    <Modal {...rest} closable={false}>
      <Typography.Title level={4}> Add Watch List</Typography.Title>

      <AutoComplete
        // allowClear
        // placeholder="Search for a stock"
        style={{
          width: "100%",
          marginTop: "20px",
        }}
        value={searchKey}
        onChange={(val) => setSearchKey(val)}
        options={searchOptions}
        onSelect={handleSelect}
        onSearch={handleSearch}
      >
        <Input.Search
          value={searchKey}
          placeholder="Search for a stock"
          style={{}}
        />
      </AutoComplete>

      <Space style={{ marginTop: "30px" }} wrap size={15}>
        {userWatchList?.map((val) => (
          <StyledTag
            closable
            onClose={() => {
              setUserWatchList((list) => list.filter((v) => v !== val));
              // message.error(`Removed ${val} from watch list`, 1);
              CustomNotification({
                type: "error",
                title: `Removed ${val} from watch list`,
                description: "",
              });
            }}
            className="neumorphic"
            style={{ border: "none" }}
            key={val}
          >
            {val}
          </StyledTag>
        ))}
      </Space>

      <Space
        style={{
          marginTop: "50px",
          display: "flex",
          justifyContent: screens?.lg ? "flex-end" : "center",
          flexDirection: screens?.lg ? "row" : "column-reverse",
        }}
        size="middle"
        wrap
      >
        <Button
          onClick={() => {
            rest?.onCancel?.();
          }}
          type="link"
          shape="round"
          style={{ padding: "0px 50px" }}
        >
          Cancel
        </Button>
        <Button
          onClick={() => {
            updateUserWatchList(currentUserId, userWatchList);
            setUserWatchListSlugList(userWatchList);
            rest?.onCancel && rest.onCancel();
          }}
          className="submit-btn"
          shape="round"
          style={{ padding: "0px 50px" }}
        >
          {"Save"}
        </Button>
      </Space>
    </Modal>
  );
};

export default AddWatchListModal;
