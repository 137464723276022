const FINAL_APIS = {
  REGION: process.env.REACT_APP_REGION,
  BACKEND: process.env.REACT_APP_BACKEND_API,
  PAYMENT: process.env.REACT_APP_PAYMENT_API,
  DOCKER_API: process.env.REACT_APP_DOCKER_API,
};

const FINAL_COGNITO = {
  REGION: process.env.REACT_APP_REGION,
  USER_POOL_ID: process.env.REACT_APP_USER_POOL_ID,
  USERPOOL_WEB_CLIENT_ID: process.env.REACT_APP_USERPOOL_WEB_CLIENT_ID,
  IDENTITY_POOL_ID: process.env.REACT_APP_IDENTITY_POOL_ID,
};
const FINAL_STORAGE = {
  BUCKET: process.env.REACT_APP_BUCKET,
  REGION: process.env.REACT_APP_REGION,
};


const NEPSE_BASE_URL = process.env.REACT_APP_NEPSE_BASE_URL;

export const ApiHeaders = {
  "Content-Type": "application/json",
};



const config = {
  cognito: FINAL_COGNITO,
  apiGateway: FINAL_APIS,
  storage: FINAL_STORAGE,
  frontendBaseUrl: window.location.origin,
  nepseApi: NEPSE_BASE_URL,
  esewaUrl: process.env.REACT_APP_ESEWA_URL,
};

export default config;
