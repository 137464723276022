import React, {
  Dispatch,
  SetStateAction,
  useEffect,
} from 'react';

import {
  Col,
  Row,
} from 'antd';
import OutlineButton from 'Components/OutlineButton';
import SubLabelWrapper from 'Components/SubLabelWrapper';
import { useSelector } from 'react-redux';
import { RootState } from 'Redux/RootReducer';

interface KarjaSuchanaProps {
    karjaKalosuchi: boolean
    setKarjaKalosuchi: Dispatch<SetStateAction<boolean>>

}

export const KarjaSuchana = ({ karjaKalosuchi, setKarjaKalosuchi }: KarjaSuchanaProps) => {
    //var
    const { kycFormValues, } =
        useSelector((state: RootState) => state?.protectedReducer);

    useEffect(() => { //set value
        if (kycFormValues?.trading && Object.keys(kycFormValues?.trading).length) {
            const values = kycFormValues?.trading;
            if (values?.karja_kalosuchi) {
                setKarjaKalosuchi(true)
            } else {
                setKarjaKalosuchi(false)

            }
        }

    }, [kycFormValues, setKarjaKalosuchi])

    return (
        <Row gutter={[16, 16]} className='groupWrapper'>
            <Col xs={24} lg={12} >
                <SubLabelWrapper
                    label="Tapai karjaa suchana khendra ltd. ko kaalosuchima rahanu bhayeko cha? :"
                    subLabel=""
                    render={
                        <Row gutter={[16, 16]}>
                            <Col xs={12} lg={6} style={{ marginTop: "1rem" }}>
                                <OutlineButton
                                    label="Yes"
                                    active={karjaKalosuchi}
                                    onClick={() => {
                                        setKarjaKalosuchi(true)
                                    }}
                                />
                            </Col>
                            <Col xs={12} lg={6} style={{ marginTop: "1rem" }}>
                                <OutlineButton
                                    label="No"
                                    active={!karjaKalosuchi}
                                    onClick={() =>
                                        // setValues({ yesVal: false, noVal: true, pressed: true })
                                        setKarjaKalosuchi(false)
                                    }
                                />
                            </Col>
                        </Row>
                    }
                />
            </Col>
        </Row>
    )
}
