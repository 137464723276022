import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import {
  Button,
  message,
  Spin,
} from 'antd';
import {
  GOOGLE_MAPS_KEY,
  MAP_STYLES,
} from 'Api/MapConfig';
import { ReactComponent as BackMapIcon } from 'Assets/Icons/mapBack.svg';
import GooglePlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-google-places-autocomplete';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { ProtectedActionCreators } from 'Redux/Protected';
import { RootState } from 'Redux/RootReducer';

import { RightOutlined } from '@ant-design/icons';
import {
  GoogleMap,
  Marker,
  useJsApiLoader,
} from '@react-google-maps/api';

//pass coordiantes props later to pre-fill markers

interface Props {
  autocompleteValue?: any
  coordinates?: {
    lat: number | undefined
    lng: number | undefined
  }
  mapBackClick: () => void
  screenName: string
  history?: any
  timeVal?: any
  formType?: any
  pageNumber?: string | number
}

const mapContainerStyle = {
  width: '100vw',
  height: '100vh',
}

const libraries: any = ['places']

const options = {
  styles: MAP_STYLES,
  disableDefaultUI: true,
}
// const markerVal: { lat: number | undefined; lng: number | undefined } = {
//   lat: undefined,
//   lng: undefined,
// };

const CustomMapComponent = ({
  autocompleteValue,
  mapBackClick,
  coordinates,
  timeVal,
  formType,
  pageNumber,
}: Props) => {
  const dispatch = useDispatch()
  let history = useHistory()
  const { clearSuccess, setKycFormValues, updateKycDetailRequest } =
    bindActionCreators(ProtectedActionCreators, dispatch)
  const { kycFormValues, success, actionLoading } = useSelector(
    (state: RootState) => state.protectedReducer
  )

  const { oid } = useParams<any>()

  const [marker, setMarker] = useState({
    lat: coordinates?.lat,
    lng: coordinates?.lng,
  })

  const [value, setValue] = useState<any>(null)
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: GOOGLE_MAPS_KEY,
    libraries,
  })

  const mapRef = useRef<any>()
  const onMapLoad = useCallback(
    (map: google.maps.Map) => {
      mapRef.current = map
      if (
        kycFormValues?.location_map &&
        Object.keys(kycFormValues?.location_map).length
      ) {
        pantTo({
          lat: Number(kycFormValues?.location_map?.latitude),
          lng: Number(kycFormValues?.location_map?.longitude),
        })
        setMarker({
          lat: Number(kycFormValues?.location_map?.latitude),
          lng: Number(kycFormValues?.location_map?.longitude),
        })
      }
    },
    [kycFormValues]
  )

  const pantTo = useCallback(({ lat, lng, zoomLevel = 19 }) => {
    mapRef.current.panTo({ lat, lng })
    mapRef.current.setZoom(zoomLevel)
  }, [])

  const onMapClickAndDrag = useCallback((event: google.maps.MapMouseEvent) => {
    setMarker({
      lat: event.latLng?.lat(),
      lng: event.latLng?.lng(),
    })
  }, [])

  useEffect(() => {
    if (marker?.lat && marker?.lng) {
      pantTo({
        lat: marker.lat,
        lng: marker.lng,
        zoomLevel: 17,
      })
    }
  }, [marker])

  const centerVal: any = useMemo(() => {
    if (marker.lat === undefined || marker.lng === undefined) {
      return {
        lat: 27.679667923850467,
        lng: 85.31052126714727,
      }
    } else {
      return {
        lat: marker.lat,
        lng: marker.lng,
      }
    }
  }, [marker])

  useEffect(() => {
    if (success) {
      if (history.location.pathname.includes('dematt')) {
        history.push(`/app/${oid}/dematt/your-details`, { scrollPosition: "location" })
      } else {
        if (kycFormValues?.form?.demat) {
          history.push('/app/trading/terms/nepali')
        } else {
          if (kycFormValues?.form?.trading && kycFormValues?.form?.meroshare) {
            if (kycFormValues?.minor) {
              history.push('/app/trading/transactionrelated')
            } else {
              history.push('/app/trading/individual/thumbprint')
            }
          } else {
            //individual case
            if (kycFormValues?.minor) {
              history.push(`/app/${oid}/trading/your-details`, { scrollPosition: "location" })
            } else {
              history.push(`/app/${oid}/trading/your-details`, { scrollPosition: "location" })
            }
          }
        }
      }
    }
    return () => {
      clearSuccess()
    }
  }, [success])

  useEffect(() => {
    if (value?.value?.description) {
      geocodeByAddress(value.value.description)
        .then((results) => getLatLng(results[0]))
        .then(({ lat, lng }) => {
          setMarker({ lat, lng })
          pantTo({ lat, lng })
        })
    } else if (
      autocompleteValue !== '' &&
      autocompleteValue !== 'Enter a place'
    ) {
      geocodeByAddress(autocompleteValue)
        .then((results) => getLatLng(results[0]))
        .then(({ lat, lng }) => {
          setMarker({ lat, lng })
          pantTo({ lat, lng })
        })
    }
  }, [value, autocompleteValue])

  const submitHandler = () => {
    if (!marker?.lat || !marker?.lng) {
      message.error('Please select a location first')
      return
    }

    setKycFormValues({
      ...kycFormValues,
      location_map: {
        latitude: marker?.lat,
        longitude: marker?.lng,
      },
    })
    updateKycDetailRequest({
      location_map: {
        latitude: marker.lat?.toString(),
        longitude: marker.lng?.toString(),
      },
      time_tracker: {
        time_tracking: {
          [formType]: [
            {
              page: pageNumber,
              time: timeVal,
            },
          ],
        },
      },
    })
  }

  if (loadError) return <div>Error loading maps</div>
  if (!isLoaded)
    return (
      <div>
        <Spin></Spin>
      </div>
    )

  return (
    <>
      <div className='autocomplete-places-back-btn'>
        <div onClick={mapBackClick}>
          <BackMapIcon />
        </div>
      </div>
      <div className='autocomplete-places-container'>
        <GooglePlacesAutocomplete
          debounce={800}
          minLengthAutocomplete={2}
          onLoadFailed={(error) =>
            console.error('Could not inject Google script', error)
          }
          selectProps={{
            value,
            onChange: setValue,
            placeholder: autocompleteValue ?? 'Select Branch',
          }}
          autocompletionRequest={{
            componentRestrictions: {
              country: ['np'],
            },
          }}
        />
      </div>
      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        ref={mapRef}
        center={centerVal}
        options={options}
        zoom={13}
        onClick={onMapClickAndDrag}
        onLoad={onMapLoad}
      >
        {/* Child components, such as markers, info windows, etc. */}
        {marker.lat !== undefined && marker.lng !== undefined && (
          <Marker
            draggable={true}
            position={{
              lat: marker.lat,
              lng: marker.lng,
            }}
            onDragEnd={onMapClickAndDrag}
          />
        )}
        <></>
      </GoogleMap>
      <div className='map-submit-container'>
        <Button
          // loading={actionLoading}
          htmlType='submit'
          className='submit-btn'
          block
          shape='round'
          onClick={submitHandler}
          loading={actionLoading}
        >
          <div className='d-flex align-items-center flex-between'>
            <div></div>
            {!actionLoading && <div>Confirm Address</div>}
            {!actionLoading && <RightOutlined />}
          </div>
        </Button>
      </div>
    </>
  )
}

CustomMapComponent.defaultProps = {
  mapBackClick: () => {
    alert('pass the mapback props')
  },
  coordinates: {
    lat: undefined,
    lng: undefined,
  },
  screenName: '',
}

export default React.memo(CustomMapComponent)
