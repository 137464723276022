import React, {
  Dispatch,
  SetStateAction,
} from 'react';

import {
  Col,
  Row,
} from 'antd';
import OutlineButton from 'Components/OutlineButton';
import SubLabelWrapper from 'Components/SubLabelWrapper';

interface MinorProps {
    isMinor: boolean
    setIsMinor: Dispatch<SetStateAction<boolean>>
}

export const Minor = ({ isMinor, setIsMinor }: MinorProps) => {
    return (
        <SubLabelWrapper
            label='Is this for Minor one?'
            subLabel=''
            render={
                <Row gutter={[16, 16]}>
                    <Col xs={12} lg={6} style={{ marginTop: '1rem' }}>
                        <OutlineButton
                            label='Yes'
                            active={isMinor}
                            onClick={() => {
                                setIsMinor(true)
                            }}
                        />
                    </Col>
                    <Col xs={12} lg={6} style={{ marginTop: '1rem' }}>
                        <OutlineButton
                            label='No'
                            active={!isMinor}
                            onClick={() =>
                                setIsMinor(false)
                            }
                        />
                    </Col>
                </Row>
            }
        />
    )
}
