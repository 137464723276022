import {
  useEffect,
  useState,
} from 'react';

import {
  Form,
  message,
} from 'antd';
import { useSetKycFormValue } from 'CustomHooks/useSetKycFormValue';
import { IPageProps } from 'Interfaces/Page';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import {
  clearSuccess,
  setKycFormValues,
  signatureBackgroundRemoverRequest,
  updateKycDetailRequest,
} from 'Redux/Protected/Action';
import { RootState } from 'Redux/RootReducer';
import RenderFooter from 'Views/Dmat/components/RenderFooter';
import ProgressbarLayout from 'Views/Dmat/Layout/ProgressbarLayout';

import { AddressInfo } from './Components/AddressInfo/AddressInfo';
import { GeneralInfo } from './Components/GeneralInfo/GeneralInfo';
import { Signature } from './Components/Signature/Signature';

const InitialValues: any = {
  username: '',
  dpid: 13200,
  clientId: '',
}

const MeroShareOpening = ({ history, pageNumber }: IPageProps) => {
  //var
  const { kycFormValues, actionLoading, success, globalTimer, } =
    useSelector((state: RootState) => state?.protectedReducer)

  //hooks
  const [form] = Form.useForm()
  const dispatch = useDispatch()
  const { oid } = useSetKycFormValue()

  //state
  const [formValues, setFormValues] = useState(InitialValues)
  const [signatureKey, setSignatureKey] = useState("");


  useEffect(() => {
    if (success) {
      //todo:this is only for individual, dmat+meroshare ko lagi redirect to terms and conds
      if (kycFormValues?.form?.demat) {
        history.push(`/app/${oid}/meroshare/terms-nepali`)
      } else {
        history.push(`/app/${oid}/meroshare/terms-nepali`)
      }
    }
    return () => {
      dispatch(clearSuccess())
    }
  }, [success, kycFormValues, dispatch, history, oid])


  const postVal = () => {
    let submitValues = {
      meroshare: {
        ...kycFormValues?.meroshare,
        dpid: formValues.dpid,

        email: formValues.email,
        phone: `+977${formValues?.phone
          .replace("+977", "")
          .replace("-", "")}`,
        full_name: formValues.username,
      },
      username: formValues.username,
      permanent_address: {
        ...kycFormValues?.permanent_address,
        sub: formValues?.sub,
        district: formValues?.district,
        province: formValues?.province,
        country: formValues?.country,
        address_code: formValues?.address_code,
        tole: formValues.tole ?? "",
        ward: formValues.ward ?? "",
        nearest_landmark: formValues.nearest_landmark,
        // block: formValues,
        // fax: null,
      },
    }
    if (formValues.clientId) {
      submitValues = {
        ...submitValues,
        meroshare: {
          ...submitValues.meroshare,
          clientId: Number(formValues.clientId),
        }
      }
    }
    if (kycFormValues?.form?.demat) {
      delete submitValues.meroshare.clientId

    }

    if (signatureKey) {
      submitValues = {
        ...submitValues,
        meroshare: {
          ...submitValues.meroshare,
          signature: signatureKey,
        }
      }
    }

    return submitValues

  }

  const dispatchKycFormValues = () => {

    const values = postVal()

    dispatch(
      setKycFormValues({
        ...kycFormValues,
        ...values
      })
    )
  }


  const submitHandler = () => {
    form
      .validateFields()
      .then((values) => {
        submitForm()
      })
      .catch((errorInfo) => { })
  }

  const submitForm = () => {

    if(!signatureKey) {
      return message.error("Signature is required")
    }

    const values = postVal()
    dispatch(
      setKycFormValues({
        ...kycFormValues,
        ...values
      })
    )

    dispatch(
      updateKycDetailRequest({
        ...values,
      })
    )
    dispatch(
      signatureBackgroundRemoverRequest(
        {
          url: signatureKey,
          transparent: false,
        },
        true
      )
    )
  }

  const backHandler = () => {
    if (kycFormValues?.demat) {
      history.push(`/app/${oid}/dematt/transaction-info`)
    } else {
      //meroshare + traidng case
      history.push(`/app/${oid}/form-selection`)
    }
  }



  return (
    <ProgressbarLayout
      progressLeftText={kycFormValues?.form?.demat ? '85%' : '0%'}
      progressRightText={globalTimer}
      progressPercent={kycFormValues?.form?.demat ? 85 : 0}
      iconTitle='Meroshare'
      title=''
      renderFooter={
        <RenderFooter
          backHandler={backHandler}
          submitHandler={submitHandler}
          loading={actionLoading}
        />
      }
    >
      <Form
        form={form}
        layout='vertical'
        name='basic'
        onFinish={submitHandler}
      >
        <GeneralInfo form={form} formValues={formValues} setFormValues={setFormValues} />

        <AddressInfo form={form} formValues={formValues} setFormValues={setFormValues} />
        <Signature signatureKey={signatureKey} setSignatureKey={setSignatureKey} dispatchKycFormValues={dispatchKycFormValues} />

      </Form>
    </ProgressbarLayout>
  )
}

export default MeroShareOpening
