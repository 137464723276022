import { Auth } from "aws-amplify";
import {API} from "aws-amplify"

export interface SignIn {
  email?: any;
  name?: any;
  phone_number?: any;
}

export const signInApi = ({ phone_number }: SignIn): any => {
    return Auth.signIn(phone_number);
};

export const signOutApi = (): any => {
  return Auth.signOut();
};

export const signUpApi = ({ email, phone_number, full_name }: any): any => {
  const url=`signup`;
  return API.post("signupApi", url, {body: {full_name, email, phone_number}})
}

export const verifyOtpApi = (payload: any): any => {
  return Auth.sendCustomChallengeAnswer(payload.userData, payload.otp);
}


