import React from 'react';

import { Slider } from 'antd';

interface Props {
  value: number;
  onChange: React.Dispatch<React.SetStateAction<number>>;
  minVal: number;
  maxVal: number;
}

const commaSeperator = (numValue: number): string => {
  return new Intl.NumberFormat("en-IN", { maximumSignificantDigits: 3 }).format(
    numValue
  );
};

const CustomSlider = ({ value, onChange, minVal, maxVal }: Props) => {
  return (
    <div>
      <div className="d-flex flex-between">
        <div className="xs-text text-light-grey">{commaSeperator(minVal)}</div>
        <div className="xs-text text-light-grey">{commaSeperator(maxVal)}</div>
      </div>
      <div>
        <Slider
          value={value}
          disabled={false}
          min={minVal}
          max={maxVal}
          step={50000}
          onChange={(val) => onChange(val)}
          trackStyle={{
            background: "#80EED0",
          }}
          handleStyle={{
            background: "#80EED0",
            border: "0",
          }}
        />
      </div>
      <div className="d-flex flex-center">
        <div className="r-text text-light-black">
          NRs. {commaSeperator(value)}/Annually
        </div>
      </div>
    </div>
  );
};

export default CustomSlider;
