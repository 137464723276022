import {
  useEffect,
  useState,
} from 'react';

import {
  Button,
  Checkbox,
  Col,
  message,
  Row,
  Spin,
} from 'antd';
import Progressbar from 'Components/Progressbar';
import StorageGetImage from 'Components/StorageGetImage';
import {
  BlackText,
  DottedInputText,
  GreyText,
  MediumGreyText,
} from 'Components/TermsText';
import ThumbprintBox from 'Components/ThumbprintBox';
import { ConnectedRouterProps } from 'connected-react-router';
import { useSetKycFormValue } from 'CustomHooks/useSetKycFormValue';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import { RootState } from 'Redux/RootReducer';

const TradingTmsTerms = ({ history }: ConnectedRouterProps) => {
  const { kycFormValues, mainSignature, globalTimer } = useSelector(
    (state: RootState) => state?.protectedReducer
  );
  const [agreeVal, setAgreeVal] = useState<boolean>(false);
  const [getImageLoading, setGetImageLoading] = useState(false);
  const [signatureImage, setSignatureImage] = useState<any>("");

  const { oid } = useSetKycFormValue()

  const getSignature = async (minor: boolean) => {
    let imageKey = "";

    if (mainSignature !== "") imageKey = mainSignature;
    else {
      if (minor) {
        imageKey =
          kycFormValues?.guardian_details.updated_signature ??
          kycFormValues?.guardian_details.signature ??
          "";
      }
      if (!minor)
        imageKey =
          kycFormValues?.updated_signature ?? kycFormValues.signature ?? "";
    }

    setGetImageLoading(true);

    StorageGetImage({
      imageKey,
      successCB: (base64data) => {
        setSignatureImage(base64data);
      },
      finalCB: () => {
        setGetImageLoading(false);
      },
    });
  };

  useEffect(() => {
    if (kycFormValues?.minor) {
      getSignature(true);
    } else if (kycFormValues?.signature || mainSignature !== "") {
      getSignature(false);
    }
  }, [kycFormValues, mainSignature]);

  const handleCheck = () => setAgreeVal((curr) => !curr);
  const backHandler = () => {
    history.push(`/app/${oid}/trading/additional-info`);

  };

  const calcAge = (): number => {
    const today = dayjs();
    if (kycFormValues?.minor) {
      return today.diff(kycFormValues?.minor_details?.dob, "year");
    } else {
      return today.diff(kycFormValues?.general_details?.dob, "year");
    }
  };

  const submitHandler = () => {
    if (agreeVal) {
      history.push(`/app/${oid}/trading/terms/2`);
    } else {
      message.error("Please agree to the terms and conditions first.");
    }
  };
  const formattedTime: any =
    ("0" + Math.floor((globalTimer / 60) % 60)).slice(-2) +
    ":" +
    ("0" + Math.floor(globalTimer % 60)).slice(-2) +
    " min";

  return (
    <>
      <Progressbar percent={99} leftText={"99%"} rightText={formattedTime} />

      <Row className="termsLayout">
        <Col span="24" className="termsTopContainer">
          <div className="text-green sb-text fw-500">Terms and Condition</div>
        </Col>

        <div
        >
          <Col span="24" className="termsMiddleContainer">
            <div className="mb-2">
              <BlackText
                data={
                  "धितोपत्र कारोबार सञ्चालन विनियमावली २0७५ को विनियम १0 संग सम्बन्धित "
                }
              />
              <BlackText data={"कारोवार सदस्य र ग्राहक बिचको सम्झौतापत्र "} />

              <GreyText
                data={`आज ...............बार, मिति ...............का दिन कम्पनी रजिस्ट्रारको कार्यलयमा दर्ता भई नेपाल धितोपत्र बोर्डबाट धितोपत्र दलाल ब्यवसायीको इजाजत प्राप्त गरी नेपाल स्टक एक्सचेञ्ज लिमिटेडको कारोबार सदस्य रहेको इन्द्रचोक, काठमाडौं-२४ मा रजिस्टर्ड कार्यालय भएको श्री एबीसी सेक्यूरिटिज प्रा. लि. (उप्रान्त प्रथम पक्ष भनिनेछ)`}
              />
              <BlackText data={"तथा"} />
              <span
                className="xs-text mt-1 text-light-grey text-justify"
                style={{ lineHeight: "20px" }}
              >
                प्रथम पक्ष मार्फत धितोपत्रको कारोबार गर्न/ अनलाईन सुबिधा प्रयोग
                गर्ने ग्राहक संकेत नम्बर (युनिक क्लाईन्ट कोड)
                <DottedInputText value={``} />
                रहेको
                <DottedInputText
                  value={`${kycFormValues?.permanent_address?.sub}-${kycFormValues?.permanent_address?.ward}, ${kycFormValues?.permanent_address?.district}`}
                />
                स्थायी ठेगाना भई हाल
                <DottedInputText
                  value={`${kycFormValues?.temporary_address?.sub}-${kycFormValues?.temporary_address?.ward}, ${kycFormValues?.temporary_address?.district}`}
                />
                स्थानमा बसोबास गर्ने श्री
                <DottedInputText
                  value={`${kycFormValues?.family_details?.grandfather_name}`}
                />
                को नाती/ नातीनी बुहारी, श्री
                <DottedInputText
                  value={`${kycFormValues?.family_details?.father_name}`}
                />
                को छोरा/ छोरी वर्ष
                <DottedInputText value={calcAge()} />
                को श्री
                <DottedInputText value={`${kycFormValues?.username}`} />
              </span>
              <BlackText data={"वा"} />

              <GreyText
                data="ग्राहक संकेत नम्बर (युनिक क्लाईन्ट कोड) ...............रहेको ............... कार्यालयमा दर्ता भई रजिस्टर्ड कार्यालय
                ............... मा भएको श्री ............... ले (उप्रान्त दोस्रो पक्ष भनिनेर
              सो शब्दले विषय वा प्रसंगले अन्यथा अर्थ लगाएकोमा बाहेक निजका उत्तराधिकारी र/वा कानुनी प्रतिनिधि समेत लाई जनाउने), बीच नेप्सेको कारोबार
              प्रणालीबाट कारोबार हुने विधि र आपैmले कारोबार गर्ने सम्बन्धमा दोस्रो पक्ष सँग आवश्यक ज्ञान रहेको मानी यो सम्झौता (उप्रान्त सम्झौता भनिने छ) सम्पन्न
              भएको छ । "
              />
            </div>
            <div className="mb-2">
              <BlackText
                data={
                  "यस सम्झौताका पक्षहरु निम्नलिखित शर्तहरु पालना गर्न मञ्जुर छन् : "
                }
              />

              <GreyText
                data={`१. प्रचालित धितोपत्र ऐन, नियम, विनियम, कार्यविधि तथा नेपाल राष्ट्र बैंक, बिमा समिति, नेपाल धितोपत्र बोर्ड, नेपाल स्टक एक्सचेन्ज लिमिटेड, सिडिएस
                        एन्ड क्लियरिङ लिमिटेड लगायतका नियमक निकायले समय समयमा जारी गरेका निर्देशिका, परिपत्र र निर्देशन समेतलाई यसै सम्झौतामा उल्लेख भए
                        सरह मानी सम्झौताका पक्षहरुले सोको पालना गर्न मञ्जुर गर्दछन्। `}
              />
              <GreyText
                data="२. दोस्रो पक्षले यस सम्झौता गर्दाको वखत वा यस भन्दा अघि प्रथम पक्षलाई दिएको सूचना, दोस्रो पक्षको ग्राहक परिचय विवरण (केवाइसी) मा भरेको
                      विवरणमा कुनै फेरवदल भए, फेरबदल भएको ७ कार्य दिनभित्र प्रथम पक्षलाई जानकारी दिई आफ्नो पहिचान तथा खाताका विवरणहरु अद्यावधिक गराउन
                      मञ्जुर गर्दछ । "
              />
              <GreyText
                data="३. दोस्रो पक्ष धितोपत्रको कारोबार गर्न कानून बमोजिम अयोग्य ठहरिने कुनैअवस्था उत्पन्न भई कारवाहीमा परेमा, आर्थिक रुपमा टाट पल्टिएमा, विघटनमा
                      गएमा, दामासाहीमा परेमा वा कर्जा सूचना केन्द्रको कालोसूचीमा परेमा वा अन्य कुनै कारणले कानूनत आर्थिक कारोवार गर्न असमर्थ भएमा प्रथम पक्षलाई
                      तुरुन्त जानकारी दिन मञ्जुर गर्दछ ।"
              />
              <GreyText
                data="४. दोस्रो पक्षले यस सम्झौतामा उल्लेख गरेको वा ग्राहक परिचय विवरण (केवाइसी) मा उल्लेख भएको इमेल ठेगाना वा मोबाइल नम्बरमा दोस्रो पक्षले प्रयोग
                गर्ने युजरनेम तथा पासवर्ड प्रथम पक्षले उपलब्ध गराउने छ । प्रथम पक्षले उपलब्ध गराएको वा सो पश्चात दोस्रो पक्षले परिर्वतन गरेको युजरनेम वा
                पासवर्डको गोपनियता कायम गर्ने र आफ्नो नियन्त्रणमा राख्ने दायित्व दोस्रो पक्षको हुनेछ ।"
              />
              <GreyText
                data="५. दोश्रो पक्षले बिक्री कारोवार गर्दा मौज्दातमा धितोपत्र रहेको वा रहने कुनै एक हीतग्राही खाता प्रथम पक्षलाई उपलब्ध गराउने छ । र उक्त खाता अन्य
                धितोपत्र कारोवार सदस्यलाइ दिने छैन । यदी एउटै खाता अन्य धितोपत्र कारोवार सदस्यलाई दिइ हुने जोखिको जिम्मेवारी दोश्रो पक्ष ले लिने छ ।"
              />
              <GreyText data="६. दोस्रो पक्षलाई दिइएको युजरनेमबाट भएगरेको सम्पूर्ण कारोबारहरुको दायित्व बहन गर्ने तथा सो को समयमा राफसाफ गर्ने जिम्मेवारी दोस्रो पक्षको हुनेछ ।" />
              <GreyText
                data="७. दोस्रो पक्षलाइ प्रदान गरीएको युजरनेमको प्रयोग गरी सिर्जना हुनेदायित्व तथा सम्झौतारत पक्ष र नेप्से समेतको सम्पत्तिमा कुनै हानी नोक्सानी भएमा सो
                      प्रति दोस्रो पक्ष जिम्मेवार हुनेछ ।"
              />
              <GreyText data="८. दोस्रो पक्षले आफ्नो युजरनेम प्रयोग गरी वा प्रथम पक्षलाई आदेश दिई बिक्री भएका धितोपत्रहरुको निर्देशन पूर्जी जारी गर्नुपर्ने दायित्व दोस्रो पक्षको हुनेछ । यसरी बिक्री भएको धितोपत्रको हस्तान्तरण दोस्रो पक्षले टिं१ को १२ बजे भित्र प्रथम पक्षको राफसाफ खातामा जम्मा गर्न नसकी उक्त कारोवारको राफसाफ हुन नसकेमा सो बाट सिर्जित सम्पूर्ण दायित्व दोस्रो पक्षले वहन गर्नुपर्नेछ ।" />
              <GreyText
                data="९. दोस्रो पक्षले धितोपत्र बिक्री गरिसकेपछि सो धितोपत्रको आधार मूल्य प्रथम पक्षलाई राफसाफ समयको १ (एक) दिन पूर्व नै उपलब्ध गराउनु पर्नेछ ।
                      आधार मूल्य समयमा उपलव्ध नगराएमा, फर्जी वा गलत आधार मूल्यका कारण उत्पन्न हुने कानूनी तथा आर्थिक दायित्च वा हानी नोक्सानी दोस्रो पक्षले
                      व्यहोर्नु पर्नेछ ।"
              />
              <GreyText
                data="१0. दोस्रो पक्षले बिक्रि गरेको धितोपत्रको कारोबारमा लाग्ने कर, शुल्क, दस्तुर तथा अन्य प्रणालीगत शुल्कहरु तथा प्रथम पक्षले धितोपत्र खरीद बिक्री सम्वन्धमा
                              थप सुविधा प्रदान गरेमा लाग्ने थप स्वीकृत दस्तुर कट्टा गरी बाँकी रकम मात्र प्रथम पक्षले दोस्रो पक्षलाई भुक्तानी गर्नेछ ।"
              />
              <GreyText
                data="११. दोस्रो पक्षले खरीद आदेश प्रणालीमा प्रविष्ट गर्दा प्रथम पक्षले तोकेको कारोबार लिमिटको सीमाभित्र रही गर्नु पर्नेछ । दोस्रो पक्षले प्रथम पक्षलाई अग्रिम
                              रुपमा बुझाएको रकमको ४ (चार) गुणा सम्म कारोबार लिमिट प्राप्त गर्न सक्नेछ । उक्त कारोबार सीमा दोस्रो पक्षले बुझाएको रकमको चार गुणा भन्दा
                              बढी हुने छैन ।"
              />
              <GreyText data="१२. दोस्रो पक्षले कारोबार सीमाका लागि प्रथम पक्षलाई बुझाउने सबै किसिमका भुक्तानी चेक वा अन्य बिद्युतीय माध्यमबाट गर्नुपर्नेछ ।" />
              <GreyText data="१३. दोस्रो पक्षले कारोबार प्रयोजनका लागि प्रथम पक्षलाई उपलब्ध गराएको रकम तथा धितोपत्र कारोवार राफसाफ प्रयोजनमा मात्र प्रयोग गर्नुपर्नेछ ।" />
              <GreyText
                data="१४. दुवै पक्षको आपसी सहमतिमा दोस्रो पक्षले प्रथम पक्षलाई यस्तो कारोबार सीमाको लागि आवश्यक अग्रिम भुक्तानी बैंक ग्यारेन्टी तथा कारोवारयोग्य
                      धितोपत्रहरुको माध्यमबाट पनि गर्न सक्ने छ । यस्तो धरौटी दोश्रो पक्षले आफनो दायीत्व समयमा भुक्तानी नगरेमा प्रथम पक्षले दोस्रो पक्षलाई जानकारी
                      दिई नगदमा परिणत गर्न सक्ने छ ।"
              />
              <GreyText
                data="१५. कारोबार सीमा गणना प्रयोजनको लागि कारोबारयोग्य धितोपत्रको मूल्यांकन बजार मूल्यको बढीमा ७0 प्रतिशत सम्म गर्न सकिने छ । धितोपत्र तथा
                       धितोपत्र बजारमा हुने सम्भावित जोखिमलाई मध्यनजर गरी प्रथम पक्षले दोस्रो पक्षलाई उपलब्ध गराएको कारोबार सीमा दोस्रो पक्षलाई जानकारी गराई
                        फेरबदल गर्न सक्नेछ ।"
              />
              <GreyText
                data="१६. खरीद कारोबारपछि बाँकी रहेको रकम खरिद बिल बमोजिम दोस्रो पक्षले टिं२ भित्रमा प्रथम पक्षको खातामा जम्मा हुने गरी प्रथम पक्षलाई उपलब्ध
                      गराउनु पर्नेछ । यसरी रकम उपलब्ध गराउदा अग्रिम भुक्तानी रकममा उपलब्ध गराएको भए सो रकम समायोजन गर्ने सुविधा दोस्रो पक्षलाई हुनेछ ।"
              />
              <GreyText
                data="१७. खरिद कारोबार पश्चात दोस्रो पक्षले राफसाफ समय भित्रमा आफ्नो दायित्व पुरा नगरेमा टिं४ मा प्रथम पक्षले दोस्रो पक्षलाई जानकारी गराई दोस्रो
                      पक्षकै नाममा खरिद गरिएको तथा जमानतमा लिइएकोधितोपत्र बिक्री, वैक जमानतबाट रकम र सो को लागत बापतको सोधभर्ना र नपुग रकम कानून
                      बमोजिम असुल उपर गरि लिन सक्नेछ ।"
              />
              <GreyText
                data="१८. दोस्रो पक्षको धितोपत्र बिक्री गरि प्रथम पक्षको लेना रकम असुलउपर गरी रकम बाँकी रहेमा दोस्रो पक्षको अग्रिम भुक्तानी सम्मको रकम दोस्रो पक्षलाई
                      फिर्ता गरी थप रकम बाँकि रहन गए धितोपत्र कारोबार सञ्चालन विनियमावली, २0७५ मा तोकिए बमोजिम हुनेछ ।"
              />
              <GreyText
                data="१९. दोस्रो पक्षले खरीद गरेको धितोपत्र खरिदबाट सिर्जित दायित्वहरु पुरा गरेपछि वा कारोवारको राफसाफको दिन मध्ये जुन पछी हुन्छ सो को भोलिपल्ट
                      ग्राहकको निक्षेप खातामा प्रथम पक्षले धितोपत्र हस्तान्तरण गर्नु पर्नेछ ।"
              />
              <GreyText data="२0. दोस्रो पक्षको नामवाट कारोवार भए पश्चात दोस्रो पक्षको मृत्यु भएमा, दामासाहीमा परेमा, मगज बिग्रिएमा वा अन्य कुनै कारणवाट दोस्रो पक्षको नामवाट भएका कारोवारको राफसाफ हुन नसक्ने स्थिति सिर्जना भएमा उक्त कारोबारको राफसाफकोजिम्मेवारी निज वा निजको कानूनी उत्तराधिकारीको हुनेछ । कानुनी उत्तराधिकारीवाट पनि राफसाफ हुन नसकेमा प्रचलित कानून बमोजिम हुनेछ ।" />
              <GreyText data="२१. नियमक निकायहरुबाट कारोबारहरु रद्द भएमा वा रद्द गर्ने आदेश प्राप्त भएमा सो कारोवारहरु स्वत: रद्द हुनेछन । सोको दायित्व प्रथम पक्षले बहन गर्ने छैन ।" />
              <GreyText data="२२. दोस्रो पक्ष नावालक वा कानूनत: पक्षको रुपमा करार गर्न अयोग्य देखिएमा निजको संरक्षक वा प्रतिनिधिले यो सम्झौता गर्न सक्नेछ ।" />
              <GreyText data="२३. प्रथम पक्षको काबु बाहिरको परिस्थितिको कारणबाट उत्पन्न हुने कुनै पनि दायित्वमा प्रथम पक्ष जिम्मेवार हुनेछैन ।" />
              <GreyText data="२४. दोस्रो पक्षले आफ्नो कारोवारको निम्ति आदेश दिन लिखित मञ्जुरी दिई प्रतिनिधि नियुक्ति गर्न सक्ने छ सो प्रतिनिधिवाट भएका सम्पूर्ण कारोबारहरु निज दोस्रो पक्षले नै गरेसरह मान्य हुनेछ ।" />
              <GreyText data="२५. धितोपत्रमा गरीने लगानीमा जोखिम हुन्छ भन्ने कुरामा दोस्रो पक्ष जानकार रहेको मानिनेछ र सो जोखिमबाट सिर्जित हुने हानी नोक्सानी प्रति दोस्रो पक्षनै जिम्मेवार रहनेछ ।" />
              <GreyText data="२६. दोस्रो पक्षले निम्न लिखित माध्यमवाट कारोबार आदेश दिन सक्ने छ:" />
              <div className="xs-text mt-1 text-light-grey text-justify ml-2">
                क. स्वयं उपस्थित भई लिखित आदेश ।
              </div>
              <div className="xs-text mt-1 text-light-grey text-justify ml-2">
                ख. टेलिफोन <DottedInputText value={""} />
              </div>
              <div className="xs-text mt-1 text-light-grey text-justify ml-2">
                ग. मोबाइल फोन <DottedInputText value={kycFormValues?.phone} />
              </div>
              <div className="xs-text mt-1 text-light-grey text-justify ml-2">
                घ. ई-मेल <DottedInputText value={kycFormValues?.email} />
              </div>
              <div className="xs-text mt-1 text-light-grey text-justify ml-2">
                ङ. दोस्रो पक्षको युजरनेम <DottedInputText value={""} />
              </div>
              <div className="xs-text mt-1 text-light-grey text-justify ml-2">
                च. प्रथम पक्षको वेभसाइट <DottedInputText value={""} />
              </div>
              <div className="xs-text mt-1 text-light-grey text-justify ml-2">
                छ. अन्य बिद्युतीय माध्यम <DottedInputText value={""} />
              </div>

              <GreyText data="थम पक्षले उल्लिखित माध्यमबाट आएका आदेशहरुलाई मात्र वैधानिक मान्यता दिई प्राथमिकताका आधारमा कारोबार प्रणालीमा आदेश प्रविष्टी गर्नेछ । नियम अनुसार उक्त आदेशहरुको सुरक्षित रेकर्डिङ्ग तथा भण्डारण प्रथम पक्षले गर्न सक्नेछ ।" />
              <GreyText data="२७. दोस्रो पक्षको मार्जिन कारोवार सुविधा उपयोग गर्ने भएमा सो सम्बन्धी सर्त तथा सुविधाहरु सम्बन्धित नियममा तोकिए बमोजिम हुनेछ ।" />
              <GreyText data="२८. दोस्रो पक्षले धितोपत्र कारोवार गर्दा उक्त धितोपत्र कारोवार गर्न प्रचलित कानुन वमोजिम आफु योग्य रहेको र सो धितोपत्रमा आफुले कानून बमोजिम धारण गर्न सक्ने सीमा भित्र रहि खरीद आदेश प्रविष्ट गर्नु पर्ने छ । सो नगरेबाट सिर्जित परिणाम तथा दायित्व दोस्रो पक्षको हुनेछ ।" />
              <GreyText data="२९. प्रचलित नीति नियममा उल्लेख भएअनुसार वा सम्बन्धित निकायहरुबाट धितोपत्रको कारोबारको श्रोतको जानकारी गराउन आवश्यक रहेमा उक्त जानकारी कारोबार राफसाफ समयाबधीभित्र प्रथम पक्षलाई बुझाउन दोस्रोपक्ष मञ्जुर गर्दछ । दोस्रो पक्षलेप्रचलित कानुन बमोजिम अवैधानिक नठहरीने रकमहरु मात्र धितोपत्रमा लगानी गर्नुपर्नेछ ।" />
              <GreyText data="३0. पक्षहरुबिच आपसि सहमतीमा एक महिनाको अग्रिम सूचना दिई यो सम्झौता रद्द गर्न सकिनेछ । सम्झौता रद्द गर्नु पूर्व पक्षहरुले आ-आफ्नो दायित्व पुरा गरेको हुनु पर्नेछ ।" />
              <GreyText data="३१. प्रचलित कानून बमोजिम प्रथम पक्षले व्यवसाय सञ्चालनका लागि प्राप्त गरेको इजाजत, अनुमतिपत्र, सदस्यता खारेज भइ कारोबार गर्न अयोग्य भएमा यो सम्झौता स्वत: खारेज हुनेछ । यसरी सम्झौता खारेज भए पनि बाँकी बक्यौता फछ्र्यौट गर्नुपर्ने दायित्व दुवै पक्षको हुनेछ ।" />
              <GreyText data="३२. यस सम्झौताअनुसार प्रथम पक्षले दिने अथवा आवश्यक हुने कुनै पनि जनाउ वा सञ्चार तोकिएको माध्यमबाट दोस्रो पक्षको अद्यावधिक ठेगानामा पठाए पश्चात दोस्रो पक्षले जानकारी पाए सरह हुनेछ ।" />
              <GreyText data="३३. सम्झौताका दुवै पक्षहरुले नेप्से अनलाइन कारोबार प्रणालीमा क्षति पु¥याउने कुनै पनि कार्य गर्ने छैनन्।" />
              <GreyText data="३४. प्रथम पक्षले प्रचलित कानूनमा उल्लेख भएको अवस्थामा बाहेक दोस्रो पक्षको गोपनीयता कायम गर्नेछ ।" />
              <GreyText data="३५. पक्षहरुका बीचमा उत्पन्न हुनसक्ने विवाद तथा मतभिन्नताहरुको समाधान सकेसम्म आपसी सहमतिमा निराकरण गरिनेछ । आपसी सहमतीबाट समाधान गर्न नसकेमा धितोपत्र कारोबार सञ्चालन विनियमावली, २0७५ मा तोकिए बमोजिम बमोजिम हुनेछ ।" />
              <GreyText data="३६. दोस्रो पक्षले देहायका सुबिधा उपयोग गरे बापत तोकिएको शुल्क तिर्न मञ्जुर गर्दछ ।" />
              <GreyText data="▪ अनलाईन कारोबार सुविधा" />
              <GreyText data="▪ एसएमएस सुबिधा" />
              <GreyText data="▪ अन्य" />
              <GreyText data="दोस्रो पक्षले उल्लिखित सुबिधाहरु प्राप्त गर्न हरेक आर्थिक बर्षमा नविकरण शुल्क तिर्नुपर्नेछ ।" />
              <GreyText data="३७. यो सम्झौतामा उल्लेख तथा व्याख्या नभएका विषय प्रचलित नेपाल कानूनद्वारा नियमन तथा व्याख्या हुनेछ ।" />
              <GreyText data="३८. यो सम्झौता दुवै पक्षले हस्ताक्षर गरेको मितिबाट ..................... सम्म कायम रहने छ र दुवै पक्षको सहमतीबाट सम्झौता पुन: नविकरण हुन सक्नेछ ।" />
              <GreyText data="३९. यो सम्झौता राम्ररी पढी, बुझी दुवै पक्ष हस्ताक्षर गर्न मञ्जुर गर्दछौं ।" />
              <div className="mb-2">
                <BlackText data={`प्रथम पक्ष :`} />
                <MediumGreyText data={`प्रथम पक्षको तर्फबाट अधिकार प्राप्त`} />
                <MediumGreyText
                  data={`व्यक्तिको नाम : ABC Securities Pvt. Ltd.`}
                />
                <MediumGreyText data={`दस्तखत : `} />
                <MediumGreyText data={`कम्पनीको छाप : `} />
                <div className="my-2">
                  <BlackText data={`साछी : `} />
                </div>
                <MediumGreyText
                  data={`१ ....................................`}
                />
              </div>
              <div className="mb-2">
                <BlackText data={`दोस्रा पक्ष :`} />
                <MediumGreyText data={`दोस्रा पक्षको तर्फबाट अधिकार प्राप्त`} />
                <MediumGreyText
                  data={`व्यक्तिको नाम : ${kycFormValues?.username ?? ""}`}
                />

                <div className="d-flex">
                  <MediumGreyText data={`दस्तखत : `} />
                  <Spin spinning={getImageLoading}>
                    {signatureImage ? (
                      <img className="signature-terms" src={signatureImage} />
                    ) : null}
                  </Spin>
                </div>
                <MediumGreyText data={`कम्पनीको छाप : `} />
                <div className="my-2">
                  <BlackText data={`साछी : `} />
                </div>
                <MediumGreyText
                  data={`१ ....................................`}
                />
              </div>
              <div className="d-flex my-2 align-items-center">
                <Checkbox
                  onChange={handleCheck}
                  checked={agreeVal}
                  className="br-8"
                />
                <div className="ml-1 cursor-hover" onClick={handleCheck}>
                  I agree
                </div>
              </div>
              <div className="mb-2 ml-2">
                <BlackText data={`औंठ छाप / Thumbprint :`} />
                <ThumbprintBox />
              </div>
              <GreyText data="इति सम्वत्............. साल ............. महिना ............. गते रोज ............. शुभम्।" />
            </div>
            <div className="text-center mt-1 " style={{ paddingInline: "5vw" }}>
              <Button
                // loading={loading}
                htmlType="submit"
                className="submit-btn"
                block
                shape="round"
                onClick={submitHandler}
              >
                I Agree
              </Button>
              <Button
                type="link"
                className="cancel-link-btn"
                onClick={backHandler}
              >
                Cancel
              </Button>
            </div>
          </Col>
        </div>
      </Row>
    </>
  );
};

export default TradingTmsTerms;
