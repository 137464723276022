import { notification } from "antd";
import { capitalizeFirstLetterEntireWord } from "./capitalizeFirstLetter";
import { similarPercent } from "./similarPercent";

/**
 *
 * @param wordsArraywithObject  reponse.text.words
 * @param currentUserName username
 * @returns a notification if username isnt found on the ocr result
 */
export function checkName(
  wordsArraywithObject: Array<any>,
  currentUserName: string
) {
  if (!wordsArraywithObject) return;
  const wordsArray = wordsArraywithObject.map((itm: any) => itm.text);
  const CAPS_WORDS_ARRAY = JSON.parse(JSON.stringify(wordsArray).toUpperCase());
  // console.log(CAPS_WORDS_ARRAY, ":HERE");
  var separateWord = currentUserName?.toUpperCase()?.split(" ") ?? "";
  if (CAPS_WORDS_ARRAY.includes(separateWord[0])) {
    return;
  } else {
    const args = {
      message: "Warning",
      description: `Please re-verify that the uploaded document is of ${capitalizeFirstLetterEntireWord(
        currentUserName ?? ""
      )} `,
      duration: 8,
      className: "br-8 custom_notification warning-notification",
      style: {
        color: "white",
      },
    };
    notification.warn(args);

    return;
  }
}

//for boid pdf reader implmentation

/**
 *
 * @param nameFromPdf name obtained from pdf ocr
 * @param currentUserName username
 * @returns a notification if username isnt found on the ocr result
 */
export function checkName2(
  nameFromPdf: string = "",
  currentUserName: string = ""
) {
  const pdfNameSeperateWord = nameFromPdf?.toUpperCase()?.split(" ") ?? "";

  var currentUserSeperateWord =
    currentUserName?.toUpperCase()?.split(" ") ?? "";

  if (
    similarPercent(
      pdfNameSeperateWord[0] ?? "",
      currentUserSeperateWord[0] ?? ""
    ) < 80
  ) {
    const args = {
      message: "Warning",
      description: `Please re-verify that the uploaded pdf is of ${capitalizeFirstLetterEntireWord(
        currentUserName ?? ""
      )} `,
      duration: 8,
      className: "br-8 custom_notification warning-notification",
      style: {
        color: "white",
      },
    };
    notification.warn(args);
  }
}
