import { Progress } from "antd";

interface Props {
  percent: number;
  leftText?: string;
  rightText?: string;
}

const Progressbar = ({ percent, leftText, rightText }: Props) => {
  return (
    <>
      <Progress
        percent={percent}
        showInfo={false}
        strokeColor="#80EED0"
        trailColor="#E5FCF6"
        className="progressBar"
      />
      <div
        className="d-flex align-items-center flex-between w-100p"
        style={{ paddingInline: "5vw" }}
      >
        <div className="text-info xxs-text fw-500">{leftText}</div>
        <div className="text-warning xxs-text fw-500">{rightText}</div>
      </div>
    </>
  );
};

export default Progressbar;
