import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
} from 'react';

import {
  Col,
  Form,
  FormInstance,
  Input,
  InputNumber,
  Row,
  Select,
  Typography,
} from 'antd';
import LabelWrapper from 'Components/LabelWrapper';
import { useSelector } from 'react-redux';
import { RootState } from 'Redux/RootReducer';
import { createDistrictList } from 'Utils/createDistrictList';
import { VALIDATIONS } from 'Utils/formValidationRules';

interface AddressInfoProps {
    formValues: any
    setFormValues: Dispatch<SetStateAction<any>>
    form: FormInstance
}

export const AddressInfo = ({ formValues, setFormValues, form }: AddressInfoProps) => {
    //var
    const { addressDetails, addressLoading } = useSelector(
        (state: RootState) => state.staticReducer
    );
    const { kycFormValues, loading } =
        useSelector((state: RootState) => state?.protectedReducer)

    //state
    const [districtDetails, setDistrictDetails] = useState<any>([]);

    useEffect(() => {
        if (addressDetails.length) { //set district list value
            const requiredDistrictList = createDistrictList({
                addressArray: addressDetails,
            });
            setDistrictDetails(requiredDistrictList);
        }
    }, [addressDetails]);

    useEffect(() => { // set form values
        if (kycFormValues?.permanent_address &&
            Object.keys(kycFormValues?.permanent_address).length) {
            const data = {
                sub: kycFormValues?.permanent_address?.sub,
                district: kycFormValues?.permanent_address?.district,
                province: kycFormValues?.permanent_address?.province,
                country: kycFormValues?.permanent_address?.country,
                address_code: kycFormValues?.permanent_address?.address_code,
                nearest_landmark: kycFormValues?.permanent_address?.nearest_landmark,
                tole: kycFormValues?.permanent_address?.tole,
                ward: kycFormValues?.permanent_address?.ward
            }

            setFormValues((prevState: any) => ({

                ...prevState,
                ...data

            }))

            form.setFieldsValue(data)

        }
    }, [kycFormValues, form, setFormValues])


    const handleInputChange = (e: any) => {
        setFormValues({ ...formValues, [e.target.name]: e.target.value })
    }

    const handleNumberChange = (e: number, name: string) => {
        setFormValues({ ...formValues, [name]: e });
    };

    const handleSelectChange = (e: any) => {
        let parsedData = JSON.parse(e);
        form.setFieldsValue({
            sub: `${parsedData.name}, ${parsedData.district}`,
        });
        let address = [...addressDetails];
        let requiredAddress = address.filter(
            (each: any) => each.id === parsedData.id
        )[0];

        let addressData = {
            ...kycFormValues?.permanent_address?.address,
            sub: requiredAddress.name,
            district: requiredAddress.district,
            province: requiredAddress.province,
            country: requiredAddress.country,
            address_code: requiredAddress.address_code,
        };
        setFormValues({
            ...formValues,
            ...addressData
        })

        form.setFieldsValue({
            ...formValues,
            ...addressData
        })

    };

    return (
        <Row gutter={[16, 16]} className='groupWrapper mt-1'>
            <Col xs={24}>
                <Typography className={`r-text fw-500 text-light-black `}>Address Information </Typography>

            </Col>
            <Col xs={24} lg={12}>
                <LabelWrapper
                    label="VDC/Municipality/Metropolitan City"
                    render={
                        <Form.Item
                            name="sub"
                            rules={VALIDATIONS.sub}
                            initialValue={formValues.sub}
                        >
                            <Select
                                defaultValue={formValues.sub}
                                loading={addressLoading}
                                showSearch={true}
                                onChange={handleSelectChange}
                                optionLabelProp="customlabel"
                                className="underlineSelect"
                            >
                                {addressDetails?.length &&
                                    addressDetails.map((each: any, index: number) => {
                                        return (
                                            <Select.Option
                                                key={index}
                                                value={JSON.stringify(each)}
                                                customlabel={<div>{each.label}</div>}
                                            >
                                                {each.name}, {each.district}
                                            </Select.Option>
                                        );
                                    })}
                            </Select>
                        </Form.Item>
                    }
                />
            </Col>
            <Col xs={24} lg={12}>
                <LabelWrapper
                    label="Country"
                    render={
                        <Form.Item
                            name="country"
                            rules={VALIDATIONS.country}
                            initialValue={formValues.country}
                        >
                            <Input
                                bordered={false}
                                name="country"
                                className="underlineInput"
                                onChange={handleInputChange}
                                disabled={true}
                            // value={formValues.country}
                            />
                        </Form.Item>
                    }
                />
            </Col>
            <Col xs={24} lg={12}>
                <LabelWrapper
                    label="District"
                    render={
                        <Form.Item
                            name="district"
                            rules={VALIDATIONS.district}
                            initialValue={formValues.district}
                        >
                            <Select
                                className="underlineSelect"
                                value={formValues.district}
                                loading={loading}
                                showSearch={true}
                                optionLabelProp="customlabel"
                                disabled={true}
                            >
                                {districtDetails?.length &&
                                    districtDetails.map((each: any, index: number) => {
                                        return (
                                            <Select.Option
                                                key={index}
                                                value={each}
                                                customlabel={<div>{each}</div>}
                                            >
                                                {each}
                                            </Select.Option>
                                        );
                                    })}
                            </Select>
                        </Form.Item>
                    }
                />
            </Col>
            <Col xs={24} lg={12}>
                <LabelWrapper
                    label="Province"
                    render={
                        <Form.Item
                            name="province"
                            rules={VALIDATIONS.province}
                            initialValue={formValues.province}
                        >
                            <Input
                                bordered={false}
                                className="underlineInput"
                                name="province"
                                onChange={handleInputChange}
                                disabled={true}
                            // value={formValues.province}
                            />
                        </Form.Item>
                    }
                />
            </Col>
            <Col xs={24} lg={12}>
                <LabelWrapper
                    label="Tole"
                    render={
                        <Form.Item
                            name="tole"
                            initialValue={formValues.tole}
                            rules={VALIDATIONS.tole}
                        >
                            <Input
                                bordered={false}
                                className="underlineInput"
                                name="tole"
                                onChange={handleInputChange}
                            // value={formValues.tole}
                            />
                        </Form.Item>
                    }
                />
            </Col>
            <Col xs={24} lg={12}>
                <LabelWrapper
                    label="Nearest Landmark"
                    render={
                        <Form.Item
                            name="nearest_landmark"
                            initialValue={formValues.nearest_landmark}
                            rules={VALIDATIONS.nearest_landmark}
                        >
                            <Input
                                bordered={false}
                                className="underlineInput"
                                name="nearest_landmark"
                                onChange={handleInputChange}
                            />
                        </Form.Item>
                    }
                />
            </Col>
            <Col xs={24} lg={12}>
                <LabelWrapper
                    label="Ward Number"
                    render={
                        <>
                            <Form.Item
                                name="ward"
                                initialValue={formValues.ward}
                                rules={VALIDATIONS.ward}
                            >
                                <InputNumber
                                    style={{ width: "100%" }}
                                    bordered={false}
                                    className="underlineInput"
                                    name="ward"
                                    type="number"
                                    onChange={(e) => handleNumberChange(e, "ward")}
                                    value={formValues.ward}
                                />
                            </Form.Item>
                        </>
                    }
                />
            </Col>
        </Row>
    )
}
