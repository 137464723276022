import FormSelection from 'Views/Auth/FormSelection';
import Payment from 'Views/Common/Payment/Payment';
import ScheduleKyc from 'Views/Common/ScheduleKyc/ScheduleKyc';
import {
  FamilyDetails,
} from 'Views/DematAndTrading/FamilyDetails/FamilyDetails';
import {
  FinancialDetails,
} from 'Views/DematAndTrading/FinancialDetails/FinancialDetails';
import { Initial } from 'Views/DematAndTrading/Initial/Initial';
import {
  OccupationDetails,
} from 'Views/DematAndTrading/OccupationDetails/OccupationDetails';
import PersonalSignature
  from 'Views/DematAndTrading/PersonalDetails/Components/PersonalSignature/PersonalSignature';
import {
  PersonalDetails,
} from 'Views/DematAndTrading/PersonalDetails/PersonalDetails';
import DematComplete from 'Views/Dmat/DematComplete/DematComplete';
import DematDigitalSignature
  from 'Views/Dmat/DematNomineeDetails/DematDigitalSignature';
import {
  DematNomineeDetails,
} from 'Views/Dmat/DematNomineeDetails/DematNomineeDetails';
import DematTerms from 'Views/Dmat/DematTerms/DematTerms';
import {
  DematTransactionInfo,
} from 'Views/Dmat/DematTransactionInfo/DematTransactionInfo';
import MeroShareOpening from 'Views/Meroshare';
import MeroshareDigitalSignature from 'Views/Meroshare/DigitalSignature';
import MeroShareFormCompletion from 'Views/Meroshare/FormCompletion';
import MeroshareTermsNepali from 'Views/Meroshare/MeroshareTermsNepali';
import Profile from 'Views/Profile/Profile';
import BoidComp from 'Views/Trading/Boid/Boid';
import TradingAdditionalInfo
  from 'Views/Trading/TradingAdditionalInfo/TradingAdditionalInfo';
import TradingFormCompletion
  from 'Views/Trading/TradingFormCompletion/TradingFormCompletion';
import TradingTermsNepaliNew
  from 'Views/Trading/TradingTerms/TradingTermsNepaliNew';
import TradingTmsTerms from 'Views/Trading/TradingTerms/TradingTmsTerms';

export default interface RouteInterface {
  path: string;
  pageNumber: string | number;
  exact: boolean;
  commponent: any;
  props?: any;
}

export const routeConfig: RouteInterface[] = [
  {
    path: "/profile",
    pageNumber: 0,
    commponent: Profile,
    exact: true,
  },
  {
    path: "/:oid/form-selection",
    pageNumber: 1,
    commponent: FormSelection,
    exact: true,
  },

  // {
  //   path: "/:oid/dematt",
  //   pageNumber: 2,
  //   commponent: DematScreen,
  //   exact: true,
  // },



  //demat new

  {
    path: "/:oid/dematt",
    pageNumber: 2,
    commponent: Initial,
    exact: true,
  },

  {
    path: "/:oid/dematt/your-details",
    pageNumber: 3,
    commponent: PersonalDetails,
    exact: true,
  },

  {
    path: "/:oid/dematt/your-details/digital-signature",
    pageNumber: 3,
    commponent: PersonalSignature,
    exact: true,
  },

  {
    path: "/:oid/dematt/family-details",
    pageNumber: 4,
    commponent: FamilyDetails,
    exact: true,
  },

  {
    path: "/:oid/dematt/occupations",
    pageNumber: 5,
    commponent: OccupationDetails,
    exact: true,
  },

  {
    path: "/:oid/dematt/financial",
    pageNumber: 6,
    commponent: FinancialDetails,
    exact: true,
  },

  {
    path: "/:oid/dematt/nominee",
    pageNumber: 7,
    commponent: DematNomineeDetails,
    exact: true,
  },
  {
    path: "/:oid/dematt/nominee/digital-signature",
    pageNumber: 8,
    commponent: DematDigitalSignature,
    exact: true,
  },

  {
    path: "/:oid/dematt/transaction-info",
    pageNumber: 9,
    commponent: DematTransactionInfo,
    exact: true,
  },

  {
    path: "/:oid/dematt/terms-and-condition",
    pageNumber: 10,
    commponent: DematTerms,
    exact: true,
  },

  {
    path: "/:oid/dematt/payment",
    pageNumber: 11,
    commponent: Payment,
    exact: true,
  },


  {
    path: "/:oid/dematt/kyc",
    pageNumber: 12,
    commponent: ScheduleKyc,
    exact: true,
  },

  {
    path: "/:oid/dematt/complete",
    pageNumber: 52,
    commponent: DematComplete,
    exact: true,
  },
  // //demat new ends

  // //trading new

  {
    path: "/:oid/trading/boid",
    pageNumber: 1,
    commponent: BoidComp,
    exact: true,
  },

  {
    path: "/:oid/trading",
    pageNumber: 2,
    commponent: Initial,
    exact: true,
  },

  {
    path: "/:oid/trading/your-details",
    pageNumber: 3,
    commponent: PersonalDetails,
    exact: true,
  },
  {
    path: "/:oid/trading/your-details/digital-signature",
    pageNumber: 3,
    commponent: PersonalSignature,
    exact: true,
  },
  {
    path: "/:oid/trading/family-details",
    pageNumber: 4,
    commponent: FamilyDetails,
    exact: true,
  },

  {
    path: "/:oid/trading/occupations",
    pageNumber: 5,
    commponent: OccupationDetails,
    exact: true,
  },

  {
    path: "/:oid/trading/financial",
    pageNumber: 6,
    commponent: FinancialDetails,
    exact: true,
  },

  {
    path: "/:oid/trading/additional-info",
    pageNumber: 7,
    commponent: TradingAdditionalInfo,
    exact: true,
  },
  {
    path: "/:oid/trading/terms/1",
    pageNumber: 8,
    commponent: TradingTmsTerms,
    exact: true,
  },

  {
    path: "/:oid/trading/terms/2",
    pageNumber: 9,
    commponent: TradingTermsNepaliNew,
    exact: true,
  },

  {
    path: "/:oid/trading/kyc",
    pageNumber: 11,
    commponent: ScheduleKyc,
    exact: true,
  },

  {
    path: "/:oid/trading/complete",
    pageNumber: 12,
    commponent: TradingFormCompletion,
    exact: true,
  },

  // //trading new ends

  // //meroshare new start



  // //meroshare new end

  {
    path: "/:oid/meroshare/digital-signature",
    pageNumber: 61,
    commponent: MeroshareDigitalSignature,
    exact: true,
  },

  {
    path: "/:oid/meroshare",
    pageNumber: 56,
    commponent: MeroShareOpening,
    exact: true,
  },

  {
    path: "/:oid/meroshare/terms-nepali",
    pageNumber: 63,
    commponent: MeroshareTermsNepali,
    exact: true,
  },
  {
    path: "/:oid/meroshare/payment",
    pageNumber: 11,
    commponent: Payment,
    exact: true,
  },


  {
    path: "/:oid/meroshare/complete",
    pageNumber: 67,
    commponent: MeroShareFormCompletion,
    exact: true,
  },

  {
    path: "/:oid/meroshare/kyc",
    pageNumber: 11,
    commponent: ScheduleKyc,
    exact: true,
  },

];
