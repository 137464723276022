import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
} from 'react';

import {
  Col,
  Form,
  FormInstance,
  Input,
  Row,
  Select,
  Typography,
} from 'antd';
import DatePicker from 'Components/CustomDatePicker';
import LabelWrapper from 'Components/LabelWrapper';
import dayjs from 'dayjs';
import NepaliDate from 'nepali-date-converter';
import { NepaliDatePicker } from 'nepali-datepicker-reactjs';
import { useSelector } from 'react-redux';
import { RootState } from 'Redux/RootReducer';
import { createDistrictList } from 'Utils/createDistrictList';
import { disabledDateValidation } from 'Utils/DateValidation';
import { VALIDATIONS } from 'Utils/formValidationRules';
import converToNepalDate, { formEnglishDate } from 'Utils/NepaliDateHelper';

interface CitizenInfoProps {
    setFormValues: Dispatch<SetStateAction<any>>
    formValues: any
    form: FormInstance

}

export const CitizenInfo = ({ formValues, setFormValues, form }: CitizenInfoProps) => {

    //var
    const { addressDetails, addressLoading } = useSelector(
        (state: RootState) => state.staticReducer
    );
    //var
    const { kycFormValues, } =
        useSelector((state: RootState) => state?.protectedReducer)

    //state
    const [districtDetails, setDistrictDetails] = useState<any>([]);

    useEffect(() => { //set district value
        if (addressDetails.length) {
            const requiredDistrictList = createDistrictList({
                addressArray: addressDetails,
            });
            setDistrictDetails(requiredDistrictList);
        }
    }, [addressDetails]);

    useEffect(() => { //set citizenship value
        if (
            (kycFormValues)
        ) {

            let values: any = {}

            if (kycFormValues.minor) {
                values = {
                    ...kycFormValues?.guardian_details,
                    reference_number: kycFormValues?.reference_number ?? "",
                };
            } else {
                values = {
                    ...kycFormValues?.general_details,
                    reference_number: kycFormValues?.reference_number ?? "",

                }
            }
            const setValues = {
                citizenship_id: values?.citizenship_id || "",
                issued_date: values?.issued_date ? dayjs(values?.issued_date) : null,
                issued_np: values?.issued_np ?? "",
                issued_district: values?.issued_district || "",
            }

            form.setFieldsValue(setValues)
            setFormValues((prevState: any) => ({
                ...prevState,
                ...setValues
            }))
        }
    }, [kycFormValues, form, setFormValues])


    const handleInputChange = (e: any) => {
        setFormValues({ ...formValues, [e.target.name]: e.target.value });
    };

    const handleSelectChange = (e: any, name: string) => {
        setFormValues({ ...formValues, [name]: e });

    };

    const handleDateChange = (date: any, dateString: string, name: string) => {
        if (name === "dob") {

            const dob_np = converToNepalDate(date);
            form.setFieldsValue({ dob: date, dob_np });
            setFormValues({ ...formValues, [name]: date, dob_np });
        }
        if (name === "issued_date") {

            const issued_np = converToNepalDate(date);
            form.setFieldsValue({ issued_date: date, issued_np });

            setFormValues({
                ...formValues,
                [name]: date,
                issued_np,
            });
        }
    };

    const handleNepaliDateChange2 = (date: string) => {
        if (date) {
            const englishDateObject = new NepaliDate(date).getAD();
            const englishDate = formEnglishDate(englishDateObject);
            form.setFieldsValue({
                issued_np: date,
                issued_date: dayjs(englishDate),
            });
            setFormValues({
                ...formValues,
                issued_np: date,
                issued_date: dayjs(englishDate),
            });

        }
    };

    return (
        <Row gutter={[16, 16]} className='groupWrapper mt-2' >

            <Col xs={24}>
                <Typography className={`r-text fw-500 text-light-black `}>Citizenship Information </Typography>
            </Col>


            <Col xs={24} lg={12} id='citizenship_id'>
                <LabelWrapper
                    label="Citizenship Id"
                    render={
                        <Form.Item
                            name="citizenship_id"
                            rules={VALIDATIONS.citizenship_id}
                            initialValue={formValues.citizenship_id}
                        >
                            <Input
                                name="citizenship_id"
                                bordered={false}
                                className="underlineInput"
                                onChange={handleInputChange}
                            />
                        </Form.Item>
                    }
                />
            </Col>
            <Col xs={24} lg={12} id='issued_district' >
                <LabelWrapper
                    label="Issued District"
                    render={
                        <Form.Item
                            name="issued_district"
                            rules={VALIDATIONS.issued_district}
                            initialValue={formValues.issued_district}
                        >
                            <Select
                                className="underlineSelect"
                                value={formValues.issued_district}
                                loading={addressLoading}
                                showSearch={true}
                                onChange={(e) => handleSelectChange(e, "issued_district")}
                                optionLabelProp="customlabel"
                            >
                                {districtDetails?.length &&
                                    districtDetails.map((each: any, index: number) => {
                                        return (
                                            <Select.Option
                                                key={index}
                                                value={each}
                                                customlabel={<div>{each}</div>}
                                            >
                                                {each}
                                            </Select.Option>
                                        );
                                    })}
                            </Select>
                        </Form.Item>
                    }
                />
            </Col>
            <Col xs={24} lg={12} id='issued_date' >
                <LabelWrapper
                    label="Issued Date"
                    render={
                        <Form.Item
                            rules={VALIDATIONS.issued_date}
                            name="issued_date"
                        // initialValue={
                        //   formValues2.issued_date
                        //     ? dayjs(formValues2.issued_date)
                        //     : null
                        // }
                        >
                            <div className="datepicker-underline">
                                <DatePicker
                                    onChange={(date: any, dateString: string) => {
                                        handleDateChange(date, dateString, "issued_date");
                                    }}
                                    placeholder=""
                                    value={
                                        formValues.issued_date
                                            ? formValues.issued_date
                                            : null
                                    }
                                    bordered={false}
                                    inputReadOnly={false}
                                    style={{ width: "100%", paddingLeft: 0 }}
                                    disabledDate={disabledDateValidation}
                                />
                            </div>
                        </Form.Item>
                    }
                />
            </Col>
            <Col xs={24} lg={12} id='issued_np'  >
                <LabelWrapper
                    label="Issued Date (BS)"
                    render={
                        <Form.Item
                            name="issued_np"
                            rules={[
                                { required: true, message: "Issued Date is required" },
                            ]}
                        >
                            <div className="nepali-calendar-wrapper">
                                <div className="nepali-calendar-value">
                                    {formValues.issued_np}
                                </div>
                                <NepaliDatePicker
                                    className="nepali-calendar"
                                    onChange={(date: any) => {
                                        handleNepaliDateChange2(date);
                                    }}
                                    value={formValues.issued_np}
                                />
                            </div>
                        </Form.Item>
                    }
                />
            </Col>

        </Row>
    )
}
