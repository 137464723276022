export const ADD_KYC_DETAIL_REQUEST = "ADD_KYC_DETAIL_REQUEST";
export const ADD_KYC_DETAIL_SUCCESS = "ADD_KYC_DETAIL_SUCCESS";
export const ADD_KYC_DETAIL_FAILURE = "ADD_KYC_DETAIL_FAILURE";

export const UPDATE_KYC_DETAIL_REQUEST = "UPDATE_KYC_DETAIL_REQUEST";
export const UPDATE_KYC_DETAIL_SUCCESS = "UPDATE_KYC_DETAIL_SUCCESS";
export const UPDATE_KYC_DETAIL_FAILURE = "UPDATE_KYC_DETAIL_FAILURE";
//2 can be merged into 1, need to refactor
export const UPDATE_KYC_DETAIL_REQUEST_2 = "UPDATE_KYC_DETAIL_REQUEST_2";
export const UPDATE_KYC_DETAIL_SUCCESS_2 = "UPDATE_KYC_DETAIL_SUCCESS_2";
export const UPDATE_KYC_DETAIL_FAILURE_2 = "UPDATE_KYC_DETAIL_FAILURE_2";

export const RESET_CONTINUE_FORM_ID = "RESET_CONTINUE_FORM_ID";

//patch api without success flag (implemented for rescan bank cheque only)
export const UPDATE_KYC_DETAIL_REQUEST_WITHOUT_FLAG =
  "UPDATE_KYC_DETAIL_REQUEST_WITHOUT_FLAG";
export const UPDATE_KYC_DETAIL_SUCCESS_WITHOUT_FLAG =
  "UPDATE_KYC_DETAIL_SUCCESS_WITHOUT_FLAG";
export const UPDATE_KYC_DETAIL_FAILURE_WITHOUT_FLAG =
  "UPDATE_KYC_DETAIL_FAILURE_WITHOUT_FLAG";

export const GET_KYC_DETAIL_REQUEST = "GET_KYC_DETAIL_REQUEST";
export const GET_KYC_DETAIL_SUCCESS = "GET_KYC_DETAIL_SUCCESS";
export const GET_KYC_DETAIL_FAILURE = "GET_KYC_DETAIL_FAILURE";

export const SET_KYC_FORM_VALUES = "SET_KYC_FORM_VALUES";
export const SET_NEW_KYC_FORM_VALUES = "SET_NEW_KYC_FORM_VALUES";
export const SET_CITIZENSHIP_OCR_DATA = "SET_CITIZENSHIP_OCR_DATA";
export const CLEAR_SUCCESS = "CLEAR_SUCCESS";

export const PAYMENT_INIT = "PAYMENT_INIT";
export const PAYMENT_SUCCESS = "PAYMENT_SUCCESS";
export const PAYMENT_FAILURE = "PAYMENT_FAILURE";

export const GET_DEMATPDF_REQUEST = "GET_DEMATPDF_REQUEST";
export const GET_DEMATPDF_SUCCESS = "GET_DEMATPDF_SUCCESS";
export const GET_DEMATPDF_FAILURE = "GET_DEMATPDF_FAILURE";
export const CLEAR_PDF_SUCCESS = "CLEAR_PDF_SUCCESS";

export const SET_CURRENT_USER = "SET_CURRENT_USER";

export const ADD_CREATE_USER_REQUEST = "ADD_CREATE_USER_REQUEST";
export const ADD_CREATE_USER_SUCCESS = "ADD_CREATE_USER_SUCCESS";
export const ADD_CREATE_USER_FAILURE = "ADD_CREATE_USER_FAILURE";

export const RESET_CREATE_USER_SUCCESS = "RESET_CREATE_USER_SUCCESS";

export const FORCE_RELOAD_REQUEST = "FORCE_RELOAD_REQUEST";

export const SET_GLOBAL_TIMER = "SET_GLOBAL_TIMER";
export const RESET_GLOBAL_TIMER = "RESET_GLOBAL_TIMER";
export const SET_INITIAL_GLOBAL_TIMER = "SET_INITIAL_GLOBAL_TIMER";

export const ADD_PDF_READER_REQUEST = "ADD_PDF_READER_REQUEST";
export const ADD_PDF_READER_SUCCESS = "ADD_PDF_READER_SUCCESS";
export const ADD_PDF_READER_FAILURE = "ADD_PDF_READER_FAILURE";

export const TOGGLE_IMAGE_LOADER = "TOGGLE_IMAGE_LOADER";
export const TOGGLE_IMAGE_LOADER2 = "TOGGLE_IMAGE_LOADER2";

export const GET_TRADING_PDFDATA_REQUEST = "GET_TRADING_PDFDATA_REQUEST";
export const GET_TRADING_PDFDATA_SUCCESS = "GET_TRADING_PDFDATA_SUCCESS";
export const GET_TRADING_PDFDATA_FAILURE = "GET_TRADING_PDFDATA_FAILURE";

export const SIGNATURE_BACKGOUND_REMOVER_REQUEST =
  "SIGNATURE_BACKGROUND_REMOVER_REQUEST";
export const SIGNATURE_BACKGOUND_REMOVER_SUCCESS =
  "SIGNATURE_BACKGROUND_REMOVER_SUCCESS";
export const SIGNATURE_BACKGOUND_REMOVER_FAILURE =
  "SIGNATURE_BACKGROUND_REMOVER_FAILURE";
export const RESET_MAIN_SIGNATURE = "RESET_MAIN_SIGNATURE";

export const COMPLETED_USER_PDF_REQUEST = "COMPLETED_USER_PDF_REQUEST";
export const COMPLETED_USER_PDF_SUCCESS = "COMPLETED_USER_PDF_SUCCESS";
export const COMPLETED_USER_PDF_FAILURE = "COMPLETED_USER_PDF_FAILURE";

export const RESET_PDF_LINK = "RESET_PDF_LINK";
export const RESET_COMPLETED_PDF_LINK = "RESET_COMPLETED_PDF_LINK";

export const SET_PAYMENT_YEARS = "SET_PAYMENT_YEARS";
export const RESET_PAYMENT_YEARS = "RESET_PAYMENT_YEARS";

export const SEND_EMAIL_REQUEST = "SEND_EMAIL_REQUEST";

export const SCHEDULE_DATE_TIME = "SCHEDULE_DATE_TIME";
export const SCHEDULE_DATE_TIME_SUCCESS = "SCHEDULE_DATE_TIME_SUCCESS";
export const SCHEDULE_DATE_TIME_FAILURE = "SCHEDULE_DATE_TIME_FAILURE";

export const REQUEST_KHALTI_URL = "REQUEST_KHALTI_URL";
export const REQUEST_KHALTI_URL_SUCCESS = "REQUEST_KHALTI_URL_SUCCESS";
export const REQUEST_KHALTI_URL_FAILURE = "REQUEST_KHALTI_URL_FAILURE";

export const GET_PAYMENT_DETAILS = "GET_PAYMENT_DETAILS"
export const GET_PAYMENT_DETAILS_SUCCESS = "GET_PAYMENT_DETAILS_SUCCESS"
export const GET_PAYMENT_DETAILS_FAILURE = "GET_PAYMENT_DETAILS_FAILURE"
export const RESET_PAYMENT_DETAILS = "RESET_PAYMENT_DETAILS"
