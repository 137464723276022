import { Col, Image, Modal, Row, Spin } from "antd";
import ThumbprintImg from "Assets/Images/thumbprintsample.svg";

type Props = {
  isVisible: boolean;
  closeModal: () => void;
};

const BoidSampleModal = ({ isVisible, closeModal }: Props) => {
  return (
    <Modal
      centered
      footer={null}
      closable={false}
      visible={isVisible}
      width={"70vw"}
      destroyOnClose
      onCancel={closeModal}
    >
      <div className="d-flex flex-center">
        <Image
          style={{
            width: "100%",
            maxHeight: "60vh",
            objectFit: "contain",
          }}
          src={process.env.PUBLIC_URL + "/imagess/boidsample.jpg"}
          alt="sample of thumbprint to upload"
        />
      </div>
    </Modal>
  );
};

export default BoidSampleModal;
