import { useEffect } from 'react';

import {
  Button,
  Col,
  Row,
} from 'antd';
import {
  ReactComponent as DmatCompletion,
} from 'Assets/Icons/dmatcomplete.svg';
import { ReactComponent as GreenPlus } from 'Assets/Icons/greenplus.svg';
import { Storage } from 'aws-amplify';
import { CustomNotification } from 'Components/CustomNotification';
import Progressbar from 'Components/Progressbar';
import { ConnectedRouterProps } from 'connected-react-router';
import { useSetKycFormValue } from 'CustomHooks/useSetKycFormValue';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import {
  getDematPdfLink,
  getKycDetailRequest,
  resetPdfLink,
  setCurrentUser,
} from 'Redux/Protected/Action';
import { RootState } from 'Redux/RootReducer';
import { downloadBlob } from 'Utils/DownloadFunction';

const TradingFormCompletion = ({ history }: ConnectedRouterProps) => {
  const {
    kycFormValues,
    kycDetails,
    globalTimer,
    currentUserId,
    pdfLoading,
    pdfLink,
  } = useSelector((state: RootState) => state?.protectedReducer);
  const dispatch = useDispatch();

  const {oid} = useSetKycFormValue()

  useEffect(() => {
    if (Object.keys(kycFormValues).length) {
      dispatch(getDematPdfLink(kycDetails?.id, oid, "trading"));
    }
    return () => {
      dispatch(resetPdfLink());
    };
  }, [kycFormValues]);

  const backHandler = () => {
    history.push("/app/trading/terms/nepali");
  };
  const submitHandler = () => {
    CustomNotification({
      title: "Form Completion",
      description:
        "Thank you for choosing ABC Securities Pvt. Ltd. We will get back to you shortly.",
      type: "success",
    });
    dispatch(setCurrentUser(""));
    dispatch(getKycDetailRequest(kycDetails?.id));
    history.push("/app/profile");
  };

  const downloadPdfHandler = async () => {
    if (pdfLink !== "") {
      try {
        const result: any = await Storage.get(pdfLink, {
          download: true,
          customPrefix: {
            public: "",
          },
        });
        downloadBlob(
          result.Body,
          `${kycFormValues?.username ?? "trading"} - trading.pdf`
        );
      } catch (err) {
        // console.log("err");
      }
    }
  };

  const formattedTime: any =
    ("0" + Math.floor((globalTimer / 60) % 60)).slice(-2) +
    ":" +
    ("0" + Math.floor(globalTimer % 60)).slice(-2) +
    " min";

  return (
    <>
      <Progressbar percent={100} leftText={"100%"} rightText={formattedTime} />

      <Row
        className="termsLayout"
        style={{ paddingInline: "5vw", paddingBlock: "2rem" }}
      >
        <Col
          span="24"
          className="d-flex"
          style={{
            justifyContent: "flex-end",
          }}
        >
          <Button
            type="default"
            className="manuallyBtn"
            shape="round"
            loading={pdfLoading}
            onClick={downloadPdfHandler}
          >
            {!pdfLoading && (
              <div className="d-flex h-100p w-100p flex-between align-items-center">
                <GreenPlus style={{ marginRight: 10 }} /> Download PDF
              </div>
            )}
          </Button>
        </Col>

        <div
          className="termsMiddleContainer"
        >
          <Col span="24" className="termsMiddleContainer">
            <div className="d-flex flex-column h-100p flex-center align-items-center">
              <DmatCompletion />
              <div className="mt-5 mb-1 sb-text">Trading Form Completion</div>
              <div className="text-center text-light-grey">
                Your trading form has successfully been submitted. We will
                contact you once the form has been verified.
              </div>
            </div>
            <Button
              // loading={loading}
              htmlType="submit"
              className="submit-btn"
              block
              shape="round"
              onClick={submitHandler}
            >
              Continue
            </Button>
            {/* <Button
              type="link"
              className="cancel-link-btn"
              onClick={backHandler}
            >
              Cancel
            </Button> */}
          </Col>
        </div>
      </Row>
    </>
  );
};

export default TradingFormCompletion;