import React, {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
} from 'react';

import {
  Col,
  Form,
  FormInstance,
  Input,
  Row,
  Select,
  Space,
  Typography,
} from 'antd';
import LabelWrapper from 'Components/LabelWrapper';
import OutlineButton from 'Components/OutlineButton';
import SelectLabelDisplay from 'Components/SelectLabelDisplay';
import { useSelector } from 'react-redux';
import { RootState } from 'Redux/RootReducer';
import { createDistrictList } from 'Utils/createDistrictList';
import { VALIDATIONS } from 'Utils/formValidationRules';

interface AddressInfoProps {
    setFormValues: Dispatch<SetStateAction<any>>
    formValues: any
    form: FormInstance
    currentAddress: "same" | "new"
    setCurrentAddress: Dispatch<SetStateAction<"same" | "new">>


}

export const AddressInfo = ({ setFormValues, formValues, form, currentAddress, setCurrentAddress }: AddressInfoProps) => {

    //var
    const { addressDetails, addressLoading } = useSelector(
        (state: RootState) => state.staticReducer
    );
    const { kycFormValues, } =
        useSelector((state: RootState) => state?.protectedReducer)

    //state
    const [vdcVal, setVdcVal] = useState<any>("");
    const [tempVdcVal, setTempVdcVal] = useState<any>("");
    const [districtDetails, setDistrictDetails] = useState<any>([]);

    useEffect(() => { //set district list value
        if (addressDetails.length) {
            const requiredDistrictList = createDistrictList({
                addressArray: addressDetails,
            });
            setDistrictDetails(requiredDistrictList);
        }
    }, [addressDetails]);

    useEffect(() => { // set address value
        if (
            (kycFormValues)
        ) {
            if (kycFormValues.hasOwnProperty("same_as_permanent")) {
                if (kycFormValues.same_as_permanent) {
                    setCurrentAddress("same")
                } else {
                    setCurrentAddress("new")
                }
            }
            const setValues = {
                sub: kycFormValues?.permanent_address?.sub,
                country: kycFormValues?.permanent_address?.country,
                district: kycFormValues?.permanent_address?.district,
                province: kycFormValues?.permanent_address?.province,
                tole: kycFormValues?.permanent_address?.tole,
                ward: kycFormValues?.permanent_address?.ward,
                nearest_landmark: kycFormValues?.permanent_address?.nearest_landmark,

                temp_sub: kycFormValues?.temporary_address?.sub,
                temp_country: kycFormValues?.temporary_address?.country,
                temp_district: kycFormValues?.temporary_address?.district,
                temp_province: kycFormValues?.temporary_address?.province,
                temp_tole: kycFormValues?.temporary_address?.tole,
                temp_ward: kycFormValues?.temporary_address?.ward,
                temp_nearest_landmark: kycFormValues?.temporary_address?.nearest_landmark,
            }

            form.setFieldsValue(setValues)
            setFormValues((prevState: any) => ({
                ...prevState,
                permanent_address: {
                    country: kycFormValues?.permanent_address?.country,
                    district: kycFormValues?.permanent_address?.district,
                    province: kycFormValues?.permanent_address?.province,
                    tole: kycFormValues?.permanent_address?.tole,
                    ward: kycFormValues?.permanent_address?.ward,
                    nearest_landmark: kycFormValues?.permanent_address?.nearest_landmark,
                    sub: kycFormValues?.permanent_address?.sub
                },
                temporary_address: {
                    country: kycFormValues?.temporary_address?.country,
                    district: kycFormValues?.temporary_address?.district,
                    province: kycFormValues?.temporary_address?.province,
                    tole: kycFormValues?.temporary_address?.tole,
                    ward: kycFormValues?.temporary_address?.ward,
                    nearest_landmark: kycFormValues?.temporary_address?.nearest_landmark,
                    sub: kycFormValues?.temporary_address?.sub
                },
            }))
        }
    }, [kycFormValues, form, setFormValues, setCurrentAddress])



    const handleInputChange = (e: any) => {
        setFormValues({ ...formValues, [e.target.name]: e.target.value });
    };

    const handleSelectChange = (e: any, name: string) => {
        setFormValues({ ...formValues, [name]: e });

    };

    const handleAddress = (e: ChangeEvent<HTMLInputElement>, parentObj: string) => {
        if (parentObj === "permanent") {
            setFormValues({
                ...formValues,
                permanent_address: {
                    ...formValues.permanent_address,
                    [e.target.name]: e.target.value
                }
            })
        }

        if (parentObj === "temp") {
            setFormValues({
                ...formValues,
                temporary_address: {
                    ...formValues.temporary_address,
                    [e.target.name]: e.target.value
                }
            })
        }

    }


    const handleSelectAddressChange = (e: any, target: string) => {

        if (target === "permanent") {
            let parsedData = JSON.parse(e);
            setVdcVal(parsedData.name);
        
            form.setFieldsValue({ country: parsedData.country, district: parsedData.district, province: parsedData.province,  sub: `${parsedData.name}`, })
            setFormValues({
                ...formValues,
                permanent_address: {
                    ...formValues.permanent_address,
                    country: parsedData.country,
                    district: parsedData.district,
                    province: parsedData.province,
                    sub: `${parsedData.name}`,
                }
            })
        }

        if (target === "temp") {
            let parsedData = JSON.parse(e);
            setTempVdcVal(parsedData.name);

            form.setFieldsValue({ temp_country: parsedData.country, temp_district: parsedData.district, temp_province: parsedData.province, temp_sub: parsedData.name, })

            setFormValues({
                ...formValues,
                temporary_address: {
                    ...formValues.temporary_address,
                    country: parsedData.country,
                    district: parsedData.district,
                    province: parsedData.province,
                    sub: parsedData.name,
                }
            })
        }

    };

    return (
        <>
            <Row gutter={[16, 16]} className='groupWrapper mt-2'>
                <Col xs={24}>
                    <Typography className={`r-text fw-500 text-light-black `}>Permanent Address </Typography>
                </Col>
                <Col xs={24} lg={12} id='sub'>
                    <LabelWrapper
                        label={"Please select a VDC/Municipality/Metropolitan City"}
                        render={
                            <Form.Item
                                name="sub"
                                rules={VALIDATIONS.sub}
                                initialValue={vdcVal}
                            >
                                <Select
                                    // defaultValue={vdcVal}
                                    loading={addressLoading}
                                    showSearch={true}
                                    onChange={(e) => handleSelectAddressChange(e, "permanent")}
                                    optionLabelProp="customlabel"
                                    className="underlineSelect"
                                >
                                    {addressDetails?.length &&
                                        addressDetails.sort().map((each: any, index: number) => {
                                            return (
                                                <Select.Option
                                                    key={index}
                                                    value={JSON.stringify(each)}
                                                    customlabel={<div>{each.label}</div>}
                                                >
                                                    <SelectLabelDisplay
                                                        label={`${each.name}, ${each.district}`}
                                                        value={each.value}
                                                        currentVal={""}
                                                    />
                                                </Select.Option>
                                            );
                                        })}
                                </Select>
                            </Form.Item>
                        }
                    />
                </Col>
                <Col xs={24} lg={12} id='country'>
                    <LabelWrapper
                        label="Country"
                        render={
                            <Form.Item
                                name="country"
                                rules={VALIDATIONS.country}
                                initialValue={formValues?.permanent_address?.country}
                            >
                                <Input
                                    bordered={false}
                                    name="country"
                                    className="underlineInput"
                                    onChange={handleInputChange}
                                    disabled={true}
                                // value={formValues.country}
                                />
                            </Form.Item>
                        }
                    />
                </Col>
                <Col xs={24} lg={12} id='district'>
                    <LabelWrapper
                        label="District"
                        render={
                            <Form.Item
                                name="district"
                                rules={VALIDATIONS.district}
                                initialValue={formValues?.permanent_address?.district}
                            >
                                <Select
                                    className="underlineSelect"
                                    value={formValues?.permanent_address?.district}
                                    loading={addressLoading}
                                    showSearch={true}
                                    onChange={(e) => handleSelectChange(e, "district")}
                                    optionLabelProp="customlabel"
                                    disabled={true}
                                >
                                    {districtDetails?.length &&
                                        districtDetails.map((each: any, index: number) => {
                                            return (
                                                <Select.Option
                                                    key={index}
                                                    value={each}
                                                    customlabel={<div>{each}</div>}
                                                >
                                                    <SelectLabelDisplay
                                                        currentVal={formValues?.permanent_address?.district}
                                                        label={each}
                                                        value={each}
                                                    />
                                                </Select.Option>
                                            );
                                        })}
                                </Select>
                            </Form.Item>
                        }
                    />
                </Col>
                <Col xs={24} lg={12} id='province'>
                    <LabelWrapper
                        label="Province"
                        render={
                            <Form.Item
                                name="province"
                                rules={VALIDATIONS.province}
                                initialValue={formValues?.permanent_address?.province}
                            >
                                <Input
                                    bordered={false}
                                    className="underlineInput"
                                    name="province"
                                    onChange={handleInputChange}
                                    disabled={true}
                                    value={formValues?.permanent_address?.province}
                                />
                            </Form.Item>
                        }
                    />
                </Col>
                <Col xs={24} lg={12} id='tole'>

                    <LabelWrapper
                        label="Tole"
                        render={
                            <Form.Item
                                name="tole"
                                initialValue={formValues?.permanent_address?.tole}
                                rules={VALIDATIONS.tole}
                            >
                                <Input
                                    bordered={false}
                                    className="underlineInput"
                                    name="tole"
                                    onChange={(e) => handleAddress(e, "permanent")}
                                    value={formValues?.permanent_address?.tole}
                                />
                            </Form.Item>
                        }
                    />

                </Col>
                <Col xs={24} lg={12} id='nearest_landmark'>
                    <LabelWrapper
                        label="Nearest Landmark"
                        render={
                            <Form.Item
                                name="nearest_landmark"
                                initialValue={formValues?.permanent_address?.nearest_landmark}
                                rules={VALIDATIONS.nearest_landmark}
                            >
                                <Input
                                    bordered={false}
                                    className="underlineInput"
                                    name="nearest_landmark"
                                    onChange={(e) => handleAddress(e, "permanent")}
                                    value={formValues?.permanent_address?.nearest_landmark}
                                />
                            </Form.Item>
                        }
                    />
                </Col>
                <Col xs={24} lg={12} id='ward'>
                    <LabelWrapper
                        label="Ward Number"
                        // subLabel="Enter Ward Number (0 - 100)"
                        render={
                            <>
                                <Form.Item
                                    name="ward"
                                    initialValue={formValues?.permanent_address?.ward}
                                // rules={VALIDATIONS.ward}
                                >
                                    <Input
                                        style={{ width: "100%" }}
                                        bordered={false}
                                        className="underlineInput"
                                        name="ward"
                                        type="number"
                                        onChange={(e) => handleAddress(e, "permanent")}
                                        value={formValues?.permanent_address?.ward}
                                    />
                                </Form.Item>
                            </>

                        }
                    />
                </Col>
                
            </Row>
            <Row gutter={[16, 16]} className='mt-2 groupWrapper'>
                <Space direction="horizontal" className="flex-between w-100p mb-1">
                    <span className="sb-text text-black ">Current Address</span>

                </Space>
                <Col xs={12} lg={6} >
                    <OutlineButton
                        label="Same As Permanent"
                        active={currentAddress === "same" && true}

                        onClick={() => {
                            setCurrentAddress("same")
                        }}
                    />
                </Col>
                <Col xs={12} lg={6} >
                    <OutlineButton
                        label="Add New"
                        active={currentAddress === "new" && true}
                        onClick={() => {
                            setCurrentAddress("new")

                        }}
                    />
                </Col>

                {currentAddress === "new" &&

                    <Row gutter={[16, 16]}>
                        <Col xs={24} lg={12} id='temp_sub'>
                            <LabelWrapper
                                label={"Please select a VDC/Municipality/Metropolitan City"}
                                render={
                                    <Form.Item
                                        name="temp_sub"
                                        rules={VALIDATIONS.sub}
                                        initialValue={tempVdcVal}
                                    >
                                        <Select
                                            // defaultValue={vdcVal}
                                            loading={addressLoading}
                                            showSearch={true}
                                            onChange={(e) => handleSelectAddressChange(e, "temp")}
                                            optionLabelProp="customlabel"
                                            className="underlineSelect"
                                        >
                                            {addressDetails?.length &&
                                                addressDetails.sort().map((each: any, index: number) => {
                                                    return (
                                                        <Select.Option
                                                            key={index}
                                                            value={JSON.stringify(each)}
                                                            customlabel={<div>{each.label}</div>}
                                                        >
                                                            <SelectLabelDisplay
                                                                label={`${each.name}, ${each.district}`}
                                                                value={each.value}
                                                                currentVal={""}
                                                            />
                                                        </Select.Option>
                                                    );
                                                })}
                                        </Select>
                                    </Form.Item>
                                }
                            />
                        </Col>
                        <Col xs={24} lg={12} id='temp_country'>
                            <LabelWrapper
                                label="Country"
                                render={
                                    <Form.Item
                                        name="temp_country"
                                        rules={VALIDATIONS.country}
                                        initialValue={formValues?.temporary_address?.country}
                                    >
                                        <Input
                                            bordered={false}
                                            name="country"
                                            className="underlineInput"
                                            onChange={handleInputChange}
                                            disabled={true}
                                        // value={formValues.country}
                                        />
                                    </Form.Item>
                                }
                            />
                        </Col>
                        <Col xs={24} lg={12} id='temp_district'>
                            <LabelWrapper
                                label="District"
                                render={
                                    <Form.Item
                                        name="temp_district"
                                        rules={VALIDATIONS.district}
                                        initialValue={formValues?.temporary_address?.district}
                                    >
                                        <Select
                                            className="underlineSelect"
                                            value={formValues?.temporary_address?.district}
                                            loading={addressLoading}
                                            showSearch={true}
                                            onChange={(e) => handleSelectChange(e, "district")}
                                            optionLabelProp="customlabel"
                                            disabled={true}
                                        >
                                            {districtDetails?.length &&
                                                districtDetails.map((each: any, index: number) => {
                                                    return (
                                                        <Select.Option
                                                            key={index}
                                                            value={each}
                                                            customlabel={<div>{each}</div>}
                                                        >
                                                            <SelectLabelDisplay
                                                                currentVal={formValues?.temporary_address?.district}
                                                                label={each}
                                                                value={each}
                                                            />
                                                        </Select.Option>
                                                    );
                                                })}
                                        </Select>
                                    </Form.Item>
                                }
                            />
                        </Col>
                        <Col xs={24} lg={12} id='temp_province'>
                            <LabelWrapper
                                label="Province"
                                render={
                                    <Form.Item
                                        name="temp_province"
                                        rules={VALIDATIONS.province}
                                        initialValue={formValues?.temporary_address?.province}
                                    >
                                        <Input
                                            bordered={false}
                                            className="underlineInput"
                                            name="province"
                                            onChange={handleInputChange}
                                            disabled={true}
                                            value={formValues?.temporary_address?.province}
                                        />
                                    </Form.Item>
                                }
                            />
                        </Col>
                        <Col xs={24} lg={12} id='temp_tole'>

                            <LabelWrapper
                                label="Tole"
                                render={
                                    <Form.Item
                                        name="temp_tole"
                                        initialValue={formValues?.temporary_address?.tole}
                                        rules={VALIDATIONS.tole}
                                    >
                                        <Input
                                            bordered={false}
                                            className="underlineInput"
                                            name="tole"
                                            onChange={(e) => handleAddress(e, "temp")}
                                            value={formValues?.temporary_address?.tole}
                                        />
                                    </Form.Item>
                                }
                            />

                        </Col>
                        <Col xs={24} lg={12} id='temp_nearest_landmark'>
                            <LabelWrapper
                                label="Nearest Landmark"
                                render={
                                    <Form.Item
                                        name="temp_nearest_landmark"
                                        initialValue={formValues?.temporary_address?.nearest_landmark}
                                        rules={VALIDATIONS.nearest_landmark}
                                    >
                                        <Input
                                            bordered={false}
                                            className="underlineInput"
                                            name="nearest_landmark"
                                            onChange={(e) => handleAddress(e, "temp")}
                                            value={formValues?.temporary_address?.nearest_landmark}
                                        />
                                    </Form.Item>
                                }
                            />
                        </Col>
                        <Col xs={24} lg={12} id='temp_ward'>
                            <LabelWrapper
                                label="Ward Number"
                                // subLabel="Enter Ward Number (0 - 100)"
                                render={
                                    <>
                                        <Form.Item
                                            name="temp_ward"
                                            initialValue={formValues?.temporary_address?.ward}
                                        // rules={VALIDATIONS.ward}
                                        >

                                            <Input
                                                style={{ width: "100%" }}
                                                bordered={false}
                                                className="underlineInput"
                                                name="ward"
                                                type="number"
                                                onChange={(e) => handleAddress(e, "temp")}
                                                value={formValues?.temporary_address?.ward}
                                            />
                                        </Form.Item>
                                    </>

                                }
                            />
                        </Col>
                    </Row>
                }
            </Row>
        </>
    )
}
