import 'Ant.less';
import './styles/index.scss';

import { Suspense } from 'react';

import { message } from 'antd';
import Amplify from 'aws-amplify';
import { Authenticator } from 'aws-amplify-react';
import LogoutTest from 'Components/LogoutTest';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
  useParams,
} from 'react-router-dom';
import AuthRoute from 'Routes/AuthRoute';
import ProtectedRoute from 'Routes/ProtectedRoute';
import ErrorPage from 'Views/ErrorPage';

import { AmazonAIPredictionsProvider } from '@aws-amplify/predictions';

import configureAmplify from './Api/amplify-configure';
import RouteGuard from './Routes/RouteGuard';

configureAmplify()
Amplify.addPluggable(new AmazonAIPredictionsProvider())

function App() {
  message.config({
    duration: 7,
  })

  const {oid} = useParams<any>()


  return (
    <Suspense fallback={<div className='loading' />}>
      <Router>
        <Switch>
          <Route path='/auth' component={AuthRoute} />
          {/* <Route path="/components" component={Components} /> */}
          <Route path='/logout' component={LogoutTest} />
          <Route path='/error' component={ErrorPage} />

          <Route
            path='/app'
            render={(props) => <ProtectedRoute {...props} />}
          />
          <Route
            path='/'
            exact
            render={(props) => (
              <Authenticator {...props}>
                <RouteGuard {...props} />
              </Authenticator>
            )}
          />
          <Redirect to='/error' />
        </Switch>
      </Router>
    </Suspense>
  )
}

export default App
