import React, {
  Dispatch,
  SetStateAction,
  useEffect,
} from 'react';

import { Col } from 'antd';
import { RcFile } from 'antd/lib/upload';
import CommonImagePicker from 'Components/CommonImagePicker';
import SubLabelWrapper from 'Components/SubLabelWrapper';
import { useSetKycFormValue } from 'CustomHooks/useSetKycFormValue';
import { useSelector } from 'react-redux';
import { RootState } from 'Redux/RootReducer';

interface ProfileUpload {
    setProfileData: Dispatch<SetStateAction<string>>
}

export const ProfileUpload = ({ setProfileData }: ProfileUpload) => {
    //var
    const { kycFormValues, } =
        useSelector((state: RootState) => state?.protectedReducer)
    //hooks
    const { store } = useSetKycFormValue()


    useEffect(() => { //set profile data
        if (kycFormValues?.profile) {
            setProfileData(kycFormValues?.profile)
        }
        if (kycFormValues?.guardian_details?.profile) {
            setProfileData(kycFormValues.guardian_details.profile)

        }
    }, [kycFormValues?.profile, kycFormValues?.guardian_details?.profile,  setProfileData])

    const onSelectProfile = (file: RcFile) => {
        let extension = file?.type.split("/")[1];
        store(extension, file, "nonMinorProfile", setProfileData)
    }

    return (
        <Col xs={24} lg={10}>
            <div className=''>
                <SubLabelWrapper
                    label={kycFormValues?.minor ? `Guardian Photo` : `Your Photo`}
                    subLabel="Please perform this step under good lighting condition"
                    showSubLabel={true}
                    render={
                        <div>
                            <CommonImagePicker
                                onSelect={onSelectProfile}
                                circleImage
                                imageUrl={kycFormValues?.minor ? kycFormValues?.guardian_details?.profile : kycFormValues?.profile ?? ""}
                            />
                        </div>
                    }
                />
            </div>
        </Col>
    )
}
