import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
} from 'react';

import {
  Button,
  Col,
  Image,
  message,
  Progress,
  Row,
  Upload,
} from 'antd';
import ImgCrop from 'antd-img-crop';
import CloseIcon from 'Assets/Icons/close.svg';
import ImageIcon from 'Assets/Icons/image.png';
import SubLabelWrapper from 'Components/SubLabelWrapper';
import { useSetKycFormValue } from 'CustomHooks/useSetKycFormValue';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { RootState } from 'Redux/RootReducer';
import { checkValidFile } from 'Utils/checkValidFile';
import { dummyRequest } from 'Utils/DummyCall';

interface SignatureProps {
    setSignatureKey: Dispatch<SetStateAction<string>>
    signatureKey: string
    dispatchKycFormValues: () => void
}

export const Signature = ({ setSignatureKey, signatureKey, dispatchKycFormValues }: SignatureProps) => {
    //var
    const { kycFormValues, loading } =
        useSelector((state: RootState) => state?.protectedReducer)

    //hooks
    const history = useHistory()
    const { oid, store } = useSetKycFormValue()

    //state
    const [imagePercent, setImagePercent] = useState(0);

    useEffect(() => {
        if (kycFormValues?.meroshare?.signature) {
            // getImage();
            setSignatureKey(kycFormValues?.meroshare?.signature)
            setImagePercent(100)
        }
    }, [kycFormValues, setSignatureKey]);




    const handleDigitalSignature = () => {
        dispatchKycFormValues()
        history.push(`/app/${oid}/meroshare/digital-signature`);
    };

    const resetSignature = () => {
        // setSignatureImage(null);
        setSignatureKey("")
        setImagePercent(0);
    };

    const beforeUpload = async (file: any) => {
        const checkFile = await checkValidFile(file);
        if (!checkFile) return;
        const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
        if (!isJpgOrPng) {
            message.error("You can only upload JPG file and PNG Files!");
        } else {
            //   setImagePercent(0);
            //   setSignatureImage(file);
            let extension = file?.type.split("/")[1];
            const call = (data: string) => {
                setSignatureKey(data)
                setImagePercent(100)

            }
            store(extension, file, "meroshareSignature", call)
        }
    };


    return (
        <Row gutter={[16, 16]} className='groupWrapper mt-1'>
            <SubLabelWrapper
                label={`Add or Upload Signature`}
                subLabel="Digitally sign or Manually upload signature"
                render={
                    <Row>
                        {/* {!signatureImage ? <></> : null} */}
                        {signatureKey || imagePercent === 100 ? (
                            <Col
                                span="24"
                                style={{ border: "1px dashed #0AB290" }}
                                className="br-8 p-1"
                            >
                                <Row className="mb-1">
                                    <Col span="22" className="d-flex">
                                        <Image src={ImageIcon} preview={false} />
                                        <span className="pl-1" style={{ color: "#60706F" }}>
                                            Meroshare Signature.png
                                        </span>
                                    </Col>
                                    <Col span="2" className="text-right pointer">
                                        <Image
                                            onClick={resetSignature}
                                            src={CloseIcon}
                                            preview={false}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span="22">
                                        {" "}
                                        <Progress
                                            strokeColor="#00DDA2"
                                            showInfo={false}
                                            percent={imagePercent}
                                        />
                                    </Col>
                                    <Col
                                        span="22"
                                        className="text-right"
                                        style={{ marginTop: "-0.4rem" }}
                                    >
                                        {imagePercent}%
                                    </Col>
                                </Row>
                            </Col>
                        ) : (
                            <>
                                <Col span="24">
                                    <Button
                                        onClick={handleDigitalSignature}
                                        block
                                        className="br-8 mb-1"
                                        type="dashed"
                                    >
                                        Add signature digitally
                                    </Button>
                                </Col>
                                <Col span="24">
                                    <ImgCrop>
                                        <Upload
                                            // style={{ width: "100%" }}
                                            multiple={false}
                                            accept="image/*"
                                            showUploadList={false}
                                            beforeUpload={beforeUpload}
                                            customRequest={dummyRequest}
                                        >
                                            <Button
                                                style={{ width: "90vw" }}
                                                block
                                                className="br-8"
                                                type="dashed"
                                            >
                                                Upload signature
                                            </Button>
                                        </Upload>
                                    </ImgCrop>
                                </Col>
                            </>
                        )}
                    </Row>
                }
            />
        </Row>
    )
}
