import React, {
  useEffect,
  useState,
} from 'react';

import {
  Col,
  Row,
} from 'antd';
import OutlineButton from 'Components/OutlineButton';
import SubLabelWrapper from 'Components/SubLabelWrapper';
import { useSetKycFormValue } from 'CustomHooks/useSetKycFormValue';
import { IPageProps } from 'Interfaces/Page';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import {
  clearSuccess,
  setKycFormValues,
  updateKycDetailRequest,
} from 'Redux/Protected/Action';
import { RootState } from 'Redux/RootReducer';

import RenderFooter from '../components/RenderFooter';
import ProgressbarLayout from '../Layout/ProgressbarLayout';

interface initialStateButton {
    yesVal: boolean;
    noVal: boolean;
    pressed: boolean;
}
const initialValues: initialStateButton = {
    yesVal: false,
    noVal: false,
    pressed: false,
};

export const DematTransactionInfo = ({ history, pageNumber }: IPageProps) => {
    //var
    const { kycFormValues, actionLoading, success, globalTimer } = useSelector(
        (state: RootState) => state?.protectedReducer
    );

    //hooks
    const dispatch = useDispatch();
    const { oid } = useSetKycFormValue()

    //state
    const [values, setValues] = useState(initialValues);
    const { yesVal, noVal, pressed } = values;





    const backHandler = () => {
        history.push(`/app/${oid}/dematt/nominee`)
    };
    // stan_auto_trans
    const skipHandler = () => {
        history.push(`/app/${oid}/dematt/terms-and-condition`);
    };

    const submitForm = () => {
        if (pressed) {
            let reqdValue;
            if (yesVal) {
                reqdValue = true;
            } else {
                reqdValue = false;
            }
            dispatch(
                setKycFormValues({
                    ...kycFormValues,
                    stan_auto_trans: reqdValue,
                })
            );
            dispatch(
                updateKycDetailRequest({
                    stan_auto_trans: reqdValue,

                })
            );
        }
    };

    useEffect(() => {
        if (kycFormValues?.stan_auto_trans === true) {
            setValues({ yesVal: true, noVal: false, pressed: true });
        } else if (kycFormValues?.stan_auto_trans === false) {
            setValues({ yesVal: false, noVal: true, pressed: true });
        } else {
            setValues({ yesVal: false, noVal: false, pressed: false });
        }
    }, [kycFormValues]);

    useEffect(() => {
        if (success) {
            history.push(`/app/${oid}/dematt/terms-and-condition`);
        }
        return () => {
            dispatch(clearSuccess());
        };
    }, [success, dispatch, history, oid]);

    return (
        <ProgressbarLayout
            progressLeftText="89%"
            progressRightText={globalTimer}
            progressPercent={89}
            title=""
            animationIn={history.action === "PUSH" ? true : false}
            renderFooter={
                <RenderFooter
                    loading={actionLoading}
                    backHandler={backHandler}
                    submitHandler={submitForm}
                    showForwardBtn={pressed}
                    showSkip={!pressed}
                    skipHandler={skipHandler}
                />
            }
        >
            <div className='groupWrapper'>
                <SubLabelWrapper
                    label="Standing Instructions for the automatic transactions"
                    subLabel=""
                    render={
                        <Row gutter={[16, 16]}>
                            <Col xs={24} lg={6} style={{ marginTop: "1rem" }}>
                                <OutlineButton
                                    label="Yes"
                                    active={yesVal}
                                    onClick={() => {
                                        setValues({
                                            yesVal: true,
                                            noVal: false,
                                            pressed: true,
                                        });
                                    }}
                                />
                            </Col>
                            <Col xs={24} lg={6} style={{ marginTop: "1rem" }}>
                                <OutlineButton
                                    label="No"
                                    active={noVal}
                                    onClick={() =>
                                        setValues({ yesVal: false, noVal: true, pressed: true })
                                    }
                                />
                            </Col>
                        </Row>
                    }
                />
            </div>
        </ProgressbarLayout>
    )
}
