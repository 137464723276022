import React from "react";

interface Props {
  label: string;
  subLabel: string;
  render: React.ReactNode;
  subLabelWarning: boolean;
  showSubLabel?: boolean;
}

const SubLabelWrapper = (props: Props) => {
  return (
    <div
      className="d-flex w-100p flex-column"
      // style={{ width: "100%"}}
    >
      <div
        className={`r-text fw-500 text-light-black `}
        style={{ width: "100%", marginBottom: props.showSubLabel ? 0 : "1vh" }}
      >
        {props.label}
      </div>
      {props.showSubLabel && (
        <div
          className={`xs-text ${
            props.subLabelWarning ? "text-warning" : "text-light-grey"
          }`}
          style={{ width: "100%", marginBottom: "1vh" }}
        >
          {props.subLabel}
        </div>
      )}

      <>{props.render}</>
    </div>
  );
};
SubLabelWrapper.defaultProps = {
  subLabelWarning: false,
  showSubLabel: false,
};

export default SubLabelWrapper;
