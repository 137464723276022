import { Rule } from 'antd/lib/form';

import {
  EIGHTEEN_TO_HUNDRED_REGEX,
  FULL_NAME_REGEX,
} from './Regex';

type Validation_Fields =
  | 'country'
  | 'province'
  | 'district'
  | 'sub'
  | 'tole'
  | 'nearest_landmark'
  | 'ward'
  | 'username'
  | 'phone'
  | 'email'
  | 'bank_name'
  | 'account_number'
  | 'dob'
  | 'scheduledDate'
  | 'nationality'
  | 'gender'
  | 'citizenship_id'
  | 'issued_date'
  | 'issued_district'
  | 'reference_number'
  | 'dp_name'
  | 'branch'
  | 'account_type'
  | 'relation'
  | 'full_name'
  | 'father_name'
  | 'mother_name'
  | 'grandfather_name'
  | 'grandmother_name'
  | 'statement_frequency'
  | 'birth_certificate_number'
  | 'nominee_name'
  | 'nominee_age'
  | 'occupation_type'
  | 'organization'
  | 'business_type'
  | 'designation'
  | 'client_id'
  | 'boid'
  | 'sampati'
  | 'broker'
  | 'company'
  | 'income_source'
  | 'referral_id'
  | 'organizationPhone'

type My_Rules = {
  [key in Validation_Fields]: Rule[] | undefined
}

export const VALIDATIONS: My_Rules = {
  username: [
    {
      min: 5,
      message: 'Username should be atleast 5 characters long',
    },
    {
      pattern: FULL_NAME_REGEX,
      message: 'Please enter first and last name',
      required: true,
    },
  ],
  phone: [
    { required: true, message: 'Phone is required ' },
    {
      min: 10,
      max: 10,
      message: 'Phone should be 10 characters long',
    },
    // {
    //   required: true,
    //   type: 'regexp',
    //   pattern: new RegExp(
    //     /^(?:9)?[0-9]{10}$/g
    //   ),
    //   message:
    //     'Phone id takes alphanumeric characters and symbols like ( ) / -.',
    // },
  ],
  organizationPhone: [
    { required: true, message: 'Phone is required ' },
    {
      min:7,
      max:10,
      message: 'Phone should be 10 characters long',
    }
    ],
  email: [
    { required: true, message: 'Email ID is required' },
    {
      type: 'email',
      message: 'Please enter valid email',
    },
  ],
  country: [
    { required: true, message: 'Please enter a country' },
    {
      min: 3,
      message: 'Country should be atleast 3 characters long',
    },
    {
      max: 20,
      message: 'Country can be upto 20 characters long',
    },
  ],
  province: [
    { required: true, message: 'Please enter a province' },
    {
      min: 5,
      message: 'Province should be atleast 5 characters long',
    },
    {
      max: 30,
      message: 'Province can be upto 30 characters long',
    },
  ],
  district: [
    {
      required: true,
      message: 'Please select district!',
    },
  ],
  sub: [
    {
      required: true,
      message: 'Please select VDC/Municipality/Metropolitan City!',
    },
  ],
  tole: [
    { required: true, message: 'Please enter tole.' },
    {
      min: 3,
      message: 'Tole should be atleast 3 characters long',
    },
    {
      max: 20,
      message: 'Tole can be upto 20 characters long',
    },
  ],
  ward: [
    { required: true, message: 'Please enter ward number.' },
    {
      type: 'number',
      min: 1,
      max: 100,
      message: 'Ward number must be between 1 to 100',
    },
  ],
  nearest_landmark: [
    { required: true, message: 'Please enter a nearby landmark.' },
    {
      min: 5,
      message: 'Nearest Landmark should be atleast 5 characters long',
    },
    {
      max: 50,
      message: 'Nearest Landmark can be upto 50 characters long',
    },
  ],
  bank_name: [
    {
      required: true,
      message: 'Please select bank!',
    },
  ],
  account_number: [
    {
      required: true,
      message: 'Please enter account number.',
    },
  ],
  dob: [{ required: true, message: 'Date of birth is required' }],
  scheduledDate: [{ required: true, message: 'Scheduled Date & Time is required' }],
  nationality: [{ required: true, message: 'Please enter nationality!' }],
  gender: [{ required: true, message: 'Please select gender!' }],
  citizenship_id: [
    {
      required: true,
      message: 'Please enter citizenship id!',
    },
    {
      max: 30,
      message: 'Citizenship ID can be upto 30 characters long',
    },
    {
      required: true,
      type: 'regexp',
      pattern: new RegExp(
        /^[ A-Za-z0-9)(-\/][^*_~`!@#$%^&_+{}\]\[:;'\"<>,.?]*$/g
      ),
      message:
        'Citizenship id takes alphanumeric characters and symbols like ( ) / -.',
    },
  ],
  issued_date: [
    {
      required: true,
      message: 'Please enter Issued at date',
    },
  ],
  issued_district: [
    {
      required: true,
      message: 'Please select issued district!',
    },
  ],
  reference_number: [
    {
      required: true,
      message: 'Please enter alternate phone number!',
    },
    {
      min: 10,
      message: 'Alternate Phone Number should be 10 characters long',
    },
    {
      max: 10,
      message: 'Alternate Phone Number should be 10 characters long',
    },
  ],
  dp_name: [{ required: true, message: 'Please enter name.' }],
  branch: [{ required: true, message: 'Please select a branch' }],
  account_type: [{ required: true, message: 'Please select an account type.' }],
  relation: [
    {
      required: true,
      message: 'Please select a relation type',
    },
  ],
  full_name: [
    { required: true, message: 'Please enter name.' },
    {
      min: 5,
      message: 'Name should be atleast 5 characters long',
    },
    {
      max: 50,
      message: 'Name can be upto 50 characters long',
    },
    {
      pattern: FULL_NAME_REGEX,
      message: 'Enter full name',
    },
  ],
  father_name: [
    { required: true, message: 'Please enter fathers name.' },
    {
      min: 5,
      message: 'Fathers Name should be atleast 5 characters long',
    },
    {
      max: 50,
      message: 'Fathers Name can be upto 50 characters long',
    },
    {
      pattern: FULL_NAME_REGEX,
      message: 'Please enter full name',
    },
  ],
  mother_name: [
    { required: true, message: 'Please enter mothers name.' },
    {
      min: 5,
      message: 'Mothers Name should be atleast 5 characters long',
    },
    {
      max: 50,
      message: 'Mothers Name can be upto 50 characters long',
    },
    {
      pattern: FULL_NAME_REGEX,
      message: 'Please enter full name',
    },
  ],
  grandfather_name: [
    { required: true, message: 'Please enter grandfathers name.' },
    {
      min: 5,
      message: 'Grandfather Name should be atleast 5 characters long',
    },
    {
      max: 50,
      message: 'Grandfather Name can be upto 50 characters long',
    },
    {
      pattern: FULL_NAME_REGEX,
      message: 'Please enter full name',
    },
  ],
  grandmother_name: [
    {
      min: 5,
      message: "Grandmother's Name should be atleast 5 characters long",
    },
    {
      max: 50,
      message: "Grandmother's Name can be upto 50 characters long",
    },
    {
      pattern: FULL_NAME_REGEX,
      message: 'Please enter full name',
    },
  ],
  statement_frequency: [
    {
      required: true,
      message: 'Please select statement frequency',
    },
  ],
  birth_certificate_number: [
    {
      required: true,
      message: 'Please enter Birth Certificate Number.',
    },
    {
      required: true,
      type: 'regexp',
      pattern: new RegExp(
        /^[ A-Za-z0-9)(-\/][^*_~`!@#$%^&_+{}\]\[:;'\"<>,.?]*$/g
      ),
      message:
        'Birth Certificate Number takes alphanumeric characters and symbols like ( ) / -.',
    },
  ],
  nominee_name: [
    { required: true, message: 'Please select a name' },
    {
      min: 5,
      message: 'Nominee Name should be atleast 5 characters long',
    },
    {
      max: 50,
      message: 'Nominee Name can be upto 50 characters long',
    },
    {
      pattern: FULL_NAME_REGEX,
      message: 'Nominee full name is required',
    },
  ],
  nominee_age: [
    { required: true, message: 'Please enter age' },
    {
      pattern: EIGHTEEN_TO_HUNDRED_REGEX,
      message: 'Age must be between 18 and 100 only',
    },
  ],
  occupation_type: [{ required: true, message: 'Please select an occupation' }],
  organization: [{ required: true, message: 'Please enter an organization' }],
  business_type: [{ required: true, message: 'Please select a business type' }],
  designation: [{ required: true, message: 'Please select a designation' }],
  client_id: [
    {
      required: true,
      max: 8,
      message: 'Client Id can be upto 8 digits.',
    },
  ],
  boid: [
    {
      min: 16,
      max: 16,
      message: 'A 16 digit BOID is required',
      required: true,
    },
  ],
  sampati: [{ required: true, message: 'Please enter sampati.' }],
  broker: [{ required: true, message: 'Please select a broker' }],
  company: [{ required: true, message: 'Please enter company name.' }],
  income_source: [{ required: true, message: 'Please enter income source.' }],
  referral_id: [{ required: false, message: 'Referral Id is required' }],
}
