import { message } from 'antd';
import { CustomNotification } from 'Components/CustomNotification';
import {
  all,
  call,
  fork,
  put,
  select,
  StrictEffect,
  takeEvery,
} from 'redux-saga/effects';

import {
  addKycDetailFailure,
  addKycDetailSuccess,
  completedUserPdfFailure,
  completedUserPdfRequest,
  completedUserPdfSuccess,
  createNewUserFailure,
  createNewUserSuccess,
  dematPdfFailure,
  dematPdfSuccess,
  getKycDetailFailure,
  getKycDetailSuccess,
  getPaymentDetailsFailure,
  getPaymentDetailsSuccess,
  getTradingPdfDataFailure,
  getTradingPdfDataRequest,
  getTradingPdfDataSuccess,
  paymentFailure,
  paymentSuccess,
  requestKhaltiUrlFailure,
  requestKhaltiUrlSuccess,
  scheduleDateTimeFailure,
  scheduleDateTimeSuccess,
  sendEmailRequest,
  signatureBackgroundRemoverFailure,
  signatureBackgroundRemoverRequest,
  signatureBackgroundRemoverSuccess,
  updateKycDetailFailure,
  updateKycDetailFailure2,
  updateKycDetailFailureWithoutSuccessFlag,
  updateKycDetailRequest2,
  updateKycDetailRequestWithoutSuccessFlag,
  updateKycDetailSuccess,
  updateKycDetailSuccess2,
  updateKycDetailSuccessWithoutSuccessFlag,
} from './Action';
import {
  addKycDetailApi,
  addNewUserApi,
  getCompletedUserPdfApi,
  getDematPdfApi,
  getKycDetailApi,
  getPaymentDetailsApi,
  getTradingPdfDataApi,
  paymentApi,
  postScheduleDateTime,
  requestKhaltiPaymentUrl,
  sendEmailApi,
  SignatureBackgroundRemoverApi,
  updateKycDetailApi,
} from './Api';
import {
  ADD_CREATE_USER_REQUEST,
  ADD_KYC_DETAIL_REQUEST,
  COMPLETED_USER_PDF_REQUEST,
  GET_DEMATPDF_REQUEST,
  GET_KYC_DETAIL_REQUEST,
  GET_PAYMENT_DETAILS,
  GET_TRADING_PDFDATA_REQUEST,
  PAYMENT_INIT,
  REQUEST_KHALTI_URL,
  SCHEDULE_DATE_TIME,
  SEND_EMAIL_REQUEST,
  SIGNATURE_BACKGOUND_REMOVER_REQUEST,
  UPDATE_KYC_DETAIL_REQUEST,
  UPDATE_KYC_DETAIL_REQUEST_2,
  UPDATE_KYC_DETAIL_REQUEST_WITHOUT_FLAG,
} from './Constant';

function* handleAddKycDetailRequest({ payload }: any): any {
  const { formValues } = payload;
  try {
    const res = yield call(() => addKycDetailApi(formValues));
    yield put(addKycDetailSuccess(res));
  } catch (error: any) {
    yield put(addKycDetailFailure(error?.response?.data?.message));
  }
}
function* watchAddKycDetail() {
  yield takeEvery(ADD_KYC_DETAIL_REQUEST, handleAddKycDetailRequest);
}

const getCurrentUserId = (state: any) => state?.protectedReducer?.currentUserId;

const getLoggedInUserId = (state: any) => {
  console.log(state);
  return state?.protectedReducer?.kycDetails?.id;
};

const getKycFormId = (state: any) => state?.protectedReducer?.kycFormValues?.id;

function* handleUpdateKycDetailRequest({ payload }: any): any {
  try {
    const currentUid = yield select(getCurrentUserId);
    const loggedInUid = yield select(getLoggedInUserId);
    const res = yield call(() =>
      updateKycDetailApi(payload, loggedInUid, currentUid)
    );
    yield put(updateKycDetailSuccess(res));
  } catch (error: any) {
    if (error?.response?.data.message) {
      const errors: any = error.response.data.message;
      if (typeof errors === "object") {
        const mainErrorKey = Object.keys(errors);
        mainErrorKey.map((each: any) => {
          if (typeof errors[each] === "object") {
            for (const [key, value] of Object.entries(errors[each])) {
              message.error(`${value}` ?? "Something went wrong");
            }
          } else {
            message.error(`${errors[each]}` ?? "Something went wrong");
          }
        });
      }
      if (typeof errors === "string") {
        message.error(`${errors}` ?? "Something went wrong");
      }
      if (Array.isArray(errors)) {
        errors.map((each: any) => {
          message.error(`${each}` ?? "Something went wrong");
        });
      }
    } else {
      message.error(error?.message ?? "Something went wrong");
    }
    yield put(updateKycDetailFailure(error?.response?.data?.message));
  }
}
function* watchUpdateKycDetail() {
  yield takeEvery(UPDATE_KYC_DETAIL_REQUEST, handleUpdateKycDetailRequest);
}

function* handleUpdateKycDetailRequest2({
  payload,
}: ReturnType<typeof updateKycDetailRequest2>): any {
  try {
    const loggedInUid = yield select(getLoggedInUserId);

    const res = yield call(() =>
      updateKycDetailApi(payload, loggedInUid, payload.oid)
    );
    yield put(updateKycDetailSuccess2(res?.data));
  } catch (error: any) {
    if (error?.response?.data.message) {
      const mainErrorKey = Object.keys(error.response.data.message)[0];

      const errorArray: any = Object.entries(
        error.response.data.message[mainErrorKey]
      );
      errorArray.map((each: any) => {
        //could be made better here checking if each[1] is an object
        message.error({
          content: `${each[0]} : ${JSON.stringify(each[1])}`,
        });
      });
    } else {
      message.error(error?.message ?? "Something went wrong");
    }
    yield put(updateKycDetailFailure2(error?.response?.data?.message));
  }
}

function* watchUpdateKycDetail2() {
  yield takeEvery(UPDATE_KYC_DETAIL_REQUEST_2, handleUpdateKycDetailRequest2);
}

function* handleUpdateKycDetailRequestwithoutFlag({
  payload,
}: ReturnType<typeof updateKycDetailRequestWithoutSuccessFlag>): any {
  try {
    const currentUid = yield select(getCurrentUserId);
    const loggedInUid = yield select(getLoggedInUserId);
    const res = yield call(() =>
      updateKycDetailApi(payload, loggedInUid, currentUid)
    );
    yield put(updateKycDetailSuccessWithoutSuccessFlag(res));
  } catch (error: any) {
    if (error?.response?.data.message) {
      const mainErrorKey = Object.keys(error.response.data.message)[0];
      const errorArray: any = Object.entries(
        error.response.data.message[mainErrorKey]
      );
      errorArray.map((each: any) => {
        message.error({
          content: `${each[0]} : ${each[1]}`,
        });
      });
    } else {
      message.error(error?.message ?? "Something went wrong");
    }
    yield put(
      updateKycDetailFailureWithoutSuccessFlag(error?.response?.data?.message)
    );
  }
}

function* watchUpdateKycDetailwithoutFlag() {
  yield takeEvery(
    UPDATE_KYC_DETAIL_REQUEST_WITHOUT_FLAG,
    handleUpdateKycDetailRequestwithoutFlag
  );
}

function* handleGetKycDetailRequest({ payload }: any): any {
  try {
    const res = yield call(() => getKycDetailApi(payload));
    yield put(getKycDetailSuccess(res));
  } catch (error: any) {
    yield put(getKycDetailFailure(error?.response?.data?.message));
  }
}
function* watchGetKycDetail() {
  yield takeEvery(GET_KYC_DETAIL_REQUEST, handleGetKycDetailRequest);
}

function* handlePaymentRequest({ payload }: any): any {
  try {
    const res = yield call(() => paymentApi(payload));
    yield put(paymentSuccess(res));
  } catch (error: any) {
    message.error(
      error.response?.data?.message
        ? error.response?.data?.message
        : error.message ??
        "Something went wrong. Please directly contact ABC regarding the payment"
    );
    yield put(paymentFailure(error?.response?.data?.message));
  }
}

function* watchInitPayment() {
  yield takeEvery(PAYMENT_INIT, handlePaymentRequest);
}

function* handleDematPdfRequest({ payload }: any): any {
  try {
    const res = yield call(() => getDematPdfApi(payload));
    yield put(dematPdfSuccess(res));
  } catch (error: any) {
    yield put(dematPdfFailure(error?.response?.data?.message));
  }
}

function* watchDownloadDematPdf() {
  yield takeEvery(GET_DEMATPDF_REQUEST, handleDematPdfRequest);
}

function* handleCreateNewUserRequest({ payload }: any): any {
  try {
    const res = yield call(() => addNewUserApi(payload));
    yield put(getKycDetailSuccess(res));
    yield put(createNewUserSuccess());
  } catch (error: any) {
    yield put(createNewUserFailure());

    if (error?.response?.data.message) {
      const errors: any = error.response.data.message;
      if (typeof errors === "object") {
        const mainErrorKey = Object.keys(errors);
        mainErrorKey.map((each: any) => {
          if (typeof errors[each] === "object") {
            for (const [key, value] of Object.entries(errors[each])) {
              message.error(`${value}` ?? "Something went wrong");
            }
          } else {
            message.error(`${errors[each]}` ?? "Something went wrong");
          }
        });
      }
      if (typeof errors === "string") {
        message.error(`${errors}` ?? "Something went wrong");
      }
      if (Array.isArray(errors)) {
        errors.map((each: any) => {
          message.error(`${each}` ?? "Something went wrong");
        });
      }
    } else {
      message.error(error?.message ?? "Something went wrong");
    }
    yield put(createNewUserFailure());
  }
}

function* watchCreateNewUserRequest() {
  yield takeEvery(ADD_CREATE_USER_REQUEST, handleCreateNewUserRequest);
}

const getBankDetails = (state: any) => state?.protectedReducer?.bankDetails;
const getCurrentUserName = (state: any) =>
  state?.protectedReducer?.kycFormValues?.username;

function* handleGetPdfTradingDataRequest({
  payload,
  callbackFn,
}: ReturnType<typeof getTradingPdfDataRequest>): any {
  try {
    const bankArray = yield select(getBankDetails);
    const currentUserName = yield select(getCurrentUserName);

    const res = yield call(() => getTradingPdfDataApi(payload));
    if (res?.success === true) {
      yield put(
        getTradingPdfDataSuccess(res?.data, bankArray, currentUserName)
      );
      callbackFn();
    } else {
      if (res?.message === "Server Down") {
        message.error(
          "Please make sure you are uploading the pdf provided from the Meroshare website (My Details)"
        );
      }
      yield put(getTradingPdfDataFailure());
      callbackFn();
    }
  } catch (err: any) {
    if (err?.response?.data?.message === "Server Down") {
      message.error(
        "Please make sure you are uploading the pdf provided from the Meroshare website (My Details)"
      );
    }
    yield put(getTradingPdfDataFailure());
    callbackFn();
  }
}

function* watchGetPdfTradingDataRequest() {
  yield takeEvery(GET_TRADING_PDFDATA_REQUEST, handleGetPdfTradingDataRequest);
}

function* handleSignatureBackgroundRemoverRequest({
  payload,
  isMainSignature,
}: ReturnType<typeof signatureBackgroundRemoverRequest>): any {
  try {
    const res = yield call(() => SignatureBackgroundRemoverApi(payload));
    if (isMainSignature) {
      yield put(signatureBackgroundRemoverSuccess(res.data));
    }
  } catch (err) {
    yield put(signatureBackgroundRemoverFailure());
  }
}

function* watchSignatureBackgroundRemoverRequest() {
  yield takeEvery(
    SIGNATURE_BACKGOUND_REMOVER_REQUEST,
    handleSignatureBackgroundRemoverRequest
  );
}

///completed user homescreen pdf download feature
function* handleCompleteUserPdfRequest({
  payload,
}: // callbackFn,
  ReturnType<typeof completedUserPdfRequest>): any {
  try {
    const res = yield call(() => getCompletedUserPdfApi(payload));
    yield put(completedUserPdfSuccess(res.data));
  } catch (error: any) {
    yield put(completedUserPdfFailure());
    CustomNotification({
      title: "Error",
      description: "Couldn't download pdf at this time",
      type: "error",
    });
  }
}

function* watchCompletedUserPdfRequest() {
  yield takeEvery(COMPLETED_USER_PDF_REQUEST, handleCompleteUserPdfRequest);
}

function* handleSendEmailRequest({
  payload,
}: ReturnType<typeof sendEmailRequest>): any {
  try {
    const res = yield call(() => sendEmailApi(payload));
  } catch (err) { }
}

function* watchSendEmailRequest() {
  yield takeEvery(SEND_EMAIL_REQUEST, handleSendEmailRequest);
}

export default function* rootSaga(): Generator<StrictEffect, void> {
  yield all([
    fork(watchAddKycDetail),
    fork(watchUpdateKycDetail),
    fork(watchGetKycDetail),
    fork(watchInitPayment),
    fork(watchDownloadDematPdf),
    fork(watchCreateNewUserRequest),
    fork(watchGetPdfTradingDataRequest),
    fork(watchSignatureBackgroundRemoverRequest),
    fork(watchCompletedUserPdfRequest),
    fork(watchUpdateKycDetailwithoutFlag),
    fork(watchSendEmailRequest),
    fork(watchUpdateKycDetail2),
    fork(watchPostScheduleDateTime),
    fork(watchRequestKhaltiUrl),
    fork(watchGetPaymentDetails)
  ]);
}

function* handlePostScheduleDateTime(preferredDateTime: any): any {
  try {
    const currentFormId = yield select(getKycFormId);
    const res = yield call(() =>
      postScheduleDateTime(currentFormId, preferredDateTime.payload.dateTime)
    );
    yield put(scheduleDateTimeSuccess());
  } catch (error: any) {
    message.error(error?.message ?? "Something went wrong");
    yield put(scheduleDateTimeFailure());
  }
}
function* watchPostScheduleDateTime() {
  yield takeEvery(SCHEDULE_DATE_TIME, handlePostScheduleDateTime);
}

function* handleKhaltiPayment(params: any): any {
  const { payload } = params;
  try {
    const response = yield requestKhaltiPaymentUrl(payload);

    if (response.data) {
      yield put(requestKhaltiUrlSuccess(JSON.parse(response.data)));
    }
  } catch (error: any) {
    message.error(error?.message ?? "Something went wrong");
    yield put(requestKhaltiUrlFailure());
  }
}

function* watchRequestKhaltiUrl() {
  yield takeEvery(REQUEST_KHALTI_URL, handleKhaltiPayment);
}

function* getPaymentDetailsFunc(param: any): any {
  const { payload } = param

  try {
    const response = yield getPaymentDetailsApi(payload)

    if (response) {
      yield put(getPaymentDetailsSuccess(response.data))
    }

  } catch (error: any) {
    message.error(error?.message ?? "Something went wrong");
    yield put(getPaymentDetailsFailure());
  }

}

function* watchGetPaymentDetails() {
  yield takeEvery(GET_PAYMENT_DETAILS, getPaymentDetailsFunc)
}
