import {
  useEffect,
  useState,
} from 'react';

import {
  Button,
  Card,
  Col,
  message,
  Row,
} from 'antd';
import { StoragePut } from 'Components/StoragePut';
import SubLabelWrapper from 'Components/SubLabelWrapper';
import useCheckMeroshareStatusHook
  from 'CustomHooks/useCheckMeroshareStatusHook';
import { useSetKycFormValue } from 'CustomHooks/useSetKycFormValue';
import useTimerHook from 'CustomHooks/useTimerHook';
import dayjs from 'dayjs';
import { IPageProps } from 'Interfaces/Page';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import SignatureCanvas from 'react-signature-canvas';
import {
  clearSuccess,
  setKycFormValues,
  signatureBackgroundRemoverRequest,
  updateKycDetailRequest,
} from 'Redux/Protected/Action';
import { RootState } from 'Redux/RootReducer';
import { capitalizeFirstLetterEntireWord } from 'Utils/capitalizeFirstLetter';
import { s3Paths } from 'Utils/s3paths';
import RenderFooter from 'Views/Dmat/components/RenderFooter';
import ProgressbarLayout from 'Views/Dmat/Layout/ProgressbarLayout';

const DigitalSignature = ({ history, pageNumber }: IPageProps) => {
  const screenWidth = window.screen.width - 50
  const screenHeight = window.screen.height - 500
  const dispatch = useDispatch()
  const {
    kycFormValues,
    kycDetails,
    actionLoading,
    success,
    currentUserId,
    globalTimer,
  } = useSelector((state: RootState) => state?.protectedReducer)
  const [imageLoading, setImageLoading] = useState<any>('')
  const [canvasRef, setCanvasRef] = useState<any>(null)
  const [dataUrl, setDataUrl] = useState<any>(null)
  const timeVal = useTimerHook()
  const [meroshareType] = useCheckMeroshareStatusHook()

  const { oid } = useSetKycFormValue()

  const trim = async () => {
    if (canvasRef?.isEmpty()) {
      message.error('Please sign the document')
    } else {
      setImageLoading(true)
      let signatureFile: any
      setDataUrl(canvasRef.getTrimmedCanvas().toDataURL('image/png'))
      await fetch(canvasRef.getTrimmedCanvas().toDataURL('image/png'))
        .then((res) => res.blob())
        .then((blob) => {
          signatureFile = new File([blob], 'signature.png', {
            type: 'image/png',
          })
        })
      let extension = signatureFile?.type.split('/')[1]
      StoragePut({
        directoryPath: `${s3Paths({
          currentUserId,
          mainId: kycDetails?.id,
          type: 'meroshareSignature',
        })}/${dayjs().format('YYMMDD-HHmmss')}.${extension}`,
        fileObj: signatureFile,
        finalCB: () => {
          setImageLoading(false)
        },
        successCB: (data) => {
          dispatch(
            setKycFormValues({
              ...kycFormValues,
              meroshare: {
                ...kycFormValues.meroshare,
                signature: data,
              },
            })
          )
          dispatch(
            updateKycDetailRequest({
              meroshare: { signature: data, },

            })
          )
          dispatch(
            signatureBackgroundRemoverRequest(
              {
                url: data,
                transparent: true,
              },
              true
            )
          )
        },
      })
    }
  }
  const clear = () => {
    canvasRef.clear()
    setDataUrl(null)
  }

  const backHandler = () => {
    history.push(`/app/${oid}/meroshare`)

  }

  useEffect(() => {
    if (success) {
      history.push(`/app/${oid}/meroshare`)
    }
    return () => {
      dispatch(clearSuccess())
    }
  }, [success])
  return (
    <ProgressbarLayout
      progressLeftText='44%'
      progressRightText={globalTimer}
      showIconHeader={false}
      progressPercent={44}
      title=''
      animationIn={history.action === 'PUSH' ? true : false}
      renderFooter={
        <RenderFooter
          backHandler={backHandler}
          loading={imageLoading || actionLoading}
          submitHandler={trim}
        />
      }
    >
      <SubLabelWrapper
        label='Add Digital Signature'
        subLabel={`${capitalizeFirstLetterEntireWord(
          kycFormValues?.username ?? ''
        )}`}
        render={
          <Row>
            <Col
              span='24'
              className='text-right'
              style={{ marginTop: '-2.5rem' }}
            >
              <Button onClick={clear} className='clear-btn' shape='round'>
                Clear Signature
              </Button>
            </Col>
            <Col span='24'>
              <Card className='signature'>
                <SignatureCanvas
                  penColor='black'
                  ref={(ref) => {
                    setCanvasRef(ref)
                  }}
                  canvasProps={{ width: screenWidth, height: screenHeight }}
                />
              </Card>
            </Col>
          </Row>
        }
      />
    </ProgressbarLayout>
  )
}

export default DigitalSignature
