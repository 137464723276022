import React, {
  Dispatch,
  SetStateAction,
  useEffect,
} from 'react';

import {
  Col,
  Form,
  FormInstance,
  Input,
  Row,
  Select,
} from 'antd';
import OutlineButton from 'Components/OutlineButton';
import SelectLabelDisplay from 'Components/SelectLabelDisplay';
import SubLabelWrapper from 'Components/SubLabelWrapper';
import { relationshipOptions } from 'Constants/SelectDefaultOptions';
import { useSelector } from 'react-redux';
import { RootState } from 'Redux/RootReducer';
import { VALIDATIONS } from 'Utils/formValidationRules';

interface EngPoliticsProps {
    engagedInPolitics: boolean,
    setEngagedInPolitics: Dispatch<SetStateAction<boolean>>
    formValues: any
    setFormValues: Dispatch<SetStateAction<any>>
    form: FormInstance
}

export const EngPolitics = ({ engagedInPolitics, setEngagedInPolitics, formValues, setFormValues, form }: EngPoliticsProps) => {

    //var
    const { kycFormValues, } =
        useSelector((state: RootState) => state?.protectedReducer);

    useEffect(() => { //set value
        if (kycFormValues?.trading && Object.keys(kycFormValues?.trading).length) {
            const values = kycFormValues?.trading;
            if (values?.eng_poli_high_pos) {
                setEngagedInPolitics(true)
                let data = {
                    politics_full_name: values?.engaged_details?.full_name ?? "",
                    politics_relations: values?.engaged_details?.relation ?? "",
                }
                setFormValues((prevState: any) => ({
                    ...prevState,
                    ...data
                }))
                form.setFieldsValue(data)

            } else {
                setEngagedInPolitics(false)

            }
        }

    }, [kycFormValues, setFormValues, form, setEngagedInPolitics])

    const handleInputChange = (e: any) => {
        setFormValues({ ...formValues, [e.target.name]: e.target.value });
    };

    const handleSelectChange = (e: any, name: string) => {
        setFormValues({ ...formValues, [name]: e });
    };

    return (
        <Row gutter={[16, 16]} className='mt-1 groupWrapper'>
            <Col xs={24} lg={12} >
                <SubLabelWrapper
                    label="Are you engaged with politics or higher position person?"
                    subLabel=""
                    render={
                        <Row gutter={[16, 16]}>
                            <Col xs={12} lg={6} style={{ marginTop: "1rem" }}>
                                <OutlineButton
                                    label="Yes"
                                    active={engagedInPolitics}
                                    onClick={() => {
                                        setEngagedInPolitics(true)
                                    }}
                                />
                            </Col>
                            <Col xs={12} lg={6} style={{ marginTop: "1rem" }}>
                                <OutlineButton
                                    label="No"
                                    active={!engagedInPolitics}
                                    onClick={() =>
                                        // setValues({ yesVal: false, noVal: true, pressed: true })
                                        setEngagedInPolitics(false)

                                    }
                                />
                            </Col>
                        </Row>
                    }
                />
            </Col>
            <Row gutter={[16, 16]} className='w-100p'>
                {
                    engagedInPolitics && <>
                        <Col xs={24} lg={12}>
                            <SubLabelWrapper
                                label="Politics Person Name"
                                subLabel="Enter full name of the involved Political Person"
                                render={
                                    <Form.Item
                                        name="politics_full_name"
                                        rules={VALIDATIONS.full_name}
                                        initialValue={formValues.politics_full_name}
                                    >
                                        <Input
                                            name="politics_full_name"
                                            onChange={handleInputChange}
                                            bordered={false}
                                            className="underlineInput"
                                        // value={formValues.full_name}
                                        />
                                    </Form.Item>
                                }
                            />
                        </Col>
                        <Col xs={24} lg={12}>
                            <SubLabelWrapper
                                label="Your Relation"
                                subLabel="Select your relation with the Political Person"
                                render={
                                    <Form.Item
                                        name="politics_relations"
                                        rules={VALIDATIONS.relation}
                                        initialValue={formValues.politics_relations}
                                    >
                                        <Select
                                            onChange={(e) => handleSelectChange(e, "politics_relations")}
                                            showArrow={!formValues.politics_relations}
                                            optionLabelProp="customlabel"
                                            className="underlineSelect"
                                        >
                                            {relationshipOptions.map((each: any, index: number) => {
                                                return (
                                                    <Select.Option
                                                        key={index}
                                                        value={each.value}
                                                        customlabel={<div>{each.label}</div>}
                                                    >
                                                        <SelectLabelDisplay
                                                            label={each.label}
                                                            value={each.value}
                                                            currentVal={formValues.politics_relations}
                                                        />
                                                    </Select.Option>
                                                );
                                            })}
                                        </Select>
                                    </Form.Item>
                                }
                            />
                        </Col>
                    </>
                }
            </Row>
        </Row>
    )
}
