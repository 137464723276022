//match keywords from here and take the last item if matched
const agricultureDevelopmentArray = [
  "AGRICULUTRE",
  "Agricultural",
  "DEVELOPMENT",
  "Agricultural Development Bank Ltd",
];
const bankOfKathmanduArray = [
  "KATHMANDU",
  "Limited",
  "BOK",
  "BOK BOK",
  "we make your life easier",
  "BANK OF KATHMANDU LTD.",
  "BANK OF KATHMANDU LTD",
  "Bank of Kathmandu Limited",
];
const bestFinanceArray = ["BEST", "FINANCE", "Best Finance Company Ltd"];
const centralFinanceArray = ["CENTRAL", "FINANCE", "Central Finance Ltd"];
const centuryBankArray = [
  "CENTURY",
  "BANK",
  "B CENTURY BANK",
  "Century Commercial Bank Limited",
];

const citizenBankArray = [
  "CITIZENS",
  "Citizons",
  "BANK",
  "INTERNATIONAL",
  "INTERNATIONAL LTD",
  "INTERNATIONAL LTD.",
  "Citizens Bank International Limited",
];
const civilBankArry = [
  "CIVIL",
  "BANK",
  "CIVIL BANK",
  "CIVIL BANK LTD",
  "Civil Bank Ltd.",
];
const corporateDevelopmentArray = [
  "CORPORATE",
  "DEVELOPMENT",
  "Corporate Development Bank Ltd.",
];
const everestArray = [
  "EVEREST",
  "BANK",
  "EVEREST BANK",
  "EVEREST BANK LTD",
  "(A Joint-venture with mational bank India)",
  "(A Joint-venture with national bank India)",
  "(A Joint-venture with national bank India",
  "A Joint-venture with national bank India)",
  "EVEREST BANK LTD.",
  "Everest Bank Limited",
];
const excelArray = [
  "EXCEL",
  "DEVELOPMENT",
  "Excel Development Bank Ltd",
  "Excel Development Bank Ltd.",
];
const garimaArray = [
  "GARIMA",
  "BIKAS",
  "GARIMA BIKAS BANK LIMITED GARIMA BIKAS BANK",
  "Garima Bikas Bank Limited",
];
const globalImeArray = ["GLOBAL", "IME", "BANK", "Global IME Bank Limited"];
const goodwillArray = ["GOODWILL", "FINANCE", "Goodwill Finance Limited"];
const greenDevelopmentArray = [
  "GREEN",
  "DEVELOPMENT",
  "Green Development Bank Ltd.",
];
const guhesworiArray = [
  "GUHESWORI",
  "MERCHANT",
  "BANKING",
  "Guheswori Merchant",
  "Guheswori Merchant Banking and Finance Limited",
];
const gurkhasArrray = [
  "GURKHAS",
  "FINANCE",
  "Gurkhas Finance Ltd.",
  "Your Financial Friend",
  "Gurkhas Finance Limited",
];
const himalayanArray = [
  "HIMALAYAN",
  "HBL",
  "BANK",
  "HIMALAYAN BANK LTD.",
  "HIMALAYAN BANK LTD",
  "Himalayan Bank Limited",
];
const icfcArray = ["ICFC", "FINANCE", "ICFC Finance Limited"];
const janakiArray = ["JANAKI", "FINANCE", "Janaki Finance Ltd."];
const jyotiArray = ["JYOTI", "BIKASH", "Jyoti Bikash Bank Ltd"];
const kamanaArray = [
  "KAMANA",
  "SEWA",
  "BIKAS",
  "KAMANA SEWA BIKAS",
  "Sewa Bikas Bank Ltd. das Sewa Bikas all dischalls",
  "Kamana Sewa Bikas Bankltd. dal do",
  "Sewa Bikas Bank Lt ROTH da",
  "Kamana Sewa Bikas Bank Ltd.",
];
const karnaliArray = [
  "KARNALI",
  "DEVELOPMENT",
  "KARNALI DEVELOPMENT",
  "Karnali Development Bank limited.",
  "Karnali Development Bank Ltd.",
];
const kumariArray = [
  "KUMARI",
  "BANK",
  "KUMARI BANK",
  "KUMARI BANK LIMITED",
  "KUMARI BANK LTD",
  "Kumari Bank Ltd.",
];
const laxmiArray = [
  "LAXMI",
  "DAXMI",
  "BANK",
  "LAXMI BANK",
  "LAXMI BANK LTD",
  "LAXMI BANK LTD.",
  "Laxmi Bank Limited",
];
const lumbiniArray = [
  "LUMBINI",
  "BIKAS",
  "LUMBINI BIKAS",
  "LUMBINI BIKAS BANK LTD.",
  "Lumbini Bikas Bank Limited",
];
const machhapuchchhreArray = [
  "MBL",
  "MACHHAPUCHCHHRE",
  "MACHHAPUCHCHHRE BANK",
  "MACHHAPUCHCHHRE BANK LTD",
  "MACHHAPUCHCHHRE BANK LIMITED",
  "Machhapuchchhre Bank Limited",
];
const mahalaxmiArray = [
  "MAHALAXMI",
  "BIKAS",
  "MAHALAXMI BIKAS",
  "MAHALAXMI BIKAS BANK LTD",
  "MAHALAXMI BIKAS BANK LIMITED",
  "Mahalaxmi Bikas Bank Ltd.",
];
const manhushreeArray = [
  "MANJUSHREE",
  "FINANCE",
  "MANJUSHREE FINANCE",
  "MANJUSHREE FINANCE LTD",
  "MANJUSHREE FINANCE LTD.",
  "Manjushree Finance Limited",
];
const megaArray = [
  "MEGA",
  "MAmeGA",
  "MEGASBANK",
  "BANK/NEPAL LIMITED",
  "MEGA BANK. NEPAL WERAL",
  "MEGA BANK",
  "MEGA BANK NEPAL",
  "MEGA BANK NEPAL LTD",
  "MEGA BANK NEPAL LIMITED",
  "Mega Bank Nepal Ltd.",
];
const miteriArray = [
  "MITERI",
  "DEVELOPMENT",
  "MITERI DEVELOPMENT",
  "MITÉRIDEVELOPUENT",
  "MITERIDEVELOPMENT",
  "MITERI DEVELOPMENT LTD",
  "MITERI DEVELOPMENT BANK LTD.",
  "Miteri Development Bank Limited",
];
const muktinathArray = [
  "MUKTINATH",
  "BIKAS",
  "MUKTINATH BIKAS BANK",
  "MUKTINATH BIKAS BANK LTD",
  "MUKTINATH BIKAS BANK LTD.",
  "MUKTINATH BIKAS BANK LTC",
  "Muktinath Bikas Bank Limited",
];
const nicArray = [
  "NIC",
  "ASIA",
  "NIC ASIA",
  "ASIA BANK",
  "NIC ASIA BANK LTD.",
  "NIC ASIA BANK LIMITED",
  "NIC Asia Bank Limited",
];
const nmbArray = [
  "NMB",
  "BANK",
  "NMB BANK",
  "NMB BANK LTD.",
  "NMB BANK LTD",
  "NMB Bank Limited",
];
const nabilArray = [
  "NABIL",
  "BANK",
  "N BIL BANK",
  "NABL",
  "sum BAA of NABIL",
  "MABIL BANK NABIL",
  "NABIL BANK NA",
  "NABIL BANK NABII NK NABIL BANK NAB",
  "NABIL LOK BACHAT",
  "NABIL BANK",
  "NABIL BANK LTD",
  "NABIL BANK LIMITED",
  "Nabil Bank Ltd.",
];
const bangaldeshArray = [
  "NEPAL",
  "NB",
  "N B Bank",
  "BANGLADESH",
  "BONGLADESH",
  "BANGLADOSH",
  "BONGLODOSH",
  "Nopal Bangladesh Bank Ltd.",
  "NEPAL BANGLADESH",
  "BANGLADESH BANK",
  "BANGLADESH BANK LTD",
  "NEPAL BANGLADESH BANK LIMITED",
  "Nepal Bangladesh Bank Ltd",
];
const nepalArray = [
  "NEPAL",
  "NEPAL BANK",
  "NBL",
  "NEPAL BANK LTD",
  "NEPAL BANK LTD.",
  "Nepal Bank Limited",
];
const creditArray = [
  "NEPAL",
  "CREDIT",
  "COMMERCE Bank",
  "NCC Bank",
  "Nepal Commerce",
  "Credit &",
  "COMMERCE",
  "CREDIT AND COMMERCE",
  "NEPAL CREDIT AND COMMERCE",
  "NEPAL CREDIT AND COMMERCE BANK",
  "NEPAL CREDIT AND COMMERCE BANK LTD",
  "NEPAL CREDIT AND COMMERCE BANK LTD.",
  "Nepal Credit and Commerce Bank Limited",
];
const nifraArray = [
  "NEPAL",
  "INFRASTRUCTURE",
  "NEPAL INFRASTRUCTURE BANK",
  "NIFRA",
  "NEPAL INFRASTRUCTURE BANK LTD",
  "NEPAL INFRASTRUCTURE BANK LIMITED",
  "Nepal Infrastucture Bank Ltd.",
];
const niblArray = [
  "NEPAL",
  "INVESTMENT",
  "NEPAL INVESTMENT BANK",
  "NIBL",
  "NEPAL INVESTMENT BANK LTD",
  "NEPAL INFRASTRUCTURE BANK LTD.",
  "Nepal Investment Bank Limited",
];
const rastraArray = ["NEPAL", "RASTRA", "RASTRA BANK", "Nepal Rastra Bank"];
const sbiArray = [
  "NEPAL",
  "SBI",
  "SBIBANK",
  "NEPAL SBI BANK",
  "NEPAL SBI BANK LTD",
  "NEPAL SBI BANK LTD.",
  "Nepal SBI Bank Limited",
];
const pokharafinArray = [
  "POKHARA",
  "FINANCE",
  "POKHARA FINANCE",
  "POKHARA FINANCE LTD",
  "POKHARA FINANCE LIMITED",
  "Pokhara Finance Ltd.",
];
const prabhuArray = [
  "PRABBU",
  "BANK",
  "PRABHU",
  "PRABHU BANK",
  "PRABHU BANK LTD",
  "PRABHU BANK LTD.",
  "prabbu Bank",
  "Prabhu Bank Limited",
];
const primeArray = [
  "PRIME",
  "COMMERCIAL",
  "PRIME BANK",
  "PRIME COMMERCIAL",
  "PRIME COMMERCIAL BANK",
  "PRIME COMMERCIAL BANK LTD.",
  "PRIME COMMERCIAL BANK LTD",
  "Prime Commercial Bank Limited",
];
const progressiveArray = [
  "PROGRESSIVE",
  "FINANCE",
  "PROGRESSIVE FINANCE",
  "PROGRESSIVE FINANCE COMPANY",
  "PROGRESSIVE FINANCE CO",
  "PROGRESSIVE FINANCE CO.",
  "PROGRESSIVE FINANCE COMPANY LTD",
  "PROGRESSIVE FINANCE COMPANY LIMITED",
  "Progressive Finance Co. Ltd.",
];
const banijyaArray = [
  "RASTRIYA",
  "BANIJYA",
  "BANUYA",
  "RASTRIYA BANIJYA",
  "RASTRIYA BANIJYA BANK",
  "BANIJYA BANK",
  "RASTRIYA BANIJYA BANK LTD.",
  "Rastriya Banijya Bank Limited",
];
const relianceArray = [
  "RELIANCE",
  "FINANCE",
  "RELIANCE FINANCE",
  "RELIANCE FINANCE LIMITED",
  "RELIANCE FINANCE LTD",
  "Reliance Finance Ltd.",
];
const saharaArray = [
  "SAHARA",
  "BIKAS",
  "SAHARA BIKAS",
  "SAHARA BIKAS BANK",
  "SAHARA BIKAS BANK LIMITED",
  "Sahara Bikas Bank Ltd.",
];
const samriddhiArray = [
  "SAMRIDDHI",
  "FINANCE",
  "SAMRIDDHI FINANCE",
  "SAMRIDDHI FINANCE COMPANY",
  "SAMRIDDHI FINANCE COMPANY LTD",
  "SAMRIDDHI FINANCE COMPANY LIMITED",
  "Samriddhi Finance Company Ltd.",
];
const sanimaArray = [
  "SANIMA",
  "BANK",
  "SANIMA BANK",
  "SANIMA BANK LTD",
  "SANIMA BANK LTD",
  "SANIMA BANK LIMITED",
  "Sanima Bank Ltd.",
];
const saptakoshiArray = [
  "SAPTAKOSHI",
  "SAPTAKOSHI DEVELOPMENT",
  "SAPTAKOSHI DEVELOPMENT BANK",
  "SAPTAKOSHI DEVELOPMENT BANK LIMITED",
  "SAPTAKOSHI DEVELOPMENT BANK LTD",
  "Saptakoshi Development Bank Ltd.",
];
const shangrilaArray = [
  "SHANGRILA",
  "SHANGRILA DEVELOPMENT",
  "SHANGRILA DEVELOPMENT BANK",
  "SHANGRILA DEVELOPMENT BANK LTD",
  "SHANGRILA DEVELOPMENT BANK LTD.",
  "Shangrila Development Bank Limited",
];
const shineArray = [
  "SHINE",
  "RESUNGA",
  "SHINE RESUNGA",
  "SHINERESUNGA",
  "Resunga Development",
  "SHINE RESUNGA DEVELOPMENT",
  "RESUNGA DEVELOPMENT BANK",
  "RESUNGA DEVELOPMENT BANK LTD",
  "RESUNGA DEVELOPMENT BANK LTD.",
  "SHINE RESUNGA DEVELOPMENT BANK",
  "SHINE RESUNGA DEVELOPMENT BANK LTD",
  "SHINE RESUNGA DEVELOPMENT BANK LIMITED",
  "Shine Resunga Development Bank Ltd.",
];
const shreeArray = [
  "SHREE",
  "Shree Investment",
  "SHREE INVESTMENT &",
  "SHREE INVESTMENT AND FINANCE",
  "FINANCE",
  "Shree Investment & Finance Co. Ltd.",
];
const siddharthaArray = [
  "SIDDHARTHA",
  "SIDDHARTHABANK",
  "BANK",
  "SIDDHARTHA BANK",
  "SIDDHARTHA BANK LTD",
  "SIDDHARTHA BANK LTD.",
  "Siddhartha Bank Limited",
];
const sindhuArray = [
  "SINDHU",
  "BIKASH",
  "BIKASH BANK",
  "SINDHU BIKASH BANK",
  "SINDHU BIKASH BANK LTD",
  "SINDHU BIKASH BANK LIMITED",
  "Sindhu Bikash Bank Ltd.",
];
const standardArray = [
  "STANDARD",
  "CHARTERED",
  "STANDARD CHARTERED",
  "CHARTERED BANK",
  "STANDARD CHARTERED BANK",
  "STANDARD CHARTERED BANK NEPAL",
  "CHARTERED BANK NEPAL",
  "CHARTERED BANK NEPAL LIMITED",
  "CHARTERED BANK NEPAL LTD",
  "CHARTERED BANK NEPAL LTD.",
  "STANDARD CHARTERED BANK NEPAL LTD",
  "STANDARD CHARTERED BANK NEPAL LTD.",
  "Standard Chartered Bank Nepal Limited",
];
const sunriseArray = [
  "SUNRISE",
  "SUNRISE BANK",
  "BANK",
  "SUNRISE BANK LTD",
  "SUNRISE BANK LTD.",
  "Sunrise Bank Limited",
];

export const BANK_KEYWORD_ARRAY_NAMES = [
  agricultureDevelopmentArray,
  bankOfKathmanduArray,
  bestFinanceArray,
  centralFinanceArray,
  centuryBankArray,
  citizenBankArray,
  civilBankArry,
  corporateDevelopmentArray,
  everestArray,
  excelArray,
  garimaArray,
  globalImeArray,
  goodwillArray,
  greenDevelopmentArray,
  guhesworiArray,
  gurkhasArrray,
  himalayanArray,
  icfcArray,
  janakiArray,
  kamanaArray,
  jyotiArray,
  karnaliArray,
  kumariArray,
  mahalaxmiArray,
  laxmiArray,
  lumbiniArray,
  machhapuchchhreArray,
  manhushreeArray,
  megaArray,
  miteriArray,
  muktinathArray,
  nicArray,
  nmbArray,
  nabilArray,
  bangaldeshArray,
  nepalArray,
  creditArray,
  nifraArray,
  niblArray,
  rastraArray,
  sbiArray,
  pokharafinArray,
  prabhuArray,
  primeArray,
  progressiveArray,
  banijyaArray,
  relianceArray,
  saharaArray,
  samriddhiArray,
  sanimaArray,
  saptakoshiArray,
  shangrilaArray,
  shineArray,
  shreeArray,
  siddharthaArray,
  sindhuArray,
  standardArray,
  sunriseArray,
];
