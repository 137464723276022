import { useEffect, useState } from "react";

import { useSelector } from "react-redux";
import { RootState } from "Redux/RootReducer";

//hook to check whether the current trading is for individual or contiuous flow
function useCheckTradingStatusHook() {
  const [tradingFormType, setTradingFormType] = useState<string>("");
  const {
    kycFormValues: { form = false },
  } = useSelector((state: RootState) => state?.protectedReducer);

  useEffect(() => {
    if (!form) {
      setTradingFormType("");
    } else if (form?.trading && !form?.demat && !form.meroshare) {
      setTradingFormType("trading_individual");
    } else {
      setTradingFormType("trading");
    }
  }, []);

  return tradingFormType;
}

export default useCheckTradingStatusHook;
