import React, {
  Dispatch,
  SetStateAction,
  useEffect,
} from 'react';

import {
  Col,
  Form,
  FormInstance,
  Input,
  Row,
  Select,
  Typography,
} from 'antd';
import CustomSlider from 'Components/CustomSlider';
import LabelWrapper from 'Components/LabelWrapper';
import SelectLabelDisplay from 'Components/SelectLabelDisplay';
import SubLabelWrapper from 'Components/SubLabelWrapper';
import {
  accountStatementOptions,
  incomeSourceOptions,
} from 'Constants/SelectDefaultOptions';
import { FinanceDetails } from 'Interfaces/Page';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { RootState } from 'Redux/RootReducer';
import { VALIDATIONS } from 'Utils/formValidationRules';

interface FinanceDetailsProps {
    formValues: FinanceDetails
    setFormValues: Dispatch<SetStateAction<FinanceDetails>>
    form: FormInstance
}

export const FinanceDetailsComp = ({ formValues, setFormValues, form }: FinanceDetailsProps) => {
    //var
    const { kycFormValues, } =
        useSelector((state: RootState) => state?.protectedReducer)
    //hooks
    const history = useHistory()

    useEffect(() => { //set finance details
        if (kycFormValues?.finance_details) {

            let finalValues = {}

            if (history.location.pathname.includes("dematt")) {

                finalValues = {
                    income: kycFormValues?.finance_details?.income ?? null,
                    statement: kycFormValues?.finance_details?.statement ?? undefined,
                    pan_number: kycFormValues?.pan_number ?? "",

                };

            } else {
                finalValues = {
                    income: kycFormValues?.finance_details?.income ?? null,
                    statement: kycFormValues?.finance_details?.statement ?? undefined,
                    pan_number: kycFormValues?.pan_number ?? "",
                    income_source: kycFormValues?.trading?.income_source ?? ""

                };
            }
            setFormValues((prevState) => ({
                ...prevState,
                ...finalValues
            }));
            form.setFieldsValue(finalValues);
        }
    }, [kycFormValues, form, history.location.pathname, setFormValues])


    const handleSliderValue = (e: any) => {
        setFormValues({ ...formValues, income: e });
    };

    const handleSelectChange = (e: any, name: string) => {
        setFormValues({ ...formValues, [name]: e });
    };

    const handleInputChange = (e: any) => {
        setFormValues({ ...formValues, [e.target.name]: e.target.value });
    };

    return (
        <Row gutter={[16, 16]} className='groupWrapper'>
            <Col xs={24}>
                <Typography className={`r-text fw-500 text-light-black `}>Finance Details </Typography>

            </Col>
            <Col xs={24} lg={24}>
                <LabelWrapper
                    label="Slide to your approx. yearly income"
                    render={
                        <CustomSlider
                            value={formValues?.income}
                            onChange={handleSliderValue}
                            minVal={50000}
                            maxVal={10000000}
                        />
                    }
                />
            </Col>
            <Col xs={24} lg={12} id='statement'>
                <SubLabelWrapper
                    label="Statement Frequency"
                    subLabel="Please enter how frequently you would like to recieve statements"
                    render={
                        <Form.Item name="statement" rules={VALIDATIONS.statement_frequency} initialValue={formValues?.statement}>
                            <Select
                                placeholder="Accountant Statement"
                                onChange={(e) => handleSelectChange(e, "statement")}
                                showArrow={!formValues?.statement}
                                className="underlineSelect"
                            >
                                {accountStatementOptions.map((each: any, index: number) => {
                                    return (
                                        <Select.Option key={index} value={each.value}>
                                            <SelectLabelDisplay
                                                label={each.label}
                                                value={each.value}
                                                currentVal={formValues?.statement}
                                            />
                                        </Select.Option>
                                    );
                                })}
                            </Select>
                        </Form.Item>
                    }
                />
            </Col>
            <Col xs={24} lg={12} id='pan_number'>
                <SubLabelWrapper
                    label="PAN Number (Optional)"
                    subLabel="Please enter PAN Number"
                    render={
                        <Form.Item name="pan_number">
                            <Input
                                bordered={false}
                                className="underlineInput"
                                placeholder="PAN Number"
                                name="pan_number"
                                type="number"
                                onChange={handleInputChange}
                            />
                        </Form.Item>
                    }
                />
            </Col>
            {
                history.location.pathname.includes("trading") &&
                <Col xs={24} id='income_source'>
                    <SubLabelWrapper
                        label="Income Source"
                        subLabel="Add your primary source of income"
                        render={
                            <Form.Item
                                name="income_source"
                                rules={VALIDATIONS.income_source}
                                initialValue={formValues?.income_source}
                            >
                                <Select
                                    onChange={(e) => handleSelectChange(e, "income_source")}
                                    optionLabelProp="customlabel"
                                    className="underlineSelect"
                                    showSearch={true}
                                >
                                    {incomeSourceOptions.map((each: any, index: number) => {
                                        return (
                                            <Select.Option
                                                key={index}
                                                value={each.value}
                                                customlabel={<div>{each.label}</div>}
                                            >
                                                <SelectLabelDisplay
                                                    label={each.label}
                                                    value={each.value}
                                                    currentVal={formValues?.income_source}
                                                />
                                            </Select.Option>
                                        );
                                    })}
                                </Select>
                            </Form.Item>
                        }
                    />
                </Col>

            }
        </Row>
    )
}
