import Base64 from 'crypto-js/enc-base64';
import HmacSHA256 from 'crypto-js/hmac-sha256';

import config from './config';

export const esewaConfig = ({
  currentUserId,
  total,
  paymentYears,
  globalTimer,
  type,
  oid
}: any) => {
  const currentTime = new Date();
  const formattedTime =
    currentTime.toISOString().slice(2, 10).replace(/-/g, "") +
    "-" +
    currentTime.getHours() +
    currentTime.getMinutes() +
    currentTime.getSeconds();

  const params: any = {
    amount: total,
    tax_amount: "0",
    total_amount: total,
    transaction_uuid: formattedTime,
    product_code: `${process.env.REACT_APP_ESEWA_SCD}`,
    product_service_charge: "0",
    product_delivery_charge: "0",
    success_url: `${config.frontendBaseUrl}/app/${oid}/${type}/payment?esewasuccess=${currentUserId}&timecount=${globalTimer}&paymentYears=${paymentYears}`,
    failure_url: `${config.frontendBaseUrl}/app/${oid}/${type}/payment?esewafailure`,
    signed_field_names: "total_amount,transaction_uuid,product_code",
  };

  const data = HmacSHA256(
    `total_amount=${params.total_amount},transaction_uuid=${params.transaction_uuid},product_code=${params.product_code}`,
    `${process.env.REACT_APP_ESEWA_SECRET_KEY}`
  );

  const hashInBase64 = Base64.stringify(data);

  // Create a form element
  const form = document.createElement("form");
  form.setAttribute("method", "POST");
  form.setAttribute("action", `${config.esewaUrl}`);

  // Create hidden input elements for each parameter
  for (const key in params) {
    const input = document.createElement("input");
    input.setAttribute("type", "hidden");
    input.setAttribute("name", key);
    input.setAttribute("value", params[key]);
    form.appendChild(input);
  }

  // Create a hidden input element for hashInBase64
  const hashInput = document.createElement("input");
  hashInput.setAttribute("type", "hidden");
  hashInput.setAttribute("name", "signature");
  hashInput.setAttribute("value", hashInBase64);
  form.appendChild(hashInput);

  // Append the form to the document body
  document.body.appendChild(form);

  // Submit the form
  form.submit();
};
