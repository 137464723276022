import { TickerDetail } from "Interfaces/watchList";
import { Typography } from "antd";
import { styled } from "styled-components";

type Props = {} & TickerDetail;

const LOW_COLOR = "#ff452c";
const HIGH_COLOR = "#5cad00";
// const HIGH_COLOR = "#00dda2";

const StyledBlock = styled.div`
  margin-right: 30px;
  cursor: pointer;
  &:hover {
  }
`;

const WatchListBlock = ({ ...detail }: Props) => {
  const handleClick = () => {
    window.open(
      `http://dev.abcsecurities.com.np/ticker-profile/${detail?.ticker?.toLocaleLowerCase()}`,
      "_blank"
    );
  };

  const isNegative = detail?.point_change < 0;

  return (
    <StyledBlock onClick={handleClick}>
      <Typography.Text style={{ fontWeight: "bold" }}>
        {detail?.ticker}
      </Typography.Text>
      <Typography.Text
        style={{
          fontWeight: "bold",
          color: isNegative ? LOW_COLOR : HIGH_COLOR,
        }}
      >
        {" "}
        {detail?.percentage_change
          ? `(${detail?.percentage_change} %)`
          : "(N/A)"}
      </Typography.Text>
      <div>
        <Typography.Text
          style={{
            fontWeight: "bold",
            color: isNegative ? LOW_COLOR : HIGH_COLOR,
            fontSize: "small",
          }}
        >
          {/* todo : change to ltp */}
          {detail?.open || "N/A"}
        </Typography.Text>{" "}
        <Typography.Text
          style={{
            fontSize: "small",
            fontWeight: "bold",
            color: isNegative ? LOW_COLOR : HIGH_COLOR,
          }}
        >
          ({detail?.point_change || "N/A"})
        </Typography.Text>
      </div>
    </StyledBlock>
  );
};
export default WatchListBlock;
