import {
  useEffect,
  useState,
} from 'react';

import {
  Button,
  Card,
  Col,
  message,
  Row,
} from 'antd';
import { StoragePut } from 'Components/StoragePut';
import SubLabelWrapper from 'Components/SubLabelWrapper';
import useCheckMinorStatusHook from 'CustomHooks/useCheckMinorStatusHook';
import useCheckTradingStatusHook from 'CustomHooks/useCheckTradingStatusHook';
import { useSetKycFormValue } from 'CustomHooks/useSetKycFormValue';
import useTimerHook from 'CustomHooks/useTimerHook';
import dayjs from 'dayjs';
import { IPageProps } from 'Interfaces/Page';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import SignatureCanvas from 'react-signature-canvas';
import { bindActionCreators } from 'redux';
import { ProtectedActionCreators } from 'Redux/Protected';
import { RootState } from 'Redux/RootReducer';
import { capitalizeFirstLetterEntireWord } from 'Utils/capitalizeFirstLetter';
import { s3Paths } from 'Utils/s3paths';

import RenderFooter from '../components/RenderFooter';
import ProgressbarLayout from '../Layout/ProgressbarLayout';

const DematDigitalSignature = ({ history, pageNumber }: IPageProps) => {
  const dispatch = useDispatch()
  const {
    clearSuccess,
    setKycFormValues,
    signatureBackgroundRemoverRequest,
    updateKycDetailRequest,
  } = bindActionCreators(ProtectedActionCreators, dispatch)
  const screenWidth = window.screen.width - 50
  const screenHeight = window.screen.height - 500
  const [imageLoading, setImageLoading] = useState<any>('')
  const [canvasRef, setCanvasRef] = useState<any>(null)
  const timeVal = useTimerHook()
  const dematFormType = useCheckMinorStatusHook()
  const tradingFormType = useCheckTradingStatusHook()

  const { oid } = useSetKycFormValue()

  const trim = async () => {
    if (canvasRef?.isEmpty()) {
      message.error('Please sign the document')
    } else {
      let formType: string = ''
      if (history.location.pathname.includes('dematt')) {
        formType = dematFormType
      } else {
        formType = tradingFormType
      }
      setImageLoading(true)
      let signatureFile: any
      await fetch(canvasRef.getTrimmedCanvas().toDataURL('image/png'))
        .then((res) => res.blob())
        .then((blob) => {
          signatureFile = new File([blob], 'signature.png', {
            type: 'image/png',
          })
        })
      let extension = signatureFile?.type.split('/')[1]
      StoragePut({
        directoryPath: `${s3Paths({
          currentUserId,
          mainId: kycDetails?.id,
          type: 'nomineeSignature',
        })}/${dayjs().format('YYMMDD-HHmmss')}.${extension}`,
        fileObj: signatureFile,
        finalCB: () => {
          setImageLoading(false)
        },
        successCB: (data) => {
          setKycFormValues({
            ...kycFormValues,
            nominee_details: {
              ...kycFormValues?.nominee_details,
              signature: data,
            },
          })
          updateKycDetailRequest({
            nominee_details: {
              signature: data,

            },
          })
          signatureBackgroundRemoverRequest(
            {
              url: data,
              transparent: true,
            },
            false
          )
        },
      })
    }
  }

  const clear = () => {
    canvasRef.clear()
  }
  const {
    kycFormValues,
    kycDetails,
    actionLoading,
    success,
    currentUserId,
    globalTimer,
  } = useSelector((state: RootState) => state?.protectedReducer)
  const backHandler = () => {
    // if (history.location.pathname.includes('dematt')) {
    //   history.push('/app/dematt-nomineedetails/3')
    // } else {
    //   history.push('/app/trading/individual/nomineedetails/3')
    // }
    history.goBack()
  }

  useEffect(() => {
    if (success) {
      if (history.location.pathname.includes('dematt')) {
        history.push(`/app/${oid}/dematt/nominee`, { scrollPosition: "signature" });
      } else {
        history.push('/app/trading/individual/nomineedetails/4')
      }
    }
    return () => {
      clearSuccess()
    }
  }, [success])
  return (
    <ProgressbarLayout
      progressLeftText={
        history.location.pathname.includes('dematt') ? '80%' : '62%'
      }
      progressRightText={globalTimer}
      showIconHeader={false}
      progressPercent={history.location.pathname.includes('dematt') ? 80 : 62}
      iconTitle={
        history.location.pathname.includes('dematt') ? 'DEMAT' : 'TRADING'
      }
      title=''
      animationIn={history.action === 'PUSH' ? true : false}
      renderFooter={
        <RenderFooter
          backHandler={backHandler}
          loading={imageLoading || actionLoading}
          submitHandler={trim}
        />
      }
    >
      <SubLabelWrapper
        label={`Add Nominee Signature`}
        subLabel={capitalizeFirstLetterEntireWord(
          kycFormValues?.nominee_details?.name ?? ''
        )}
        render={
          <Row>
            <Col
              span='24'
              className='text-right'
              style={{ marginTop: '-2.5rem' }}
            >
              <Button onClick={clear} className='clear-btn' shape='round'>
                Clear Signature
              </Button>
            </Col>
            <Col span='24'>
              <Card className='signature'>
                <SignatureCanvas
                  penColor='black'
                  ref={(ref) => {
                    setCanvasRef(ref)
                  }}
                  canvasProps={{ width: screenWidth, height: screenHeight }}
                />
              </Card>
            </Col>
          </Row>
        }
      />
    </ProgressbarLayout>
  )
}

export default DematDigitalSignature
