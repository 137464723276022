import {
  SIGN_IN,
  SIGN_IN_SUCCESS,
  SIGN_IN_FAILED,
  SIGN_UP,
  SIGN_UP_SUCCESS,
  SIGN_UP_FAILED,
  CLEAR_DATA,
  VERIFY_OTP,
  VERIFY_OTP_SUCCESS,
  VERIFY_OTP_FAILED,
  SIGN_OUT,
  RESET_OTP_TRIES,
} from "./Constant";

export interface Action {
  type:
    | typeof SIGN_IN
    | typeof SIGN_IN_SUCCESS
    | typeof SIGN_IN_FAILED
    | typeof SIGN_UP
    | typeof SIGN_UP_SUCCESS
    | typeof SIGN_UP_FAILED
    | typeof CLEAR_DATA
    | typeof VERIFY_OTP
    | typeof VERIFY_OTP_SUCCESS
    | typeof VERIFY_OTP_FAILED
    | typeof SIGN_OUT;
  payload: any;
}

export interface OnlyType {
  type: typeof CLEAR_DATA | typeof RESET_OTP_TRIES;
}

interface Message {
  errorMessage?: string;
  successMessage?: string;
  userName?: string;
  message?: string;
}

export const signIn = ({ email, phone_number }: any): Action => ({
  type: SIGN_IN,
  payload: { email, phone_number },
});

export const signInSuccess = (payload: any): Action => ({
  type: SIGN_IN_SUCCESS,
  payload,
});

export const signInFailed = (errorMessage: Message): Action => ({
  type: SIGN_IN_FAILED,
  payload: { message: errorMessage },
});

export const signUp = ({ full_name, email, phone_number }: any): Action => ({
  type: SIGN_UP,
  payload: { full_name, email, phone_number },
});

export const signUpSuccess = (payload: any): Action => ({
  type: SIGN_UP_SUCCESS,
  payload,
});

export const signUpFailed = (errorMessage: Message): Action => ({
  type: SIGN_UP_FAILED,
  payload: { message: errorMessage },
});

export const clearData = (): OnlyType => ({
  type: CLEAR_DATA,
});

export const verifyOtp = ({ otp }: any, userData: any): Action => ({
  type: VERIFY_OTP,
  payload: { otp, userData },
});

export const verifyOtpSuccess = (payload: any): Action => ({
  type: VERIFY_OTP_SUCCESS,
  payload,
});

export const verifyOtpFailed = (errorMessage: Message): Action => ({
  type: VERIFY_OTP_FAILED,
  payload: { message: errorMessage },
});

export const signOut = (history: any): Action => ({
  type: SIGN_OUT,
  payload: { history },
});

export const resetOtp = (): OnlyType => ({
  type: RESET_OTP_TRIES,
});
