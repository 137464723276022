import React, {
  Dispatch,
  SetStateAction,
  useEffect,
} from 'react';

import {
  Col,
  Form,
  FormInstance,
  Input,
  Row,
} from 'antd';
import OutlineButton from 'Components/OutlineButton';
import SubLabelWrapper from 'Components/SubLabelWrapper';
import { useSelector } from 'react-redux';
import { RootState } from 'Redux/RootReducer';
import { VALIDATIONS } from 'Utils/formValidationRules';

interface RentProps {
    onRent: boolean,
    setOnRent: Dispatch<SetStateAction<boolean>>
    formValues: any
    setFormValues: Dispatch<SetStateAction<any>>
    form: FormInstance
}

export const Rent = ({ onRent, setOnRent, formValues, setFormValues, form }: RentProps) => {
    //var
    const { kycFormValues, } =
        useSelector((state: RootState) => state?.protectedReducer);

    useEffect(() => { //set value
        if (kycFormValues?.trading && Object.keys(kycFormValues?.trading).length) {
            const values = kycFormValues?.trading;
            if (values?.rent) {
                setOnRent(true)
                let data = {
                    owner_full_name: values?.rent_details?.full_name ?? "",
                    owner_mobile: values?.rent_details?.mobile  ? values?.rent_details?.mobile.replace("+977", "").replace("-", "") : "",
                }
                setFormValues((prevState: any) => ({
                    ...prevState,
                    ...data
                }))
                form.setFieldsValue(data)

            } else {
                setOnRent(false)
            }
        }

    }, [kycFormValues, setFormValues, form, setOnRent])

    const handleInputChange = (e: any) => {
        setFormValues({ ...formValues, [e.target.name]: e.target.value });
    };


    return (
        <Row gutter={[16, 16]} className='mt-1 groupWrapper'>
            <Col xs={24} lg={12} >
                <SubLabelWrapper
                    label="Do you live on rent?"
                    subLabel=""
                    render={
                        <Row gutter={[16, 16]}>
                            <Col xs={12} lg={6} style={{ marginTop: "1rem" }}>
                                <OutlineButton
                                    label="Yes"
                                    active={onRent}
                                    onClick={() => {
                                        setOnRent(true)
                                    }}
                                />
                            </Col>
                            <Col xs={12} lg={6} style={{ marginTop: "1rem" }}>
                                <OutlineButton
                                    label="No"
                                    active={!onRent}
                                    onClick={() =>
                                        setOnRent(false)
                                    }
                                />
                            </Col>
                        </Row>
                    }
                />
            </Col>
            <Row gutter={[16, 16]} className='w-100p'>
                {
                    onRent &&

                    <>
                        <Col xs={24} lg={12}>
                            <SubLabelWrapper
                                label="Home Owner Name"
                                subLabel="Enter full name of the Home Owner"
                                render={
                                    <Form.Item
                                        name="owner_full_name"
                                        rules={VALIDATIONS.full_name}
                                        initialValue={formValues.owner_full_name}
                                    >
                                        <Input
                                            name="owner_full_name"
                                            onChange={handleInputChange}
                                            bordered={false}
                                            className="underlineInput"
                                        // value={formValues.full_name}
                                        />
                                    </Form.Item>
                                }
                            />
                        </Col>
                        <Col xs={24} lg={12}>
                            <SubLabelWrapper
                                label="Mobile Number"
                                subLabel="Enter Home Owner's mobile number"
                                render={
                                    <Form.Item
                                        name="owner_mobile"
                                        rules={VALIDATIONS.phone}
                                        initialValue={formValues.owner_mobile}
                                    >
                                        <Input
                                            name="owner_mobile"
                                            onChange={handleInputChange}
                                            bordered={false}
                                            className="underlineInput"
                                        // value={formValues.mobile}
                                        />
                                    </Form.Item>
                                }
                            />
                        </Col>
                    </>
                }

            </Row>
        </Row>
    )
}
