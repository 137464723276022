import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
} from 'react';

import {
  Col,
  message,
} from 'antd';
import { Predictions } from 'aws-amplify';
import ImgPickerCitizenshipOcr from 'Components/ImgPickerCitizenshipOcr';
import SubLabelWrapper from 'Components/SubLabelWrapper';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import { setKycFormValues } from 'Redux/Protected/Action';
import { RootState } from 'Redux/RootReducer';
import { checkName } from 'Utils/checkName';
import { getCitizenshipOcrData } from 'Utils/getOcrData';

interface CitizenshipUploadProps {
    selectedBack: string,
    selectedFront: string
    setSelectedFront: Dispatch<SetStateAction<string>>
    setSelectedBack: Dispatch<SetStateAction<string>>
    initKycFormValues: () => void
}

export const CitizenshipUpload = ({ setSelectedBack, setSelectedFront, selectedBack, selectedFront, initKycFormValues }: CitizenshipUploadProps) => {
    //var
    const { kycFormValues, } =
        useSelector((state: RootState) => state?.protectedReducer)

    //hooks
    const dispatch = useDispatch()

    //state    
    const [ocrLoading, setOcrLoading] = useState(false);

    const [extractedKeyValueData, setExtractedKeyValueData] = useState<any>(null);



    useEffect(() => {
        //sideEffect run when we set extractedKeyValueData from OCR result
        if (extractedKeyValueData && extractedKeyValueData?.length) {
            const payload = getCitizenshipOcrData(extractedKeyValueData);
            initKycFormValues()


            if (kycFormValues?.minor) {
                dispatch(setKycFormValues({
                    ...kycFormValues,
                    guardian_details: {
                        ...kycFormValues?.guardian_details,
                        gender: payload.gender ? payload.gender : kycFormValues?.guardian_details?.gender ? kycFormValues?.guardian_details?.gender : "",
                        citizenship_id: payload.citizenship_id ? payload.citizenship_id : kycFormValues?.guardian_details?.citizenship_id ? kycFormValues?.guardian_details?.citizenship_id : "",
                        dob: payload.dob ? payload.dob : kycFormValues?.guardian_details?.dob ? kycFormValues?.guardian_details?.dob : "",
                        citizenship_images: {
                            front: selectedFront,
                            back: selectedBack
                        }
                    },

                }))

            } else {

                dispatch(setKycFormValues({
                    ...kycFormValues,
                    general_details: {
                        ...kycFormValues?.general_details,
                        gender: payload.gender ? payload.gender : kycFormValues?.general_details?.gender ? kycFormValues?.general_details?.gender : "",
                        citizenship_id: payload.citizenship_id ? payload.citizenship_id : kycFormValues?.general_details?.citizenship_id ? kycFormValues?.general_details?.citizenship_id : "",
                        dob: payload.dob ? payload.dob : kycFormValues?.general_details?.dob ? kycFormValues?.general_details?.dob : "",
                    },
                    citizenship_images: {
                        front: selectedFront,
                        back: selectedBack
                    }
                }))

            }


        }
    }, [extractedKeyValueData, dispatch]);


    useEffect(() => { //set citizenship value
        if (kycFormValues?.citizenship_images?.back && kycFormValues?.citizenship_images?.front) {
            setSelectedFront(kycFormValues?.citizenship_images.front)
            setSelectedBack(kycFormValues?.citizenship_images.back)
        }

        if (kycFormValues?.guardian_details?.citizenship_images?.back && kycFormValues?.guardian_details?.citizenship_images?.front) {
            setSelectedFront(kycFormValues?.guardian_details?.citizenship_images?.front)
            setSelectedBack(kycFormValues?.guardian_details?.citizenship_images?.back)
        }
    }, [kycFormValues, setSelectedBack, setSelectedFront])


    const getBackImageHandler = (data: any) => {
        const fileMime = data.substring(
            data.indexOf("/") + 1,
            data.indexOf(";base64")
        );
        // var file = base64toFile(data, `ocrfile.${fileMime}`);
        // setBacksideFile(file);
    };

    const onSelectFront = (file: any) => {
        setSelectedFront(file);
    };

    const onSelectBack = async (file: any, data: any) => {
        setSelectedBack(data);
        setOcrLoading(true);
        await Predictions.identify({
            text: {
                source: {
                    file,
                },
                format: "ALL",
            },
        })
            .then((response: any) => {
                checkName(response?.text?.words, kycFormValues?.username);
                setExtractedKeyValueData(response?.text?.keyValues);
            })
            .catch((err: any) => {
                message.error("Failed to read document");
            })
            .finally(() => {
                setOcrLoading(false);
            });
    };


    return (
        <Col xs={24} lg={14}>
            <div className=''>
                <SubLabelWrapper
                    label={`Citizenship Certificate `}
                    subLabel=""
                    showSubLabel={true}
                    render={
                        <div>
                            <ImgPickerCitizenshipOcr
                                onSelectFront={onSelectFront}
                                onSelectBack={onSelectBack}
                                getBackImageHandler={getBackImageHandler}
                            />
                        </div>
                    }
                />
            </div>
        </Col>
    )
}
