import './style.scss';

import {
  useEffect,
  useState,
} from 'react';

import {
  Button,
  Spin,
} from 'antd';
import { ReactComponent as GreenPlus } from 'Assets/Icons/boy.svg';
import {
  API,
  Storage,
} from 'aws-amplify';
import { CustomNotification } from 'Components/CustomNotification';
import Progressbar from 'Components/Progressbar';
import { ConnectedRouterProps } from 'connected-react-router';
import { useSetKycFormValue } from 'CustomHooks/useSetKycFormValue';
import moment from 'moment';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import {
  getDematPdfLink,
  getKycDetailRequest,
  resetPdfLink,
  setCurrentUser,
} from 'Redux/Protected/Action';
import { RootState } from 'Redux/RootReducer';
import { downloadBlob } from 'Utils/DownloadFunction';

interface TransactionDataProps {
  totalAmount: number;
  years: number;
  transactionId: string;
  type: "Khalti" | "Esewa";
}

const MeroShareFormCompletion = ({ history }: ConnectedRouterProps) => {
  const [downloadLoading, setdownloadLoading] = useState(false);

  const [transactionData, setTransactionData] =
    useState<TransactionDataProps | null>(null);

  const dispatch = useDispatch();
  const {
    kycFormValues: { form },
    kycDetails,
    kycFormValues,
    currentUserId,
    pdfLoading,
    pdfLink,
    globalTimer,
    paymentData,
  } = useSelector((state: RootState) => state?.protectedReducer);

  const { paymentLoading, paymentRate } = useSelector(
    (state: RootState) => state.staticReducer
  );

  const { oid } = useSetKycFormValue()

  const backHandler = () => {
    dispatch(setCurrentUser(""));
    dispatch(getKycDetailRequest(kycDetails?.id));
    history.push("/app/profile")
  };



  useEffect(() => {
    if (Object.keys(kycFormValues).length) {
      const target =
        form.demat && form.meroshare
          ? "demat_meroshare"
          : form.demat
            ? "demat"
            : form.meroshare
              ? "meroshare"
              : form.trading
                ? "trading"
                : "";

      dispatch(getDematPdfLink(kycDetails?.id, oid, target));

    }
    return () => {
      dispatch(resetPdfLink());
    };
  }, [kycFormValues]);





  useEffect(() => {
    if (paymentData) {
      if (paymentData.medium === "Khalti") {
        setTransactionData({
          totalAmount: paymentData?.extra?.total_amount / 100,
          years: paymentData?.extra?.years,
          transactionId: paymentData?.extra?.transaction_id,
          type: "Khalti",
        });
      }
      if (paymentData?.medium === "Esewa") {
        setTransactionData({
          totalAmount: paymentData?.extra?.total_amount,
          years: paymentData?.extra?.years,
          transactionId: paymentData?.extra?.transaction_uuid,
          type: "Esewa",
        });
      }
    }
  }, [paymentData]);


  const downloadPdfHandler = async () => {
    if (pdfLink !== "") {
      try {
        const result: any = await Storage.get(pdfLink, {
          download: true,
          customPrefix: {
            public: "",
          },
        });
        downloadBlob(
          result.Body,
          `${kycFormValues?.username ?? "meroshare"} - meroshare.pdf`
        );
      } catch (err) { }
    }
  };

  const continueTrading = () => {
    if (kycFormValues.form.demat && kycFormValues.form.trading) {
      history.push(`/app/${oid}/trading/financial`)
    } else if (kycFormValues?.form.meroshare && !kycFormValues.form.demat && kycFormValues.trading) {
      history.push(`/app/${oid}/trading/boid`)

    }
  }

  const submitHandler = () => {
    if (!form?.trading) {
      CustomNotification({
        title: "Form Completion",
        description:
          "Thank you for choosing ABC Securities Pvt. Ltd. We will get back to you shortly.",
        type: "success",
      });
      dispatch(setCurrentUser(""));
      dispatch(getKycDetailRequest(kycDetails?.id));
    } else {
      history.push(`/app/${oid}/trading`)
    }
  };

  const formattedTime: any =
    ("0" + Math.floor((globalTimer / 60) % 60)).slice(-2) +
    ":" +
    ("0" + Math.floor(globalTimer % 60)).slice(-2) +
    " min";

  const download = async () => {
    setdownloadLoading(true);
    const url = `payment-pdf/${kycFormValues?.id}`;
    const res = await API.get("docker_api", url, { body: {} });
    const pdfName = `${(kycFormValues?.username, "-", paymentData?.extra?.ref_id)
      }`;
    pdfUrlCallback(res?.data, pdfName);
  };

  const pdfUrlCallback = async (s3Url: string, pdfName: string = "") => {
    try {
      const result: any = await Storage.get(s3Url, {
        download: true,
        customPrefix: {
          public: "",
        },
      });
      downloadBlob(result.Body, `${pdfName ?? "MyFormData"}.pdf`);
    } catch (err) {
    } finally {
      resetPdfLink();
      setdownloadLoading(false);
    }
  };

  const getRate = (paymentType: String) => {
    return paymentRate.find((item: any) => item.rate === paymentType).value
  }

  return (
    <>
      <Progressbar percent={100} leftText={"100%"} rightText={formattedTime} />
      <div className="container">
        <section>
          <h2 className="section-title">Payment Receipt</h2>
          <div className="download-btn-container">
            <button
              disabled={downloadLoading}
              onClick={() => download()}
              className="download-btn center"
              style={{ width: "60%", margin: 0 }}
            >
              {downloadLoading ? <Spin size="small" /> : "Download Receipt"}
            </button>
          </div>
          <div className="card card-padding" style={{ width: "100%" }}>
            <div className="card-header center">
              <div className="icon-container center">
                {/* <img
                  src={
                    'https://firebasestorage.googleapis.com/v0/b/portfolio-ef9e7.appspot.com/o/boy.svg?alt=media&token=ff8462e1-cd3d-4b1f-9844-5e19b8516b03'
                  }
                  alt='icon'
                /> */}
                <GreenPlus />
                {/* <img src={require(GreenPlus)} alt='icon' /> */}
              </div>
              <div className="amount center">
                <p>Transfered Amount</p>
                <span>{transactionData?.totalAmount}</span>
              </div>
              <div className="status success center">
                <h4>Success</h4>
              </div>
            </div>
            <div className="table-header">invoice details</div>
            <div
              className="card-section-breaker"
              style={{ marginTop: 0 }}
            ></div>
            <div className="card-content space-between">
              <div className="card-flex center">
                <p>No. of Years</p>
                <span>{transactionData?.years}</span>
              </div>
              <div className="card-flex center">
                <p>Transaction Date</p>
                <span>{moment().format("YYYY-MM-DD")}</span>
              </div>
              <div className="card-flex center">
                <p>Transaction ID</p>
                <span>{transactionData?.transactionId || 0}</span>
              </div>
              <div className="card-flex center">
                <p>Payment Type</p>
                <span>{paymentData?.medium}</span>
              </div>
              <div className="card-flex center">
                <p>Beneficiary Name</p>
                <span>{kycFormValues?.username}</span>
              </div>
            </div>
          </div>
          <div style={{ padding: 15 }}>
            <div className="table-header">invoice details</div>
            <div
              className="card-section-breaker"
              style={{ marginTop: 0 }}
            ></div>

            <div className="card-content space-between">

              {form?.demat && transactionData?.years && (
                <div className="card-flex center">
                  <p>DEMAT Annual Maintaince Charge</p>
                  <span>Rs. {getRate("demat") * transactionData?.years}</span>
                </div>
              )}
              {form?.demat && (
                <div className="card-flex center">
                  <p>DEMAT Account Opening Form</p>
                  <span>Rs. {getRate("demat_opening")}</span>
                </div>
              )}
              {form?.meroshare && transactionData?.years && (
                <div className="card-flex center">
                  <p>Meroshare Annual Charge</p>
                  <span>Rs.{getRate("meroshare") * transactionData?.years}</span>
                </div>
              )}
              {form?.trading && (
                <div className="card-flex center">
                  <p>Trading Account Opening Form</p>
                  <span>Rs. 00</span>
                </div>
              )}
              <div className="card-section-breaker" style={{ margin: 0 }}></div>
              <div className="card-flex center">
                <p style={{ fontWeight: "bold" }}>Total Amount</p>
                <span>Rs. {transactionData?.totalAmount}</span>
              </div>
            </div>
          </div>
          <div style={{ marginTop: 15 }}>
            {kycFormValues?.form?.trading ? (
              <Button
                // loading={loading}
                htmlType="submit"
                className="submit-btn"
                block
                shape="round"
                onClick={continueTrading}
              >
                Continue to Trading
              </Button>
            ) : (
              <Button
                // loading={loading}
                htmlType="submit"
                className="submit-btn"
                block
                shape="round"
                onClick={backHandler}
              >
                Finish
              </Button>
            )}

            {kycFormValues?.form?.trading && (
              <Button
                type="link"
                className="cancel-link-btn"
                onClick={backHandler}
              >
                Fill Up Later
              </Button>
            )}
          </div>
        </section>
      </div>
    </>
  );
};

export default MeroShareFormCompletion;
