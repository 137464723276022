import NepaliDate from "nepali-date-converter";

export default function converToNepalDate(date: any): string {
  const NepaliDateObject = new NepaliDate(new Date(date)).getBS();
  const monthDate = Number(NepaliDateObject.month) + 1;
  const issuedNepaliDate = `${NepaliDateObject.year}-${("0" + monthDate).slice(
    -2
  )}-${("0" + NepaliDateObject.date).slice(-2)}`;
  return issuedNepaliDate;
}

export const formNepaliDate = (date: any): string => {
  const nepaliDate = `${date.bsYear}-${("0" + date.bsMonth).slice(-2)}-${(
    "0" + date.bsDate
  ).slice(-2)}`;
  return nepaliDate;
};

export const formEnglishDate = (date: any): string => {
  const monthDate = Number(date.month) + 1;
  const englishDate = `${date.year}-${("0" + monthDate).slice(-2)}-${(
    "0" + date?.date
  ).slice(-2)}`;
  return englishDate;
};
