import {
  useCallback,
  useEffect,
} from 'react';

import { RcFile } from 'antd/lib/upload';
import { StoragePut } from 'Components/StoragePut';
import dayjs from 'dayjs';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  getPaymentDetails,
  setCurrentUser,
  setNewKycFormValues,
} from 'Redux/Protected/Action';
import { RootState } from 'Redux/RootReducer';
import { s3Paths } from 'Utils/s3paths';

export const useSetKycFormValue = () => {
    //params
    const { oid } = useParams<any>()

    //var
    const dispatch = useDispatch();
    const { kycDetails, kycFormValues, currentUserId } = useSelector(
        (state: RootState) => state.protectedReducer
    );
    //state
    // const [formInstance, setFormInstance] = useState('')

    const store = useCallback((extension: string, fileObj: RcFile, type: any, successCB: (data: string) => void) => {
        StoragePut({
            directoryPath: `${s3Paths({
                currentUserId,
                mainId: kycDetails?.id,
                type: type,
            })}/${dayjs().format("YYMMDD-HHmmss")}.${extension}`,
            fileObj: fileObj,
            successCB: (data) => {
                successCB(data) //set response value
            },
        });

    }, [currentUserId, kycDetails?.id])

    useEffect(() => {
        if (oid && kycDetails?.others?.length > 0 && Object.keys(kycFormValues).length <= 0) {
            const currentOtherUser = kycDetails.others.find((item: any) => item.id === oid)
            dispatch(setCurrentUser(oid))
            dispatch(setNewKycFormValues({
                ...currentOtherUser,

            }))
        }
    }, [oid, kycDetails, dispatch, kycFormValues])

    const fetchPaymentsDetails = useCallback(() => {
        dispatch(getPaymentDetails(kycFormValues.payments[0]))

    }, [dispatch, kycFormValues?.payments])

    useEffect(() => {
        if (kycFormValues?.payments?.length > 0) {
            fetchPaymentsDetails()
        }

    }, [kycFormValues?.payments,  fetchPaymentsDetails])


    return {
        oid,
        store
    }
}
