import { StaticAction } from "./Action";
import {
  GET_ADDRESS_REQUEST,
  GET_ADDRESS_SUCCESS,
  GET_ADDRESS_FAILURE,
  GET_BANK_FAILURE,
  GET_BANK_REQUEST,
  GET_BANK_SUCCESS,
  GET_ORGANIZATION_FAILURE,
  GET_ORGANIZATION_REQUEST,
  GET_ORGANIZATION_SUCCESS,
  GET_BROKER_FAILURE,
  GET_BROKER_REQUEST,
  GET_BROKER_SUCCESS,
  GET_PAYMENTRATE_FAILURE,
  GET_PAYMENTRATE_REQUEST,
  GET_PAYMENTRATE_SUCCESS,
} from "./Constant";

interface StaticInitialState {
  addressDetails: any;
  bankDetails: any;
  orgDetails: any;
  brokerDetails: any;
  paymentLoading: boolean;
  paymentRate: any;
  organizationLoading: boolean;
  addressLoading: boolean;
  bankLoading: boolean;
  brokerLoading: boolean;
}

const ProtectedInitialState: StaticInitialState = {
  addressLoading: false,
  addressDetails: [],
  bankDetails: [],
  orgDetails: [],
  brokerDetails: [],
  paymentLoading: false,
  paymentRate: [],
  organizationLoading: false,
  bankLoading: false,
  brokerLoading: false,
};

export default function protectedReducer(
  state = ProtectedInitialState,
  action: StaticAction
): StaticInitialState {
  switch (action.type) {
    case GET_ADDRESS_REQUEST: {
      return {
        ...state,
        addressLoading: true,
      };
    }
    case GET_ADDRESS_SUCCESS: {
      return {
        ...state,
        addressLoading: false,
        addressDetails: action.payload.data,
      };
    }
    case GET_ADDRESS_FAILURE: {
      return {
        ...state,
        addressLoading: false,
      };
    }

    case GET_BANK_REQUEST: {
      return {
        ...state,
        bankLoading: true,
      };
    }
    case GET_BANK_SUCCESS: {
      return {
        ...state,
        bankLoading: false,
        bankDetails: action.payload.data,
      };
    }
    case GET_BANK_FAILURE: {
      return {
        ...state,
        bankLoading: false,
      };
    }
    case GET_ORGANIZATION_REQUEST: {
      return {
        ...state,
        organizationLoading: true,
      };
    }
    case GET_ORGANIZATION_SUCCESS: {
      return {
        ...state,
        orgDetails: action.payload.data,
        organizationLoading: false,
      };
    }
    case GET_ORGANIZATION_FAILURE: {
      return {
        ...state,
        organizationLoading: false,
      };
    }

    case GET_BROKER_REQUEST: {
      return {
        ...state,
        brokerDetails: [],
        brokerLoading: true,
      };
    }

    case GET_BROKER_SUCCESS: {
      return {
        ...state,
        brokerDetails: action.payload,
        brokerLoading: false,
      };
    }

    case GET_BROKER_FAILURE: {
      return {
        ...state,
        brokerDetails: [],
        brokerLoading: false,
      };
    }
    case GET_PAYMENTRATE_REQUEST: {
      return {
        ...state,
        paymentLoading: true,
      };
    }

    case GET_PAYMENTRATE_SUCCESS: {
      return {
        ...state,
        paymentLoading: false,
        paymentRate: action.payload.data,
      };
    }
    case GET_PAYMENTRATE_FAILURE: {
      return {
        ...state,
        paymentLoading: false,
      };
    }
    default: {
      return state;
    }
  }
}
