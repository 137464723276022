type Props = {
  addressArray: any[];
};
/**
 *
 * @param addressArray
 * @returns list of district
 */
export function createDistrictList({ addressArray }: Props): string[] {
  let districtList = addressArray.map((each: any) => each?.district);
  let requiredDistrictList = districtList.filter(
    (x: any, i: any, a: any) => a.indexOf(x) === i
  );
  return requiredDistrictList ?? [""];
}
