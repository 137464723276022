export function capitalizeFirstLetter(inputString: string): string {
  return (
    inputString.charAt(0).toUpperCase() + inputString.slice(1).toLowerCase()
  );
}

export function capitalizeFirstLetterEntireWord(
  inputString: string = ""
): string {
  if (inputString) {
    var separateWord = inputString?.toLowerCase()?.split(" ") ?? "";
    for (var i = 0; i < separateWord?.length; i++) {
      separateWord[i] =
        separateWord[i]?.charAt(0)?.toUpperCase() +
        separateWord[i]?.substring(1);
    }
    return separateWord.join(" ");
  } else {
    return "";
  }
}
