import { API } from 'aws-amplify';

export const addKycDetailApi = (formValues: any) => {
  // console.log(formValues, "fromapi")
  // const url=``;
  // return API.post("kycApi", url, {})
};

export const addNewUserApi = (payload: any) => {
  const url = `user/${payload.userId}/other/add`;
  return API.patch("kycApi", url, {
    body: payload.formValues,
  });
};

export const updateKycDetailApi = (
  payload: any,
  loggedInUid: string,
  currentUid: string
) => {
  var accountType = "";
  if (payload?.formValues?.accountType) {
    delete payload?.formValues["accountType"];
  }
  const location = window?.location?.href;
  const slicedLocation = location?.split("/");
  if (slicedLocation.includes("dematt")) {
    accountType = "demat";
  }
  if (slicedLocation.includes("trading")) {
    accountType = "trading";
  }
  if (slicedLocation.includes("meroshare")) {
    accountType = "meroshare";
  }

  const url = `user/${loggedInUid}/other/${currentUid}`;
  return API.patch("kycApi", url, {
    body: payload.formValues,
    // headers: { accountType },
  });
};

export const getKycDetailApi = (id: string) => {
  const url = `user/${id}`;
  return API.get("kycApi", url, {});
};

export const getAddressApi = () => {
  const url = `search/address`;
  return API.get("kycApi", url, {});
};

export const getBankApi = () => {
  const url = `search/bank`;
  return API.get("kycApi", url, {});
};

export const getOrganizationApi = () => {
  const url = `search/organization`;
  return API.get("kycApi", url, {});
};

export const getPaymentRateApi = () => {
  const url = `search/rate`;
  return API.get("kycApi", url, {});
};

export const paymentApi = (payload: any) => {
  const url = `user/${payload.userId}/other/${payload.oid}/payment/validation`;
  return API.patch("paymentApi", url, { body: payload.formValues });
};

export const getDematPdfApi = (payload: any) => {
  const url = `generate-pdf/${payload.oid}/${payload.target}`;
  return API.get("docker_api", url, {});
};

export const getTradingPdfDataApi = (payload: any) => {
  const url = `pdf/reader`;
  return API.post("docker_api", url, {
    body: {
      url: payload,
    },
  });
};
export const SignatureBackgroundRemoverApi = (payload: any) => {
  const url = `image/signature`;
  return API.post("docker_api", url, {
    body: payload,
  });
};

export const getCompletedUserPdfApi = (payload: any) => {
  const url = `generate-pdf/${payload.oid}/${payload.target}`;
  return API.get("docker_api", url, {});
};

export const sendEmailApi = (payload: any) => {
  const url = `email/${payload.oid}`;
  return API.patch("kycApi", url, {
    body: {
      account: payload.message,
    },
  });
};

export const postScheduleDateTime = (
  currentFormId: string,
  preferredDateTime: string
) => {
  const url = `schedule-video-kyc/${currentFormId}`;
  return API.post("kycApi", url, {
    body: {
      preferred_datetime: preferredDateTime,
    },
  });
};

export const requestKhaltiPaymentUrl = (payload: any) => {
  const url = `user/${payload.id}/other/${payload.oid}/payment/initiate`;
  return API.post("paymentApi", url, { body: payload.formValues });
};

export const getPaymentDetailsApi = async(id: string) => {
  const url = `payment/${id}`
  return await API.get("kycApi", url, {})

  
}
