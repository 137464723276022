import { notification } from "antd";

interface Custom_Notification {
  type: "success" | "warn" | "error";
  title: string;
  description: string;
}
/**
 * 
 * @returns A custom Popup notification according to 3 types
 */
export function CustomNotification({
  type,
  description,
  title,
}: Custom_Notification) {
  let defaultClassName = "br-8 custom_notification";
  let finalClassName: string = "";
  if (type === "warn")
    finalClassName = `${defaultClassName} warning-notification`;
  if (type === "error")
    finalClassName = `${defaultClassName} custom_notification_error`;
  if (type === "success") finalClassName = `${defaultClassName}`;
  const args = {
    message: title,
    description,
    duration: 7,
    className: finalClassName,
  };
  notification[type](args);
}

CustomNotification.defaultProps = {
  type: "warn",
  title: "",
  description: "",
};
