import { Image } from "antd";
import StorageGetImage from "Components/StorageGetImage";
import { useEffect, useState } from "react";
import { capitalizeFirstLetterEntireWord } from "Utils/capitalizeFirstLetter";

type Props = {
  name: string;
  imageUrl: string;
};
const TopSection = ({ name = "", imageUrl = "" }: Props) => {
  const [profileImageData, setProfileImageData] = useState<any>("");

  useEffect(() => {
    //@ts-ignore:next-line
    if (imageUrl !== "" && imageUrl !== 0) {
      (async () => {
        StorageGetImage({
          imageKey: imageUrl,
          successCB: (base64data) => {
            setProfileImageData(base64data);
          },
          failureCB: () => {
            setProfileImageData("");
          },
        });
      })();
    }
  }, [imageUrl]);

  return (
    <div className="topProfileContainer">
      <div className="d-flex flex-between align-items-center">
        <div>
          <div className="text-green l-text fw-600">Hello,</div>
          <div className="text-green l-text fw-600">
            {capitalizeFirstLetterEntireWord(name ?? "")}
          </div>
        </div>
        <div>
          {/* <ProfileIcon /> */}
          {profileImageData !== "" && (
            <Image
              preview={false}
              height={70}
              width={70}
              className="br-8"
              style={{
                objectFit: "cover",
              }}
              src={profileImageData}
            />
          )}
        </div>
      </div>
    </div>
  );
};
export default TopSection;
