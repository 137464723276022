import React, {
  Dispatch,
  SetStateAction,
  useEffect,
} from 'react';

import {
  Col,
  Form,
  FormInstance,
  Input,
  Row,
  Typography,
} from 'antd';
import LabelWrapper from 'Components/LabelWrapper';
import { useSelector } from 'react-redux';
import { RootState } from 'Redux/RootReducer';
import { VALIDATIONS } from 'Utils/formValidationRules';

interface GeneralInfoProps {
    formValues: any
    setFormValues: Dispatch<SetStateAction<any>>
    form: FormInstance
}

export const GeneralInfo = ({ formValues, setFormValues, form }: GeneralInfoProps) => {
    //var
    const { kycFormValues } =
        useSelector((state: RootState) => state?.protectedReducer)


    useEffect(() => { // set form values
        if (kycFormValues) {
            const data = {
                username: kycFormValues?.username,
                email: kycFormValues?.email,
                phone: kycFormValues?.phone?.replace("+977", "").replace("-", "")
            }

            setFormValues((prevState: any) => ({
                ...prevState,
                ...data
            }))
            form.setFieldsValue(data)
        }
        if (kycFormValues?.meroshare &&
            Object.keys(kycFormValues?.meroshare).length) {
            const data = {
                dpid: kycFormValues?.meroshare?.dpid ?? 13200,
                clientId: `${kycFormValues?.meroshare?.clientId}` ?? null,
                email: kycFormValues?.email ?? "",
                phone: kycFormValues?.meroshare?.phone
                    ? kycFormValues?.meroshare.phone.replace("+977", "").replace("-", "")
                    : kycFormValues?.phone
                        ? kycFormValues.phone.replace("+977", "").replace("-", "")
                        : "" ?? "",
            }

            setFormValues((prevState: any) => ({
                ...prevState,
                ...data
            }))

            form.setFieldsValue(data)

        }
    }, [kycFormValues, form, setFormValues])



    const handleInputChange = (e: any) => {
        if (['dpid', 'clientId'].includes(e.target.name)) {
            setFormValues({ ...formValues, [e.target.name]: Number(e.target.value) })
        } else {
            setFormValues({ ...formValues, [e.target.name]: e.target.value })
        }
    }

    return (
        <Row gutter={[16, 16]} className='groupWrapper mt-1'>
            <Col xs={24}>
                <Typography className={`r-text fw-500 text-light-black `}>General Information </Typography>

            </Col>
            <Col xs={24}>
                <LabelWrapper
                    label='Full Name'
                    render={
                        <Form.Item
                            name='username'
                        >
                            <Input
                                bordered={false}
                                className="underlineInput"
                                name='username'
                                onChange={handleInputChange}
                                disabled={true}
                            />
                        </Form.Item>
                    }
                />
            </Col>
            <Col xs={24} lg={12}>
                <LabelWrapper
                    label='DPID'
                    render={
                        <Form.Item name='dpid' initialValue={formValues.dpid}>
                            <Input
                                bordered={false}
                                className="underlineInput"
                                name='dpid'
                                onChange={handleInputChange}
                                type='number'
                                value={formValues.dpid}
                                disabled
                            />
                        </Form.Item>
                    }
                />
            </Col>
            <Col xs={24} lg={12}>
                {!kycFormValues?.form?.demat && (
                    <LabelWrapper
                        label='Client Id'
                        render={
                            <Form.Item name='clientId' rules={VALIDATIONS.client_id}>
                                <Input
                                    bordered={false}
                                    className="underlineInput"
                                    name='clientId'
                                    onChange={handleInputChange}
                                    type='number'
                                />
                            </Form.Item>
                        }
                    />
                )}
                {kycFormValues?.form?.demat &&
                    kycFormValues?.form?.demat_status?.toLowerCase() === 'completed' && (
                        <LabelWrapper
                            label='Client Id'
                            render={
                                <Form.Item name='clientId' rules={VALIDATIONS.client_id}>
                                    <Input
                                        bordered={false}
                                        className="underlineInput"
                                        name='clientId'
                                        onChange={handleInputChange}
                                        type='number'
                                    />
                                </Form.Item>
                            }
                        />
                    )}
            </Col>
            <Col xs={24} lg={12}>
                <LabelWrapper
                    label="Email ID"
                    render={
                        <Form.Item
                            name="email"
                            rules={VALIDATIONS.email}
                            initialValue={formValues.email}
                        >
                            <Input
                                bordered={false}
                                className="underlineInput"
                                name="email"
                                onChange={handleInputChange}

                            />
                        </Form.Item>
                    }
                />
            </Col>
            <Col xs={24} lg={12}>
                <LabelWrapper
                    label="Mobile Number"
                    render={
                        <Form.Item
                            name="phone"
                            rules={VALIDATIONS.phone}
                            initialValue={formValues.phone}
                        >
                            <Input
                                bordered={false}
                                className="underlineInput"
                                name="phone"
                                onChange={handleInputChange}

                            />
                        </Form.Item>
                    }
                />
            </Col>
        </Row>
    )
}
