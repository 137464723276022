import {
  SIGN_IN,
  SIGN_IN_SUCCESS,
  SIGN_IN_FAILED,
  SIGN_OUT,
  SIGN_UP,
  SIGN_UP_FAILED,
  SIGN_UP_SUCCESS,
  VERIFY_OTP,
  VERIFY_OTP_FAILED,
  VERIFY_OTP_SUCCESS,
  CLEAR_DATA,
} from "./Constant";
import { Action } from "./Action";

interface InitialState {
  loading: boolean;
  actionLoading: boolean;
  error: string;
  message: string;
  loggedIn: boolean;
  user: string;
  userData?: any;
  userObj?: {
    [key: string]: any;
  };
  signUpSuccessful?: boolean;
  success?: boolean;
  generalDetails?: any;
  otpTries: number;
}

const AuthInitialState: InitialState = {
  error: "",
  loading: false,
  actionLoading: false,
  loggedIn: false,
  message: "",
  user: "",
  signUpSuccessful: false,
  generalDetails: {},
  otpTries: 3,
};

export default function authReducer(
  state = AuthInitialState,
  action: Action
): InitialState {
  switch (action.type) {
    case SIGN_IN: {
      return {
        ...state,
        error: "",
        userData: null,
        success: false,
        signUpSuccessful: false,
        loading: true,
      };
    }
    case SIGN_IN_SUCCESS: {
      return {
        ...state,
        error: "",
        success: true,
        userData: action.payload,
        loggedIn: true,
        loading: false,
        message: "Successfully logged in",
        otpTries: 3,
      };
    }
    case SIGN_IN_FAILED: {
      return {
        ...state,
        loading: false,
        userData: null,
        success: false,
        error: action.payload.message,
        loggedIn: false,
        message: "",
      };
    }
    case SIGN_UP: {
      return {
        ...state,
        error: "",
        signUpSuccessful: false,
        generalDetails: action.payload,
        loading: true,
      };
    }
    case SIGN_UP_SUCCESS: {
      return {
        ...state,
        error: "",
        loading: false,
        signUpSuccessful: true,
        message: "Successfully signed up",
      };
    }
    case SIGN_UP_FAILED: {
      return {
        ...state,
        loading: false,
        signUpSuccessful: false,
        error: action.payload.message,
        message: "",
      };
    }
    case CLEAR_DATA: {
      return { ...state, success: false, error: "", loading: false };
    }
    case VERIFY_OTP: {
      return { ...state, error: "", user: "", actionLoading: true };
    }
    case VERIFY_OTP_SUCCESS: {
      return {
        ...state,
        error: "",
        success: true,
        user: action.payload.attributes["custom:id"],
        actionLoading: false,
        message: "Successfully verified otp",
      };
    }
    case VERIFY_OTP_FAILED: {
      const returnObj = {
        ...state,
        actionLoading: false,
        user: "",
        error: action.payload.message,
        message: "",
        otpTries: state.otpTries - 1,
      };

      return returnObj;
    }
    case SIGN_OUT: {
      return { ...state, error: "", user: "", message: "", loggedIn: false };
    }

    default: {
      return state;
    }
  }
}
