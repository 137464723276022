import {
  useEffect,
  useRef,
  useState,
} from 'react';

import {
  Button,
  Col,
  message,
  Row,
} from 'antd';
import { ConnectedRouterProps } from 'connected-react-router';
import ReactCodeInput from 'react-code-input';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import {
  clearData,
  resetOtp,
  signIn,
  verifyOtp,
} from 'Redux/Auth/Action';
import { RootState } from 'Redux/RootReducer';

export default function OtpVerification({ history }: ConnectedRouterProps) {
  const dispatch = useDispatch();
  const { userData, generalDetails, actionLoading, otpTries } = useSelector(
    (state: RootState) => state.authReducer
  );

  const [seconds, setSeconds] = useState(0);

  const [pinCode, setPinCode] = useState("");

  const inputRef = useRef<any>();

  useEffect(() => {
    if (!Object.keys(generalDetails).length) {
      history.replace("/auth/general-details");
    }
  }, []);

  useEffect(() => {
    let myInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds((currentSecond) => currentSecond - 1);
      }

      if (seconds === 0) {
        clearInterval(myInterval);
      }
    }, 1000);
    return () => {
      clearInterval(myInterval);
    };
  });

  

  const handlePinChange = (pinCode: string) => {
    setPinCode(pinCode);
  };

  const sumbitHandler = async () => {
    if (pinCode.length < 4) {
      message.error("Please enter otp code");
    } else {
      const submitValue = {
        otp: pinCode,
      };
      dispatch(verifyOtp(submitValue, userData));
    }
  };

  const handleResendCode = () => {
    setPinCode("");
    inputRef.current.state.input[0] = "";
    inputRef.current.state.input[1] = "";
    inputRef.current.state.input[2] = "";
    inputRef.current.state.input[3] = "";
    setSeconds(30);
    dispatch(signIn(generalDetails));
  };

  useEffect(() => {
    dispatch(clearData());
    return () => {
      dispatch(resetOtp());
    };
  }, []);

  let timer = "";
  let tempSeconds;
  if (seconds < 10) {
    tempSeconds = `0${seconds}`;
  } else {
    tempSeconds = seconds;
  }
  timer = `in 00:${tempSeconds}`;
  if (seconds === 0) {
    timer = "";
  }

  return (
    <Row>
      <Col span="24" style={{ minHeight: "20vh" }}>
        <div
          className="d-flex w-100p mt-2"
          style={{ justifyContent: "flex-end" }}
        >
          <div
            className="fw-600 text-light-black d-flex align-items-center"
            style={{ gap: 5 }}
          >
            No.of Tries Remaining :{" "}
            <span className="fw-800 text-green m-text">{otpTries}</span>
          </div>
        </div>
      </Col>
      <Col span="24" className="content-container">
        <Row>
          <Col span="24" className="mb-2">
            <div className="greeting-text">Hello,</div>
            <div className="greeting-name">{generalDetails.full_name}</div>
          </Col>
          <Col span="24" className="mb-3">
            <div className="title">OTP Verification</div>
            <div className="subtitle">
              Enter the verification code, we sent on
            </div>
            <div className="subtitle-email">{generalDetails.email}</div>
          </Col>

          <Col span="24">
            {/* @ts-ignore:next-line */}

            <ReactCodeInput
              ref={inputRef}
              type="number"
              fields={4}
              onChange={handlePinChange}
              value={pinCode}
              inputStyle={{
                width: 50,
                marginInline: 10,
                boxShadow: "none",
                border: "none",
                borderBlockEnd: "1.5px solid #2e4342",
                fontSize: 25,
                fontWeight: "bold",
                textAlign: "center",
              }}
            />
          </Col>
          <Col span="24" className="text-center mt-5">
            {otpTries > 0 && (
              <Button
                loading={actionLoading}
                htmlType="submit"
                className="submit-btn"
                block
                shape="round"
                onClick={sumbitHandler}
              >
                Verify
              </Button>
            )}
            <Button
              onClick={handleResendCode}
              type="link"
              disabled={seconds === 0 ? false : true}
              className={otpTries === 0 ? "submit-btn" : "cancel-link-btn"}
              shape="round"
            >
              {otpTries === 0 ? "Request new code" : "Resend Code"}{" "}
              {timer !== "" && `${timer}`}
            </Button>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
