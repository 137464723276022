import {
  put,
  takeEvery,
  StrictEffect,
  all,
  fork,
  call,
} from "redux-saga/effects";
import {
  getAddressSuccess,
  getAddressFailure,
  getBankFailure,
  getBankSuccess,
  getOrganizationFailure,
  getOrganizationSuccess,
  getBrokerFailure,
  getBrokerSuccess,
  getPaymentRateFailure,
  getPaymentRateSuccess,
} from "./Action";
import {
  getAddressApi,
  getBankApi,
  getBrokerApi,
  getOrganizationApi,
  getPaymentRateApi,
} from "./Api";
import {
  GET_ADDRESS_REQUEST,
  GET_BANK_REQUEST,
  GET_BROKER_REQUEST,
  GET_ORGANIZATION_REQUEST,
  GET_PAYMENTRATE_REQUEST,
} from "./Constant";

function* handleGetAddressRequest(): any {
  try {
    const res = yield call(() => getAddressApi());
    yield put(getAddressSuccess(res));
  } catch (error: any) {
    yield put(getAddressFailure());
  }
}
function* watchGetAddress() {
  yield takeEvery(GET_ADDRESS_REQUEST, handleGetAddressRequest);
}

function* handleGetBankRequest(): any {
  try {
    const res = yield call(() => getBankApi());
    yield put(getBankSuccess(res));
  } catch (error: any) {
    yield put(getBankFailure());
  }
}
function* watchGetBank() {
  yield takeEvery(GET_BANK_REQUEST, handleGetBankRequest);
}

function* handleGetOrganizationRequest(): any {
  try {
    const res = yield call(() => getOrganizationApi());
    yield put(getOrganizationSuccess(res));
  } catch (error: any) {
    yield put(getOrganizationFailure());
  }
}
function* watchGetOrganization() {
  yield takeEvery(GET_ORGANIZATION_REQUEST, handleGetOrganizationRequest);
}

function* handleGetBrokerRequest(): any {
  try {
    const res = yield call(() => getBrokerApi());
    yield put(getBrokerSuccess(res?.data));
  } catch (err) {
    yield put(getBrokerFailure());
  }
}

function* watchGetBrokerRequest() {
  yield takeEvery(GET_BROKER_REQUEST, handleGetBrokerRequest);
}

function* handleGetPaymentRate(): any {
  try {
    const res = yield call(() => getPaymentRateApi());
    yield put(getPaymentRateSuccess(res));
  } catch (error: any) {
    yield put(getPaymentRateFailure());
  }
}

function* watchGetPaymentRate() {
  yield takeEvery(GET_PAYMENTRATE_REQUEST, handleGetPaymentRate);
}

export default function* StaticSaga(): Generator<StrictEffect, void> {
  yield all([fork(watchGetAddress)]);
  yield all([fork(watchGetBank)]);
  yield all([fork(watchGetOrganization)]);
  yield all([fork(watchGetBrokerRequest)]);
  yield all([fork(watchGetPaymentRate)]);
}
