import {
  useEffect,
  useState,
} from 'react';

import {
  Image,
  message,
  Spin,
  Upload,
} from 'antd';
import { ReactComponent as ReUploadIcon } from 'Assets/Icons/reupload.svg';
import dayjs from 'dayjs';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import { bindActionCreators } from 'redux';
import { ProtectedActionCreators } from 'Redux/Protected';
import { RootState } from 'Redux/RootReducer';
import { checkValidFile } from 'Utils/checkValidFile';
import { toBase64 } from 'Utils/convertTobase64';
import { dummyRequest } from 'Utils/DummyCall';
import { resizeImage } from 'Utils/ImageCompressor';
import { s3Paths } from 'Utils/s3paths';

import { CameraFilled } from '@ant-design/icons';

import StorageGetImage from './StorageGetImage';
import { StoragePut } from './StoragePut';

const { Dragger } = Upload;
interface Props {
  onSelectFront: (file: any) => void;
  onSelectBack: (file: any, data: any) => void;
  getBackImageHandler: (data: any) => void;
  circleImage?: boolean;
  label: string;
}

const ImgPickerCitizenshipOcr = ({
  onSelectFront,
  onSelectBack,
  circleImage,
  label,
  getBackImageHandler,
}: Props) => {
  const dispatch = useDispatch();
  const { toggleImageLoader } = bindActionCreators(
    ProtectedActionCreators,
    dispatch
  );
  const {
    kycFormValues,
    kycDetails,
    currentUserId,
    imageLoader,
    actionLoading,
  } = useSelector((state: RootState) => state?.protectedReducer);
  const [imageFront, setImageFront] = useState<string | any>("");
  const [imageBack, setImageBack] = useState<string | any>("");
  const [frontImageLoading, setFrontImageLoading] = useState(false);
  const [getImageLoading, setGetImageLoading] = useState(false);
  const [getImageLoading2, setGetImageLoading2] = useState(false);

  const getImageFront = async () => {
    let imageKey = kycFormValues.minor ? kycFormValues?.guardian_details?.citizenship_images?.front : kycFormValues?.citizenship_images?.front ?? "";
   if(imageKey) {
    setGetImageLoading(true);
    StorageGetImage({
      imageKey,
      successCB: (base64data) => {
        setImageFront(base64data);
      },
      finalCB: () => {
        setGetImageLoading(false);
      },
    });
   }
  };
  const getImageBack = async () => {
    let imageKey = kycFormValues.minor ? kycFormValues?.guardian_details?.citizenship_images?.back : kycFormValues?.citizenship_images?.back ?? "";
  if(imageKey) {
    setGetImageLoading2(true);
    StorageGetImage({
      imageKey,
      successCB: (base64data) => {
        getBackImageHandler(base64data);
        setImageBack(base64data);
      },
      finalCB: () => {
        setGetImageLoading2(false);
      },
    });
  }
  };
  useEffect(() => {
    if (kycFormValues?.citizenship_images?.front || kycFormValues?.guardian_details?.citizenship_images?.front) {
      getImageFront();
    }
    if (kycFormValues?.citizenship_images?.back || kycFormValues?.guardian_details?.citizenship_images?.back) {
      getImageBack();
    }
  }, [kycFormValues]);

  const beforeUploadFront = async (file: any) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG file and PNG Files!");
    } else {
      setFrontImageLoading(true);
      const checkFile = await checkValidFile(file);
      if (!checkFile) {
        setFrontImageLoading(false);
        return;
      }
      const compressedFile = await resizeImage(file);

      const imageData = await toBase64(compressedFile);
      setFrontImageLoading(false);
      toggleImageLoader(true);

      setImageFront(imageData);
      let extension = compressedFile?.type.split("/")[1];
      StoragePut({
        directoryPath: `${s3Paths({
          currentUserId,
          mainId: kycDetails?.id,
          type: "nonMinorCitizenshipFront",
        })}/${dayjs().format("YYMMDD-HHmmss")}.${extension}`,
        fileObj: compressedFile,
        successCB: function (resKey: string): void {
          const data = resKey;
          onSelectFront(data);
        },
        failureCB: function (): void {
          message.error(`Error uploading image. Please try again`);
          setImageFront("");
        },
        finalCB: function (): void {
          toggleImageLoader(false);
        },
      });
    }
  };
  const beforeUploadBack = async (file: any) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";

    if (!isJpgOrPng) {
      message.error("You can only upload JPG file and PNG Files!");
    } else {
      toggleImageLoader(true);
      // setIsBackSubmitting(true);
      const checkFile = await checkValidFile(file);
      if (!checkFile) {
        toggleImageLoader(false);
        return;
      }
      const compressedFile = await resizeImage(file);

      const imageData = await toBase64(compressedFile);
      setImageBack(imageData);
      let extension = compressedFile?.type.split("/")[1];
      StoragePut({
        directoryPath: `${s3Paths({
          currentUserId,
          mainId: kycDetails?.id,
          type: "nonMinorCitizenshipBack",
        })}/${dayjs().format("YYMMDD-HHmmss")}.${extension}`,
        fileObj: compressedFile,
        successCB: (resKey) => {
          const data = resKey;
          onSelectBack(compressedFile, data);
        },
        failureCB: () => {
          message.error(`Error uploading image. Please try again`);
          setImageBack("");
        },
        finalCB: () => {
          toggleImageLoader(false);
        },
      });
    }
  };



  

  return (
    <div>
      <Spin
        spinning={
          imageLoader ||
          frontImageLoading ||
          getImageLoading ||
          getImageLoading2 ||
          actionLoading
        }
      >
        <div className='d-flex flex-wrap '>
          <div className='mr-1 mb-2'>
            <p
              className="xs-text text-light-grey  mb-0"
            >
              Front
            </p>
            {imageFront ?

              <div
                style={{
                  position: "relative",
                  height: "120px",
                  width: circleImage ? "150px" : " 15rem",
                  borderRadius: circleImage ? "75px" : "8px",
                }}
              >
                <Image
                  src={imageFront}
                  style={{
                    objectFit: "cover",
                    position: "absolute",
                    height: "120px",
                    width: circleImage ? "150px" : " 15rem",
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    borderRadius: circleImage ? "75px" : "8px",
                  }}
                  preview={true}
                />
                <div
                  style={{
                    position: "absolute",
                    right: -10,
                    bottom: 0,
                    zIndex: 300,
                  }}
                >
                  <Upload
                    multiple={false}
                    accept="image/*"
                    showUploadList={false}
                    beforeUpload={beforeUploadFront}
                    customRequest={dummyRequest}
                  >
                    <ReUploadIcon />
                  </Upload>
                </div>
              </div>
              :
              <Dragger
                multiple={false}
                className="imagepicker-dragger"
                accept="image/*"
                style={{
                  // background: "#F6F8FA",
                  borderColor: "#0AB290",
                  height: "120px",
                  width: "216px",
                  borderRadius: "8px",
                }}
                showUploadList={false}
                beforeUpload={beforeUploadFront}
                customRequest={dummyRequest}
              >
                <p className="ant-upload-drag-icon">
                  <CameraFilled style={{ color: "#00DDA2", fontSize: 60 }} />
                </p>

              </Dragger>
            }
          </div>

          <div className='mb-2'>
            <p
              className="xs-text text-light-grey  mb-0"
            >
              Back
            </p>

            {imageBack ?

              <div
                style={{
                  position: "relative",
                  height: "120px",
                  width: circleImage ? "150px" : " 15rem",
                  borderRadius: circleImage ? "75px" : "8px",
                }}
              >
                <Image
                  src={imageBack}
                  style={{
                    objectFit: "cover",
                    position: "absolute",
                    height: "120px",
                    width: circleImage ? "150px" : " 15rem",
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    borderRadius: circleImage ? "75px" : "8px",
                  }}
                  preview={true}
                />
                <div
                  style={{
                    position: "absolute", 
                    right: -10,
                    bottom: 0,
                    zIndex: 300,
                  }}
                >
                  <Upload
                    multiple={false}
                    accept="image/*"
                    showUploadList={false}
                    beforeUpload={beforeUploadBack}
                    customRequest={dummyRequest}
                    disabled={imageLoader}
                  >
                    <ReUploadIcon />
                  </Upload>
                </div>
              </div>
              :

              <Dragger
                multiple={false}
                className="imagepicker-dragger"
                accept="image/*"
                style={{
                  // background: "#F6F8FA",
                  borderColor: "#0AB290",
                  height: "120px",
                  width: "216px",
                  borderRadius: "8px",
                }}
                showUploadList={false}
                beforeUpload={beforeUploadBack}
                customRequest={dummyRequest}
              >
                <p className="ant-upload-drag-icon">
                  <CameraFilled style={{ color: "#00DDA2", fontSize: 60 }} />
                </p>

              </Dragger>
            }


          </div>
        </div>
        {/* <div className='d-flex'>
          {imageFront === "" ? (
            <Dragger
              multiple={false}
              className="imagepicker-dragger"
              accept="image/*"
              style={{
                background: "#F6F8FA",
                borderColor: "#0AB290",
                height: "120px",
                width: "216px",
                borderRadius: "8px",
              }}
              showUploadList={false}
              beforeUpload={beforeUploadFront}
              customRequest={dummyRequest}
            >
              <p className="ant-upload-drag-icon">
                <CameraFilled style={{ color: "#00DDA2", fontSize: 60 }} />
              </p>
              <p
                className="xs-text text-light-grey text-center"
                style={{ paddingInline: "1vw" }}
              >
                Add/Upload Front Citizenship
              </p>
            </Dragger>
          ) : (
            <div
              style={{
                position: "relative",
                height: "120px",
                width: circleImage ? "150px" : " 15rem",
                borderRadius: circleImage ? "75px" : "8px",
              }}
            >
              <Image
                src={imageFront}
                style={{
                  objectFit: "cover",
                  position: "absolute",
                  height: "120px",
                  width: circleImage ? "150px" : " 15rem",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  borderRadius: circleImage ? "75px" : "8px",
                }}
                preview={true}
              />
              <div
                style={{
                  position: "absolute",
                  right: -10,
                  bottom: 0,
                  zIndex: 300,
                }}
              >
                <Upload
                  multiple={false}
                  accept="image/*"
                  showUploadList={false}
                  beforeUpload={beforeUploadFront}
                  customRequest={dummyRequest}
                >
                  <ReUploadIcon />
                </Upload>
              </div>
            </div>
          )}
          <br />
          {imageBack === "" && imageFront ? (
            <Dragger
              multiple={false}
              className="imagepicker-dragger"
              accept="image/*"
              style={{
                background: "#F6F8FA",
                borderColor: "#0AB290",
                height: "120px",
                width: "216px",
                borderRadius: "8px",
              }}
              showUploadList={false}
              beforeUpload={beforeUploadBack}
              customRequest={dummyRequest}
            >
              <p className="ant-upload-drag-icon">
                <CameraFilled style={{ color: "#00DDA2", fontSize: 60 }} />
              </p>
              <p
                className="xs-text text-light-grey text-center"
                style={{ paddingInline: "1vw" }}
              >
                Add/Upload Back Citizenship
              </p>
            </Dragger>
          ) : null}
          {imageBack ? (
            <div
              style={{
                position: "relative",
                height: "120px",
                width: circleImage ? "150px" : " 15rem",
                borderRadius: circleImage ? "75px" : "8px",
              }}
            >
              <Image
                src={imageBack}
                style={{
                  objectFit: "cover",
                  position: "absolute",
                  height: "120px",
                  width: circleImage ? "150px" : " 15rem",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  borderRadius: circleImage ? "75px" : "8px",
                }}
                preview={true}
              />
              <div
                style={{
                  position: "absolute",
                  right: -10,
                  bottom: 0,
                  zIndex: 300,
                }}
              >
                <Upload
                  multiple={false}
                  accept="image/*"
                  showUploadList={false}
                  beforeUpload={beforeUploadBack}
                  customRequest={dummyRequest}
                  disabled={imageLoader}
                >
                  <ReUploadIcon />
                </Upload>
              </div>
            </div>
          ) : null}
          <br />
        </div> */}
      </Spin>
    </div>
  );
};

ImgPickerCitizenshipOcr.defaultProps = {
  circleImage: false,
  label: "Add/ Clear Image",
};

export default ImgPickerCitizenshipOcr;
