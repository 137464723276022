import React, {
  Dispatch,
  SetStateAction,
} from 'react';

import {
  Col,
  Form,
  Row,
  Select,
} from 'antd';
import LabelWrapper from 'Components/LabelWrapper';
import SelectLabelDisplay from 'Components/SelectLabelDisplay';
import SubLabelWrapper from 'Components/SubLabelWrapper';
import {
  abcBranchOptions,
  personTypeOptions,
} from 'Constants/SelectDefaultOptions';
import { DepositoryParticipant } from 'Interfaces/Page';
import { VALIDATIONS } from 'Utils/formValidationRules';

interface DpInfoProps {
    dpFormValues: DepositoryParticipant
    setDpFormValues: Dispatch<SetStateAction<DepositoryParticipant>>
}

export const DpInfo = ({ dpFormValues, setDpFormValues }: DpInfoProps) => {

    const handleSelectDpChange = (e: any, name: string) => {
        setDpFormValues({ ...dpFormValues, [name]: e });
    };

    return (
        <div className='groupWrapper'>
            <SubLabelWrapper
                label="DP Info (ABC Securities Pvt. Ltd.)"
                subLabel=''
                render={
                    // <Form name="basic" form={form} layout="vertical" onFinish={submitHandler} >
                    <Row gutter={[16, 16]}>
                        <Col xs={24} lg={12}>
                            <LabelWrapper
                                label="Branch Name"
                                render={
                                    <Form.Item
                                        name="branch"
                                        rules={VALIDATIONS.branch}
                                        initialValue={dpFormValues.branch}
                                    >
                                        <Select
                                            dropdownClassName="dropdown-default"
                                            onChange={(e) => handleSelectDpChange(e, "branch")}
                                            optionLabelProp="customlabel"
                                            className="underlineSelect"
                                        >
                                            {abcBranchOptions.map((each: any, index: number) => {
                                                return (
                                                    <Select.Option
                                                        key={index}
                                                        value={each.value}
                                                        customlabel={<div>{each.label}</div>}
                                                    >
                                                        <SelectLabelDisplay
                                                            label={each.label}
                                                            value={each.value}
                                                            currentVal={dpFormValues.branch}
                                                        />
                                                    </Select.Option>
                                                );
                                            })}
                                        </Select>
                                    </Form.Item>
                                }
                            />
                        </Col>

                        <Col xs={24} lg={12}>
                            <LabelWrapper
                                label="Type Of Account"
                                render={
                                    <Form.Item
                                        name="account_type"
                                        rules={VALIDATIONS.account_type}
                                        initialValue={dpFormValues.account_type}
                                    >
                                        <Select
                                            onChange={(e) => handleSelectDpChange(e, "account_type")}
                                            optionLabelProp="customlabel"
                                            className="underlineSelect"

                                        >
                                            {personTypeOptions.map((each: any, index: number) => {
                                                return (
                                                    <Select.Option
                                                        key={index}
                                                        value={each.value}
                                                        customlabel={<div>{each.label}</div>}
                                                    >
                                                        <SelectLabelDisplay
                                                            label={each.label}
                                                            value={each.value}
                                                            currentVal={dpFormValues.account_type}
                                                        />
                                                    </Select.Option>
                                                );
                                            })}
                                        </Select>
                                    </Form.Item>
                                }
                            />
                        </Col>

                    </Row>
                    // </Form>
                }
            />
        </div>
    )
}
