import React from 'react';

import { useHistory } from 'react-router-dom';
import { FormSteps } from 'Views/Common/FormSteps/FormSteps';

import DematHeader from '../components/DematHeader';
import DmatHeaderProgress from '../components/DmatHeaderProgress';

interface Props {
  children?: React.ReactNode;
  renderFooter?: React.ReactNode;
  progressLeftText: string;
  progressRightText: string | number;
  progressPercent: number;
  title: string;
  showIconHeader: boolean;
  iconHeaderDirection?: "row" | "column";
  progressStartPercent: number;
  animationIn?: boolean;
  columnBtn?: boolean;
  disableFooter?: boolean;
  showSubTitle?: boolean;
  subTitle1?: string;
  subTitle2?: string;
  iconTitle?: string;
  showTitle?: boolean;
  path?: string
}

const middleContainerVariant = {
  hidden: {
    // x: props.animationIn ? "100vw" : "-100vw",
    x: "100vw",
    opacity: 0,
  },
  visible: {
    x: 0,
    opacity: 1,
    transition: {
      duration: 0.6,
      ease: "easeInOut",
      delay: 0.3,
    },
  },
  exit: {
    // x: props.animationIn ? "-100vw" : "100vw",
    x: "-100vw",
    opacity: 0,
    transition: {
      duration: 0.6,
      ease: "easeInOut",
    },
  },
};

const ProgressbarLayout = (props: Props) => {

  const history = useHistory()

  const testTime =
    typeof props.progressRightText === "string" ? 0 : props.progressRightText;
  const formattedTime: any =
    ("0" + Math.floor((testTime / 60) % 60)).slice(-2) +
    ":" +
    ("0" + Math.floor(testTime % 60)).slice(-2) +
    " min";

  //middleContainer Animation

  return (

    <div className="progressLayout">
      <div className="progressTopContainer">
        <DmatHeaderProgress
          progressLeftText={props.progressLeftText}
          progressPercent={props.progressPercent}
          // progressPercent={progressVal}
          progressRightText={formattedTime}
          title={props.title}
          showSubTitle={props.showSubTitle}
          subTitle1={props.subTitle1}
          subTitle2={props.subTitle2}
          showTitle={props.showTitle}
        />
      </div>
      <div className="progressMiddleContainer">
        <div className="mb-1 d-flex align-items-center flex-between flex-wrap">
          {props.showIconHeader && (
            <>
              <DematHeader
                title={props.iconTitle}
                direction={props.iconHeaderDirection}
              />
              {!history.location.pathname.includes("meroshare") &&

                <FormSteps path={props.path} />
              }
            </>
          )}
        </div>

        {/* <motion.div
            variants={middleContainerVariant}
            initial="hidden"
            animate="visible"
            exit="exit"
          >
            {props.children}
          </motion.div> */}
        <div> {props.children} </div>
      </div>
      {!props.disableFooter && (
        <div
          className={
            props.columnBtn
              ? "progressBottomContainer2"
              : "progressBottomContainer"
          }
        >
          {props.renderFooter}
        </div>
      )}
    </div>

  );
};
ProgressbarLayout.defaultProps = {
  showTitle: true,
  showIconHeader: true,
  progressStartPercent: 0,
  columnBtn: false,
  disableFooter: false,
  showSubTitle: false,
  subTitle1: "",
  subTitle2: "",
  iconTitle: "DEMAT",
  iconHeaderDirection: "column",
};

export default ProgressbarLayout;
