import React, {
  Dispatch,
  MouseEvent,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import {
  Button,
  Col,
  Image,
  message,
  Progress,
  Row,
  Upload,
} from 'antd';
import ImgCrop from 'antd-img-crop';
import CloseIcon from 'Assets/Icons/close.svg';
import ImageIcon from 'Assets/Icons/image.png';
import LabelWrapper from 'Components/LabelWrapper';
import StorageGetImage from 'Components/StorageGetImage';
import { useSetKycFormValue } from 'CustomHooks/useSetKycFormValue';
import { useSelector } from 'react-redux';
import {
  useHistory,
  useLocation,
} from 'react-router-dom';
import { RootState } from 'Redux/RootReducer';
import { checkValidFile } from 'Utils/checkValidFile';
import { dummyRequest } from 'Utils/DummyCall';

interface SignatureUploadProps {
    setSignatureKey: Dispatch<SetStateAction<string>>
    dispatchKycFormValues: () => void
    setSignatureImage: Dispatch<SetStateAction<any>>
    signatureImage: any
}

export const SignatureUpload = ({ setSignatureKey, dispatchKycFormValues, signatureImage, setSignatureImage }: SignatureUploadProps) => {
    //var
    const signatureRef = useRef<HTMLDivElement>(null)
    const { kycFormValues, } =
        useSelector((state: RootState) => state?.protectedReducer)

    //hooks
    const history = useHistory()
    const location: any = useLocation()
    const { oid, store } = useSetKycFormValue()

    //state
    const [imagePercent, setImagePercent] = useState<any>(0);


    const getImage = useCallback(async () => { //get base64 image
        let imageKey = kycFormValues?.nominee_details?.signature ?? "";
        await StorageGetImage({
            imageKey,
            successCB: (base64data) => {
                setSignatureImage(base64data);
            },
        });
        setImagePercent(100);
    }, [kycFormValues?.nominee_details?.signature, setSignatureImage])

    useEffect(() => {
        if (kycFormValues?.nominee_details?.signature) { //get signature image
            getImage();
            setImagePercent(100);
        }
    }, [kycFormValues, getImage])


    useEffect(() => {
        if (location.state?.scrollPosition === "signature") { //scroll to signature position

            signatureRef.current?.scrollIntoView({ behavior: 'smooth', })
        }
    }, [location.state?.scrollPosition,])


    const handleDigitalSignature = () => {
        dispatchKycFormValues()
        if (history.location.pathname.includes("dematt")) {
            history.push(`/app/${oid}/dematt/nominee/digital-signature`);
        } else {
            history.push("/app/trading/individual/nominee/digital-signature");
        }
    };

    const resetSignature = () => {
        setSignatureImage(null);
        setImagePercent(0);
    };

    const beforeUpload = async (file: any) => {
        const checkFile = await checkValidFile(file);
        if (!checkFile) return;
        const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";

        if (!isJpgOrPng) {
            message.error("You can only upload JPG file and PNG Files!");
        } else {
            setImagePercent(0);
            setSignatureImage(file);
            let extension = file?.type.split("/")[1];
            await store(extension, file, "nomineeSignature", setSignatureKey) //upload file to aws
            setImagePercent(100)
        }
    };

    return (
        <Col xs={24} lg={12} ref={signatureRef}>
            <LabelWrapper
                label={`Add or Upload Signature `}
                render={
                    <Row>

                        {signatureImage || imagePercent === 100 ? (
                            <Col
                                span="24"
                                style={{ border: "1px dashed #0AB290" }}
                                className="br-8 p-1"
                            >
                                <Row className="mb-1">
                                    <Col span="22" className="d-flex">
                                        <Image src={ImageIcon} preview={false} />
                                        <span className="pl-1" style={{ color: "#60706F" }}>
                                            Nominee Signature.png
                                        </span>
                                    </Col>
                                    <Col span="2" className="text-right pointer">
                                        <Image
                                            onClick={resetSignature}
                                            src={CloseIcon}
                                            preview={false}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span="22">
                                        {" "}
                                        <Progress
                                            strokeColor="#00DDA2"
                                            showInfo={false}
                                            percent={imagePercent}
                                        />
                                    </Col>
                                    <Col
                                        span="22"
                                        className="text-right"
                                        style={{ marginTop: "-0.4rem" }}
                                    >
                                        {imagePercent}%
                                    </Col>
                                </Row>
                            </Col>
                        ) : (
                            <>
                                <Col span="24" className='mb-1'>
                                    <Button
                                        onClick={handleDigitalSignature}
                                        block
                                        className="br-8 mb-1"
                                        type="dashed"
                                        style={{ width: "100%" }}

                                    >
                                        Add nominee signature digitally
                                    </Button>
                                </Col>
                                <Col span="24">
                                    <ImgCrop>
                                        <Upload
                                            multiple={false}
                                            accept="image/*"
                                            showUploadList={false}
                                            beforeUpload={beforeUpload}
                                            customRequest={dummyRequest}
                                            className='upload-signature'
                                        >
                                            <Button

                                                block
                                                className="br-8 w-100p"
                                                type="dashed"
                                                style={{ width: "100%" }}
                                                onClick={(e: MouseEvent<HTMLButtonElement>) => e.preventDefault()}
                                            >
                                                Upload nominee signature
                                            </Button>
                                        </Upload>
                                    </ImgCrop>
                                </Col>
                            </>
                        )}
                    </Row>
                }
            />
        </Col>
    )
}
