import React, {
  Dispatch,
  SetStateAction,
  useEffect,
} from 'react';

import {
  Col,
  Form,
  FormInstance,
  Input,
  Row,
  Select,
  Typography,
} from 'antd';
import LabelWrapper from 'Components/LabelWrapper';
import SelectLabelDisplay from 'Components/SelectLabelDisplay';
import { relationshipOptions } from 'Constants/SelectDefaultOptions';
import { NomineeDetails } from 'Interfaces/Page';
import { useSelector } from 'react-redux';
import { RootState } from 'Redux/RootReducer';
import { VALIDATIONS } from 'Utils/formValidationRules';
import {
  EMAIL_REGEX,
  ONLY_DIGITS,
} from 'Utils/Regex';

interface NomineeDetailsProps {
    formValues: NomineeDetails
    setFormValues: Dispatch<SetStateAction<NomineeDetails>>
    form: FormInstance
}

export const NomineeDetailsComp = ({ formValues, setFormValues, form }: NomineeDetailsProps) => {
    //var
    const { kycFormValues, } =
        useSelector((state: RootState) => state?.protectedReducer)

    useEffect(() => { //set nominee val
        if (kycFormValues.nominee) {
            const finalValues = {
                name: kycFormValues?.nominee_details?.name ?? "",
                relationship: kycFormValues?.nominee_details?.relationship ?? "",
                age: kycFormValues?.nominee_details?.age ?? "",
                email_mobile: kycFormValues?.nominee_details?.email_mobile ?? "",
            };

            setFormValues(finalValues);
            form.setFieldsValue(finalValues);
        }

    }, [kycFormValues, form, setFormValues])


    const handleInputChange = (e: any) => {
        setFormValues({ ...formValues, [e.target.name]: e.target.value });
    };

    const handleSelectChange = (e: any, name: string) => {
        setFormValues({ ...formValues, [name]: e });
    };

    return (
        <Row gutter={[16, 16]} className='groupWrapper'>
            <Col xs={24}>
                <Typography className={`r-text fw-500 text-light-black `}>Nominee Details </Typography>

            </Col>
            <Col xs={24} lg={12} id='name'>
                <LabelWrapper
                    label="Name of Nominee"
                    render={
                        <Form.Item
                            name="name"
                            rules={VALIDATIONS.nominee_name}
                            initialValue={formValues.name}
                        >
                            <Input
                                bordered={false}
                                className="underlineInput"
                                name="name"
                                onChange={handleInputChange}
                            // value={formValues.name}
                            />
                        </Form.Item>
                    }
                />
            </Col>
            <Col xs={24} lg={12} id='relationship'>
                <LabelWrapper
                    label="Relationship"
                    // subLabel="Select the type of relationship with the Nominee"
                    render={
                        <Form.Item
                            name="relationship"
                            rules={VALIDATIONS.relation}
                            initialValue={formValues.relationship}
                        >
                            <Select
                                onChange={(e) => handleSelectChange(e, "relationship")}
                                showArrow={!formValues.relationship}
                                optionLabelProp="customlabel"
                                className="underlineSelect"

                            >
                                {relationshipOptions.map((each: any, index: number) => {
                                    return (
                                        <Select.Option
                                            key={index}
                                            value={each.value}
                                            customlabel={<div>{each.label}</div>}
                                        >
                                            <SelectLabelDisplay
                                                label={each.label}
                                                value={each.value}
                                                currentVal={formValues.relationship}
                                            />
                                        </Select.Option>
                                    );
                                })}
                            </Select>
                        </Form.Item>
                    }
                />
            </Col>
            <Col xs={24} lg={12} id='age'>
                <LabelWrapper
                    label="Age"
                    render={
                        <Form.Item
                            name="age"
                            rules={VALIDATIONS.nominee_age}
                            initialValue={formValues.age}
                        >
                            <Input
                                bordered={false}
                                className="underlineInput"
                                name="age"
                                type="number"
                                onChange={handleInputChange}
                            // value={formValues.age}
                            />
                        </Form.Item>
                    }
                />
            </Col>
            <Col xs={24} lg={12} id='email_mobile'>
                <LabelWrapper
                    label="Mobile Number / Email Id"
                    render={
                        <Form.Item
                            name="email_mobile"
                            rules={[
                                ...[
                                    {
                                        required: true,
                                        message: "Please enter Mobile Number or Email Id",
                                    },
                                ],
                                ...(formValues?.email_mobile?.includes("@")
                                    ? [
                                        {
                                            pattern: EMAIL_REGEX,
                                            message: "Please enter a Valid Email Id",
                                        },
                                    ]
                                    : [
                                        {
                                            min: 10,
                                            max: 10,
                                            pattern: ONLY_DIGITS,
                                            message: "Please enter a Valid Mobile Number",
                                        },
                                    ]),
                            ]}
                            initialValue={formValues.email_mobile}
                        >
                            <Input
                                bordered={false}
                                className="underlineInput"
                                name="email_mobile"
                                onChange={handleInputChange}
                            />
                        </Form.Item>
                    }
                />
            </Col>

        </Row>
    )
}
