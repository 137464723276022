import { CheckOutlined } from "@ant-design/icons";

interface Props {
  label: string;
  value: string;
  currentVal: string | any;
}
/**
 * 
 * @param label display text for select input 
 * @param value value for select input 
 * @param currentVal current active input value 
 * @returns 
 */
const SelectLabelDisplay = ({ label, value, currentVal }: Props) => {
  return (
    <div className="d-flex flex-between">
      <div>{label}</div>
      <div>{value === currentVal && <CheckOutlined />}</div>
    </div>
  );
};

export default SelectLabelDisplay;
