import React, {
  useEffect,
  useState,
} from 'react';

import {
  Col,
  Form,
  message,
  Row,
} from 'antd';
import OutlineButton from 'Components/OutlineButton';
import SubLabelWrapper from 'Components/SubLabelWrapper';
import { useSetKycFormValue } from 'CustomHooks/useSetKycFormValue';
import {
  IPageProps,
  NomineeDetails,
} from 'Interfaces/Page';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import {
  clearSuccess,
  setKycFormValues,
  signatureBackgroundRemoverRequest,
  updateKycDetailRequest,
} from 'Redux/Protected/Action';
import { RootState } from 'Redux/RootReducer';

import RenderFooter from '../components/RenderFooter';
import ProgressbarLayout from '../Layout/ProgressbarLayout';
import { NomineeDetailsComp } from './Components/NomineeDetails/NomineeDetails';
import { CitizenshipUpload } from './Components/Upload/CitizenshipUpload';
import { SignatureUpload } from './Components/Upload/SignatureUpload';
import { ThumbPrintUpload } from './Components/Upload/ThumbPrintUpload';

const InitialValues: NomineeDetails = {
    name: "",
    relationship: "",
    age: "",
    email_mobile: "",
};

export const DematNomineeDetails = ({ history, pageNumber }: IPageProps) => {
    //var
    const { kycFormValues, success, actionLoading, globalTimer } =
        useSelector((state: RootState) => state?.protectedReducer)

    //hook
    const dispatch = useDispatch()
    const [form] = Form.useForm();
    const { oid } = useSetKycFormValue()


    //state
    const [formValues, setFormValues] = useState(InitialValues);
    const [signatureImage, setSignatureImage] = useState<any>(null);
    const [signatureKey, setSignatureKey] = useState<any>("");
    const [nomineeVal, setNomineeVal] = useState(false)
    const [thumbPrintData, setThumbPrintData] = useState("")
    const [selectedFront, setSelectedFront] = useState<any>(null);
    const [selectedBack, setSelectedBack] = useState<any>(null);

    useEffect(() => {
        if (success) {
            history.push(`/app/${oid}/dematt/transaction-info`)
        }
        return () => {
            dispatch(clearSuccess())
        }
    }, [success, dispatch, history, oid])


    useEffect(() => {
        if (kycFormValues?.nominee) { //set nominee state
            setNomineeVal(kycFormValues.nominee)
        }
    }, [kycFormValues?.nominee])


    const backHandler = () => {
        if (history.location.pathname.includes("dematt")) {
            history.push(`/app/${oid}/dematt/financial`)
        }
    }

    const dispatchKycFormValues = () => { //save form value when uploading signature

        if (nomineeVal) {
            let submitValues = {
                nominee: true,
                nominee_details: {
                    ...kycFormValues.nominee_details,
                    name: formValues?.name ?? "",
                    relationship: formValues?.relationship ?? "",
                    email: formValues?.email ?? "",
                    age: formValues?.age ? parseInt(formValues?.age) : "",
                    thumbprint: thumbPrintData ?? "",
                    email_mobile: formValues?.email_mobile ?? "",
                    citizenship_images: {
                        ...kycFormValues?.nominee_details?.citizenship_images,
                        front: selectedFront ?? "",
                        back: selectedBack ?? "",
                    }
                }
            }
            dispatch(setKycFormValues({
                ...kycFormValues,
                ...submitValues
            }))
        }


    }

    const submitHandler = () => {
        form
            .validateFields()
            .then((values: any) => {
                submitForm();
            })
            .catch((errorInfo: any) => {
                const element = document.getElementById(`${errorInfo.errorFields[0].name[0]}`)
                element?.scrollIntoView({ behavior: "smooth" })
            });
    };


    const submitForm = () => {
        if (nomineeVal) {
            if (!thumbPrintData) {
                return message.error("Thumbprint is required ")
            }
            if (!selectedBack && !selectedFront) {
                return message.error("Citizenship  is required ")
            }
            if (!signatureImage) {
                return message.error("Signature is required")
            }
            let submitValues = {
                nominee: true,
                nominee_details: {
                    ...kycFormValues.nominee_details,
                    name: formValues.name,
                    relationship: formValues.relationship,
                    email: formValues.email,
                    age: formValues?.age ? parseInt(formValues.age) : "",
                    thumbprint: thumbPrintData,
                    email_mobile: formValues.email_mobile,
                    citizenship_images: {
                        ...kycFormValues?.nominee_details?.citizenship_images,
                        front: selectedFront,
                        back: selectedBack,
                    }
                }
            }

            if (signatureKey) {
                submitValues = {
                    ...submitValues,
                    nominee_details: {
                        ...submitValues.nominee_details,
                        signature: signatureKey,
                        
                    }
                }
            }

            dispatch(setKycFormValues({
                ...kycFormValues,
                ...submitValues
            }))

            dispatch(updateKycDetailRequest({
                ...submitValues,
            }))
            dispatch(signatureBackgroundRemoverRequest(
                {
                  url: signatureKey,
                  transparent: false,
                },
                false
              ))

        } else {
            //redirect


            dispatch(setKycFormValues({
                ...kycFormValues,
                nominee: false,
            }))

            dispatch(updateKycDetailRequest({
                nominee: false,
            }))
        }
    }
    return (
        <ProgressbarLayout
            progressLeftText={'80%'}
            progressRightText={globalTimer}
            progressPercent={history.location.pathname.includes('dematt') ? 80 : 3}
            iconTitle={
                history.location.pathname.includes('dematt') ? 'DEMAT' : 'TRADING'
            }
            title=''
            animationIn={history.action === 'PUSH' ? true : false}
            renderFooter={
                <RenderFooter
                    loading={actionLoading}
                    backHandler={backHandler}
                    submitHandler={submitHandler}
                />
            }
            path='nominee'
        >
            < >
                <div className='groupWrapper'>
                    <SubLabelWrapper
                        label="Add Nominee Details ?"
                        subLabel="Select to add or skip Nominee Information"
                        showSubLabel={true}
                        render={
                            <Row gutter={[16, 16]}>
                                <Col xs={12} lg={6} style={{ marginTop: "1rem" }}>
                                    <OutlineButton
                                        label="Yes"
                                        active={nomineeVal}
                                        onClick={() => {
                                            setNomineeVal(true)
                                        }}
                                    />
                                </Col>
                                <Col xs={12} lg={6} style={{ marginTop: "1rem" }}>
                                    <OutlineButton
                                        label="No"
                                        active={!nomineeVal}
                                        onClick={() => {
                                            setNomineeVal(false)

                                        }
                                        }
                                    />
                                </Col>
                            </Row>
                        }
                    />
                </div>

                {nomineeVal &&

                    <Form form={form} layout="vertical" name="basic" onFinish={submitHandler} className='mt-3'>

                        <NomineeDetailsComp formValues={formValues} setFormValues={setFormValues} form={form} />
                        <CitizenshipUpload setSelectedBack={setSelectedBack} setSelectedFront={setSelectedFront} />
                        <Row gutter={[16, 16]} className='mt-2 groupWrapper'>
                            <ThumbPrintUpload setThumbPrintData={setThumbPrintData} />
                            <SignatureUpload setSignatureKey={setSignatureKey} dispatchKycFormValues={dispatchKycFormValues} signatureImage={signatureImage} setSignatureImage={setSignatureImage} />
                        </Row>

                    </Form>

                }

            </>
        </ProgressbarLayout>
    )
}
