type Props = {
  addressArray: any[];
  districtName: string;
};
/**
 * 
 * @param addressArray array of address fields from stored in db 
 * @param districtName selected district 
 * @returns addressCode for the selected district
 */
export function findAddressCode({ addressArray, districtName }: Props) {
  const findAddressCode = addressArray.find(
    (item: any) =>
      item?.district?.toLowerCase() === districtName.toLowerCase() ?? 0
  );
  return findAddressCode.address_code;
}
