import { Modal as AntModal, ModalFuncProps, ModalProps } from "antd";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import { ReactNode } from "react";
import { styled } from "styled-components";

type Props = {
  children: ReactNode;
} & ModalFuncProps &
  ModalProps;

const Modal = ({ children, ...rest }: Props) => {
  const screens = useBreakpoint();

  return (
    <AntModal
      centered
      footer={null}
      width={screens?.md ? "50vw" : undefined}
      //   onCancel={() => {}}
      //   destroyOnClose
      //   closable={false}
      {...rest}
    >
      {children}
    </AntModal>
  );
};

export default Modal;
