import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react';

import {
  Button,
  Col,
  Form,
  FormInstance,
  Image,
  Input,
  message,
  Row,
  Select,
  Upload,
} from 'antd';
import { Predictions } from 'aws-amplify';
import ForwardButton from 'Components/ForwardButton';
import LabelWrapper from 'Components/LabelWrapper';
import SelectLabelDisplay from 'Components/SelectLabelDisplay';
import StorageGetImage from 'Components/StorageGetImage';
import { StoragePut } from 'Components/StoragePut';
import {
  accountOptions,
  tradingAccountOptions,
} from 'Constants/SelectDefaultOptions';
import dayjs from 'dayjs';
import { FinanceDetails } from 'Interfaces/Page';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { RootState } from 'Redux/RootReducer';
import { checkName } from 'Utils/checkName';
import { checkValidFile } from 'Utils/checkValidFile';
import { dummyRequest } from 'Utils/DummyCall';
import { findBankName2 } from 'Utils/findBankName2';
import { VALIDATIONS } from 'Utils/formValidationRules';
import { getOcrAccountNumber } from 'Utils/getOcrAccountNumber';
import { resizeImage } from 'Utils/ImageCompressor';
import { s3Paths } from 'Utils/s3paths';

interface BankDetailsProps {
    formValues: FinanceDetails
    setFormValues: Dispatch<SetStateAction<FinanceDetails>>
    form: FormInstance
    setBankCode: Dispatch<SetStateAction<number>>
    setChequeData: Dispatch<SetStateAction<any>>
    chequeData: any
}

export const BankDetails = ({ formValues, setFormValues, form, setBankCode, setChequeData, chequeData }: BankDetailsProps) => {
    //var

    const { kycFormValues, kycDetails, success, actionLoading, globalTimer, currentUserId } =
        useSelector((state: RootState) => state?.protectedReducer)

    const { bankDetails, bankLoading } = useSelector(
        (state: RootState) => state.staticReducer
    );

    //hooks
    const history = useHistory()

    //state
    const [chequeDataBase64, setChequeDataBase64] = useState<any>("")
    const [imageLoading, setImageLoading] = useState<boolean>(false);
    const [branchList, setBranchList] = useState<any>([])


    const finalAccountOptions = history.location.pathname.includes('dematt')
        ? accountOptions
        : tradingAccountOptions



    const getChequeImage = useCallback(() => { //get base64 url
        let imageKey;
        if (history.location.pathname.includes("dematt")) {
            imageKey = kycFormValues?.bank_details?.cheque ?? "";
        } else {
            imageKey = kycFormValues?.trading_bank_details?.cheque ?? "";
        }

        setChequeData(imageKey)

        if (imageKey !== "") {
            //   setChequeLoading(true);
            StorageGetImage({
                imageKey,
                successCB: (base64data) => {
                    setChequeDataBase64(base64data);
                },
                failureCB: () => {
                    setChequeDataBase64("");
                },
                finalCB: () => {
                    //   setChequeLoading(false);
                },
            });
        }
    }, [history.location.pathname, kycFormValues])



    useEffect(() => {//set bank branch list and bank code
        let banks = [...bankDetails]
        if (history.location.pathname.includes('dematt')) {
            if (bankDetails.length && formValues?.bank_name) {
                setBranchList(
                    banks.filter(
                        (each: any) => each.name === formValues?.bank_name
                    )[0]?.branches
                )
                setBankCode(
                    banks.filter(
                        (each: any) => each.name === formValues?.bank_name
                    )[0]?.code
                )
            }
        } else {

            setBranchList(
                banks.filter(
                    (each: any) =>
                        each.name === formValues?.bank_name
                )[0]?.branches
            )
            setBankCode(
                banks.filter(
                    (each: any) =>
                        each.name === formValues?.bank_name
                )[0]?.code
            )
        }

    }, [kycFormValues, formValues?.bank_name, history.location.pathname, setBankCode, bankDetails])

    useEffect(() => {// get cheque image
        if (
            kycFormValues?.bank_details?.cheque ||
            kycFormValues?.trading_bank_details?.cheque
        ) {
            getChequeImage();
        }
    }, [kycFormValues, getChequeImage])


    useEffect(() => {//set bank details value
        if (kycFormValues?.bank_details || kycFormValues?.trading_bank_details) {

            let finalValues = {}

            if (history.location.pathname.includes("dematt")) {

                finalValues = {
                    bank_name: kycFormValues?.bank_details?.bank_name ?? "",
                    branch: kycFormValues?.bank_details?.branch ?? "",
                    account_type: kycFormValues?.bank_details?.account_type ?? "",
                    account_number: kycFormValues?.bank_details?.account_number ?? "",

                };

            } else {
                finalValues = {
                    bank_name: kycFormValues?.trading_bank_details?.bank_name ?? "",
                    branch: kycFormValues?.trading_bank_details?.branch ?? "",
                    account_type: kycFormValues?.trading_bank_details?.account_type ?? "",
                    account_number: kycFormValues?.trading_bank_details?.account_number ?? "",
                    income_source: kycFormValues?.trading?.income_source

                };
            }
            setFormValues((prevState) => ({
                ...prevState,
                ...finalValues
            }));
            form.setFieldsValue(finalValues);
        }
    }, [kycFormValues, history.location.pathname, form, setFormValues])


    const handleSelectChange = (e: any, name: string) => {
        setFormValues({ ...formValues, [name]: e });
    };

    const handleInputChange = (e: any) => {
        setFormValues({ ...formValues, [e.target.name]: e.target.value });
    };

    const beforeUpload = async (file: any) => {

        const checkFile = await checkValidFile(file);
        if (!checkFile) return;

        const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";

        if (!isJpgOrPng) {
            message.error("You can only upload JPG file and PNG Files!");
        } else {
            setImageLoading(true);
            let bankName = "";
            let accountNumber = "";
            try {
                const response: any = await Predictions.identify({
                    text: {
                        source: {
                            file,
                        },
                        format: "ALL",
                    },
                });
                setImageLoading(false)
                if (response?.text.lines?.length) {
                    const extractedBankData = response.text;

                    bankName = findBankName2(extractedBankData.words);
                    checkName(response.text.words, kycFormValues?.username);

                    accountNumber = getOcrAccountNumber(extractedBankData);

                    const values = {
                        ...formValues,
                        bank_name: bankName ?? "",
                        account_number: accountNumber ?? "",
                    }

                    setFormValues(values)

                    form.setFieldsValue(values);
                }
            } catch (err) {
            }
            finally {
                const compressedFile = await resizeImage(file);
                let extension = compressedFile?.type.split("/")[1];
                StoragePut({
                    directoryPath: `${s3Paths({
                        currentUserId,
                        mainId: kycDetails?.id,
                        type: "bankCheque",
                    })}/${dayjs().format("YYMMDD-HHmmss")}.${extension}`,
                    fileObj: compressedFile,
                    finalCB: () => {
                        setImageLoading(false);
                    },
                    successCB: (data) => {
                        setChequeData(data)

                        StorageGetImage({
                            imageKey: data,
                            successCB: (base64data) => {
                                setChequeDataBase64(base64data);
                            },
                            failureCB: () => {
                                setChequeDataBase64("");
                            },
                            finalCB: () => {
                                //   setChequeLoading(false);
                            },
                        });

                    },
                });


            }
        }
    };


    return (
        <Row className='groupWrapper mt-2'>
            <Col xs={24} lg={12}>
                {chequeDataBase64 ?

                    <div className='d-flex flex-center flex-column align-items-center'
                    >
                        <Image
                            src={chequeDataBase64}
                            style={{
                                height: "160px",
                                width: "auto",
                                marginBottom: 20
                            }}
                        />
                        <Upload
                            multiple={false}
                            accept="image/*"
                            showUploadList={false}
                            customRequest={dummyRequest}
                            beforeUpload={beforeUpload}
                        >
                            <Button
                                type="default"
                                className="manuallyBtn"
                                shape="round"
                                onClick={(e: any) => e.preventDefault()}
                            >
                                {chequeData === "" ? "Scan Cheque" : "Rescan Cheque"}
                            </Button>
                        </Upload>
                    </div>
                    :

                    <div className="d-flex flex-between flex-column">
                        <div className="d-flex mt-2 flex-column w-100p  align-items-center">
                            <div className="sb-text text-light-black">Scan Bank Cheque</div>
                            <div className="s-text text-light-grey" style={{ marginTop: 5 }}>
                                Press below to upload or scan your check
                            </div>
                        </div>
                        <div className="d-flex h-100p flex-center mt-2">
                            <Upload
                                multiple={false}
                                accept="image/*"
                                showUploadList={false}
                                customRequest={dummyRequest}
                                beforeUpload={beforeUpload}
                            >
                                <ForwardButton
                                    loading={imageLoading || actionLoading}
                                    type="scan"
                                />
                            </Upload>
                        </div>
                    </div>
                }
            </Col>
            <Col xs={24} lg={12} >
                <div id='bank_name'>
                    <LabelWrapper
                        label="Bank Name"
                        render={
                            <Form.Item name="bank_name" rules={VALIDATIONS.bank_name}>
                                <Select
                                    value={formValues.bank_name}
                                    loading={bankLoading}
                                    showSearch={true}
                                    onChange={(e) => handleSelectChange(e, "bank_name")}
                                    optionLabelProp="customlabel"
                                    className="underlineSelect"
                                >
                                    {bankDetails?.length &&
                                        bankDetails.map((each: any, index: number) => {
                                            return (
                                                <Select.Option
                                                    key={index}
                                                    value={each.name}
                                                    customlabel={<div>{each.name}</div>}
                                                >
                                                    <SelectLabelDisplay
                                                        label={each.name}
                                                        value={each.name}
                                                        currentVal={formValues.bank_name}
                                                    />
                                                    {/* {each.name} */}
                                                </Select.Option>
                                            );
                                        })}
                                </Select>
                            </Form.Item>
                        }
                    />
                </div>
                <div id='account_number'>
                    <LabelWrapper
                        label="Account Number"
                        render={
                            <Form.Item
                                name="account_number"
                                rules={VALIDATIONS.account_number}
                                initialValue={formValues.account_number}
                            >
                                <Input
                                    name="account_number"
                                    bordered={false}
                                    className="underlineInput"
                                    onChange={handleInputChange}
                                    value={formValues.account_number}
                                />
                            </Form.Item>
                        }
                    />
                </div>
                <div id='branch'>
                    <LabelWrapper
                        label="Branch"
                        render={
                            <Form.Item
                                name='branch'
                                rules={VALIDATIONS.branch}
                                initialValue={formValues.branch}
                            >
                                <Select
                                    onChange={(e) => handleSelectChange(e, 'branch')}
                                    optionLabelProp='customlabel'
                                    className="underlineSelect"
                                    showSearch={true}
                                >
                                    {branchList && branchList.length &&
                                        branchList.sort().map((brch: any, index: number) => {
                                            return (
                                                <Select.Option
                                                    key={index}
                                                    value={brch}
                                                    customlabel={<div>{brch}</div>}
                                                >
                                                    <SelectLabelDisplay
                                                        label={brch}
                                                        value={brch}
                                                        currentVal={formValues.branch}
                                                    />
                                                </Select.Option>
                                            )
                                        })}
                                </Select>
                            </Form.Item>
                        }
                    />
                </div>
                <div id='account_type'>
                    <LabelWrapper
                        label="Account Type"
                        render={
                            <Form.Item
                                name='account_type'
                                rules={VALIDATIONS.account_type}
                                initialValue={formValues.account_type}
                            >
                                <Select
                                    onChange={(e) => handleSelectChange(e, 'account_type')}
                                    optionLabelProp='customlabel'
                                    loading={bankLoading}
                                    className="underlineSelect"
                                >
                                    {finalAccountOptions.map((each: any, index: number) => {
                                        return (
                                            <Select.Option
                                                key={index}
                                                value={each.value}
                                                customlabel={<div>{each.label}</div>}
                                            >
                                                <SelectLabelDisplay
                                                    label={each.label}
                                                    value={each.value}
                                                    currentVal={formValues.account_type}
                                                />
                                            </Select.Option>
                                        )
                                    })}
                                </Select>
                            </Form.Item>
                        }
                    />
                </div>
            </Col>
        </Row>
    )
}
