import React, {
  useEffect,
  useState,
} from 'react';

import {
  Form,
  message,
} from 'antd';
import { useSetKycFormValue } from 'CustomHooks/useSetKycFormValue';
import {
  FinanceDetails,
  IPageProps,
} from 'Interfaces/Page';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import {
  clearSuccess,
  setKycFormValues,
  updateKycDetailRequest,
} from 'Redux/Protected/Action';
import { RootState } from 'Redux/RootReducer';
import RenderFooter from 'Views/Dmat/components/RenderFooter';
import ProgressbarLayout from 'Views/Dmat/Layout/ProgressbarLayout';

import { BankDetails } from './Components/BankDetails/BankDetails';
import { FinanceDetailsComp } from './Components/FinanceDetails/FinanceDetails';

const InitialValues: FinanceDetails = {
    income: "",
    statement: "Monthly",
    // pan_number: "",
    bank_name: "",
    branch: "",
    account_type: "",
    account_number: "",
    income_source: ""
};

export const FinancialDetails = ({ history, pageNumber }: IPageProps) => {
    //var
    const { kycFormValues, success, actionLoading, globalTimer, } =
        useSelector((state: RootState) => state?.protectedReducer)

    //hooks
    const dispatch = useDispatch()
    const [form] = Form.useForm();
    const { oid } = useSetKycFormValue()

    //state
    const [formValues, setFormValues] = useState(InitialValues);
    const [bankCode, setBankCode] = useState<number>(0)
    const [chequeData, setChequeData] = useState<any>("")


    useEffect(() => {
        if (success) {
            if (history.location.pathname.includes("dematt")) {
                history.push(`/app/${oid}/dematt/nominee`);
            } else {
                history.push(`/app/${oid}/trading/additional-info`);
            }
        }
        return () => {
            dispatch(clearSuccess())
        };
    }, [success, dispatch, history, oid]);


    const backHandler = () => {
        if (history.location.pathname.includes("dematt")) {
            history.push(`/app/${oid}/dematt/occupations`)
        } else {
            history.push(`/app/${oid}/trading/occupations`)
        }

    }

    const submitHandler = () => {
        form
            .validateFields()
            .then((values: any) => {
                submitForm();
            })
            .catch((errorInfo: any) => {
                const element = document.getElementById(`${errorInfo.errorFields[0].name[0]}`)
                element?.scrollIntoView({ behavior: "smooth" })
            });
    };

    const submitForm = () => {

        let finalObjLocal: any = {};
        let finalObjRemote: any = {};

        if (formValues?.income === "") {
            message.error("Please input your yearly income");
            return;
        }

        let dataToPost = {
            income: formValues?.income,
            statement: formValues.statement,
        };

        let bankDetails = {
            account_number: formValues?.account_number,
            account_type: formValues.account_type,
            bank_name: formValues.bank_name,
            branch: formValues.branch,
            cheque: chequeData,
            code: bankCode
        }

        if (formValues?.pan_number === "" || !formValues?.pan_number) {
            finalObjLocal = {
                ...kycFormValues,
                finance_details: {
                    ...kycFormValues?.finance_details,
                    ...dataToPost,
                },
                [history.location.pathname.includes("trading") ? "trading_bank_details" : "bank_details"]: bankDetails
            };
            finalObjRemote = {
                finance_details: dataToPost,
                [history.location.pathname.includes("trading") ? "trading_bank_details" : "bank_details"]: bankDetails,

            };
        } else {
            finalObjLocal = {
                ...kycFormValues,
                finance_details: {
                    ...kycFormValues?.finance_details,
                    ...dataToPost,
                },
                pan_number: formValues.pan_number,
                [history.location.pathname.includes("trading") ? "trading_bank_details" : "bank_details"]: bankDetails

            };
            finalObjRemote = {
                finance_details: dataToPost,
                pan_number: Number(formValues.pan_number),
                [history.location.pathname.includes("trading") ? "trading_bank_details" : "bank_details"]: bankDetails,
            };
        }

        if (history.location.pathname.includes("trading")) {
            finalObjLocal = {
                ...finalObjLocal,
                trading: {
                    income_source: formValues.income_source,

                }
            }
            finalObjRemote = {
                ...finalObjRemote,
                trading: {
                    income_source: formValues.income_source
                }
            }
        }

        if (kycFormValues?.form?.demat && kycFormValues?.form?.trading) {
            finalObjLocal = {
                ...finalObjLocal,
                trading: {
                    ...finalObjLocal.trading,
                    email: kycFormValues?.email

                }
            }
            finalObjRemote = {
                ...finalObjRemote,
                trading: {
                    ...finalObjRemote.trading,
                    email: kycFormValues?.email
                }
            }
        }

        dispatch(setKycFormValues(finalObjLocal))
        dispatch(
            updateKycDetailRequest(finalObjRemote)

        )
    }

    return (
        <ProgressbarLayout
            progressLeftText={history.location.pathname.includes('dematt') ? "70%" : "80%"}
            progressRightText={globalTimer}
            progressPercent={history.location.pathname.includes('dematt') ? 70 : 80}
            iconTitle={
                history.location.pathname.includes('dematt') ? 'DEMAT' : 'TRADING'
            }
            title=''
            animationIn={history.action === 'PUSH' ? true : false}
            renderFooter={
                <RenderFooter
                    loading={actionLoading}
                    backHandler={backHandler}
                    submitHandler={submitHandler}
                />
            }
            path='financial'
        >
            < >
                <Form form={form} layout="vertical" name="basic" onFinish={submitHandler}>
                    <FinanceDetailsComp formValues={formValues} setFormValues={setFormValues} form={form} />
                    <BankDetails formValues={formValues} setFormValues={setFormValues} form={form} setBankCode={setBankCode} chequeData={chequeData} setChequeData={setChequeData} />

                </Form>
            </>
        </ProgressbarLayout>
    )
}
