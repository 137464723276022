import {
  useEffect,
  useState,
} from 'react';

import {
  Image,
  message,
  Spin,
  Upload,
} from 'antd';
import { ReactComponent as ReUploadIcon } from 'Assets/Icons/reupload.svg';
import dayjs from 'dayjs';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import { bindActionCreators } from 'redux';
import { ProtectedActionCreators } from 'Redux/Protected';
import { RootState } from 'Redux/RootReducer';
import { checkValidFile } from 'Utils/checkValidFile';
import { resizeImage } from 'Utils/ImageCompressor';
import { s3Paths } from 'Utils/s3paths';

import { CameraFilled } from '@ant-design/icons';

import StorageGetImage from './StorageGetImage';
import { StoragePut } from './StoragePut';

const { Dragger } = Upload;
interface Props {
  onSelectFront: (file: any) => void;
  onSelectBack: (file: any) => void;
  circleImage?: boolean;
  label: string;
}
const toBase64 = (file: any) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const ImgPickerNomineeCitizenship = ({
  onSelectFront,
  onSelectBack,
  circleImage,
  label,
}: Props) => {
  const dispatch = useDispatch();
  const { toggleImageLoader, toggleImageLoader2 } = bindActionCreators(
    ProtectedActionCreators,
    dispatch
  );
  const {
    kycFormValues,
    kycDetails,
    currentUserId,
    imageLoader,
    imageLoader2,
  } = useSelector((state: RootState) => state?.protectedReducer);
  const [imageFront, setImageFront] = useState<string | any>("");
  const [imageBack, setImageBack] = useState<string | any>("");
  const [getImageLoading, setGetImageLoading] = useState(false);
  const [getImageLoading2, setGetImageLoading2] = useState(false);

  const beforeUploadFront = async (file: any) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";

    if (!isJpgOrPng) {
      message.error("You can only upload JPG file and PNG Files!");
    } else {
      toggleImageLoader(true);
      const checkFile = await checkValidFile(file);
      if (!checkFile) {
        toggleImageLoader(false);
        return;
      }
      const compressedFile = await resizeImage(file);
      const imageData = await toBase64(compressedFile);
      setImageFront(imageData);
      let extension = compressedFile?.type.split("/")[1];
      StoragePut({
        directoryPath: `${s3Paths({
          currentUserId,
          mainId: kycDetails?.id,
          type: "nomineeCitizenshipFront",
        })}/${dayjs().format("YYMMDD-HHmmss")}.${extension}`,
        fileObj: compressedFile,
        successCB: (resKey) => {
          onSelectFront(resKey);
        },
        failureCB: () => {
          setImageFront("");
        },
        finalCB: () => {
          toggleImageLoader(false);
        },
      });
    }
  };
  const beforeUploadBack = async (file: any) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";

    if (!isJpgOrPng) {
      message.error("You can only upload JPG file and PNG Files!");
    } else {
      toggleImageLoader2(true);
      const checkFile = await checkValidFile(file);
      if (!checkFile) {
        toggleImageLoader2(false);
        return;
      }
      const compressedFile = await resizeImage(file);
      const imageData = await toBase64(compressedFile);
      setImageBack(imageData);
      let extension = compressedFile?.type.split("/")[1];
      StoragePut({
        directoryPath: `${s3Paths({
          currentUserId,
          mainId: kycDetails?.id,
          type: "nomineeCitizenshipBack",
        })}/${dayjs().format("YYMMDD-HHmmss")}.${extension}`,
        fileObj: compressedFile,
        successCB: (resKey) => {
          onSelectBack(resKey);
        },
        failureCB: () => {
          setImageBack("");
        },
        finalCB: () => {
          toggleImageLoader2(false);
        },
      });
    }
  };
  const getImageFront = async () => {
    let imageKey =
      kycFormValues?.nominee_details?.citizenship_images?.front ?? "";
    setGetImageLoading(true);
    StorageGetImage({
      imageKey,
      successCB: (base64data) => {
        setImageFront(base64data);
      },
      finalCB: () => {
        setGetImageLoading(false);
      },
    });
  };
  const getImageBack = async () => {
    let imageKey =
      kycFormValues?.nominee_details?.citizenship_images?.back ?? "";
    setGetImageLoading2(true);
    StorageGetImage({
      imageKey,
      successCB: (base64data) => {
        setImageBack(base64data);
      },
      finalCB: () => {
        setGetImageLoading2(false);
      },
    });
  };
  useEffect(() => {
    if (kycFormValues?.nominee_details?.citizenship_images?.front) {
      getImageFront();
    }
    if (kycFormValues?.nominee_details?.citizenship_images?.back) {
      getImageBack();
    }
  }, [kycFormValues]);

  return (
    <div>
      <Spin
        spinning={
          getImageLoading || getImageLoading2 || imageLoader || imageLoader2
        }
      >
        <div className='d-flex flex-wrap'>
          <div className='mr-1 mb-2'>
            <p
              className="xs-text text-light-grey  mb-0"
            >
              Front
            </p>
            {
              imageFront ?
                <div
                  style={{
                    position: "relative",
                    height: "120px",
                    width: circleImage ? "150px" : " 15rem",
                    borderRadius: circleImage ? "75px" : "8px",
                  }}
                >
                  <Image
                    src={imageFront}
                    style={{
                      objectFit: "cover",
                      position: "absolute",
                      height: "120px",
                      width: circleImage ? "150px" : " 15rem",
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                      borderRadius: circleImage ? "75px" : "8px",
                    }}
                  />
                  <div
                    style={{
                      position: "absolute",
                      right: -10,
                      bottom: 0,
                      zIndex: 300,
                    }}
                  >
                    <Upload
                      multiple={false}
                      accept="image/*"
                      showUploadList={false}
                      beforeUpload={beforeUploadFront}
                    >
                      <ReUploadIcon />
                    </Upload>
                  </div>
                </div>
                :

                <Dragger
                  multiple={false}
                  className="imagepicker-dragger"
                  accept="image/*"
                  style={{
                    borderColor: "#0AB290",
                    height: "180px",
                    width: "216px",
                    borderRadius: "8px",
                  }}
                  showUploadList={false}
                  beforeUpload={beforeUploadFront}
                >
                  <p className="ant-upload-drag-icon">
                    <CameraFilled style={{ color: "#00DDA2", fontSize: 60 }} />
                  </p>
               
                </Dragger>
            }

          </div>
          <div className='mb-2'>
            <p
              className="xs-text text-light-grey  mb-0"
            >
              Back
            </p>
            {imageBack ?
              <div
                style={{
                  position: "relative",
                  height: "120px",
                  width: circleImage ? "150px" : " 15rem",
                  borderRadius: circleImage ? "75px" : "8px",
                }}
              >
                <Image
                  src={imageBack}
                  style={{
                    objectFit: "cover",
                    position: "absolute",
                    height: "120px",
                    width: circleImage ? "150px" : " 15rem",
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    borderRadius: circleImage ? "75px" : "8px",
                  }}
                />
                <div
                  style={{
                    position: "absolute",
                    right: -10,
                    bottom: 0,
                    zIndex: 300,
                  }}
                >
                  <Upload
                    multiple={false}
                    accept="image/*"
                    showUploadList={false}
                    beforeUpload={beforeUploadBack}
                  >
                    <ReUploadIcon />
                  </Upload>
                </div>
              </div>
              :
              <Dragger
                multiple={false}
                className="imagepicker-dragger"
                accept="image/*"
                style={{
                  borderColor: "#0AB290",
                  height: "180px",
                  width: "216px",
                  borderRadius: "8px",
                }}
                showUploadList={false}
                beforeUpload={beforeUploadBack}
              >
                <p className="ant-upload-drag-icon">
                  <CameraFilled style={{ color: "#00DDA2", fontSize: 60 }} />
                </p>
              
              </Dragger>
            }
          </div>
        </div>
        {/* {imageFront === "" ? (
          
        ) : (
          
        )}
        <br />
        {imageBack === "" && imageFront ? (
          <Dragger
            multiple={false}
            className="imagepicker-dragger"
            accept="image/*"
            style={{
              background: "#F6F8FA",
              borderColor: "#0AB290",
              height: "120px",
              width: "216px",
              borderRadius: "8px",
            }}
            showUploadList={false}
            beforeUpload={beforeUploadBack}
          >
            <p className="ant-upload-drag-icon">
              <CameraFilled style={{ color: "#00DDA2", fontSize: 60 }} />
            </p>
            <p
              className="xs-text text-light-grey text-center"
              style={{ paddingInline: "1vw" }}
            >
              Add/Upload Back Citizenship
            </p>
          </Dragger>
        ) : null}
        {imageBack ? (
          <div
            style={{
              position: "relative",
              height: "120px",
              width: circleImage ? "150px" : " 15rem",
              borderRadius: circleImage ? "75px" : "8px",
            }}
          >
            <Image
              src={imageBack}
              style={{
                objectFit: "cover",
                position: "absolute",
                height: "120px",
                width: circleImage ? "150px" : " 15rem",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                borderRadius: circleImage ? "75px" : "8px",
              }}
            />
            <div
              style={{
                position: "absolute",
                right: -10,
                bottom: 0,
                zIndex: 300,
              }}
            >
              <Upload
                multiple={false}
                accept="image/*"
                showUploadList={false}
                beforeUpload={beforeUploadBack}
              >
                <ReUploadIcon />
              </Upload>
            </div>
          </div>
        ) : null}
        <br /> */}
      </Spin>
    </div>
  );
};

ImgPickerNomineeCitizenship.defaultProps = {
  circleImage: false,
  label: "Add/ Clear Image",
};

export default ImgPickerNomineeCitizenship;
