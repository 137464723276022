import React, {
  Dispatch,
  SetStateAction,
  useEffect,
} from 'react';

import { Col } from 'antd';
import CommonImagePicker from 'Components/CommonImagePicker';
import LabelWrapper from 'Components/LabelWrapper';
import { useSetKycFormValue } from 'CustomHooks/useSetKycFormValue';
import { useSelector } from 'react-redux';
import { RootState } from 'Redux/RootReducer';

interface ThumbPrintUploadProps {
    setThumbPrintData: Dispatch<SetStateAction<string>>
}

export const ThumbPrintUpload = ({ setThumbPrintData }: ThumbPrintUploadProps) => {
    //var
    const { kycFormValues, } =
        useSelector((state: RootState) => state?.protectedReducer)

    //hooks
    const { store } = useSetKycFormValue()

    useEffect(() => {
        if (kycFormValues.nominee) { //set thumbprint image
            setThumbPrintData(kycFormValues?.nominee_details?.thumbprint)
        }

    }, [kycFormValues, setThumbPrintData])

    const onSelect = (file: any, imageData: any) => {
        if (file) {
            // setImageLoading(true);
            let extension = file?.type.split("/")[1];
            store(extension, file, "nomineeFingerPrint", setThumbPrintData)

        }

    };

    return (
        <Col xs={24} lg={12}>
            <LabelWrapper
                label={`Upload Nominee Thumbprint `}
                // subLabel="Please perform this step under good lighting condition"
                render={
                    <div>
                        <CommonImagePicker
                            onSelect={onSelect}
                            imageUrl={kycFormValues?.nominee_details?.thumbprint ?? ""}
                        />
                    </div>
                }
            />
        </Col>
    )
}
