import {
  useEffect,
  useState,
} from 'react';

import {
  Button,
  Col,
  Form,
  Image,
  Input,
  InputNumber,
  message,
  Progress,
  Row,
  Select,
  Spin,
  Typography,
} from 'antd';
import CloseIcon from 'Assets/Icons/close.svg';
import ImageIcon from 'Assets/Icons/image.png';
import BoidLetterUpload from 'Components/BoidLetterUpload';
import BoidSampleModal from 'Components/BoidSampleModal';
import LabelWrapper from 'Components/LabelWrapper';
import SelectLabelDisplay from 'Components/SelectLabelDisplay';
import { StoragePut } from 'Components/StoragePut';
import { useSetKycFormValue } from 'CustomHooks/useSetKycFormValue';
import dayjs from 'dayjs';
import {
  Boid,
  IPageProps,
} from 'Interfaces/Page';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import {
  clearSuccess,
  getTradingPdfDataRequest,
  setKycFormValues,
  updateKycDetailRequest,
} from 'Redux/Protected/Action';
import { RootState } from 'Redux/RootReducer';
import { VALIDATIONS } from 'Utils/formValidationRules';
import { s3Paths } from 'Utils/s3paths';
import RenderFooter from 'Views/Dmat/components/RenderFooter';
import ProgressbarLayout from 'Views/Dmat/Layout/ProgressbarLayout';

const InitialValues: Boid = {
    boid: '',
    email: '',
    reference_number: '',
    referral_id: null,
    online: true,
}

const onlineOptions = [
    { label: 'Online', value: true },
    { label: 'Offline', value: false },
]

const BoidComp = ({ history, pageNumber }: IPageProps) => {
    //var
    const {
        kycFormValues,
        actionLoading,
        kycDetails,
        success,
        currentUserId,
        globalTimer,
        pdfReaderLoading
    } = useSelector((state: RootState) => state?.protectedReducer);

    //hooks
    const dispatch = useDispatch();
    const [form] = Form.useForm()
    const { oid } = useSetKycFormValue()

    //state
    const [imageLoading, setImageLoading] = useState<any>(false);
    const [imagePercent, setImagePercent] = useState<any>(0);
    const [showSample, setShowSample] = useState(false);
    const [pdfReaderData, setPdfReaderData] = useState("")
    const [formValues, setFormValues] = useState(InitialValues)


    useEffect(() => {
        if (success) {
            history.push(`/app/${oid}/trading`);
        }
        return () => {
            dispatch(clearSuccess());
        };
    }, [success, history, dispatch, oid]);

    useEffect(() => {
        if (kycFormValues?.trading && Object.keys(kycFormValues?.trading).length > 0) {
            let values = {
                boid: kycFormValues?.trading?.boid ?? "",
                email: kycFormValues?.trading?.email ?? "",
                reference_number: kycFormValues?.reference_number
                    ? kycFormValues.reference_number.replace("+977", "").replace("-", "")
                    : "",
                referral_id: kycFormValues?.trading?.referral_id,
                online: kycFormValues?.trading?.online,
            }
            setFormValues(values)
            form.setFieldsValue(values)
            if (kycFormValues?.trading.boid_pdf) {
                setPdfReaderData(kycFormValues?.trading.boid_pdf)
                setImagePercent(100);
            } else {
                setImagePercent(0)
            }
        }

    }, [kycFormValues, form])


    const toggleSampleModal = () => setShowSample((prev) => !prev);

    const onSelect = (file: any, imageData: any) => {
        setImagePercent(100);

        setImageLoading(true);

        let extension = file?.type.split("/")[1];
        StoragePut({
            directoryPath: `${s3Paths({
                currentUserId,
                mainId: kycDetails?.id,
                type: "boid_pdf",
            })}/${dayjs().format("YYMMDD-HHmmss")}.${extension}`,
            fileObj: file,
            finalCB: () => {
                setImageLoading(false);
            },
            successCB: (data) => {
                setImageLoading(false);
                setPdfReaderData(data)
                dispatch(getTradingPdfDataRequest(data));
            },
        })

    };

    const resetImage = () => {
        setImagePercent(0);
        setPdfReaderData("")
    };

    const handleNumberChange = (e: number, name: string) => {
        setFormValues({ ...formValues, [name]: e })
    }

    const onSelectChange = (e: any) => {
        setFormValues({ ...formValues, online: e })
    }

    const handleInputChange = (e: any) => {
        setFormValues({ ...formValues, [e.target.name]: e.target.value })
    }

    const backHandler = () => {
        history.push(`/app/${oid}/form-selection`)
    };

    const submitHandler = () => {
        form
            .validateFields()
            .then((values: any) => {
                submitForm();
            })
            .catch((errorInfo: any) => {

                const element = document.getElementById(`${errorInfo.errorFields[0].name[0]}`)
                element?.scrollIntoView({ behavior: "smooth" })
            });
    };

    const submitForm = async () => {
        if (!pdfReaderData) {
            return message.error("Please upload your BOID")
        }

        let submitValues = {
            trading: { ...kycFormValues.trading, boid_pdf: pdfReaderData, boid: formValues.boid, email: formValues.email, online: formValues.online },
            reference_number: `+977${formValues?.reference_number ?? ""
                .replace("+977", "")
                .replace("-", "")}`
        }
        if (formValues.referral_id) {
            submitValues = {
                ...submitValues,
                trading: {
                    ...submitValues.trading,
                    referral_id: formValues.referral_id,
                }
            }
        }

        dispatch(
            setKycFormValues({
                ...kycFormValues,
                ...submitValues
            })
        );
        dispatch(
            updateKycDetailRequest({
                ...submitValues,
            })
        );
    };


    return (
        <ProgressbarLayout
            progressLeftText={'0%'}
            progressRightText={globalTimer}
            progressPercent={0}
            iconTitle={
                'TRADING'
            }
            title=''
            animationIn={history.action === 'PUSH' ? true : false}
            renderFooter={
                <RenderFooter
                    loading={actionLoading}
                    backHandler={backHandler}
                    submitHandler={submitHandler}
                    showForwardBtn={true}
                />
            }
            path='boid'
        >
            <Spin spinning={imageLoading || pdfReaderLoading}>

                <Row gutter={[16, 16]}>
                    <Col xs={24} lg={8}>
                        <div className='groupWrapper'>
                            <Typography className={`r-text fw-500 text-light-black mb-1 `}>Upload BOID letter </Typography>

                            {imagePercent === 0 ? (
                                <BoidLetterUpload onSelect={onSelect} />
                            ) : (
                                <Col
                                    span="24"
                                    style={{ border: "1px dashed #0AB290" }}
                                    className="br-8 p-1 mb-1"
                                >
                                    <Row className="mb-1">
                                        <Col span="22" className="d-flex">
                                            <Image src={ImageIcon} preview={false} />
                                            <span className="pl-1" style={{ color: "#60706F" }}>
                                                boid.pdf
                                            </span>
                                        </Col>
                                        <Col span="2" className="text-right pointer">
                                            <Image
                                                onClick={resetImage}
                                                src={CloseIcon}
                                                preview={false}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span="22">
                                            {" "}
                                            <Progress
                                                strokeColor="#00DDA2"
                                                showInfo={false}
                                                percent={imagePercent}
                                            />
                                        </Col>
                                        <Col
                                            span="22"
                                            className="text-right"
                                            style={{ marginTop: "-0.4rem" }}
                                        >
                                            {imagePercent}%
                                        </Col>
                                    </Row>
                                </Col>
                            )}

                            <Button onClick={toggleSampleModal}>Show Sample</Button>
                        </div>
                        <BoidSampleModal isVisible={showSample} closeModal={toggleSampleModal} />
                    </Col>
                    <Col xs={24} lg={16} >
                        <div className='groupWrapper'>
                            <Typography className={`r-text fw-500 text-light-black `}>BOID Information </Typography>

                            <div className='mb-1'>
                                <span className='xs-text  fw-500 text-warning '>
                                    Please verify the following information
                                </span>
                            </div>

                            <Form
                                form={form}
                                layout='vertical'
                                name='basic'
                                onFinish={submitHandler}
                            >
                                <LabelWrapper
                                    label='BOID'
                                    render={
                                        <Form.Item
                                            name='boid'
                                            rules={VALIDATIONS.boid}
                                            initialValue={formValues.boid}
                                        >
                                            <Input
                                                style={{ width: '100%' }}
                                                bordered={false}
                                                className='underlineInput'
                                                name='boid'
                                                type='number'
                                                // onChange={(e) => handleNumberChange(e, "boid")}
                                                onChange={handleInputChange}
                                                value={formValues.boid}
                                                disabled={
                                                    kycFormValues?.form?.meroshare &&
                                                    !kycFormValues?.form?.demat
                                                }
                                            />
                                        </Form.Item>
                                    }
                                />

                                <LabelWrapper
                                    label='Email'
                                    render={
                                        <Form.Item
                                            name='email'
                                            rules={VALIDATIONS.email}
                                            initialValue={formValues.email}
                                        >
                                            <Input
                                                name='email'
                                                // value={formValues.email}
                                                onChange={handleInputChange}
                                                bordered={false}
                                                className='underlineInput'
                                            />
                                        </Form.Item>
                                    }
                                />
                                <LabelWrapper
                                    label='Alternate Number'
                                    render={
                                        <Form.Item
                                            name='reference_number'
                                            rules={VALIDATIONS.reference_number}
                                            initialValue={formValues.reference_number}
                                        >
                                            <Input
                                                name='reference_number'
                                                onChange={handleInputChange}
                                                bordered={false}
                                                className='underlineInput'
                                            />
                                        </Form.Item>
                                    }
                                />

                                <LabelWrapper
                                    label='Select type of trading account'
                                    render={
                                        <Form.Item
                                            name='online'
                                            rules={VALIDATIONS.account_type}
                                            initialValue={formValues.online}
                                        >
                                            <Select
                                                onChange={onSelectChange}
                                                optionLabelProp='customlabel'
                                                className='underlineSelect'
                                            >
                                                {onlineOptions.map((each: any, index: number) => {
                                                    return (
                                                        <Select.Option
                                                            key={index}
                                                            value={each.value}
                                                            customlabel={<div>{each.label}</div>}
                                                        >
                                                            <SelectLabelDisplay
                                                                label={each.label}
                                                                value={each.value}
                                                                currentVal={formValues.online}
                                                            />
                                                        </Select.Option>
                                                    )
                                                })}
                                            </Select>
                                        </Form.Item>
                                    }
                                />

                                <LabelWrapper
                                    label='Referred by - Client Code (Optional)'
                                    render={
                                        <Form.Item
                                            name='referral_id'
                                            rules={VALIDATIONS.referral_id}
                                            initialValue={formValues.referral_id}
                                        >
                                            <InputNumber
                                                style={{ width: '100%' }}
                                                bordered={false}
                                                className='underlineInput'
                                                name='referral_id'
                                                type='text'
                                                onChange={(e) => handleNumberChange(e, 'referral_id')}
                                                value={formValues.referral_id}
                                            />
                                        </Form.Item>
                                    }
                                />
                            </Form>
                        </div>
                    </Col>
                </Row>
            </Spin>


        </ProgressbarLayout>
    );
};

export default BoidComp;
