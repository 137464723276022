import dayjs from 'dayjs';
import { SignatureRemover } from 'Interfaces/action';
import { checkName2 } from 'Utils/checkName';
import { findGender } from 'Utils/findGender';
import { findMobileNumber } from 'Utils/findMobileNumber';
import { similarPercent } from 'Utils/similarPercent';

import {
  ADD_CREATE_USER_FAILURE,
  ADD_CREATE_USER_REQUEST,
  ADD_CREATE_USER_SUCCESS,
  ADD_KYC_DETAIL_FAILURE,
  ADD_KYC_DETAIL_REQUEST,
  ADD_KYC_DETAIL_SUCCESS,
  ADD_PDF_READER_FAILURE,
  ADD_PDF_READER_REQUEST,
  ADD_PDF_READER_SUCCESS,
  CLEAR_PDF_SUCCESS,
  CLEAR_SUCCESS,
  COMPLETED_USER_PDF_FAILURE,
  COMPLETED_USER_PDF_REQUEST,
  COMPLETED_USER_PDF_SUCCESS,
  FORCE_RELOAD_REQUEST,
  GET_DEMATPDF_FAILURE,
  GET_DEMATPDF_REQUEST,
  GET_DEMATPDF_SUCCESS,
  GET_KYC_DETAIL_FAILURE,
  GET_KYC_DETAIL_REQUEST,
  GET_KYC_DETAIL_SUCCESS,
  GET_PAYMENT_DETAILS,
  GET_PAYMENT_DETAILS_FAILURE,
  GET_PAYMENT_DETAILS_SUCCESS,
  GET_TRADING_PDFDATA_FAILURE,
  GET_TRADING_PDFDATA_REQUEST,
  GET_TRADING_PDFDATA_SUCCESS,
  PAYMENT_FAILURE,
  PAYMENT_INIT,
  PAYMENT_SUCCESS,
  REQUEST_KHALTI_URL,
  REQUEST_KHALTI_URL_FAILURE,
  REQUEST_KHALTI_URL_SUCCESS,
  RESET_COMPLETED_PDF_LINK,
  RESET_CONTINUE_FORM_ID,
  RESET_CREATE_USER_SUCCESS,
  RESET_GLOBAL_TIMER,
  RESET_MAIN_SIGNATURE,
  RESET_PAYMENT_DETAILS,
  RESET_PAYMENT_YEARS,
  RESET_PDF_LINK,
  SCHEDULE_DATE_TIME,
  SCHEDULE_DATE_TIME_FAILURE,
  SCHEDULE_DATE_TIME_SUCCESS,
  SEND_EMAIL_REQUEST,
  SET_CURRENT_USER,
  SET_GLOBAL_TIMER,
  SET_INITIAL_GLOBAL_TIMER,
  SET_KYC_FORM_VALUES,
  SET_NEW_KYC_FORM_VALUES,
  SET_PAYMENT_YEARS,
  SIGNATURE_BACKGOUND_REMOVER_FAILURE,
  SIGNATURE_BACKGOUND_REMOVER_REQUEST,
  SIGNATURE_BACKGOUND_REMOVER_SUCCESS,
  TOGGLE_IMAGE_LOADER,
  TOGGLE_IMAGE_LOADER2,
  UPDATE_KYC_DETAIL_FAILURE,
  UPDATE_KYC_DETAIL_FAILURE_2,
  UPDATE_KYC_DETAIL_FAILURE_WITHOUT_FLAG,
  UPDATE_KYC_DETAIL_REQUEST,
  UPDATE_KYC_DETAIL_REQUEST_2,
  UPDATE_KYC_DETAIL_REQUEST_WITHOUT_FLAG,
  UPDATE_KYC_DETAIL_SUCCESS,
  UPDATE_KYC_DETAIL_SUCCESS_2,
  UPDATE_KYC_DETAIL_SUCCESS_WITHOUT_FLAG,
} from './Constant';

export interface Action {
  type:
  | typeof ADD_KYC_DETAIL_REQUEST
  | typeof ADD_KYC_DETAIL_SUCCESS
  | typeof ADD_KYC_DETAIL_FAILURE
  | typeof UPDATE_KYC_DETAIL_REQUEST
  | typeof UPDATE_KYC_DETAIL_REQUEST_2
  | typeof UPDATE_KYC_DETAIL_SUCCESS
  | typeof UPDATE_KYC_DETAIL_SUCCESS_2
  | typeof UPDATE_KYC_DETAIL_FAILURE_2
  | typeof RESET_CONTINUE_FORM_ID
  | typeof UPDATE_KYC_DETAIL_FAILURE
  | typeof UPDATE_KYC_DETAIL_REQUEST_WITHOUT_FLAG
  | typeof UPDATE_KYC_DETAIL_SUCCESS_WITHOUT_FLAG
  | typeof UPDATE_KYC_DETAIL_FAILURE_WITHOUT_FLAG
  | typeof GET_KYC_DETAIL_REQUEST
  | typeof GET_KYC_DETAIL_SUCCESS
  | typeof GET_KYC_DETAIL_FAILURE
  | typeof SET_KYC_FORM_VALUES
  | typeof CLEAR_SUCCESS
  | typeof PAYMENT_INIT
  | typeof PAYMENT_SUCCESS
  | typeof PAYMENT_FAILURE
  | typeof GET_DEMATPDF_REQUEST
  | typeof GET_DEMATPDF_SUCCESS
  | typeof GET_DEMATPDF_FAILURE
  | typeof CLEAR_PDF_SUCCESS
  | typeof SET_CURRENT_USER
  | typeof ADD_CREATE_USER_REQUEST
  | typeof ADD_CREATE_USER_SUCCESS
  | typeof ADD_CREATE_USER_FAILURE
  | typeof RESET_CREATE_USER_SUCCESS
  | typeof SET_NEW_KYC_FORM_VALUES
  | typeof FORCE_RELOAD_REQUEST
  | typeof SET_GLOBAL_TIMER
  | typeof SET_INITIAL_GLOBAL_TIMER
  | typeof RESET_GLOBAL_TIMER
  | typeof ADD_PDF_READER_REQUEST
  | typeof ADD_PDF_READER_SUCCESS
  | typeof ADD_PDF_READER_FAILURE
  | typeof TOGGLE_IMAGE_LOADER
  | typeof TOGGLE_IMAGE_LOADER2
  | typeof GET_TRADING_PDFDATA_REQUEST
  | typeof GET_TRADING_PDFDATA_SUCCESS
  | typeof GET_TRADING_PDFDATA_FAILURE
  | typeof SIGNATURE_BACKGOUND_REMOVER_REQUEST
  | typeof SIGNATURE_BACKGOUND_REMOVER_SUCCESS
  | typeof SIGNATURE_BACKGOUND_REMOVER_FAILURE
  | typeof RESET_MAIN_SIGNATURE
  | typeof COMPLETED_USER_PDF_REQUEST
  | typeof COMPLETED_USER_PDF_SUCCESS
  | typeof COMPLETED_USER_PDF_FAILURE
  | typeof RESET_PDF_LINK
  | typeof RESET_COMPLETED_PDF_LINK
  | typeof SET_PAYMENT_YEARS
  | typeof RESET_PAYMENT_YEARS
  | typeof SEND_EMAIL_REQUEST
  | typeof SCHEDULE_DATE_TIME
  | typeof SCHEDULE_DATE_TIME_SUCCESS
  | typeof SCHEDULE_DATE_TIME_FAILURE
  | typeof REQUEST_KHALTI_URL
  | typeof REQUEST_KHALTI_URL_SUCCESS
  | typeof REQUEST_KHALTI_URL_FAILURE
  | typeof GET_PAYMENT_DETAILS
  | typeof GET_PAYMENT_DETAILS_SUCCESS
  | typeof GET_PAYMENT_DETAILS_FAILURE
  | typeof RESET_PAYMENT_DETAILS;

  payload?: any;
}

interface Message {
  errorMessage?: string;
  successMessage?: string;
  userName?: string;
  message?: string;
}

export const addKycDetailRequest = (formValues: any): Action => ({
  type: ADD_KYC_DETAIL_REQUEST,
  payload: formValues,
});
//forceReloadRequest : used only as a hack to force renrender, can be removed now (test first)
export const forceReloadRequest = (): Action => ({
  type: FORCE_RELOAD_REQUEST,
});

export const addKycDetailSuccess = (payload: any): Action => ({
  type: ADD_KYC_DETAIL_SUCCESS,
  payload,
});

export const addKycDetailFailure = (errorMessage: Message): Action => ({
  type: ADD_KYC_DETAIL_FAILURE,
  payload: { message: errorMessage },
});

//update each page request
export const updateKycDetailRequest = (formValues: any): Action => ({
  type: UPDATE_KYC_DETAIL_REQUEST,
  payload: { formValues },
});

export const updateKycDetailSuccess = (payload: any): Action => ({
  type: UPDATE_KYC_DETAIL_SUCCESS,
  payload,
});

export const updateKycDetailFailure = (errorMessage: Message): Action => ({
  type: UPDATE_KYC_DETAIL_FAILURE,
  payload: { message: errorMessage },
});

/**
 *
 * @param userId
 * @param oid
 * @param formValues
 *  used only in profile page, to continue filling up new forms
 *  This is present in the modal if the previous forms have already been completed
 *  see : fillUpTradingHandler, fillUpMeroshareHandler in Profile.tsx
 */

export const updateKycDetailRequest2 = (
  oid: string,
  formValues: any
): Action => ({
  type: UPDATE_KYC_DETAIL_REQUEST_2,
  payload: { oid, formValues },
});

export const updateKycDetailSuccess2 = (payload: any): Action => ({
  type: UPDATE_KYC_DETAIL_SUCCESS_2,
  payload,
});
export const updateKycDetailFailure2 = (errorMessage: Message): Action => ({
  type: UPDATE_KYC_DETAIL_FAILURE_2,
  payload: { message: errorMessage },
});

export const clearContinueFormId = () => ({
  type: RESET_CONTINUE_FORM_ID,
});

/**
 *
 * @param userId uid of the main user
 * @param oid oid of the user, currenntly not in use
 * @param formValues
 * success flag concept: we are using success flag which is set in the reducer to handle redirect from within each page
 * for rescan bank cheque
 * withoutsuccessFlag concept: after uploading image to s3, we submit the success response to our backend server,
 * we don't want to go to next page here, so here we dont set the success flag in our reducer
 */
export const updateKycDetailRequestWithoutSuccessFlag = (
  formValues: any
): Action => ({
  type: UPDATE_KYC_DETAIL_REQUEST_WITHOUT_FLAG,
  payload: { formValues },
});
export const updateKycDetailSuccessWithoutSuccessFlag = (
  payload: any
): Action => ({
  type: UPDATE_KYC_DETAIL_SUCCESS_WITHOUT_FLAG,
  payload,
});
export const updateKycDetailFailureWithoutSuccessFlag = (
  errorMessage: Message
): Action => ({
  type: UPDATE_KYC_DETAIL_FAILURE_WITHOUT_FLAG,
  payload: { message: errorMessage },
});

/**
 *
 * @param id
 * Gets KycDetails of the Current User
 * @returns
 */
export const getKycDetailRequest = (id: any): Action => ({
  type: GET_KYC_DETAIL_REQUEST,
  payload: id,
});

export const getKycDetailSuccess = (payload: any): Action => ({
  type: GET_KYC_DETAIL_SUCCESS,
  payload,
});

export const getKycDetailFailure = (errorMessage: Message): Action => ({
  type: GET_KYC_DETAIL_FAILURE,
  payload: { message: errorMessage },
});

/**
 *
 * @param formValues
 * setKycFormValues concept
 * In each form page, as we are submitting to the server, we maintain a local copy of that state on our reducer
 * sets the value inside kycFormValues
 * this function doesnot replace the previous values inside kycFormValues, it merges it
 */

export const setKycFormValues = (formValues: any): Action => ({
  type: SET_KYC_FORM_VALUES,
  payload: formValues,
});

/**
 * @param formValues
 * setNewKycFormValues concept
 * used to completely replace the reducer state of kycFromValues
 * different from setKycFormValues as this replaces the entire kycFormValues field with given params
 * used for reseting the state by simply setting setNewKycFormValues({})
 */

export const setNewKycFormValues = (formValues: any): Action => ({
  type: SET_NEW_KYC_FORM_VALUES,
  payload: formValues,
});

/**
 * clear the success flag on each unmount
 */
export const clearSuccess = (): any => ({
  type: CLEAR_SUCCESS,
});

export const initPayments = (
  userId: string,
  oid: string,
  formValues: any
): Action => ({
  type: PAYMENT_INIT,
  payload: { userId, oid, formValues },
});

export const paymentSuccess = (payload: any): any => ({
  type: PAYMENT_SUCCESS,
  payload,
});

export const paymentFailure = (errorMessage: Message): any => ({
  type: PAYMENT_FAILURE,
  payload: { message: errorMessage },
});

//following two apis used for formcompletion screens, tala pani arko euta gareko chu, need to refactor paachi
export const getDematPdfLink = (
  userId: string,
  oid: string,
  target: string
): Action => ({
  type: GET_DEMATPDF_REQUEST,
  payload: { userId, oid, target },
});

export const dematPdfSuccess = (payload: any): any => ({
  type: GET_DEMATPDF_SUCCESS,
  payload,
});

export const dematPdfFailure = (errorMessage: Message): any => ({
  type: GET_DEMATPDF_FAILURE,
  payload: { message: errorMessage },
});

export const clearPdfSuccess = () => ({
  type: CLEAR_PDF_SUCCESS,
});

export const setCurrentUser = (userId: string) => ({
  type: SET_CURRENT_USER,
  payload: { userId },
});

export const resetCreateUserSuccess = () => ({
  type: RESET_CREATE_USER_SUCCESS,
});

export const createNewUserRequest = (
  userId: string,
  formValues: any
): Action => ({
  type: ADD_CREATE_USER_REQUEST,
  payload: { userId, formValues },
});

export const createNewUserSuccess = () => ({
  type: ADD_CREATE_USER_SUCCESS,
});
export const createNewUserFailure = () => ({
  type: ADD_CREATE_USER_FAILURE,
});

export const setGlobalTimer = () => ({
  type: SET_GLOBAL_TIMER,
});
export const setInitialGlobalTImer = (timeInSeconds: number) => ({
  type: SET_INITIAL_GLOBAL_TIMER,
  payload: timeInSeconds,
});

export const resetGlobalTimer = () => ({
  type: RESET_GLOBAL_TIMER,
});

export const postPdfReader = (url: string) => ({
  type: ADD_PDF_READER_REQUEST,
});

export const pdfReaderSuccess = () => ({
  type: ADD_PDF_READER_SUCCESS,
});
export const pdfReaderFailure = () => ({
  type: ADD_PDF_READER_FAILURE,
});

export const toggleImageLoader = (isLoading: boolean) => ({
  type: TOGGLE_IMAGE_LOADER,
  payload: isLoading,
});
export const toggleImageLoader2 = (isLoading: boolean) => ({
  type: TOGGLE_IMAGE_LOADER2,
  payload: isLoading,
});

export const getTradingPdfDataRequest = (
  pdfUrl: string,
  callbackFn: Function = () => { }
) => ({
  type: GET_TRADING_PDFDATA_REQUEST,
  payload: pdfUrl,
  callbackFn,
});
export const getTradingPdfDataSuccess = (
  payload: any,
  bankArray: Array<any> = [],
  currentUserName: string = ""
) => {
  checkName2(payload?.Name ?? "", currentUserName ?? "");
  //find bank
  let matchedBankValue: any = "";
  let bankData = [...bankArray];
  bankData.map((each: any) => {
    for (var i = 0; i < bankData?.length; i++) {
      if (
        similarPercent(
          each?.name ?? "",
          payload?.["Bank Name"] === "N/A" ? "" : payload?.["Bank Name"] ?? ""
        ) > 65
      ) {
        matchedBankValue = each;
        break;
      }
    }
  });
  const finalBankName = matchedBankValue?.name ?? "";
  const finalPayload = {
    boid: payload?.BOID ?? "",
    email: payload?.Email === "N/A" ? "" : payload?.Email?.toLowerCase() ?? "",
    phone: findMobileNumber(payload?.["Contact Number"]) ?? "",
    // full_name: currentUserName ?? "",
    dob: payload?.["Date Of Birth"] ?? dayjs().format("YYYY-MM-DD"),
    gender: findGender(payload?.Gender ?? ""),
    issued_district:
      payload?.["Issued District "] === "N/A"
        ? ""
        : payload?.["Issued District "] ?? "",
    citizenship_id: payload?.["Citizenship No"] ?? "",
    father_name:
      payload?.["Father's Name"] === "N/A"
        ? ""
        : payload?.["Father's Name"] ?? "",
    mother_name:
      payload?.["Mother's Name"] === "N/A"
        ? ""
        : payload?.["Mother's Name"] ?? "",
    grandfather_name:
      payload?.["GrandFather's"] === "N/A"
        ? ""
        : payload?.["GrandFather's"] ?? "",
    spouse_name:
      payload?.["Spouse Name"] === "N/A" ? "" : payload?.["Spouse Name"] ?? "",
    bank_name: finalBankName ?? "",
    branch: "",
    account_number:
      payload?.["Account Number"] === "N/A"
        ? ""
        : payload?.["Account Number"] ?? "",
    account_type: "",
  };
  return {
    type: GET_TRADING_PDFDATA_SUCCESS,
    payload: finalPayload,
  };
};

export const getTradingPdfDataFailure = () => ({
  type: GET_TRADING_PDFDATA_FAILURE,
});

export const signatureBackgroundRemoverRequest = (
  payload: SignatureRemover,
  isMainSignature: boolean = false
) => ({
  type: SIGNATURE_BACKGOUND_REMOVER_REQUEST,
  payload: payload,
  isMainSignature,
});

export const signatureBackgroundRemoverSuccess = (
  updatedSignature: string
) => ({
  type: SIGNATURE_BACKGOUND_REMOVER_SUCCESS,
  payload: updatedSignature,
});
export const signatureBackgroundRemoverFailure = () => ({
  type: SIGNATURE_BACKGOUND_REMOVER_FAILURE,
});

export const resetMainSignature = () => ({
  type: RESET_MAIN_SIGNATURE,
});

//callback ko concept is used here
export const completedUserPdfRequest = (
  userId: string,
  oid: string,
  target: string,
  userName: string
  // callbackFn: Function
) => ({
  type: COMPLETED_USER_PDF_REQUEST,
  payload: { userId, oid, target, userName },
  // callbackFn,
});
export const completedUserPdfSuccess = (pdfLink: string) => ({
  type: COMPLETED_USER_PDF_SUCCESS,
  payload: pdfLink,
});
export const completedUserPdfFailure = () => ({
  type: COMPLETED_USER_PDF_FAILURE,
});

export const resetPdfLink = () => ({
  type: RESET_PDF_LINK,
});
export const resetCompletedUserPdfLink = () => ({
  type: RESET_COMPLETED_PDF_LINK,
});

export const setPaymentYears = (numOfYears: number) => ({
  type: SET_PAYMENT_YEARS,
  payload: numOfYears,
});

export const resetPaymentYears = () => ({
  type: RESET_PAYMENT_YEARS,
});

export const sendEmailRequest = (oid: string, message: string) => ({
  type: SEND_EMAIL_REQUEST,
  payload: { oid, message },
});

export const scheduleDateTime = (dateTime: string | null): Action => ({
  type: SCHEDULE_DATE_TIME,
  payload: { dateTime },
});

export const scheduleDateTimeSuccess = (): Action => ({
  type: SCHEDULE_DATE_TIME_SUCCESS,
});

export const scheduleDateTimeFailure = (): Action => ({
  type: SCHEDULE_DATE_TIME_FAILURE,
});

export const requestKhaltiUrl = (payload: any) => ({
  type: REQUEST_KHALTI_URL,
  payload: payload,
});

export const requestKhaltiUrlSuccess = (payload: any) => ({
  type: REQUEST_KHALTI_URL_SUCCESS,
  payload: payload,
});

export const requestKhaltiUrlFailure = () => ({
  type: REQUEST_KHALTI_URL_FAILURE,
});

export const getPaymentDetails = (id: string) => ({
  type: GET_PAYMENT_DETAILS,
  payload: id
})

export const getPaymentDetailsSuccess = (payload: any) => ({
  type: GET_PAYMENT_DETAILS_SUCCESS,
  payload: payload
})

export const getPaymentDetailsFailure = () => ({
  type: GET_PAYMENT_DETAILS_FAILURE,
})

export const resetPaymentDetails = () => ({
  type: RESET_PAYMENT_DETAILS
})


