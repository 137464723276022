import React, {
  useCallback,
  useEffect,
  useState,
} from 'react';

import {
  Col,
  Form,
  Row,
} from 'antd';
import { useSetKycFormValue } from 'CustomHooks/useSetKycFormValue';
import dayjs from 'dayjs';
import {
  DepositoryParticipant,
  IPageProps,
  MinorDetails,
} from 'Interfaces/Page';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import {
  clearSuccess,
  setKycFormValues,
  updateKycDetailRequest,
} from 'Redux/Protected/Action';
import { RootState } from 'Redux/RootReducer';
import RenderFooter from 'Views/Dmat/components/RenderFooter';
import ProgressbarLayout from 'Views/Dmat/Layout/ProgressbarLayout';

import { DpInfo } from './Components/DpInfo/DpInfo';
import { Minor } from './Components/Minor/Minor';
import { MinorForm } from './Components/MinorForm/MinorForm';

const DpInitialValues: DepositoryParticipant = {
    name: "ABC Securities Pvt. Ltd.",
    branch: "Kathmandu",
    account_type: "Individual",
};

const InitialValues: MinorDetails = {
    birth_certificate_number: "",
    issued_date: null,
    issued_district: "",
    issued_np: "",
    dob: "",
    dob_np: ""
};

export const Initial = ({ history, pageNumber }: IPageProps) => {
    //var
    const dispatch = useDispatch()
    const { kycFormValues, kycDetails, success, actionLoading, globalTimer, currentUserId } =
        useSelector((state: RootState) => state?.protectedReducer)
    //hooks
    const { oid, store } = useSetKycFormValue()
    const [form] = Form.useForm();

    //state
    const [dpFormValues, setDpFormValues] = useState(DpInitialValues)
    const [minorFormValues, setMinorFormValues] = useState(InitialValues);
    const [isMinor, setIsMinor] = useState<boolean>(false)
    const [selectedMinorProfile, setSelectedMinorProfile] = useState<any>(null)
    const [selectedMinorCertificate, setSelectedMinorCertificate] = useState<any>(null)
    const [minorCertificateData, setMinorCertificateData] = useState("")
    const [profileDataMinor, setProfileDataMinor] = useState("")


    const initMinorFormValues = useCallback(() => { // set minor form values
        if (kycFormValues.minor_details && Object.keys(kycFormValues.minor_details).length > 0) {
            const { minor_details, username } = kycFormValues;
            const values = {
                birth_certificate_number: minor_details.birth_certificate_number,
                issued_date: minor_details.issued_date ? dayjs(minor_details.issued_date) : null,
                issued_district: minor_details.issued_district,
                issued_np: minor_details.issued_np,
                dob: minor_details.dob ? dayjs(minor_details.dob) : null,
                gender: minor_details.gender ?? "",
                dob_np: minor_details.dob_np ?? "",
                // name: username
            };
            setMinorCertificateData(minor_details.birth_certificate_image);
            setProfileDataMinor(minor_details.profile);
            setMinorFormValues(values);
            form.setFieldsValue(values);
        }
    }, [kycFormValues, form])


    const initDPFormValues = useCallback(() => { // set dp form values
        if (kycFormValues.depository_participant && Object.keys(kycFormValues.depository_participant).length) {
            const { depository_participant } = kycFormValues;
            const values = {
                // name: depository_participant.name ?? "ABC Securities Pvt. Ltd.",
                branch: depository_participant.branch ?? "Kathmandu",
                account_type: depository_participant.account_type ?? "Individual"
            };
            setDpFormValues(values);
            form.setFieldsValue(values);
        }
    }, [kycFormValues, form])

    useEffect(() => {
        if (success) {
            //redirect after success
            if (history.location.pathname.includes('dematt')) {
                history.push(`/app/${oid}/dematt/your-details`)
            } else {
                history.push(`/app/${oid}/trading/your-details`)
            }
        }
        return () => {
            dispatch(clearSuccess())
        }
    }, [success, dispatch, history, oid])

    

    useEffect(() => {
        // Check for minor case
        if (kycFormValues.minor ) {
            setIsMinor(true);
            initMinorFormValues();
        } else {
            setIsMinor(false);
        }
        // Set form values for depository participant
        initDPFormValues();
    }, [kycFormValues, initDPFormValues, initMinorFormValues,])

    useEffect(() => {
        if(isMinor) {
            initMinorFormValues();

        }
    }, [isMinor, initMinorFormValues])


    useEffect(() => { //store minor profile to aws
        if (selectedMinorProfile) {
            let extension = selectedMinorProfile?.type?.split("/")[1];
            store(extension, selectedMinorProfile, "minorProfile", setProfileDataMinor)
        }
    }, [selectedMinorProfile, store])

    useEffect(() => { //store birth certificate to aws
        if (selectedMinorCertificate) {
            let extension = selectedMinorCertificate?.type?.split("/")[1];
            store(extension, selectedMinorCertificate, "birthCertificate", setMinorCertificateData)
        }
    }, [selectedMinorCertificate, store])

    useEffect(() => {
        if (minorCertificateData) {//set form fields values
            form.setFieldsValue({ "birth_certificate": minorCertificateData })
        }
    }, [minorCertificateData, form])
    useEffect(() => {
        if (profileDataMinor) {//set form fields values
            form.setFieldsValue({ "minor_profile": profileDataMinor })
        }
    }, [profileDataMinor, form])

    //back handler
    const backHandler = () => {
        if (history.location.pathname.includes("dematt")) {

            history.push(`/app/${oid}/form-selection`)
        } else {
            history.push(`/app/${oid}/trading/boid`)
        }
    }

    //submit handler
    const submitHandler = () => {
        form
            .validateFields() //check for validation
            .then(() => {
                submitForm();
            })
            .catch((errorInfo: any) => {
                const element = document.getElementById(`${errorInfo.errorFields[0].name[0]}`)
                element?.scrollIntoView({ behavior: "smooth" })
            });

    };

    const submitForm = () => {
        let submitValues: any = {
            depository_participant: {
                account_type: dpFormValues.account_type,
                branch: dpFormValues.branch,
                name: "ABC Securities Pvt. Ltd."
            },
        }

        if (isMinor) {
            submitValues = {
                ...submitValues,
                minor: true,
                minor_details: {
                    birth_certificate_image: minorCertificateData,
                    profile: profileDataMinor,
                    issued_date: dayjs(minorFormValues.issued_date).format("YYYY-MM-DD"),
                    birth_certificate_number: minorFormValues.birth_certificate_number,
                    dob: dayjs(minorFormValues.dob).format("YYYY-MM-DD"),
                    gender: minorFormValues.gender,
                    dob_np: minorFormValues.dob_np,
                    issued_np: minorFormValues.issued_np,
                    name: kycFormValues.username,
                    issued_district: minorFormValues.issued_district,
                }
            }
        } else {
            submitValues = {
                ...submitValues,
                minor: false
            }
        }
        dispatch(
            setKycFormValues({
                ...kycFormValues,
                ...submitValues
            }))

        dispatch(updateKycDetailRequest({
            ...submitValues,

        }))
    }



    return (
        <ProgressbarLayout
            progressLeftText={'2%'}
            progressRightText={globalTimer}
            progressPercent={history.location.pathname.includes('dematt') ? 1 : 3}
            iconTitle={
                history.location.pathname.includes('dematt') ? 'DEMAT' : 'TRADING'
            }
            title=''
            animationIn={history.action === 'PUSH' ? true : false}
            renderFooter={
                <RenderFooter
                    loading={actionLoading}
                    backHandler={backHandler}
                    submitHandler={submitHandler}
                    showForwardBtn={true}
                />
            }
            path='initial'
        >
            <Form name="basic" form={form} layout="vertical" onFinish={submitHandler} validateTrigger={"onsubmit"}>
                <Row gutter={[16, 16]}>
                    <Col xs={24} lg={12}>
                        <Minor isMinor={isMinor} setIsMinor={setIsMinor} />
                    </Col>
                    <Col xs={24} lg={12}>
                        <DpInfo dpFormValues={dpFormValues} setDpFormValues={setDpFormValues} />
                    </Col>
                </Row>
                {
                    isMinor &&

                    <MinorForm
                        setSelectedMinorProfile={setSelectedMinorProfile}
                        setSelectedMinorCertificate={setSelectedMinorCertificate}
                        setFormValues={setMinorFormValues}
                        formValues={minorFormValues}
                        form={form}
                    />

                }
            </Form>

        </ProgressbarLayout>
    )
}
