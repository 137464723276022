import dayjs, { Dayjs } from 'dayjs';

export function disabledDateValidation(current: Dayjs) {
  // Can not select days before today and today
  const startingDate = dayjs("1943-04-14");
  const endingDate = dayjs();

  return !(startingDate.isBefore(current) && endingDate.isAfter(current));

  // return current && current > dayjs().endOf("day");
}

export function disabledDobValidation(current: Dayjs) {
  // Can not select days before today and today
  const eighteenYearsAgoDate = dayjs().subtract(18, "year");
  const endingDate = eighteenYearsAgoDate.startOf("day");
  const startingDate = dayjs().subtract(100, "year");

  return !(startingDate.isBefore(current) && endingDate.isAfter(current));
  // return current && current > dayjs().endOf("day");
}

export function disabledDateValidationMinor(current: Dayjs) {
  const eighteenYearsAgoDate = dayjs().subtract(18, "year");
  const startingDate = eighteenYearsAgoDate.startOf("day");
  const endingDate = dayjs().endOf("day");
  return !(startingDate.isBefore(current) && endingDate.isAfter(current));
}


export function scheduleKycDateValidation(current:Dayjs) {
  return current && (current.isBefore(dayjs()) || current.day() === 6)
}

const range = (start: number, end: number) => {
  const result = [];
  for (let i = start; i < end; i++) {
    result.push(i);
  }
  result.splice(10, 7)
  return result 
};

export function scheduleKycTimeValidation() {
  return (
    {
      disabledHours: () => range(0, 24)
    }
  )
}