import { useEffect, useState } from "react";

import { useSelector } from "react-redux";
import { RootState } from "Redux/RootReducer";

//hook to check whether the current demat form is for demat minor or non-minor

function useCheckMinorStatusHook() {
  const [dematFormType, setDematFormType] = useState<string>("");
  const {
    kycFormValues: { minor = false },
  } = useSelector((state: RootState) => state?.protectedReducer);

  useEffect(() => {
    if (minor) {
      setDematFormType("demat_minor");
    } else {
      setDematFormType("demat");
    }
  }, [minor]);

  return dematFormType;
}

export default useCheckMinorStatusHook;
