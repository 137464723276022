export const accountOptions = [
  { label: "Saving", value: "Saving" },
  { label: "Fixed", value: "Fixed" },
  { label: "Salary", value: "Salary" },
  { label: "Minor", value: "Minor" },
  { label: "Current", value: "Current" },
];
export const tradingAccountOptions = [
  { label: "Current", value: "Current" },
  { label: "Transactional", value: "Transactional" },
  { label: "Settlement", value: "Settlement" },
  { label: "Collateral", value: "Collateral" },
  { label: "Saving", value: "Saving" },
];

export const relationshipOptions = [
  { label: "Son", value: "Son" },
  { label: "Daughter", value: "Daughter" },
  { label: "Brother", value: "Brother" },
  { label: "Sister", value: "Sister" },
  { label: "Mother", value: "Mother" },
  { label: "Father", value: "Father" },
  { label: "Mother-in-law", value: "Mother-in-law" },
  { label: "Father-in-law", value: "Father-in-law" },
  { label: "Other", value: "Other" },
];

export const incomeSourceOptions = [
  { label: "Business Income", value: "Business Income" },
  { label: "Remuneration", value: "Remuneration" },
  { label: "Return on Investment", value: "Return on Investment" },
  { label: "House Rent", value: "House Rent" },
  { label: "Securities Trading", value: "Securities Trading" },
  { label: "Remittance", value: "Remittance" },
  { label: "Inheritance", value: "Inheritance" },
  { label: "Interest", value: "Interest" },
  { label: "Others", value: "Others" },
];

export const occupationOptions = [
  { label: "Service", value: "Service" },
  { label: "Government", value: "Government" },
  { label: "Public/ Private Sector", value: "Public/ Private Sector" },
  { label: "NGO/ INGO", value: "NGO/ INGO" },
  { label: "Expert", value: "Expert" },
  { label: "Business Person", value: "Business Person" },
  { label: "Agriculture", value: "Agriculture" },
  { label: "House Wife", value: "House Wife" },
  { label: "Student", value: "Student" },
  { label: "Retired", value: "Retired" },
];
export const designationOptions = [
  { label: "Director", value: "Director" },
  { label: "Executive", value: "Executive" },
  { label: "Shareholder", value: "Shareholder" },
  { label: "Employee", value: "Employee" },
  { label: "Chairman", value: "Chairman" },
  { label: "PROPRIETOR", value: "PROPRIETOR" },
];

export const personTypeOptions = [
  { label: "Individual", value: "Individual" },
  { label: "Non Resident Nepalese", value: "Non Resident Nepalese" },
  { label: "Foreigner", value: "Foreigner" },
];

export const businessTypeOptions = [
  { label: "Manufacturing", value: "Manufacturing" },
  { label: "Service Oriented", value: "Service Oriented" },
  { label: "Trading", value: "Trading" },
  // { label: "Others", value: "Others" },
];

export const accountStatementOptions = [
  { label: "Daily", value: "Daily" },
  { label: "Weekly", value: "Weekly" },
  { label: "15 days", value: "15 days" },
  { label: "Monthly", value: "Monthly" },
  { label: "Yearly", value: "Yearly" },
];

export const genderOptions = [
  { label: "Male", value: "Male" },
  { label: "Female", value: "Female" },
  { label: "Others", value: "Others" },
];

export const abcBranchOptions = [
  { label: "Kathmandu", value: "Kathmandu" },
  { label: "Dharan", value: "Dharan" },
];

export const childrenOptions = [
  { label: "Son", value: "Son" },
  { label: "Daughter", value: "Daughter" },
  { label: "Others", value: "Others" },
];
