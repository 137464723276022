import { Redirect, RouteComponentProps } from "react-router-dom";

const RouteGuard: React.FC<RouteComponentProps> = (props: any) => {
  if (!(localStorage.getItem("user-logged-in") === "true")) {
    return <Redirect to="/auth/general-details" />;
  }
  return (
    <>
      {localStorage.getItem("user-logged-in") === "true" ? (
        <>
          <Redirect to="/app/profile" />
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default RouteGuard;
