export interface formInterface {
  demat?: boolean;
  demat_status?: string;
  meroshare?: boolean;
  meroshare_status?: string;
  trading?: boolean;
  trading_status?: string;
}
/**
 * 
 * @param form 
 * @returns false if any one of the selected forms is still "N/A" state
 */
export default function checkFormStatus(form: formInterface): boolean {
  let trueCount: number = 0;
  let incompleteCount: number = 0;
  if (form === undefined) return false;

  const {
    demat,
    demat_status,
    meroshare,
    meroshare_status,
    trading,
    trading_status,
  } = form;

  if (demat) {
    trueCount = trueCount + 1;
    if (demat_status === "N/A") incompleteCount = incompleteCount + 1;
  }
  if (meroshare) {
    trueCount = trueCount + 1;
    if (meroshare_status === "N/A") incompleteCount = incompleteCount + 1;
  }
  if (trading) {
    trueCount = trueCount + 1;
    if (trading_status === "N/A") incompleteCount = incompleteCount + 1;
  }

  //all forms are completed
  if (trueCount > 0 && incompleteCount === 0) return true;
  //forms remaining to be completed
  else return false;
}
