import {
  useEffect,
  useState,
} from 'react';

import {
  Checkbox,
  Col,
  message,
  Row,
  Spin,
} from 'antd';
import Progressbar from 'Components/Progressbar';
import StorageGetImage from 'Components/StorageGetImage';
import {
  BlackText,
  GreyText,
  MediumGreyText,
} from 'Components/TermsText';
import ThumbprintBox from 'Components/ThumbprintBox';
import useCheckTradingStatusHook from 'CustomHooks/useCheckTradingStatusHook';
import { useSetKycFormValue } from 'CustomHooks/useSetKycFormValue';
import useTimerHook from 'CustomHooks/useTimerHook';
import { IPageProps } from 'Interfaces/Page';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import {
  clearSuccess,
  setKycFormValues,
  updateKycDetailRequest,
} from 'Redux/Protected/Action';
import { RootState } from 'Redux/RootReducer';
import RenderFooter from 'Views/Dmat/components/RenderFooter';

const TradingTermsNepaliNew = ({ history, pageNumber }: IPageProps) => {
  const dispatch = useDispatch();
  const [agreeVal, setAgreeVal] = useState<boolean>(false);
  const [signatureImage, setSignatureImage] = useState<any>("");
  const [getImageLoading, setGetImageLoading] = useState(false);

  const {
    kycFormValues,
    actionLoading,
    success,
    globalTimer,
    mainSignature,
    currentUserId,
  } = useSelector((state: RootState) => state?.protectedReducer);
  const timeVal = useTimerHook();
  const tradingFormType = useCheckTradingStatusHook();
  const handleCheck = () => {
    setAgreeVal((curr) => !curr);
  };

  const {oid} = useSetKycFormValue()

  const backHandler = () => {
    history.push(`/app/${oid}/trading/terms/1`);
  };
  const submitHandler = () => {
    if (agreeVal) {
      dispatch(
        setKycFormValues({
          ...kycFormValues,
          trading: { ...kycFormValues.trading, terms_conditions_status: true },
        })
      );
      dispatch(
        updateKycDetailRequest({
          trading: { terms_conditions_status: true },
          time_tracker: {
            time_tracking: {
              [tradingFormType]: [
                {
                  page: pageNumber,
                  time: timeVal,
                },
              ],
            },
          },
        })
      );
    } else {
      message.error("Please agree to the terms and conditions first.");
    }
  };
  const getSignature = async () => {
    let imageKey = "";
    if (mainSignature !== "") imageKey = mainSignature;
    else {
      if (kycFormValues?.minor) {
        imageKey =
          kycFormValues?.guardian_details?.updated_signature ??
          kycFormValues?.guardian_details?.signature ??
          "";
      }
      if (!kycFormValues?.minor)
        imageKey =
          kycFormValues?.updated_signature ?? kycFormValues.signature ?? "";
    }
    setGetImageLoading(true);

    StorageGetImage({
      imageKey,
      successCB: (base64data) => {
        setSignatureImage(base64data);
      },
      finalCB: () => {
        setGetImageLoading(false);
      },
    });
  };
  useEffect(() => {
    if (
      kycFormValues?.signature ||
      mainSignature !== "" ||
      kycFormValues?.guardian_details?.signature
    ) {
      getSignature();
    }
  }, [kycFormValues]);

  useEffect(() => {
    if (success) {
      if(kycFormValues?.form.demat && kycFormValues?.form?.trading) {
        history.push(`/app/${oid}/trading/complete`);
      } else {

        history.push(`/app/${oid}/trading/kyc`);
      }
    }
    return () => {
      dispatch(clearSuccess());
    };
  }, [success]);

  const formattedTime: any =
    ("0" + Math.floor((globalTimer / 60) % 60)).slice(-2) +
    ":" +
    ("0" + Math.floor(globalTimer % 60)).slice(-2) +
    " min";

  return (
    <>
      <Progressbar percent={100} leftText={"100%"} rightText={formattedTime} />

      <Row className="termsLayout">
        <Col span="24" className="termsTopContainer">
          <div className="text-green sb-text fw-500">Terms and Condition</div>
        </Col>
        <div
        >
          <Col span="24" className="termsMiddleContainer">
            <BlackText
              data={`धितोपत्र कारोबारको सम्बन्धमा तपसिल बमोजिमको स्वघोषणा गर्दछु`}
            />

            <GreyText
              data={`१. म/हामीले धितोपत्र खरिदका लागि प्रयोग गर्ने रकम सम्पत्ती शुद्धिकरण सम्बन्धी प्रचलित कानुन विपरित आर्जन गरेको हुने छैन ।`}
            />
            <GreyText
              data={`२. धितोपत्रमा गरिएको लगानीमा निहित जोखिमको सम्बन्धमा जानकार छु ।`}
            />
            <GreyText
              data={`३. म/हामीले खरिद गरेका धितोपत्रहरु वापतको भुक्तानी लिने दिने कार्य तोकएको समय भित्र गर्ने छु ।`}
            />
            <GreyText
              data={`४. म/हामीले धितोपत्र सम्बन्धी तथा अन्य प्रचलित नियम कानूनहरुको पालना गर्ने छु।`}
            />
            <GreyText
              data={`५. म/हामी कर्जा सूचना केन्द्रको कालो सुचीमा रहेको छु/छैन ।`}
            />
            <GreyText
              data={`६. सम्पति शुद्धिकरण (मनी लाउण्डरिङ्ग) निवारण ऐन २0६४ र यस अन्तर्गत जारी भएका नियमावली तथा निर्देशन आदीबाट माग भए अनुसारका सुचना, विवरण तथा कागजातहरु कम्पनीलाई उपलब्ध गराउने छु/छै ।`}
            />
            <GreyText
              data={`७. कम्पनीलाई गर्नु पर्ने भुक्तानी नगरि बांकी राखेकौ कारणबाट मेरो/हाम्रो कारोबार अन्य धितोपत्र दलाल कम्पनीहरुमा समेत निलम्बन गर्न पत्राचार वा परिपत्र गर्न मेरो/हाम्रो मन्जुरी छ ।`}
            />

            <BlackText data={`संलग्न गर्नु पर्ने कागजात`} />

            <GreyText
              data={`१. नेपाली नगरिकहरुको हकमा नागरिकताका प्रमाणपत्रको प्रतिलिपी ।`}
            />
            <GreyText
              data={`२. अन्य देशको नागरिकको हकमा पासपोर्टको प्रतिलिपी ।`}
            />
            <GreyText
              data={`३. नाबालकको हकमा संरक्षक तथा नाबालक दुवैक्रो फोटो ।`}
            />
            <GreyText data={`४. कानूनी संरक्षक भए सो सम्बन्धी कागजात ।`} />
            <GreyText
              data={`५. आमा वा बाबु संरक्षक भएमा छोरा वा छोरीको जन्मदर्ता प्रमण पत्रको प्रतिलिपी ।`}
            />
            <GreyText
              data={`६. निवेदकको हस्ताक्षर तथा औठा छापमा संरक्षकको हस्ताक्षर तथा औंठा छाप ।`}
            />
            <GreyText
              data={`७. कुनै संस्थाको कर्मचारी रहेको हकमा कर्मचारी परिचयपत्रको प्रतिलिपी ।`}
            />

            <BlackText data={`उद्घोषण:`} />
            <div className="d-flex mt-1 mb-2 ">
              <Checkbox
                onChange={handleCheck}
                checked={agreeVal}
                className="br-8"
              />
              <div
                className="ml-1 cursor-hover text-light-grey text-justify"
                style={{ lineHeight: "22px" }}
                onClick={handleCheck}
              >
                माथि उल्लेखित विवरण सत्य तथ्य रहेको र सो विवरणमा कुनै फरक परे
                कानून बमोजिम सहुँला, बुझाउँला ।
                <div className="mt-1">
                  I/We hereby acknowledge that the above disclosed details are
                  true. I further hereby consent to bear any legal actions in
                  case any false disclosure of information related to me/us.
                </div>
              </div>
            </div>

            <div className="mb-2 ml-2">
              <Spin spinning={getImageLoading}>
                {signatureImage ? (
                  <img className="signature-terms" src={signatureImage} />
                ) : null}
              </Spin>
              <MediumGreyText data={`....................................`} />
              <BlackText data={`ग्राहकको  हस्ताछ्यर :`} />
            </div>
            <div className="mb-2 ml-2">
              <BlackText data={`औंठ छाप / Thumbprint :`} />
              <ThumbprintBox />
            </div>
          </Col>
        </div>
        <Col span="24" className="termsBottomContainer">
          <RenderFooter
            backHandler={backHandler}
            loading={actionLoading}
            submitHandler={submitHandler}
          />
        </Col>
      </Row>
    </>
  );
};

export default TradingTermsNepaliNew;
