import { ReactNode } from "react";
import styled, { keyframes } from "styled-components";
import { Carousel } from "antd";

type MarqueeProps = {
  itemCount: number;
  children: ReactNode;
};

const marqueeAnimation = keyframes`
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
`;

const StyledContent = styled.div`
  white-space: nowrap;
  animation: ${marqueeAnimation} 5s linear infinite;

  background: green;
  padding: 5px;

  display: flex;
  width: 500px;
`;

const StyledCarousel = styled(Carousel)``;

const MarqueeContainer = ({ itemCount, children }: MarqueeProps) => {
  return (
    <div style={{ overflow: "hidden", width: "100%" }}>
      <Carousel
        autoplay
        infinite={itemCount > 6}
        dots={false}
        autoplaySpeed={0}
        slidesToShow={6}
        speed={1000}
        slidesToScroll={0.5}
        cssEase="linear"
        pauseOnHover
        pauseOnFocus
        responsive={[
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
            },
          },
        ]}
      >
        {children}
      </Carousel>
    </div>
  );
};

export default MarqueeContainer;
